import React, { useState, Fragment } from "react";
import axios from "axios";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";

const Hostevent = () => {
  const [formData, setFormData] = useState({
    email: '',
    contactNumber: '',
    organization: '',
    eventType: '',
    eventDetails: ''
  });
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');

    try {
      const response = await axios.post('https://api.tapcoe.com/api/v1/hostevent', formData);
      setMessage(response.data.message || 'Event hosting request submitted successfully!');
      setMessageType('success');
      setFormData({
        email: '',
        contactNumber: '',
        organization: '',
        eventType: '',
        eventDetails: ''
      });
    } catch (error) {
      console.error('Error hosting event:', error);
      setMessage(error.response?.data?.message || 'Failed to host the event. Please try again.');
      setMessageType('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const eventTypes = [
    { id: 'photo-contest', name: 'Photo Contest' },
    { id: 'talent-show', name: 'Talent Show' },
    { id: 'brand-promotion', name: 'Brand Promotion' },
    { id: 'college-event', name: 'College Event' },
    { id: 'corporate-event', name: 'Corporate Event' },
    { id: 'other', name: 'Other' }
  ];

  return (
    <Fragment>
      <SEO
        title="Host an Event - Create Photo Contests & More | tapcoe"
        description="Host your own photo contest or event on tapcoe. Engage with your audience, promote your brand, and discover talent with our user-friendly event hosting platform."
        keywords="host photo contest, create event online, tapcoe events, brand promotion, talent discovery, online contest hosting"
        url="https://tapcoe.com/host-event"
        image="https://tapcoe.com/assets/host-event-og.webp"
      />
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            {/* Hero Section */}
            <div className="host-event-hero position-relative overflow-hidden rounded-xxl mb-4"
              style={{
                background: "linear-gradient(135deg, rgba(66, 133, 244, 0.95), rgba(120, 85, 245, 0.95))",
                padding: "60px 0"
              }}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 text-lg-start text-center">
                    <div className="hero-content p-4">
                      <h1 className="fw-bold text-white mb-3" style={{ fontSize: "42px" }}>Host Your Event</h1>
                      <p className="text-white opacity-90 mb-4" style={{ fontSize: "18px", lineHeight: "1.6" }}>
                        Boost engagement, promote your brand, and discover talent through exciting photo contests and events
                      </p>
                      <a href="#host-form" className="btn border-0 bg-white px-4 py-3 text-primary fw-600 rounded-lg d-inline-block">
                        Get Started Now
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 d-none d-lg-block text-center">
                    <img 
                      src="/assets/images/host-event.png" 
                      alt="Host Event" 
                      className="img-fluid" 
                      style={{
                        maxHeight: "320px",
                        filter: "drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15))"
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Decorative elements */}
              <div className="position-absolute" style={{ top: "15%", left: "10%", width: "150px", height: "150px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
              <div className="position-absolute" style={{ bottom: "10%", right: "15%", width: "120px", height: "120px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
              <div className="position-absolute" style={{ top: "30%", right: "20%", width: "30px", height: "30px", background: "rgba(255,255,255,0.2)", borderRadius: "50%" }}></div>
            </div>

            {/* Benefits Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <div className="section-heading text-center">
                      <div className="decoration-line mx-auto mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Why Host with tapcoe?</h2>
                      <p className="font-xsss fw-500 text-grey-500 mx-auto" style={{ maxWidth: "700px" }}>
                        Our platform offers everything you need to create and manage successful events
                      </p>
                    </div>
                  </div>

                  {[
                    {
                      title: "Expand Your Reach",
                      description: "Connect with a wider audience and increase your brand visibility through engaging contests.",
                      icon: "users"
                    },
                    {
                      title: "Easy Management",
                      description: "Our intuitive platform makes it simple to set up, track, and manage your events.",
                      icon: "settings"
                    },
                    {
                      title: "Real-time Analytics",
                      description: "Monitor participation, engagement, and voting in real-time with detailed analytics.",
                      icon: "bar-chart"
                    },
                    {
                      title: "Community Engagement",
                      description: "Build stronger relationships with your audience through interactive events and contests.",
                      icon: "heart"
                    }
                  ].map((benefit, index) => (
                    <div key={index} className="col-md-6 col-lg-3 mb-4">
                      <div className="benefit-card p-4 h-100 text-center rounded-lg" 
                        style={{ 
                          background: 'linear-gradient(135deg, rgba(120, 85, 245, 0.03) 0%, rgba(66, 133, 244, 0.06) 100%)',
                          border: '1px solid rgba(120, 85, 245, 0.1)',
                          transition: 'all 0.3s ease'
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = 'translateY(-5px)';
                          e.currentTarget.style.boxShadow = '0 10px 30px rgba(0, 0, 0, 0.08)';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = 'translateY(0)';
                          e.currentTarget.style.boxShadow = 'none';
                        }}
                      >
                        <div className="icon-wrapper mb-3 d-flex align-items-center justify-content-center rounded-circle mx-auto" 
                          style={{
                            width: "70px",
                            height: "70px",
                            background: "linear-gradient(135deg, rgba(120, 85, 245, 0.1) 0%, rgba(66, 133, 244, 0.2) 100%)"
                          }}
                        >
                          <i className={`feather-${benefit.icon}`} style={{ fontSize: "28px", color: "#4285f4" }}></i>
                        </div>
                        <h4 className="fw-700 text-grey-900 font-xs mb-2">{benefit.title}</h4>
                        <p className="font-xsss fw-500 text-grey-500 mb-0 lh-24">
                          {benefit.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* How It Works Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4">
              <div className="card-body">
                <div className="section-heading text-center mb-4">
                  <div className="decoration-line mx-auto mb-3" style={{
                    width: '60px',
                    height: '4px',
                    background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                    borderRadius: '4px'
                  }}></div>
                  <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>How It Works</h2>
                  <p className="font-xsss fw-500 text-grey-500 mx-auto" style={{ maxWidth: "700px" }}>
                    Simple process to start hosting your events on tapcoe
                  </p>
                </div>

                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <div className="process-wrapper position-relative mb-4">
                      {/* Process connector line */}
                      <div className="process-line position-absolute" style={{
                        height: "2px",
                        background: "linear-gradient(90deg, #4285f4, #7855f5)",
                        width: "100%",
                        top: "40px",
                        left: "0",
                        zIndex: "0"
                      }}></div>

                      <div className="row">
                        {[
                          {
                            step: 1,
                            title: "Submit Details",
                            description: "Fill out the form with your contact information and event requirements."
                          },
                          {
                            step: 2,
                            title: "Connect with Team",
                            description: "Our team will contact you to discuss your event specifics and requirements."
                          },
                          {
                            step: 3,
                            title: "Event Setup",
                            description: "We'll set up your event with all the features you need for success."
                          },
                          {
                            step: 4,
                            title: "Launch & Promote",
                            description: "Your event goes live and we help you promote it to the right audience."
                          }
                        ].map((step, index) => (
                          <div key={index} className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="text-center position-relative">
                              <div className="step-circle d-flex align-items-center justify-content-center rounded-circle mx-auto position-relative bg-white" style={{
                                width: "80px",
                                height: "80px",
                                border: "2px solid #4285f4",
                                zIndex: "1",
                                background: "white"
                              }}>
                                <span className="fw-bold" style={{ fontSize: "24px", color: "#4285f4" }}>{step.step}</span>
                              </div>
                              <h4 className="fw-700 text-grey-900 font-xs mt-3 mb-2">{step.title}</h4>
                              <p className="font-xsss fw-500 text-grey-500 mb-0 lh-24" style={{ maxWidth: "200px", margin: "0 auto" }}>
                                {step.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Form Section */}
            <div id="host-form" className="card border-0 rounded-xxl shadow-xss mb-4">
              <div className="card-body p-0 overflow-hidden">
                <div className="row g-0">
                  <div className="col-lg-6 p-5">
                    <div className="form-section-heading mb-4">
                      <div className="decoration-line mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Connect With Us</h2>
                      <p className="font-xsss fw-500 text-grey-500">
                        Fill out this form and our team will get back to you shortly to help set up your event
                      </p>
                    </div>

                    {message && (
                      <div className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'} mb-4`}>
                        {message}
                      </div>
                    )}

                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="email" className="form-label fw-500 text-grey-900">
                            Email <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                              <i className="feather-mail" style={{ color: "#4285f4" }}></i>
                            </span>
                            <input
                              type="email"
                              className="form-control border-start-0 ps-0"
                              id="email"
                              placeholder="Enter your email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                              style={{ borderColor: "#e5e5e5" }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="contactNumber" className="form-label fw-500 text-grey-900">
                            Contact Number <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                              <i className="feather-phone" style={{ color: "#4285f4" }}></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0 ps-0"
                              id="contactNumber"
                              placeholder="Enter your contact number"
                              value={formData.contactNumber}
                              onChange={handleChange}
                              required
                              style={{ borderColor: "#e5e5e5" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="organization" className="form-label fw-500 text-grey-900">
                          Organization <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-briefcase" style={{ color: "#4285f4" }}></i>
                          </span>
                          <input
                            type="text"
                            className="form-control border-start-0 ps-0"
                            id="organization"
                            placeholder="Enter your organization"
                            value={formData.organization}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="eventType" className="form-label fw-500 text-grey-900">
                          Type of Event <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-calendar" style={{ color: "#4285f4" }}></i>
                          </span>
                          <select
                            className="form-select border-start-0 ps-0"
                            id="eventType"
                            value={formData.eventType}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          >
                            <option value="" disabled>Select event type</option>
                            {eventTypes.map(type => (
                              <option key={type.id} value={type.name}>{type.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="mb-4">
                        <label htmlFor="eventDetails" className="form-label fw-500 text-grey-900">
                          Event Details <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0 align-items-start pt-2" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-file-text" style={{ color: "#4285f4" }}></i>
                          </span>
                          <textarea
                            className="form-control border-start-0 ps-0"
                            id="eventDetails"
                            rows="4"
                            placeholder="Describe your event requirements, goals, and any specific features you need"
                            value={formData.eventDetails}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          ></textarea>
                        </div>
                        <small className="text-muted">Please provide details about your target audience, expected participants, and event duration.</small>
                      </div>

                      <button 
                        type="submit" 
                        className="btn border-0 w-100 p-3 rounded-lg d-inline-block"
                        disabled={isSubmitting}
                        style={{
                          background: "linear-gradient(90deg, #4285f4, #7855f5)",
                          color: "white",
                          fontWeight: "600",
                          fontSize: "16px"
                        }}
                      >
                        {isSubmitting ? (
                          <span>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Submitting...
                          </span>
                        ) : 'Submit Request'}
                      </button>
                    </form>
                  </div>

                  <div className="col-lg-6 d-none d-lg-block p-0">
                    <div className="feature-image h-100" style={{ 
                      background: "linear-gradient(135deg, rgba(66, 133, 244, 0.9), rgba(120, 85, 245, 0.9))", 
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      padding: "40px"
                    }}>
                      <img 
                        src="/assets/images/host-event-side.png" 
                        alt="Host Event Features" 
                        className="img-fluid mb-4" 
                        style={{ 
                          maxHeight: "280px",
                          filter: "drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15))"
                        }}
                      />
                      <h3 className="fw-700 text-white mb-3" style={{ fontSize: "22px" }}>Ready to Host Your Event?</h3>
                      <p className="text-white opacity-90 text-center mb-4" style={{ maxWidth: "400px", fontSize: "15px" }}>
                        Join hundreds of organizations who have successfully hosted events on tapcoe to engage their audience and promote their brand.
                      </p>
                      <div className="features-list">
                        {[
                          "Customized Event Pages",
                          "Secure Voting System",
                          "Real-time Analytics",
                          "Social Media Integration",
                          "Dedicated Support Team"
                        ].map((feature, index) => (
                          <div key={index} className="d-flex align-items-center mb-2">
                            <div className="icon-check d-flex align-items-center justify-content-center rounded-circle me-2"
                              style={{
                                width: "22px",
                                height: "22px",
                                backgroundColor: "white"
                              }}
                            >
                              <i className="feather-check" style={{ fontSize: "14px", color: "#4285f4" }}></i>
                            </div>
                            <p className="text-white mb-0">{feature}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* FAQ Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4">
              <div className="card-body">
                <div className="section-heading text-center mb-4">
                  <div className="decoration-line mx-auto mb-3" style={{
                    width: '60px',
                    height: '4px',
                    background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                    borderRadius: '4px'
                  }}></div>
                  <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Frequently Asked Questions</h2>
                </div>

                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <div className="accordion" id="hostEventFaq">
                      {[
                        {
                          question: "What types of events can I host on tapcoe?",
                          answer: "You can host various types of events including photo contests, talent shows, corporate events, college fests, brand promotions, and more. Our platform is flexible and can be customized to suit your specific requirements."
                        },
                        {
                          question: "How long does it take to set up an event?",
                          answer: "Once you submit your details, our team will contact you within 24-48 hours. The setup time depends on your specific requirements, but typically we can have your event ready to launch within 3-5 business days."
                        },
                        {
                          question: "Can I customize the look and feel of my event page?",
                          answer: "Absolutely! We offer extensive customization options including branded headers, custom color schemes, and personalized messaging to match your brand identity and event theme."
                        },
                        {
                          question: "How are votes verified for fairness?",
                          answer: "Our platform uses advanced verification techniques to ensure vote integrity, including IP tracking, device fingerprinting, and optional SMS verification to prevent duplicate voting and maintain fairness."
                        },
                        {
                          question: "What support will I receive during my event?",
                          answer: "You'll be assigned a dedicated event manager who will assist you throughout the process, from setup to conclusion. We also provide technical support, analytics reports, and promotional guidance to ensure your event's success."
                        }
                      ].map((faq, index) => (
                        <div key={index} className="accordion-item border-0 mb-3 rounded-lg overflow-hidden" style={{ background: 'linear-gradient(135deg, rgba(120, 85, 245, 0.02) 0%, rgba(66, 133, 244, 0.04) 100%)' }}>
                          <h2 className="accordion-header" id={`heading${index}`}>
                            <button 
                              className="accordion-button collapsed fw-600" 
                              type="button" 
                              data-bs-toggle="collapse" 
                              data-bs-target={`#collapse${index}`} 
                              aria-expanded="false" 
                              aria-controls={`collapse${index}`}
                              style={{ background: 'transparent', fontSize: '16px' }}
                            >
                              {faq.question}
                            </button>
                          </h2>
                          <div 
                            id={`collapse${index}`} 
                            className="accordion-collapse collapse" 
                            aria-labelledby={`heading${index}`} 
                            data-bs-parent="#hostEventFaq"
                          >
                            <div className="accordion-body font-xsss fw-500 text-grey-500 lh-26">
                              {faq.answer}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <Copyright />
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default Hostevent;
