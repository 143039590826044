import React, { useState, useEffect } from 'react';

const Countdown = ({ eventName, expiryDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(expiryDate));
  
  // Calculate time left based on the given target date
  function calculateTimeLeft(targetDate) {
    const now = new Date().getTime();
    const distance = new Date(targetDate).getTime() - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds, distance };
  }

  useEffect(() => {
    // Update the time left every second
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(expiryDate));
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [expiryDate]); // Dependency array includes expiryDate

  const isExpired = timeLeft.distance <= 0; // Check if the event has expired

  return (
    <div className="card-body p-0 position-relative">
      {eventName && (
        <div className="text-center">
          <h6 className="fw-00 font-sm mt-2 mb-4">{eventName}</h6>
        </div>
      )}

      {/* Countdown Timer */}
      {isExpired ? (
        <div className="font-sm text-center text-danger">
          <h5>This event has expired.</h5>
        </div>
      ) : (
        <div className="font-sm text-center text-white bg-dark p-2 rounded">
          <div className="font-xss fw-600 d-inline-block p-1">{timeLeft.days}d</div>
          <div className="font-xss fw-600 d-inline-block p-1">:</div>
          <div className="font-xss fw-600 d-inline-block p-1">{timeLeft.hours}h</div>
          <div className="font-xss fw-600 d-inline-block p-1">:</div>
          <div className="font-xss fw-600 d-inline-block p-1">{timeLeft.minutes}m</div>
          <div className="font-xss fw-600 d-inline-block p-1">:</div>
          <div className="font-xss fw-600 d-inline-block p-1">{timeLeft.seconds}s</div>
        </div>
      )}
    </div>
  );
};

export default Countdown;
