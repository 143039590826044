import axios from 'axios';

const API_URL =  "https://api.picvot.com/api/v1";// Replace with your actual backend API URL

export const login = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/customers/loginCustomer`, credentials);
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};
// Registration API
export const register = async (data) => {
  const response = await axios.post(`${API_URL}/customers/registerCustomer`, data);
  return response.data;
};

//ReSend OTP API
export const ReSendOTP = async (data) => {
 // const { mobileNumber } = data;
  const response = await axios.post(`${API_URL}/customers/resendOTP`,   data );
  return response.data;
};

// Verify OTP API
export const verifyOTP = async (data) => {
  const response = await axios.post(`${API_URL}/customers/verifyOTP`, data);
  return response.data;
};

// Forgot Password API
export const forgotPassword = async (data) => {
  const response = await axios.post(`${API_URL}/customers/forget-password`, data);
  return response.data;
};

export const verifyForget = async (data) => {
  const response = await axios.post(`${API_URL}/customers/verifyForget`, data);
  return response.data;
};