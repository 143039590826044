import React, { Fragment, useEffect, useState } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Countdown from "../components/CountDown";
import VotingDashboard from "../components/VotingDashboard";
import { getPostDetails } from '../api/getPostDetails';
import CommentSection from "../components/commentSection";  // Assuming your API call is in this file
import LiveDiscussion from "../components/LiveDiscussion"; // Import LiveDiscussion component
import { useParams } from 'react-router-dom';
import { castVote, happyVote } from '../api/castVoteApi';
import Loader from "../components/Loader";
import { useAuth } from "../context/AuthContext";
import Copyright from "../components/Copyright";
import GoogleAd from "../components/GoogleAd";
import GenerateQR from "../components/Qr";
const Post = () => {
    const { isLoggedIn } = useAuth();
    const { eventId, postId } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isVoting, setIsVoting] = useState(false); // State to handle loading
    const [currentVote, setCurrentVote] = useState(0); // Start with 0
    const [activeTab, setActiveTab] = useState('comments'); // For tabbed interface

    useEffect(() => {
        // Check if post.totalVotes is available before setting it
        if (post?.totalVotes !== undefined) {
            setCurrentVote(post?.totalVotes); // Set to actual votes when loaded
        }
    }, [post]);
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        if (post?.hasVoted && typeof post.hasVoted === 'string') {
            const lastVoteTime = new Date(post.hasVoted); // Convert UTC string to Date
            const currentTime = new Date();
            const diff = 1 * 60 * 1000 - (currentTime - lastVoteTime); // 5 minutes in milliseconds
            setRemainingTime(diff > 0 ? diff : 0);

            const timer = setInterval(() => {
                const now = new Date();
                const newDiff = 1 * 60 * 1000 - (now - lastVoteTime);
                setRemainingTime(newDiff > 0 ? newDiff : 0);
                if (newDiff <= 0) clearInterval(timer);
            }, 1000);

            return () => clearInterval(timer); // Cleanup interval on unmount
        }
    }, [post?.hasVoted]);

    const formatTime = (ms) => {
        const seconds = Math.floor((ms / 1000) % 60);
        const minutes = Math.floor((ms / 1000 / 60) % 60);
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };


    const animateVoteIncrease = () => {
        let startVote = currentVote; // Store the initial vote
        const targetVote = startVote + 1; // Increment by 1

        const increment = () => {
            if (startVote < targetVote) {
                setCurrentVote((prev) => prev + 1); // Increment vote count in UI
                startVote++; // Increment the startVote
                setTimeout(increment, 50); // Recursively call for smooth animation
            }
        };
        increment();
    };
    const getTimeAgo = (postCreatedAt) => {
        const now = new Date();
        const postTime = new Date(postCreatedAt);
        const timeDifference = now - postTime;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
        if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
        if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        return `just now`;
    };
    const obfuscateId = (id) => {
        const salt = 53; // Static or dynamic salt value
        const numericId = parseInt(id, 36); // Convert the id to base 36 for alphanumeric support
        const transformedId = (numericId + salt) % 10000; // Add salt and take mod to reduce size
        return transformedId.toString().slice(0, 2); // Take the first 2 digits
    };
    const handleVoteClick = async () => {
        try {
            setIsVoting(true); // Start loading
            const response = await castVote(eventId, postId);
            alert('Vote cast successfully!');
            // console.log('Vote Response:', response);

            // Call animation function after successfully casting vote
            animateVoteIncrease();
            window.location.reload();
        } catch (error) {
            alert(error.response.data.message);
            console.error('Error during vote:', error.response.data.message);
        } finally {
            setIsVoting(false); // Stop loading
        }
    };
    const handleHappyHourVoteClick = async () => {
        try {
            setIsVoting(true); // Start loading
            const response = await happyVote(eventId, postId);
            alert('Vote cast successfully!');
            // console.log('Vote Response:', response);

            // Call animation function after successfully casting vote
            window.location.href = "https://tapcoe.com";

        } catch (error) {
            alert(error.response.data.message);
            console.error('Error during vote:', error.response.data.message);
        } finally {
            setIsVoting(false); // Stop loading
        }
    };
    // Fetch post details when the component mounts
    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const data = await getPostDetails(eventId, postId);
                // console.log(data);
                setPost(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchPostData();
    }, [eventId, postId]);

    // Sample data for votes


    const shareUrls = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
        instagram: `https://www.instagram.com/?url=${window.location.href}`,
        whatsapp: `https://api.whatsapp.com/send?text=${window.location.href}`,


        whatsapp1: `${window.location.href}`,
    };

    //if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Fragment>
            {/* Header and Navigation */}
            <Header />
            <Leftnav />
            {/* <Rightchat /> */}

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="row">
                                <div className="card w-100 p-2 shadow-xss rounded-xxl  m-1" >
                                    <div style={{ paddingTop: '20.83%', position: 'relative' }}> {/* 250/1200 = 0.2083 or 20.83% */}
                                        <img
                                            src={`${post.eventDetails.coverPic}`}
                                            alt="event cover"
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover'  // Ensures the image fills the container while maintaining aspect ratio
                                            }}
                                        />
                                    </div>
                                    <Countdown eventName={post.eventDetails.name} expiryDate={post.eventDetails.endDate} />
                                </div>


                   

                                {/* Left Side - Post Details */}
                                <div className="col-lg-8">
                                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-2 mb-3" style={{ marginTop: '20px', borderRadius: '16px', overflow: 'hidden' }}>
                                        <div className="card-body p-0 d-flex align-items-center p-3 border-bottom">
                                            <figure className="avatar me-3 m-0">
                                                <img
                                                    src={`${post.createdBy.profilePic}`}
                                                    alt="avatar"
                                                    className="shadow-sm rounded-circle"
                                                    style={{ width: '50px', height: '50px', objectFit: 'cover', border: '2px solid #f0f2f5' }}
                                                />
                                            </figure>

                                            <div className="flex-grow-1">
                                                <h4 className="fw-700 text-grey-900 font-xssss mt-1 mb-0">
                                                    {post.createdBy.name}
                                                </h4>
                                                <span className="d-flex align-items-center font-xssss fw-500 text-grey-500">
                                                    <i className="feather-clock me-1" style={{ fontSize: '11px' }}></i>
                                                    {getTimeAgo(post.createdAt)}
                                                </span>
                                            </div>
                                            
                                            <div className="dropdown">
                                                <button 
                                                    className="btn btn-link text-grey-600 p-0 bg-transparent border-0"
                                                    data-bs-toggle="dropdown"
                                                >
                                                    <i className="feather-more-horizontal"></i>
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end">
                                                    <li><a className="dropdown-item" href="#"><i className="feather-share-2 me-2"></i>Share</a></li>
                                                    <li><a className="dropdown-item" href="#"><i className="feather-flag me-2"></i>Report</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* Post Image */}
                                        {post.postImageUrl && (
                                            <div className="card-body d-block p-0">
                                                <div className="row ps-2 pe-2">
                                                    <div className="col-sm-12 p-1 position-relative">
                                                        {/* Image Wrapper with relative positioning */}
                                                        <div className="position-relative">
                                                            {/* Main Post Image */}
                                                            <img
                                                                src={`${post.postImageUrl}`}
                                                                className="rounded-3 w-100"
                                                                alt="post"
                                                            />

                                                            {/* Frame Overlay - Ensuring it's on top of the image */}
                                                            {post.eventDetails.IsFrameAllow && post.eventDetails.frameUrl && (
                                                                <img
                                                                    src={post.eventDetails.frameUrl}
                                                                    alt="Frame"
                                                                    className="position-absolute top-0 start-0 w-100 h-100"
                                                                    style={{
                                                                        pointerEvents: 'none', // Prevents interaction with the frame
                                                                        zIndex: 2, // Ensures it appears above the image
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}


                                        {/* Post Details - ID, Vote, Rank */}
                                        <div className="card-body pt-3 pb-0 px-3">
                                            <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mb-3">
                                                <button className="stat-button id-button">
                                                    <i className="feather-hash me-1"></i>
                                                    ID: {obfuscateId(post._id)}
                                                </button>

                                                <button className="stat-button vote-button">
                                                    <i className="feather-heart me-1"></i>
                                                    Vote: {post.totalVotes}
                                                </button>

                                                <button className="stat-button rank-button">
                                                    <i className="feather-chevrons-up me-1"></i>
                                                    Rank: {post.rank}
                                                </button>
                                            </div>
                                        
                                        {isLoggedIn ? (
                                            post?.status === 'rejected' ? (
                                                <div className="alert-message alert-danger">
                                                    <i className="feather-x-circle me-2"></i>
                                                    Your post was rejected. Reason: {post?.rejectReason}
                                                </div>
                                            ) : remainingTime > 0 ? (
                                                <div className="alert-message alert-info">
                                                    <i className="feather-clock me-2"></i>
                                                    <div>
                                                    {post?.happyHourVote ? (
                                                        <span>Happy Hour starts in <strong>{formatTime(remainingTime)}</strong>!</span>
                                                    ) : (
                                                        <span>Please wait <strong>{formatTime(remainingTime)}</strong> to vote again.</span>
                                                    )}
                                                    </div>
                                                </div>
                                            ) : post?.happyHourVote ? (
                                                <button
                                                    className="action-button happy-hour-action"
                                                    onClick={handleHappyHourVoteClick}
                                                    disabled={isVoting}
                                                >
                                                    {isVoting ? (
                                                        <><i className="feather-loader rotating me-2"></i> Processing...</>
                                                    ) : (
                                                        <><i className="feather-zap me-2"></i> Happy Hour Vote!</>
                                                    )}
                                                </button>
                                            ) : post?.hasVoted ? (  // Check if the user has already voted
                                                <div className="alert-message alert-success">
                                                    <i className="feather-check-circle me-2"></i>
                                                    <div>You have already voted.</div>
                                                </div>
                                            ) : (
                                                <button
                                                    className="action-button vote-action"
                                                    onClick={handleVoteClick}
                                                    disabled={isVoting}
                                                >
                                                    {isVoting ? (
                                                        <><i className="feather-loader rotating me-2"></i> Processing...</>
                                                    ) : (
                                                        <><i className="feather-thumbs-up me-2"></i> Vote For This Entry</>
                                                    )}
                                                </button>
                                            )
                                        ) : (
                                            <div className="alert-message alert-warning">
                                                <i className="feather-alert-circle me-2"></i>
                                                <span>Please </span>
                                                <button 
                                                    className="login-button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        localStorage.setItem("redirectAfterLogin", window.location.href);
                                                        window.location.href = "/register";
                                                    }}
                                                >
                                                    Login
                                                </button>
                                                <span> to vote for this entry</span>
                                            </div>
                                        )}
                                        </div>

                                        {/* Share Buttons */}
                                        <div className="card-body border-top pt-3">
                                            <div className="d-flex align-items-center mb-2">
                                                <i className="feather-share-2 me-2 text-grey-500"></i>
                                                <h6 className="mb-0 text-grey-600 font-xssss text-uppercase fw-600">Share This Post</h6>
                                            </div>
                                            <div className="d-flex flex-wrap gap-2">
                                                {/* Share on Facebook */}
                                                <a
                                                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrls.whatsapp1)}&quote=${encodeURIComponent(`Hi, I have participated in the online virtual face of the event *${post.eventDetails.name}*. To help me win the title, please register on Tapcoe and vote for me`)}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="share-btn facebook-btn"
                                                >
                                                    <i className="feather-facebook"></i>
                                                </a>

                                                {/* Share on Instagram */}
                                                <a
                                                    href={shareUrls.instagram}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="share-btn instagram-btn"
                                                >
                                                    <i className="feather-instagram"></i>
                                                </a>

                                                {/* Share on WhatsApp */}
                                                <a
                                                    href={`https://wa.me/?text=${encodeURIComponent(`Hi, I have participated in the online virtual face of the event *${post.eventDetails.name}*. To help me win the title, please register on Tapcoe and vote for me! ${shareUrls.whatsapp1}`)}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="share-btn whatsapp-btn"
                                                >
                                                    <i className="feather-message-circle"></i>
                                                </a>
                                                
                                                {/* Copy Link */}
                                                <button
                                                    className="share-btn copy-btn"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(shareUrls.whatsapp1);
                                                        alert('Link copied to clipboard!');
                                                    }}
                                                >
                                                    <i className="feather-copy"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Tabbed Discussion & Comments Section */}
                                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                                        <div className="card-body d-flex align-items-center p-0">
                                            <h4 
                                                className={`fw-700 font-xssss text-grey-700 ps-3 pe-3 mb-0 ${activeTab === 'comments' ? 'text-current' : ''}`}
                                                style={{ 
                                                    cursor: 'pointer', 
                                                    padding: '15px 0',
                                                    flex: 1,
                                                    textAlign: 'center',
                                                    borderBottom: activeTab === 'comments' ? '2px solid #007bff' : 'none'
                                                }}
                                                onClick={() => setActiveTab('comments')}
                                            >
                                                <i className="feather-message-square me-2"></i>
                                                Comments
                                            </h4>
                                            <h4 
                                                className={`fw-700 font-xssss text-grey-700 ps-3 pe-3 mb-0 ${activeTab === 'liveDiscussion' ? 'text-current' : ''}`}
                                                style={{ 
                                                    cursor: 'pointer', 
                                                    padding: '15px 0',
                                                    flex: 1,
                                                    textAlign: 'center',
                                                    borderBottom: activeTab === 'liveDiscussion' ? '2px solid #007bff' : 'none'
                                                }}
                                                onClick={() => setActiveTab('liveDiscussion')}
                                            >
                                                <i className="feather-message-circle me-2"></i>
                                                Live Discussion
                                            </h4>
                                        </div>
                                    </div>
                                    
                                    {/* Comments Tab Content */}
                                    {activeTab === 'comments' && (
                                        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                                            <CommentSection postId={postId} eventId={eventId} />
                                        </div>
                                    )}
                                    
                                    {/* Live Discussion Tab Content */}
                                    {activeTab === 'liveDiscussion' && (
                                        <div className="card w-100 shadow-xss rounded-xxl border-0 p-0 mb-3">
                                            <div className="card-body p-0" style={{ height: '500px' }}>
                                                <LiveDiscussion eventId={eventId} />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Right Side - Chart and other widgets */}
                                <div className="col-lg-4">
                                    <GenerateQR logoUrl={post.postImageUrl} />
                                    <GoogleAd />
                                    <VotingDashboard postId={postId} eventId={eventId} />
                                </div>
                            </div>
                        )}
                        <div className="mt-2">
                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer and Popup */}
            <Popupchat />
            <Appfooter />

            {/* Add this style block at the end of your component, before the closing Fragment tag */}
            <style jsx="true">{`
                .stat-badge {
                    transition: transform 0.2s ease;
                }
                .stat-badge:hover {
                    transform: translateY(-2px);
                }
                .share-btn {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: #f0f2f5;
                    color: #444;
                    text-decoration: none;
                    transition: all 0.2s ease;
                    cursor: pointer;
                    border: none;
                }
                .facebook-btn {
                    background-color: #e6f1ff;
                    color: #1877f2;
                }
                .facebook-btn:hover {
                    background-color: #1877f2;
                    color: white;
                }
                .instagram-btn {
                    background-color: #ffe6f5;
                    color: #e1306c;
                }
                .instagram-btn:hover {
                    background-color: #e1306c;
                    color: white;
                }
                .whatsapp-btn {
                    background-color: #e6ffe6;
                    color: #25d366;
                }
                .whatsapp-btn:hover {
                    background-color: #25d366;
                    color: white;
                }
                .copy-btn {
                    background-color: #f0f2f5;
                    color: #666;
                }
                .copy-btn:hover {
                    background-color: #666;
                    color: white;
                }
            `}</style>
        </Fragment>
    );
};

export default Post;
