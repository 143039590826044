import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { castVote } from '../api/castVoteApi';
import { useAuth } from "../context/AuthContext";
import LocationComponent from './Location';
const Postview = ({ name, postedAt, userId, voteCount, postId, des, userProfilePic, postImageUrl, postvideo, id, rank, hasVoted, status, rejectReason, registerLocation, userName, refreshParent, isFrameAllow, frameUrl }) => {
  const { isLoggedIn, user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [currentVote, setCurrentVote] = useState(voteCount); // State for current vote count
  const [lastShare] = useState('https://socia.be/1rGxjoJKVF0');
  const [showOptions, setShowOptions] = useState(false);
  const history = useHistory();
  const [isVoting, setIsVoting] = useState(false); // State to handle loading
  const [showLocation, setShowLocation] = useState(true);
  const [clickHere, setClickHere] = useState(hasVoted)
  useEffect(() => {
    const interval = setInterval(() => {
      setShowLocation((prev) => !prev);
    }, 2000); // Toggle every 2 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);
  const animateVoteIncrease = () => {
    let startVote = currentVote; // Store the initial vote
    const targetVote = startVote + 1; // Increment by 1

    const increment = () => {
      if (startVote < targetVote) {
        setCurrentVote((prev) => prev + 1); // Increment vote count in UI
        startVote++; // Increment the startVote
        setTimeout(increment, 50); // Recursively call for smooth animation
      }
    };
    increment();
  };
console.log(isFrameAllow,frameUrl)
  const handleVoteClick = async () => {
    try {
      setIsVoting(true); // Start loading
      const response = await castVote(id, postId);

      alert('Vote cast successfully!');
      setClickHere(true)
      // console.log('Vote Response:', response);
      // window.location.reload();
      // Call animation function after successfully casting vote
      animateVoteIncrease();

      // window.location.reload();
    } catch (error) {
      alert(error.response.data.message);
      console.error('Error during vote:', error.response.data.message);
    } finally {
      setIsVoting(false); // Stop loading
    }
  };

  const handleMoreClick = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionClick = (option) => {
    switch (option) {
      case 'post':
        history.push(`/post/${id}/${postId}`); // Redirect to post without reload
        break;
      case 'profile':
        history.push(`/profile/${userName}/`); // Redirect to profile without reload
        break;
      case 'report':
        alert('This Post Is reported, admin will check and update you!');
        break;
      case 'delete':
        alert('Send Your Post Url On contact@tapcoe.com for delete this post!');
        break;
      default:
        break;
    }
    setShowOptions(false); // Hide the dropdown after clicking an option
  };

  const obfuscateId = (id) => {
    const salt = 53; // Static or dynamic salt value
    const numericId = parseInt(id, 36); // Convert the id to base 36 for alphanumeric support
    const transformedId = (numericId + salt) % 10000; // Add salt and take mod to reduce size
    return transformedId.toString().slice(0, 2); // Take the first 2 digits
  };

  const getTimeAgo = (postCreatedAt) => {
    const now = new Date();
    const postTime = new Date(postCreatedAt);
    const timeDifference = now - postTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
    if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
    if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `just now`;
  };

  const displayedId = obfuscateId(postId);

  // Don't render if essential data is missing
  if (!postId || !id) {
    return null;
  }

  return (
    <div className="card w-100 shadow-sm rounded-lg border-0 p-0 mb-4 post-card">
      <div className="card-body p-3 d-flex align-items-center">
        <figure className="avatar me-3 mb-0" style={{ height: '45px', width: '45px' }}>
          <img
            src={`${userProfilePic}`}
            alt="avatar"
            className="shadow-sm rounded-circle"
            style={{ height: '100%', width: '100%', objectFit: 'cover', border: '2px solid #ebedf6' }}
          />
        </figure>

        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          {name}
          {showLocation ? (
            <LocationComponent location={registerLocation} />
          ) : (
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{getTimeAgo(postedAt)}</span>
          )}
        </h4>
        <div className="ms-auto pointer position-relative">
          <i
            className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"
            onClick={handleMoreClick}
            style={{ cursor: 'pointer' }}
          ></i>
          {showOptions && (
            <div
              className="dropdown-menu show position-absolute"
              style={{
                right: 0,
                top: '100%',
                maxWidth: '200px',
                width: '100%',
                padding: '8px',
                backgroundColor: '#fff',
                boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
                borderRadius: '12px',
                zIndex: '999',
                overflow: 'hidden',
              }}
            >
              <ul className="list-group">
                <li
                  className="list-group-item border-0 p-2 d-flex align-items-center"
                  onClick={() => handleOptionClick('post')}
                  style={{ fontSize: '11px', cursor: 'pointer' }}
                >
                  <i className="feather-external-link me-2 text-grey-500"></i>
                  Open Post
                </li>
                <li
                  className="list-group-item border-0 p-2 d-flex align-items-center"
                  onClick={() => handleOptionClick('profile')}
                  style={{ fontSize: '11px', cursor: 'pointer' }}
                >
                  <i className="feather-user me-2 text-grey-500"></i>
                  Open Profile
                </li>
                {isLoggedIn ? (
                  <li
                    className="list-group-item border-0 p-2 d-flex align-items-center text-danger"
                    onClick={() => handleOptionClick('report')}
                    style={{ fontSize: '11px', cursor: 'pointer' }}
                  >
                    <i className="feather-flag me-2"></i>
                    Report
                  </li>
                ) : null}
                {isLoggedIn && user?.id === userId ? (
                  <li
                    className="list-group-item border-0 p-2 d-flex align-items-center text-danger"
                    onClick={() => handleOptionClick("delete")}
                    style={{ fontSize: '11px', cursor: 'pointer' }}
                  >
                    <i className="feather-trash-2 me-2"></i>
                    Delete Request
                  </li>
                ) : null}
              </ul>
            </div>
          )}
        </div>
      </div>
      
      {des && (
        <div className="card-body pt-0 pb-0 px-3">
          <p className="fw-500 text-grey-700 lh-24 font-xsss mb-2">{des}</p>
        </div>
      )}
      
      {postvideo && (
        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden">
          <a href="/defaultvideo" className="video-btn">
            <video autoPlay loop className="float-right w-100">
              <source src={`/assets/images/${postvideo}`} type="video/mp4" />
            </video>
          </a>
        </div>
      )}
      
      {postImageUrl && (
        <div className="card-body d-block p-0">
          <div className="row m-0">
            <div className="col-sm-12 p-0 position-relative">
              <div className="position-relative image-container">
                <a href={`/post/${id}/${postId}`} rel="noopener noreferrer">
                  <img
                    src={postImageUrl}
                    className="rounded-lg w-100"
                    alt="post"
                    style={{
                      width: '100%',
                      height: 'auto',
                      backgroundColor: 'white',
                      display: 'block',
                      margin: 'auto',
                    }}
                  />
                </a>

                {isFrameAllow && frameUrl && (
                  <img
                    src={frameUrl}
                    alt="Frame"
                    className="position-absolute top-0 start-0 w-100 h-100"
                    style={{
                      pointerEvents: 'none',
                      zIndex: 2,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card-body pt-3 pb-0 px-3">
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mb-3">
          <button
            className="stat-button id-button"
          >
            <i className="feather-hash me-1"></i>
            ID: {displayedId}
          </button>

          <button
            className="stat-button vote-button"
          >
            <i className="feather-heart me-1"></i>
            Vote: {currentVote}
          </button>

          {typeof rank !== "undefined" && (
            <button
              className="stat-button rank-button"
            >
              <i className="feather-chevrons-up me-1"></i>
              Rank: {rank}
            </button>
          )}
        </div>

        {isLoggedIn ? (
          status === 'rejected' ? (
            <div className="alert-message alert-danger">
              <i className="feather-x-circle me-2"></i>
              {rejectReason}
            </div>
          ) : (
            clickHere ? (
              <div className="alert-message alert-success">
                <i className="feather-check-circle me-2"></i>
                You have already voted!
              </div>
            ) : (
              <button
                className="action-button vote-action"
                onClick={handleVoteClick}
                disabled={isVoting}
              >
                {isVoting ? (
                  <><i className="feather-loader rotating me-2"></i> Voting...</>
                ) : (
                  <><i className="feather-thumbs-up me-2"></i> Click here to vote</>
                )}
              </button>
            )
          )
        ) : (
          <div className="alert-message alert-warning">
            Please{" "}
            <button
              className="login-button"
              onClick={(e) => {
                e.preventDefault();
                localStorage.setItem("redirectAfterLogin", window.location.href);
                window.location.href = "/register";
              }}
            >
              Log in
            </button>{" "}
            to vote.
          </div>
        )}
      </div>

      <style jsx>{`
        .post-card {
          transition: all 0.2s ease;
          border: 1px solid #eef0f5 !important;
        }
        
        .post-card:hover {
          transform: translateY(-3px);
          box-shadow: 0 8px 20px rgba(0,0,0,0.08) !important;
        }
        
        .image-container {
          overflow: hidden;
          border-radius: 8px;
        }
        
        .stat-button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #eef0f5;
          background-color: #f5f7fa;
          border-radius: 20px;
          padding: 6px 12px;
          font-size: 12px;
          font-weight: 600;
          color: #444;
          cursor: default;
          transition: all 0.2s ease;
        }
        
        .id-button {
          color: #5271ff;
        }
        
        .vote-button {
          color: #f25268;
        }
        
        .rank-button {
          color: #f7a541;
        }
        
        .action-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          background-color: #5271ff;
          color: white;
          border: none;
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          font-weight: 600;
          transition: all 0.2s ease;
          margin-bottom: 15px;
        }
        
        .action-button:hover:not(:disabled) {
          background-color: #3c59e8;
        }
        
        .action-button:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
        
        .vote-action {
          background-color: #5271ff;
        }
        
        .alert-message {
          display: flex;
          align-items: center;
          padding: 12px;
          border-radius: 8px;
          margin-bottom: 15px;
          font-size: 13px;
        }
        
        .alert-success {
          background-color: #e8f5e9;
          color: #2e7d32;
        }
        
        .alert-danger {
          background-color: #ffebee;
          color: #c62828;
        }
        
        .alert-warning {
          background-color: #fff8e1;
          color: #f57f17;
          text-align: center;
        }
        
        .login-button {
          background: none;
          border: none;
          color: #5271ff;
          font-weight: 600;
          cursor: pointer;
          padding: 0 5px;
        }
        
        .login-button:hover {
          text-decoration: underline;
        }
        
        .rotating {
          animation: rotating 1s linear infinite;
        }
        
        @keyframes rotating {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default Postview;
