import React from "react";
import Slider from "react-slick"; // Ensure you have this import

const testimonialList = [
  {
    imageUrl: "https://media.picvot.com/uploads/partners/profile/fitness.webp",
    name: "Fitness Studio",
    position: "Founder",
    comment:
      "PicVot was a total different experience for us it helped the brand to reach to the masses which resulted in a ton of progress in a very short period of time. The collaboration was very profound and rewarding and it will never be a one time business transaction for us. It was amazing.",
  },
  {
    imageUrl: "https://media.picvot.com/uploads/partners/profile/jane_smith.webp",
    name: "Jane Smith",
    position: "Marketing Director, Takoyaki Resturant",
    comment:
      "We love using PicVot for our marketing campaigns. The platform is intuitive, and the engagement is outstanding.",
  },
  // Add more testimonials as needed
];

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      {testimonialList.map((testimonial, index) => (
        <div key={index} className="testimonial-slide row align-items-center justify-content-center">
          <div className="col-lg-12 text-center">
            <div className="testimonial-image-container" style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={`${testimonial.imageUrl}`}
                alt={`testimonial-${index}`}
                className="testimonial-image rounded-circle"
                style={{ width: '100px', height: '100px' }}
              />
            </div>
            <div className="testimonial-content">
              <h4 className="fw-700 font-xssss mt-3 mb-1 d-block">
                {testimonial.name}
              </h4>
              <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-2">
                {testimonial.position}
              </p>
              <p className="fw-500 font-xsssss text-grey-700 mt-0 mb-3 lh-2">
                {testimonial.comment}
              </p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default TestimonialSlider;
