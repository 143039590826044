import React, { Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";

const AboutUs = () => {
    return (
        <Fragment>
            <SEO
                title="About Us - Discover the Vision & Story Behind tapcoe"
                description="Learn about tapcoe, our mission, and how we connect users and vendors through exciting photo contests. Join us in celebrating creativity and community!"
                keywords="About tapcoe, our story, tapcoe mission, photo contest platform, community engagement, vendor partnerships"
                url="https://tapcoe.com/about"
                image="https://tapcoe.com/assets/about-us-og-image.webp"
            />
            <Header />
            <Leftnav />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        {/* Hero Section */}
                        <div className="about-hero position-relative overflow-hidden rounded-xxl mb-4"
                            style={{
                                background: "linear-gradient(135deg, rgba(66, 133, 244, 0.95), rgba(120, 85, 245, 0.95))",
                                height: "400px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <div className="hero-content text-center p-4">
                                <div className="mb-4 d-inline-block position-relative">
                                    <div className="circle-icon d-flex align-items-center justify-content-center rounded-circle mx-auto"
                                        style={{
                                            width: "90px",
                                            height: "90px",
                                            backgroundColor: "white",
                                            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)"
                                        }}>
                                        <i className="feather-users" style={{ fontSize: "36px", color: "#7855f5" }}></i>
                                    </div>
                                </div>
                                <h1 className="fw-bold text-white mb-3" style={{ fontSize: "42px" }}>About tapcoe</h1>
                                <p className="text-white opacity-90 mx-auto" style={{ maxWidth: "700px", fontSize: "18px" }}>
                                    Empowering creativity through community engagement and exciting photo contests
                                </p>
                            </div>

                            {/* Decorative elements */}
                            <div className="position-absolute" style={{ top: "20%", left: "10%", width: "200px", height: "200px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
                            <div className="position-absolute" style={{ bottom: "10%", right: "15%", width: "150px", height: "150px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
                            <div className="position-absolute" style={{ top: "30%", right: "20%", width: "30px", height: "30px", background: "rgba(255,255,255,0.2)", borderRadius: "50%" }}></div>
                            <div className="position-absolute" style={{ bottom: "25%", left: "20%", width: "20px", height: "20px", background: "rgba(255,255,255,0.15)", borderRadius: "50%" }}></div>
                        </div>

                        {/* Our Story Section */}
                        <div className="row mb-5">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="card border-0 rounded-xxl shadow-xss h-100" style={{ padding: "30px" }}>
                                    <div className="section-heading mb-4">
                                        <div className="decoration-line mb-3" style={{
                                            width: '60px',
                                            height: '4px',
                                            background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                                            borderRadius: '4px'
                                        }}></div>
                                        <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Our Story</h2>
                                    </div>
                                    <p className="font-xsss fw-500 text-grey-500 lh-26 mb-2">
                                        At tapcoe, we are passionate about creating a platform that celebrates talent, creativity, and community engagement. We started with a simple idea: to create a space where people can showcase their abilities and receive recognition.
                                    </p>
                                    <p className="font-xsss fw-500 text-grey-500 lh-26 mb-0">
                                        Our journey began with a team of developers, designers, and event professionals who shared a vision of making talent discovery accessible to everyone. Today, we've grown into a vibrant community that connects talented individuals with appreciative audiences.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card border-0 rounded-xxl shadow-xss h-100" style={{ padding: "30px" }}>
                                    <div className="section-heading mb-4">
                                        <div className="decoration-line mb-3" style={{
                                            width: '60px',
                                            height: '4px',
                                            background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                                            borderRadius: '4px'
                                        }}></div>
                                        <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Our Mission</h2>
                                    </div>
                                    <p className="font-xsss fw-500 text-grey-500 lh-26 mb-0">
                                        Our mission is to spread talent and creativity across the digital landscape. We believe that everyone deserves a platform to showcase their skills and receive recognition. By facilitating meaningful connections between creators and audiences, we aim to:
                                    </p>
                                    <ul className="list-style-none pl-0 mt-3">
                                        <li className="d-flex align-items-center mb-2">
                                            <span className="bg-primary-gradiant rounded-circle d-inline-block me-3" style={{ width: '8px', height: '8px' }}></span>
                                            <p className="font-xsss fw-500 text-grey-500 mb-0">Discover and promote exceptional talent</p>
                                        </li>
                                        <li className="d-flex align-items-center mb-2">
                                            <span className="bg-primary-gradiant rounded-circle d-inline-block me-3" style={{ width: '8px', height: '8px' }}></span>
                                            <p className="font-xsss fw-500 text-grey-500 mb-0">Create engaging contests that inspire creativity</p>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <span className="bg-primary-gradiant rounded-circle d-inline-block me-3" style={{ width: '8px', height: '8px' }}></span>
                                            <p className="font-xsss fw-500 text-grey-500 mb-0">Build a supportive community that celebrates achievement</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* How It Works Section */}
                        <div className="card border-0 rounded-xxl shadow-xss p-4 mb-5">
                            <div className="card-body">
                                <div className="section-heading text-center mb-5">
                                    <div className="decoration-line mx-auto mb-3" style={{
                                        width: '60px',
                                        height: '4px',
                                        background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                                        borderRadius: '4px'
                                    }}></div>
                                    <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>How It Works</h2>
                                    <p className="font-xsss fw-500 text-grey-500 mx-auto" style={{ maxWidth: "600px" }}>
                                        Our platform makes event hosting and voting simple and accessible to all users
                                    </p>
                                </div>

                                <div className="row">
                                    {[
                                        {
                                            step: 1,
                                            title: "Create Your Event",
                                            description: "Set up your event poll by choosing from various event types and setting voting rules like vote restrictions and deadlines.",
                                            icon: "calendar"
                                        },
                                        {
                                            step: 2,
                                            title: "Share Your Content",
                                            description: "After participating, share your content with your audience via WhatsApp, social media, or embed it on your website.",
                                            icon: "share-2"
                                        },
                                        {
                                            step: 3,
                                            title: "Cast Your Vote",
                                            description: "Voters can access the poll from any device with internet connection and cast their vote. Our system ensures fair counting.",
                                            icon: "check-circle"
                                        },
                                        {
                                            step: 4,
                                            title: "Track the Results",
                                            description: "Monitor poll results in real-time with detailed reports on vote counts, percentages, and other relevant statistics.",
                                            icon: "bar-chart-2"
                                        }
                                    ].map((item, index) => (
                                        <div key={index} className="col-md-6 col-lg-3 mb-4">
                                            <div className="how-it-works-card p-4 h-100 position-relative rounded-lg" 
                                                style={{ 
                                                    background: 'linear-gradient(135deg, rgba(120, 85, 245, 0.05) 0%, rgba(66, 133, 244, 0.1) 100%)',
                                                    border: '1px solid rgba(120, 85, 245, 0.1)',
                                                    transition: 'all 0.3s ease'
                                                }}
                                                onMouseOver={(e) => {
                                                    e.currentTarget.style.transform = 'translateY(-5px)';
                                                    e.currentTarget.style.boxShadow = '0 10px 30px rgba(0, 0, 0, 0.08)';
                                                }}
                                                onMouseOut={(e) => {
                                                    e.currentTarget.style.transform = 'translateY(0)';
                                                    e.currentTarget.style.boxShadow = 'none';
                                                }}
                                            >
                                                <div className="step-number position-absolute" style={{
                                                    top: "15px",
                                                    right: "15px",
                                                    width: "30px",
                                                    height: "30px",
                                                    borderRadius: "50%",
                                                    background: "linear-gradient(90deg, #4285f4, #7855f5)",
                                                    color: "white",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    fontSize: "14px",
                                                    fontWeight: "bold"
                                                }}>
                                                    {item.step}
                                                </div>
                                                <div className="icon-wrapper mb-3 d-flex align-items-center justify-content-center rounded-circle" style={{
                                                    width: "60px",
                                                    height: "60px",
                                                    background: "linear-gradient(135deg, rgba(120, 85, 245, 0.1) 0%, rgba(66, 133, 244, 0.2) 100%)"
                                                }}>
                                                    <i className={`feather-${item.icon}`} style={{ fontSize: "24px", color: "#4285f4" }}></i>
                                                </div>
                                                <h4 className="fw-700 text-grey-900 font-xs mb-2">{item.title}</h4>
                                                <p className="font-xsss fw-500 text-grey-500 mb-0 lh-24">
                                                    {item.description}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Our Values Section */}
                        <div className="card border-0 rounded-xxl p-5 mb-5"
                            style={{
                                background: "linear-gradient(135deg, rgba(66, 133, 244, 0.95), rgba(120, 85, 245, 0.95))",
                                color: "white"
                            }}>
                            <div className="card-body">
                                <div className="text-center mb-5">
                                    <h2 className="fw-700 text-white mb-2" style={{ fontSize: "28px" }}>Our Values</h2>
                                    <p className="fw-500 text-white opacity-80 mx-auto" style={{ maxWidth: "600px", fontSize: "15px" }}>
                                        The principles that guide everything we do at tapcoe
                                    </p>
                                </div>

                                <div className="row justify-content-center">
                                    {[
                                        {
                                            title: "Creativity",
                                            description: "We believe in the power of creative expression and strive to provide a platform that inspires and nurtures it.",
                                            icon: "feather-bulb"
                                        },
                                        {
                                            title: "Community",
                                            description: "We foster a supportive environment where people can connect, share, and grow together through meaningful engagement.",
                                            icon: "feather-users"
                                        },
                                        {
                                            title: "Integrity",
                                            description: "We are committed to fair and transparent practices that ensure a level playing field for all participants.",
                                            icon: "feather-shield"
                                        },
                                        {
                                            title: "Innovation",
                                            description: "We continuously improve our platform to deliver the best possible experience for our users and partners.",
                                            icon: "feather-trending-up"
                                        }
                                    ].map((value, index) => (
                                        <div key={index} className="col-md-6 col-lg-3 mb-4">
                                            <div className="p-4 text-center">
                                                <div className="icon-wrapper mb-3 d-flex align-items-center justify-content-center rounded-circle mx-auto"
                                                    style={{
                                                        width: "70px",
                                                        height: "70px",
                                                        background: "rgba(255, 255, 255, 0.2)",
                                                        backdropFilter: "blur(10px)"
                                                    }}>
                                                    <i className={value.icon.replace("feather-", "feather-")} style={{ fontSize: "28px", color: "white" }}></i>
                                                </div>
                                                <h4 className="fw-700 text-white font-xs mb-2">{value.title}</h4>
                                                <p className="font-xsss fw-500 text-white opacity-80 mb-0 lh-24">
                                                    {value.description}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Technology Section */}
                        <div className="row mb-5">
                            <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="card border-0 rounded-xxl shadow-xss h-100 p-4">
                                    <div className="card-body">
                                        <div className="section-heading mb-4">
                                            <div className="decoration-line mb-3" style={{
                                                width: '60px',
                                                height: '4px',
                                                background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                                                borderRadius: '4px'
                                            }}></div>
                                            <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Our Technology</h2>
                                        </div>
                                        <p className="font-xsss fw-500 text-grey-500 lh-26 mb-4">
                                            Our platform is built on cutting-edge technology and follows best practices in data security and privacy. We understand the importance of protecting your data and ensuring that your event is fair and transparent.
                                        </p>
                                        <div className="row">
                                            <div className="col-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <i className="feather-check-circle me-2" style={{ color: "#4285f4" }}></i>
                                                    <p className="font-xsss fw-500 text-grey-500 mb-0">Secure Voting System</p>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <i className="feather-check-circle me-2" style={{ color: "#4285f4" }}></i>
                                                    <p className="font-xsss fw-500 text-grey-500 mb-0">Real-time Analytics</p>
                                                </div>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <i className="feather-check-circle me-2" style={{ color: "#4285f4" }}></i>
                                                    <p className="font-xsss fw-500 text-grey-500 mb-0">Advanced Encryption</p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="d-flex align-items-center">
                                                    <i className="feather-check-circle me-2" style={{ color: "#4285f4" }}></i>
                                                    <p className="font-xsss fw-500 text-grey-500 mb-0">Mobile Optimization</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card border-0 rounded-xxl shadow-xss h-100 overflow-hidden">
                                    <div className="card-image h-100">
                                        <img src="/assets/images/about-tech.jpg" alt="Technology" className="w-100 h-100 object-fit-cover" style={{objectFit: "cover"}} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Join Us CTA Section */}
                        <div className="card border-0 rounded-xxl overflow-hidden mb-5" style={{ background: "linear-gradient(45deg, rgba(66, 133, 244, 0.06) 0%, rgba(120, 85, 245, 0.03) 100%)" }}>
                            <div className="card-body p-5">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 mb-4 mb-lg-0">
                                        <h2 className="fw-700 text-grey-900 display-6 lh-sm mb-3">Ready to join the tapcoe community?</h2>
                                        <p className="font-xss fw-500 text-grey-500 mb-4">
                                            Whether you're looking to showcase your talent, discover new creators, or host engaging contests, tapcoe is the platform for you.
                                        </p>
                                        <a href="/register" className="btn border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-lg d-inline-block font-xssss">
                                            Get Started Today
                                        </a>
                                    </div>
                                    <div className="col-lg-5 text-center">
                                        <img src="/assets/images/avatar.png" alt="Join Us" className="w-75" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2">
                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />
        </Fragment>
    );
}

export default AboutUs;
