import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Profilephoto = ({ eventDetails }) => {
    const [tab1, setTab1] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    // Ensure that eventDetails contains eventPhotos and is an array
    const photos = eventDetails || []; // Fallback to an empty array if not provided
    // console.log("Photos:", photos);

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Event Photos</h4>
            </div>
            <div className="card-body d-block pt-0 pb-2">
                <div className="row ps-3 pe-3">
                    {photos.map((photo, index) => (
                        <div className="col-6 mb-1 p-1" key={photo._id}> {/* Use photo._id as key for better uniqueness */}
                            <div onClick={() => { setIsOpen(true); setTab1(index); }}>
                                <a href="#event-photos">
                                    <img src={photo.url} alt="Portfolio" className="img-fluid rounded-3 w-100" />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="card-body d-block w-100 pt-0 mb-2">
                {/* Additional content can be added here if needed */}
            </div>

            {/* Lightbox for displaying the image in full screen */}
            {isOpen && (
                <Lightbox
                    mainSrc={photos[tab1].url}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setTab1((tab1 + photos.length - 1) % photos.length)
                    }
                    onMoveNextRequest={() =>
                        setTab1((tab1 + 1) % photos.length)
                    }
                />
            )}
        </div>
    );
};

export default Profilephoto;
