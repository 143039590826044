import React from 'react';

const Companyabout = ({ about, prize }) => {
    return (
        <div className="about-container">
            <div className="about-card">
                <div className="about-header">
                    <div className="header-icon">
                        <i className="feather-info"></i>
                    </div>
                    <h2 className="header-title">About Event</h2>
                </div>
                <div className="about-content">
                    {about ? (
                        <p className="content-text">{about}</p>
                    ) : (
                        <div className="empty-content">
                            <i className="feather-calendar-off"></i>
                            <p>No details available about this event.</p>
                        </div>
                    )}
                </div>
            </div>

            <div className="about-card">
                <div className="about-header">
                    <div className="header-icon prize-icon">
                        <i className="feather-award"></i>
                    </div>
                    <h2 className="header-title">About Prize</h2>
                </div>
                <div className="about-content">
                    {prize ? (
                        <p className="content-text">{prize}</p>
                    ) : (
                        <div className="empty-content">
                            <i className="feather-package"></i>
                            <p>No prize available for this event.</p>
                        </div>
                    )}
                </div>
            </div>

            <div className="about-card">
                <div className="about-header">
                    <div className="header-icon rules-icon">
                        <i className="feather-check-circle"></i>
                    </div>
                    <h2 className="header-title">Rules and Guidelines</h2>
                </div>
                <div className="about-content">
                    <ul className="rules-list">
                        <li className="rule-item">
                            <div className="rule-bullet">1</div>
                            <p className="rule-text">
                                Photos must be <strong>clicked</strong> by the participant; AI-generated images are <strong>not allowed</strong>.
                            </p>
                        </li>
                        <li className="rule-item">
                            <div className="rule-bullet">2</div>
                            <p className="rule-text">
                                The <strong>winning prize</strong> will be awarded <strong>only</strong> if the participant's profile is complete, meaning a valid profile picture and all required details are uploaded.
                            </p>
                        </li>
                        <li className="rule-item">
                            <div className="rule-bullet">3</div>
                            <p className="rule-text">
                                Participants should ensure that the photos comply with the event's <strong>theme and guidelines</strong>.
                            </p>
                        </li>
                        <li className="rule-item">
                            <div className="rule-bullet">4</div>
                            <p className="rule-text">
                                Misconduct or use of fraudulent means may result in <strong>disqualification</strong>.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <style jsx>{`
                .about-container {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;
                }
                
                .about-card {
                    background-color: white;
                    border-radius: 12px;
                    overflow: hidden;
                    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
                    transition: transform 0.2s, box-shadow 0.2s;
                    border: 1px solid #eaeaea;
                }
                
                .about-card:hover {
                    transform: translateY(-3px);
                    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
                }
                
                .about-header {
                    display: flex;
                    align-items: center;
                    padding: 16px 20px;
                    border-bottom: 1px solid #f0f0f0;
                    background-color: #fafafa;
                }
                
                .header-icon {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #e3f2fd;
                    color: #1976d2;
                    border-radius: 8px;
                    margin-right: 15px;
                    font-size: 20px;
                }
                
                .prize-icon {
                    background-color: #fff8e1;
                    color: #f57c00;
                }
                
                .rules-icon {
                    background-color: #e8f5e9;
                    color: #2e7d32;
                }
                
                .header-title {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                    color: #333;
                }
                
                .about-content {
                    padding: 20px;
                }
                
                .content-text {
                    font-size: 14px;
                    line-height: 1.6;
                    color: #555;
                    margin: 0;
                }
                
                .empty-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 20px;
                    color: #9e9e9e;
                    text-align: center;
                }
                
                .empty-content i {
                    font-size: 32px;
                    margin-bottom: 10px;
                }
                
                .empty-content p {
                    margin: 0;
                    font-size: 14px;
                }
                
                .rules-list {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
                
                .rule-item {
                    display: flex;
                    align-items: flex-start;
                }
                
                .rule-bullet {
                    width: 24px;
                    height: 24px;
                    background-color: #5271ff;
                    color: white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: 600;
                    margin-right: 12px;
                    flex-shrink: 0;
                }
                
                .rule-text {
                    margin: 0;
                    font-size: 14px;
                    line-height: 1.6;
                    color: #555;
                }
                
                @media (max-width: 576px) {
                    .about-header {
                        padding: 14px 16px;
                    }
                    
                    .header-icon {
                        width: 34px;
                        height: 34px;
                        font-size: 16px;
                    }
                    
                    .header-title {
                        font-size: 16px;
                    }
                    
                    .about-content {
                        padding: 16px;
                    }
                    
                    .content-text, .rule-text {
                        font-size: 13px;
                    }
                }
            `}</style>
        </div>
    );
};

export default Companyabout;
