import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./main.scss";
import SEO from "../src/components/Seo"
import Demo from "./demo/Demo";
import EventPost from "./pages/EventPost";
import Hostevent from "./pages/Hostevent";
import Campusambassador from "./pages/Campusambassador";
import Advertisewithus from "./pages/Advertisewithus";
import Feed from "./pages/Feed";
import Policy from "./pages/Policy";
import Termandcondition from "./pages/Termandcondition";
import Settings from "./pages/Settings";
import Account from "./pages/Account";
import Contactinfo from "./pages/Contactinfo";
import Socialaccount from "./pages/Socialaccount";
import Password from "./pages/Password";
import Winners from "./pages/Winners";
import Post from "./pages/Post";
import Event from "./pages/Event";
import Userpage from "./pages/Userpage";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Forgot from "./pages/Forgot";
import Home from "./pages/Home";
import AboutUs from "./pages/Aboutus";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AuthProvider, useAuth } from './context/AuthContext';
import PrivateRoute from './PrivateRoute';
import { initGA, logPageView } from './analytics';
import * as serviceWorker from "./serviceWorker";
import Faq from "./pages/Faq";
// Track Page Views on Route Changes
const TrackPageView = () => {
  const location = useLocation(); // Use useLocation inside the component

  useEffect(() => {
    logPageView(location.pathname); // Log page view on route change
  }, [location]);

  return null;
};

const Root = () => {
  const { isLoggedIn } = useAuth(); // Get authentication status

  useEffect(() => {
    initGA(); // Initialize Google Analytics when the app loads
  }, []);

  console.log(isLoggedIn); // Debugging authentication status

  return (
    <Router>
      <TrackPageView />
     
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
        <Route exact path={`${process.env.PUBLIC_URL}/eventpost/:slug`} component={EventPost} />
        <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={AboutUs} />
        <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={Faq} />
        <Route exact path={`${process.env.PUBLIC_URL}/hostevent`} component={Hostevent} />
        <Route exact path={`${process.env.PUBLIC_URL}/campusambassador`} component={Campusambassador} />
        <Route exact path={`${process.env.PUBLIC_URL}/advertisewithus`} component={Advertisewithus} />
        <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={Policy} />
        <Route exact path={`${process.env.PUBLIC_URL}/terms-and-conditions`} component={Termandcondition} />
        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/accountsettings`} component={Settings} />
        <Route exact path={`${process.env.PUBLIC_URL}/events`} component={Event} />
        <Route exact path={`${process.env.PUBLIC_URL}/accountinformation`} component={Account} />
        <Route exact path={`${process.env.PUBLIC_URL}/feed`} component={Feed} />
        <Route exact path={`${process.env.PUBLIC_URL}/contactinformation`} component={Contactinfo} />
        <Route exact path={`${process.env.PUBLIC_URL}/socialaccount`} component={Socialaccount} />
        <Route exact path={`${process.env.PUBLIC_URL}/password`} component={Password} />
        <Route exact path={`${process.env.PUBLIC_URL}/winners`} component={Winners} />
        <Route exact path={`${process.env.PUBLIC_URL}/post/:eventId/:postId`} component={Post} />
        <PrivateRoute exact path={`${process.env.PUBLIC_URL}/profile`} component={Userpage} />
        <Route exact path={`${process.env.PUBLIC_URL}/contact-us`} component={Contact} />
        <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
     
        <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register} />
        <Route exact path={`${process.env.PUBLIC_URL}/forgot`} component={Forgot} />
        <Redirect to={`${process.env.PUBLIC_URL}/`} />
      </Switch>
    </Router>
  );
};

ReactDOM.render(
  <AuthProvider>
    <Root />
  </AuthProvider>,
  document.getElementById("root")
);

// Register the service worker
serviceWorker.register();
