import React, { Fragment, useState, useEffect, useContext } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import { useParams } from "react-router-dom";
import ProfilecardThree from '../components/ProfilecardThree';
import Events from '../components/Events';
import Postview from '../components/Postview';
import Load from '../components/Load';
import Copyright from "../components/Copyright";
import { getProfileDetails, getPublicProfileDetails } from '../api/getProfileDetails';
import { AuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";

/**
 * User Profile Page Component
 * Displays user profile information including posts, events, and supporters
 */
const Userpage = () => {
    // User context for authentication data
    const { user } = useContext(AuthContext);
    
    // State variables
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [activeTab, setActiveTab] = useState('posts');
    
    // Get username from URL params
    const { username } = useParams();

    // Fetch profile data on component mount or when username changes
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                setLoading(true);

                if (username) {
                    // Public profile - fetch by username
                    const publicProfileData = await getPublicProfileDetails(username);
                    setProfile(publicProfileData);
                } else {
                    // Private profile - fetch by token
                    const token = localStorage.getItem("token");
                    if (!token) {
                        setError("No token found, please log in.");
                        return;
                    }
                    const privateProfileData = await getProfileDetails(token);
                    setProfile(privateProfileData);
                }
            } catch (err) {
                console.error("Error fetching profile:", err);
                setError("Failed to fetch profile.");
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [username]);

    // Loading state
    if (loading) {
        return (
            <Fragment>
                <Header />
                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0 d-flex justify-content-center align-items-center" style={{ minHeight: "70vh" }}>
                            <Loader />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    // Error state
    if (error) {
        return (
            <Fragment>
                <Header />
                <div className="main-content">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                                <div className="alert alert-danger">{error}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Header />
            <Leftnav />
            {/* Rightchat component commented out - can be enabled if needed */}
            {/* <Rightchat /> */}

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        {/* No need for loading check here since we have an early return above */}
                        <div className="row">
                            {/* Profile card section */}
                            <div className="col-xl-12 mb-3">
                                <ProfilecardThree
                                    supporter={profile?.profile?.totalSupporters}
                                    about={profile?.profile?.about}
                                    profilePic={profile?.profile?.profilePic}
                                    coverPic={profile?.profile?.coverPic}
                                    name={profile?.profile?.name}
                                    userId={profile?.profile?._id}
                                />
                            </div>

                            {/* Navigation Tabs */}
                            <div className="col-12 mb-3">
                                <div className="card shadow-xss w-100 d-block d-flex border-0">
                                    <div className="card-body d-flex p-3">
                                        <h4 className={`font-xsss fw-600 ps-4 pe-4 mb-0 ${activeTab === 'posts' ? 'text-primary' : 'text-grey-500'}`} 
                                            style={{cursor: 'pointer'}}
                                            onClick={() => setActiveTab('posts')}>
                                            Posts
                                        </h4>
                                        <h4 className={`font-xsss fw-600 ps-4 pe-4 mb-0 ${activeTab === 'events' ? 'text-primary' : 'text-grey-500'}`}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => setActiveTab('events')}>
                                            Events
                                        </h4>
                                        <h4 className={`font-xsss fw-600 ps-4 pe-4 mb-0 ${activeTab === 'supporters' ? 'text-primary' : 'text-grey-500'}`}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => setActiveTab('supporters')}>
                                            Supporters
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            {/* Events Tab Content */}
                            <div className="col-xl-4 col-xxl-3 col-lg-4" style={{ display: activeTab === 'events' ? 'block' : 'none' }}>
                                <Events joinedEvents={profile?.joinedEvents} />
                            </div>

                            {/* Posts Tab Content */}
                            <div className="col-xl-8 col-xxl-9 col-lg-8" style={{ display: activeTab === 'posts' ? 'block' : 'none' }}>
                                {profile?.joinedEvents?.length ? (
                                    profile.joinedEvents.map((event, index) => {
                                        const { post } = event; // Destructure post from event
                                        const hasValidPost = post?.postId; // Check if postId exists

                                        if (!hasValidPost) {
                                            return null;
                                        }

                                        return (
                                            <Postview
                                                key={`post-${post.postId || index}`}
                                                id={event.eventId}
                                                postid={post.postId}
                                                vote={post.votes || 0}
                                                postvideo=""
                                                postimage={post.postImageUrl || "/assets/images/default-post.webp"}
                                                avatar={user?.profilePic || "/assets/images/default-profile.webp"}
                                                user={profile?.profile?.name || "Anonymous"}
                                                time={post.postCreatedAt || "N/A"}
                                            />
                                        );
                                    })
                                ) : (
                                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                                        <div className="card-body d-flex align-items-center flex-column pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                                            <img src="/assets/images/empty-state.svg" alt="No posts" className="w-50 mb-3" style={{maxWidth: "200px", opacity: 0.7}} />
                                            <h4 className="fw-700 text-grey-900 font-xss mt-2 text-center">
                                                No posts available yet.
                                            </h4>
                                            <p className="text-grey-500 font-xssss mt-2 text-center">
                                                When posts are created, they'll appear here.
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Supporters Tab Content */}
                            <div className="col-xl-8 col-xxl-9 col-lg-8" style={{ display: activeTab === 'supporters' ? 'block' : 'none' }}>
                                <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                                    <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                                        <h4 className="fw-700 text-grey-900 font-xss mt-2">
                                            {profile?.profile?.totalSupporters ? `${profile.profile.totalSupporters} Supporters` : 'No supporters yet'}
                                        </h4>
                                    </div>
                                    {profile?.profile?.totalSupporters === 0 && (
                                        <div className="card-body d-flex align-items-center flex-column pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                                            <img src="/assets/images/empty-state.svg" alt="No supporters" className="w-50 mb-3" style={{maxWidth: "200px", opacity: 0.7}} />
                                            <p className="text-grey-500 font-xssss mt-2 text-center">
                                                Start engaging with the community to gain supporters.
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Footer */}
                            <div className="mt-2">
                                <Copyright />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />
        </Fragment>
    );
};

export default Userpage;
