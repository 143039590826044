import React, { Fragment, useEffect, useState } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Countdown from "../components/CountDown";
import VotingDashboard from "../components/VotingDashboard";
import { getPostDetails } from '../api/getPostDetails';
import CommentSection from "../components/commentSection";  // Assuming your API call is in this file
import { useParams } from 'react-router-dom';
import { castVote } from '../api/castVoteApi';
import Loader from "../components/Loader";
import { useAuth } from "../context/AuthContext";
import Copyright from "../components/Copyright";
import GoogleAd from "../components/GoogleAd";
const Post = () => {
    const { isLoggedIn } = useAuth();
    const { eventId, postId } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isVoting, setIsVoting] = useState(false); // State to handle loading
    const [currentVote, setCurrentVote] = useState(0); // Start with 0

    useEffect(() => {
        // Check if post.totalVotes is available before setting it
        if (post?.totalVotes !== undefined) {
            setCurrentVote(post?.totalVotes); // Set to actual votes when loaded
        }
    }, [post]);
    const animateVoteIncrease = () => {
        let startVote = currentVote; // Store the initial vote
        const targetVote = startVote + 1; // Increment by 1

        const increment = () => {
            if (startVote < targetVote) {
                setCurrentVote((prev) => prev + 1); // Increment vote count in UI
                startVote++; // Increment the startVote
                setTimeout(increment, 50); // Recursively call for smooth animation
            }
        };
        increment();
    };
    const getTimeAgo = (postCreatedAt) => {
        const now = new Date();
        const postTime = new Date(postCreatedAt);
        const timeDifference = now - postTime;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
        if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
        if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        return `just now`;
    };
    const obfuscateId = (id) => {
        const salt = 53; // Static or dynamic salt value
        const numericId = parseInt(id, 36); // Convert the id to base 36 for alphanumeric support
        const transformedId = (numericId + salt) % 10000; // Add salt and take mod to reduce size
        return transformedId.toString().slice(0, 2); // Take the first 2 digits
    };
    const handleVoteClick = async () => {
        try {
            setIsVoting(true); // Start loading
            const response = await castVote(eventId, postId);
            alert('Vote cast successfully!');
            console.log('Vote Response:', response);

            // Call animation function after successfully casting vote
            animateVoteIncrease();
        } catch (error) {
            alert(error.response.data.message);
            console.error('Error during vote:', error.response.data.message);
        } finally {
            setIsVoting(false); // Stop loading
        }
    };

    // Fetch post details when the component mounts
    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const data = await getPostDetails(eventId, postId);
                console.log(data);
                setPost(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchPostData();
    }, [eventId, postId]);

    // Sample data for votes


    const shareUrls = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
        instagram: `https://www.instagram.com/?url=${window.location.href}`,
        whatsapp: `https://api.whatsapp.com/send?text=${window.location.href}`,
    };

    //if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Fragment>
            {/* Header and Navigation */}
            <Header />
            <Leftnav />
            {/* <Rightchat /> */}

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        {loading ? (
                            <Loader />
                        ) : (
                            <div className="row">
                                <div className="card-body p-0 rounded-xxl overflow-hidden m-3" style={{ width: '100%', maxWidth: '1200px', margin: 'auto', position: 'relative' }}>
                                    <div style={{ paddingTop: '20.83%', position: 'relative' }}> {/* 250/1200 = 0.2083 or 20.83% */}
                                        <img
                                            src={`${post.eventDetails.coverPic}`}
                                            alt="event cover"
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover'  // Ensures the image fills the container while maintaining aspect ratio
                                            }}
                                        />
                                    </div>
                                </div>


                                <Countdown eventName={post.eventDetails.name} expiryDate={post.eventDetails.endDate} />
                                <GoogleAd />
                                {/* Left Side - Post Details */}
                                <div className="col-lg-8">
                                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-2 mb-3" style={{ marginTop: '20px' }}>
                                        <div className="card-body p-0 d-flex">
                                            <figure className="avatar me-3">
                                                <img
                                                    src={`${post.createdBy.profilePic}`}
                                                    alt="avatar"
                                                    className="shadow-sm rounded-circle"
                                                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                />
                                            </figure>

                                            <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                                                {post.createdBy.name}
                                                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                                                    {getTimeAgo(post.createdAt)}
                                                </span>
                                            </h4>

                                        </div>

                                        {/* Post Image */}
                                        {post.postImageUrl && (
                                            <div className="card-body d-block p-0 mb-3">
                                                <div className="row ps-2 pe-2">
                                                    <div className="col-sm-12 p-1">
                                                        <img src={`${post.postImageUrl}`} className="rounded-3 w-100" alt="post" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* Post Details - ID, Vote, Rank */}
                                        <div className="card-body d-flex p-0">
                                            <button className="btn btn-primary me-2">{`ID: ${obfuscateId(post._id)}`}</button>

                                            <button className="btn btn-secondary mx-auto me-2">{`Vote: ${post.totalVotes}`}</button>
                                            <button className="btn btn-info ms-auto">{`Rank: ${post.rank}`}</button>
                                        </div>
                                        {isLoggedIn ? (
                                            post?.status === 'rejected' ? (
                                                <div className="alert alert-danger w-100 mt-2">
                                                    Your post was rejected. Reason: {post?.rejectReason}
                                                </div>
                                            ) : post?.hasVoted ? (
                                                <div className="alert alert-success w-100 mt-2">
                                                    You have already voted.
                                                </div>
                                            ) : (
                                                <button
                                                    className="btn btn-success w-100 mt-2"
                                                    onClick={handleVoteClick}
                                                    disabled={isVoting} // Disable button while voting
                                                >
                                                    {isVoting ? 'Voting...' : 'Click here to vote'}
                                                </button>
                                            )
                                        ) : (
                                            <div className="alert alert-warning w-100 mt-2">
                                                Please log in to vote.
                                            </div>
                                        )}


                                        {/* Share Buttons */}
                                        <div className="d-flex justify-content-center mt-3">
                                            <a href={shareUrls.facebook} target="_blank" rel="noopener noreferrer" className="btn btn-primary me-2">Share on Facebook</a>
                                            <a href={shareUrls.instagram} target="_blank" rel="noopener noreferrer" className="btn btn-danger me-2">Share on Instagram</a>
                                            <a href={shareUrls.whatsapp} target="_blank" rel="noopener noreferrer" className="btn btn-success">Share on WhatsApp</a>
                                        </div>
                                    </div>
                                </div>

                                {/* Right Side - Chart and Comment Section */}
                                <CommentSection postId={postId} eventId={eventId} />

                                {/* Chart */}
                                <VotingDashboard postId={postId} />
                            </div>
                        )}
                        <div className="mt-2">

                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer and Popup */}
            <Popupchat />
            <Appfooter />
        </Fragment>
    );
};

export default Post;
