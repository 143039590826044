// src/components/Loader.js
import React from 'react';
import Lottie from 'lottie-react';
import loader from "./loader.json"; // Ensure the correct path to the animation file

const Loader = () => {
    console.log('Lottie:', Lottie); // Check if Lottie is defined

    return (
        <div style={loaderContainer}>
            <div style={animationContainer}>
                <Lottie animationData={loader} loop={true} />
            </div>
            <p style={{color:"GrayText"}}>Loading ...</p>
        </div>
    );
};

const loaderContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    
    // Removed background color here
};

const animationContainer = {
    // Optional styling for the animation container
    height: '150px', // Set height for the animation
    width: '150px', // Set width for the animation
};

export default Loader;
