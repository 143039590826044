import React, { Component, Fragment } from "react";
import axios from "axios"; // Import axios

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Copyright from "../components/Copyright";
class Contact extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    typeOfQuestion: "",
    loading: false,
    error: "",
    successMessage: "",
  };

  // Handle input changes
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  // Form submit handler
  submitHandler = async (e) => {
    e.preventDefault(); // Prevent page reload
    this.setState({ loading: true, error: "", successMessage: "" });

    try {
      const response = await axios.post("https://api.picvot.com/api/v1/contact", {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        subject: this.state.subject,
        typeOfQuestion: this.state.typeOfQuestion,
      });

      // Success response handling
      this.setState({
        loading: false,
        successMessage: "Your message has been sent successfully!",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        subject: "",
        typeOfQuestion: "",
      });
    } catch (error) {
      // Error response handling
      this.setState({
        loading: false,
        error: error.response?.data?.message || "Something went wrong!",
      });
    }
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      subject,
      typeOfQuestion,
      loading,
      error,
      successMessage,
    } = this.state;

    return (
      <Fragment>
        <Header />
        <Leftnav />
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div
                    className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                    style={{
                      backgroundImage: `url("/assets/images/help-bg.png")`,
                    }}
                  >
                    <div
                      className="card-body p-md-5 p-4 text-center"
                      style={{ backgroundColor: "rgba(0,85,255,0.8)" }}
                    >
                      <h2 className="fw-700 display2-size text-white display2-md-size lh-2">
                        Welcome to the PicVot!
                      </h2>
                    </div>
                  </div>

                  {/* Form Section */}
                  <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                    <div className="card-body">
                      <h5 className="fw-600 mb-3" style={{ color: 'rgba(0,85,255,0.8)' }}>
                        Connect Us
                      </h5>



                      <form onSubmit={this.submitHandler}>
                        {/* First Name field */}
                        <div className="mb-3">
                          <label
                            htmlFor="firstName"
                            className="form-label"
                            style={{ color: "rgba(0,85,255,0.8)" }}
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            value={firstName}
                            onChange={this.handleChange}
                            placeholder="Enter your first name"
                            required
                          />
                        </div>

                        {/* Last Name field */}
                        <div className="mb-3">
                          <label
                            htmlFor="lastName"
                            className="form-label"
                            style={{ color: "rgba(0,85,255,0.8)" }}
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            value={lastName}
                            onChange={this.handleChange}
                            placeholder="Enter your last name"
                            required
                          />
                        </div>

                        {/* Email field */}
                        <div className="mb-3">
                          <label
                            htmlFor="email"
                            className="form-label"
                            style={{ color: "rgba(0,85,255,0.8)" }}
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={email}
                            onChange={this.handleChange}
                            placeholder="Enter your email"
                            required
                          />
                        </div>

                        {/* Phone Number field */}
                        <div className="mb-3">
                          <label
                            htmlFor="phoneNumber"
                            className="form-label"
                            style={{ color: "rgba(0,85,255,0.8)" }}
                          >
                            Phone Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={this.handleChange}
                            placeholder="Enter your phone number"
                            required
                          />
                        </div>

                        {/* Subject field */}
                        <div className="mb-3">
                          <label
                            htmlFor="subject"
                            className="form-label"
                            style={{ color: "rgba(0,85,255,0.8)" }}
                          >
                            Subject
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="subject"
                            value={subject}
                            onChange={this.handleChange}
                            placeholder="Enter the subject"
                            required
                          />
                        </div>

                        {/* Type of Question field */}
                        <div className="mb-3">
                          <label
                            htmlFor="typeOfQuestion"
                            className="form-label"
                            style={{ color: "rgba(0,85,255,0.8)" }}
                          >
                            Type of Question
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="typeOfQuestion"
                            value={typeOfQuestion}
                            onChange={this.handleChange}
                            placeholder="Enter the type of question"
                            required
                          />
                        </div>
                        {/* Error and Success Messages */}
                        {error && <div className="alert alert-danger">{error}</div>}
                        {successMessage && (
                          <div className="alert alert-success">{successMessage}</div>
                        )}
                        {/* Submit button */}
                        <div className="d-grid gap-2">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}
                          >
                            {loading ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="mt-2">

                               <Copyright  />
                               </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }
}

export default Contact;
