import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Countdown from "./CountDown";
import Createpost from '../components/Createpost';
import LiveDiscussion from './LiveDiscussion';
import Postview from '../components/Postview';
import Companyabout from '../components/Companyabout';
import { getEventBySlug } from '../api/getEventBySlug';
import { useAuth } from "../context/AuthContext";
import Load from '../components/Load';
import Profilephoto from '../components/Profilephoto';
import GoogleAd from './GoogleAd';

const EventBanner = ({ slug }) => {
    const history = useHistory();
    const { isLoggedIn } = useAuth();
    const [activeTab, setActiveTab] = useState('post');
    const [eventDetails, setEventDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fadeIn, setFadeIn] = useState(false);

    // Post states
    const [page, setPage] = useState(1);
    const [posts, setPosts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [loadingMorePosts, setLoadingMorePosts] = useState(false);
    const postsObserver = useRef(null);
    const lastPostElementRef = useRef(null);

    // Leaderboard states
    const [leaderboardPage, setLeaderboardPage] = useState(1);
    const [leaderboardPosts, setLeaderboardPosts] = useState([]);
    const [hasMoreLeaderboard, setHasMoreLeaderboard] = useState(true);
    const [loadingMoreLeaderboard, setLoadingMoreLeaderboard] = useState(false);
    const leaderboardObserver = useRef(null);
    const lastLeaderboardElementRef = useRef(null);

    const fetchEventDetails = async (newPage = 1, isLeaderboard = false) => {
        try {
            if (newPage === 1) setLoading(true);
            if (newPage > 1) isLeaderboard ? setLoadingMoreLeaderboard(true) : setLoadingMorePosts(true);

            const response = await getEventBySlug(slug, newPage);

            if (newPage === 1) {
                setEventDetails(response);
                setPosts(response.posts || []);
                setLeaderboardPosts(response.leaderboard || []);
                // Add small delay before fade in effect
                setTimeout(() => setFadeIn(true), 150);
            } else {
                if (isLeaderboard) {
                    setLeaderboardPosts(prevLeaderboard => [...prevLeaderboard, ...(response.leaderboard || [])]);
                } else {
                    setPosts(prevPosts => [...prevPosts, ...(response.posts || [])]);
                }
            }

            setHasMore(response.posts?.length > 0);
            setHasMoreLeaderboard(response.leaderboard?.length > 0);
        } catch (error) {
            console.error("Error fetching event details:", error);
        } finally {
            setLoading(false);
            setLoadingMorePosts(false);
            setLoadingMoreLeaderboard(false);
        }
    };

    // Setup intersection observer for posts
    const setupPostsObserver = useCallback(() => {
        if (loadingMorePosts || !hasMore) return;
        
        // Disconnect previous observer if it exists
        if (postsObserver.current) postsObserver.current.disconnect();

        postsObserver.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && activeTab === 'post') {
                setPage(prevPage => prevPage + 1);
                fetchEventDetails(page + 1, false);
            }
        }, {
            rootMargin: '200px', // Load a bit earlier for smoother experience
            threshold: 0.1
        });

        if (lastPostElementRef.current) {
            postsObserver.current.observe(lastPostElementRef.current);
        }
    }, [loadingMorePosts, hasMore, page, activeTab]);

    // Setup intersection observer for leaderboard
    const setupLeaderboardObserver = useCallback(() => {
        if (loadingMoreLeaderboard || !hasMoreLeaderboard) return;
        
        // Disconnect previous observer if it exists
        if (leaderboardObserver.current) leaderboardObserver.current.disconnect();

        leaderboardObserver.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreLeaderboard && activeTab === 'leaderboard') {
                setLeaderboardPage(prevPage => prevPage + 1);
                fetchEventDetails(leaderboardPage + 1, true);
            }
        }, {
            rootMargin: '200px',
            threshold: 0.1
        });

        if (lastLeaderboardElementRef.current) {
            leaderboardObserver.current.observe(lastLeaderboardElementRef.current);
        }
    }, [loadingMoreLeaderboard, hasMoreLeaderboard, leaderboardPage, activeTab]);

    // Initial data fetch
    useEffect(() => {
        fetchEventDetails();
        return () => {
            // Clean up observers when component unmounts
            if (postsObserver.current) postsObserver.current.disconnect();
            if (leaderboardObserver.current) leaderboardObserver.current.disconnect();
        };
    }, [slug]);

    // Reset animation when tab changes
    useEffect(() => {
        setFadeIn(false);
        setTimeout(() => setFadeIn(true), 150);
    }, [activeTab]);

    // Reset pagination when tab changes
    useEffect(() => {
        if (activeTab === 'post') {
            setupPostsObserver();
        } else if (activeTab === 'leaderboard') {
            setupLeaderboardObserver();
        }
    }, [activeTab, setupPostsObserver, setupLeaderboardObserver]);

    // Setup observers when posts or leaderboard data changes
    useEffect(() => {
        setupPostsObserver();
    }, [posts, setupPostsObserver]);

    useEffect(() => {
        setupLeaderboardObserver();
    }, [leaderboardPosts, setupLeaderboardObserver]);

    // Setup ref callback for last post element
    const lastPostRef = useCallback(node => {
        lastPostElementRef.current = node;
        setupPostsObserver();
    }, [setupPostsObserver]);

    // Setup ref callback for last leaderboard element
    const lastLeaderboardRef = useCallback(node => {
        lastLeaderboardElementRef.current = node;
        setupLeaderboardObserver();
    }, [setupLeaderboardObserver]);

    const memoizedPosts = useMemo(() => posts, [posts]);
    const memoizedLeaderboard = useMemo(() => leaderboardPosts, [leaderboardPosts]);

    const renderContent = () => {
        if (loading) return (
            <div className="d-flex justify-content-center align-items-center py-5">
                <div className="fancy-loader">
                    <div className="ring"></div>
                    <div className="ring"></div>
                    <div className="dot"></div>
                    <p className="loading-text mt-3">Loading amazing content...</p>
                </div>
            </div>
        );

        return (
            <div className={`row no-gutters ${fadeIn ? 'fade-in' : ''}`}>
                <div className="col-12">
                    {activeTab === 'post' && (
                        <>
                            {isLoggedIn && eventDetails?.eventDetails ? (
                                eventDetails.eventDetails.restrictions?.isUploadAllowed ? (
                                    <Createpost
                                        eventId={eventDetails.eventDetails._id}
                                        alertMessage={eventDetails.eventDetails.alert}
                                        refreshParent={fetchEventDetails}
                                    />
                                ) : (
                                    <div className="alert alert-warning mb-3 shadow-sm rounded-lg">
                                        <i className="feather-alert-circle me-2"></i>
                                        This event does not allow uploads.
                                    </div>
                                )
                            ) : (
                                <div className="mb-3 text-center p-4 bg-light rounded-lg shadow-sm">
                                    <h4 className="mb-3 fw-600">Join the fun!</h4>
                                    <p className="font-xssss fw-400 mb-3">Login to create posts or vote in this event</p>
                                    <button
                                        className="btn btn-primary shadow-xss rounded-lg"
                                        onClick={() => {
                                            localStorage.setItem("redirectAfterLogin", window.location.pathname);
                                            document.body.classList.remove("theme-dark");
                                            document.body.style.backgroundColor = "#5271ff";
                                            history.push(`${process.env.PUBLIC_URL}/register`);
                                        }}
                                    >
                                        <i className="feather-user-plus me-2"></i>
                                        Login or Register
                                    </button>
                                </div>
                            )}

                            {memoizedPosts?.length > 0 ? 
                                <div className="posts-container">
                                    {memoizedPosts.filter(post => post && post.postId).map((post, index) => (
                                        <div 
                                            key={post.postId || index}
                                            className={`post-item animate-item delay-${index % 5}`}
                                            ref={index === memoizedPosts.length - 1 ? lastPostRef : null}
                                        >
                                            <Postview
                                                {...post}
                                                id={eventDetails.eventDetails._id}
                                                isFrameAllow={eventDetails.eventDetails.votingSettings.IsFrameAllow}
                                                frameUrl={eventDetails.eventDetails.votingSettings.IsFrameAllow ? eventDetails.eventDetails?.frameUrl : undefined}
                                                refreshParent={fetchEventDetails}
                                            />

                                            {(index + 1) % 3 === 0 && <GoogleAd />}
                                        </div>
                                    ))}
                                </div>
                            : 
                                <div className="empty-state text-center p-5 bg-light rounded-lg shadow-sm">
                                    <div className="empty-icon mb-3">
                                        <i className="feather-award text-primary" style={{fontSize: '48px'}}></i>
                                    </div>
                                    <h4 className="fw-600 text-grey-900">You are the first to join this event!</h4>
                                    <p className="font-xssss fw-400 text-grey-500 mt-2">
                                        Create a post and start the trend
                                    </p>
                                </div>
                            }

                            {/* Loading indicator instead of button */}
                            {loadingMorePosts && (
                                <div className="loading-wave-container text-center py-3 mt-3">
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                </div>
                            )}

                            {/* End of content message */}
                            {!hasMore && memoizedPosts.length > 0 && !loadingMorePosts && (
                                <div className="end-message text-center py-3 text-grey-500 font-xsss mt-3 bg-light rounded-lg shadow-sm">
                                    <i className="feather-check-circle me-2"></i>
                                    You've seen all posts
                                </div>
                            )}
                        </>
                    )}

                    {activeTab === 'leaderboard' && (
                        <>
                            <div className="leaderboard-header p-3 mb-4 bg-primary rounded-lg shadow-sm text-white">
                                <div className="d-flex align-items-center">
                                    <i className="feather-award me-3" style={{fontSize: '24px'}}></i>
                                    <h3 className="m-0">Leaderboard</h3>
                                </div>
                            </div>
                            {memoizedLeaderboard?.length > 0 ? 
                                <div className="leaderboard-container">
                                    {memoizedLeaderboard.map((post, index) => (
                                        <div 
                                            key={post.postId || index}
                                            className="leaderboard-item animate-item delay-${index % 5}"
                                            ref={index === memoizedLeaderboard.length - 1 ? lastLeaderboardRef : null}
                                        >
                                            <Postview
                                                {...post}
                                                id={eventDetails.eventDetails._id}
                                                isFrameAllow={eventDetails.eventDetails.votingSettings.IsFrameAllow}
                                                frameUrl={eventDetails.eventDetails.votingSettings.IsFrameAllow ? eventDetails.eventDetails?.frameUrl : undefined}
                                                refreshParent={fetchEventDetails}
                                            />

                                            {(index + 1) % 3 === 0 && <GoogleAd />}
                                        </div>
                                    ))}
                                </div>
                            : 
                                <div className="empty-state text-center p-5 bg-light rounded-lg shadow-sm">
                                    <div className="empty-icon mb-3">
                                        <i className="feather-award text-grey-500" style={{fontSize: '48px'}}></i>
                                    </div>
                                    <h4 className="fw-600 text-grey-900">No leaderboard data yet</h4>
                                    <p className="font-xssss fw-400 text-grey-500 mt-2">
                                        Start voting on posts to populate the leaderboard!
                                    </p>
                                </div>
                            }

                            {/* Loading indicator */}
                            {loadingMoreLeaderboard && (
                                <div className="loading-wave-container text-center py-3 mt-3">
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                    <div className="loading-bar"></div>
                                </div>
                            )}

                            {/* End of content message */}
                            {!hasMoreLeaderboard && memoizedLeaderboard.length > 0 && !loadingMoreLeaderboard && (
                                <div className="end-message text-center py-3 text-grey-500 font-xsss mt-3 bg-light rounded-lg shadow-sm">
                                    <i className="feather-check-circle me-2"></i>
                                    You've seen all leaderboard entries
                                </div>
                            )}
                        </>
                    )}

                    {activeTab === 'about' && (
                        <div className="about-container animate-fade-in">
                            <Companyabout about={eventDetails?.eventDetails?.about} prize={eventDetails?.eventDetails?.prize} />
                        </div>
                    )}
                    
                    {activeTab === 'discussion' && (
                        <div className="discussion-container animate-fade-in">
                            <div className="discussion-header p-3 mb-4 bg-primary rounded-lg shadow-sm text-white">
                                <div className="d-flex align-items-center">
                                    <i className="feather-message-circle me-3" style={{fontSize: '24px'}}></i>
                                    <h3 className="m-0">Live Discussion</h3>
                                </div>
                            </div>
                            <LiveDiscussion eventId={eventDetails.eventDetails._id} refreshParent={fetchEventDetails} />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl mb-4 event-banner-card">
                <div className="card-body p-0 rounded-xxl overflow-hidden m-0 banner-container">
                    <div className="banner-overlay"></div>
                    <picture>
                        {/* Use poster for small devices (mobile) */}
                        <source 
                            media="(max-width: 768px)" 
                            srcSet={eventDetails?.eventDetails?.poster?.url || eventDetails?.eventDetails?.banner?.url} 
                        />
                        {/* Use banner for larger devices (tablet/desktop) */}
                        <source 
                            media="(min-width: 769px)" 
                            srcSet={eventDetails?.eventDetails?.banner?.url} 
                        />
                        {/* Fallback image */}
                        <img 
                            src={eventDetails?.eventDetails?.banner?.url} 
                            alt="Event Banner"
                            className="event-banner-img img-fluid w-100"
                        />
                    </picture>
                </div>

                <div className="countdown-wrapper rounded-xxl mx-2 mt-0">
                    <Countdown 
                        eventName={eventDetails?.eventDetails?.title} 
                        expiryDate={eventDetails?.eventDetails?.expiryDate}
                    />
                </div>

                <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                    <div className="tabs-scroll-container">
                        <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-3 pe-3 event-tabs">
                            {['post', 'leaderboard', 'discussion', 'about'].map(tab => (
                                <li key={tab} className="list-inline-item me-2 position-relative">
                                    <a 
                                        className={`fw-700 font-xssss pb-3 pt-3 ls-1 d-inline-block ${activeTab === tab ? 'active' : ''}`}
                                        onClick={() => setActiveTab(tab)} 
                                        href={`#${tab}`}
                                    >
                                        <span className="tab-icon">
                                            {tab === 'post' && <i className="feather-grid"></i>}
                                            {tab === 'leaderboard' && <i className="feather-award"></i>}
                                            {tab === 'discussion' && <i className="feather-message-circle"></i>}
                                            {tab === 'about' && <i className="feather-info"></i>}
                                        </span>
                                        <span className="tab-text">
                                            {tab === 'post' ? 'Posts' : 
                                             tab === 'discussion' ? 'Live Discussion' :
                                             tab.charAt(0).toUpperCase() + tab.slice(1)}
                                        </span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="tab-content">{renderContent()}</div>
            <GoogleAd />

            <style jsx global>{`
                .fancy-loader {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    height: 100px;
                }
                .ring {
                    width: 60px;
                    height: 60px;
                    border: 4px solid rgba(0, 0, 0, 0.1);
                    border-radius: 50%;
                    position: absolute;
                }
                .ring:nth-child(1) {
                    border-bottom-color: #5271FF;
                    animation: rotate 1.5s linear infinite;
                }
                .ring:nth-child(2) {
                    border-top-color: #5271FF;
                    width: 40px;
                    height: 40px;
                    animation: rotate 1.5s linear infinite reverse;
                }
                .dot {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #5271FF;
                }
                .loading-text {
                    font-size: 14px;
                    color: #5271FF;
                    margin-top: 10px;
                }
                @keyframes rotate {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                
                .fade-in {
                    animation: fadeIn 0.4s ease-in;
                }
                @keyframes fadeIn {
                    from { opacity: 0; transform: translateY(10px); }
                    to { opacity: 1; transform: translateY(0); }
                }
                
                .post-item, .leaderboard-item {
                    opacity: 0;
                    transform: translateY(15px);
                    animation: slideIn 0.5s forwards;
                }
                
                .animate-item {
                    opacity: 0;
                    transform: translateY(15px);
                    animation: slideIn 0.5s forwards;
                }
                
                .delay-0 { animation-delay: 0s; }
                .delay-1 { animation-delay: 0.1s; }
                .delay-2 { animation-delay: 0.2s; }
                .delay-3 { animation-delay: 0.3s; }
                .delay-4 { animation-delay: 0.4s; }
                
                @keyframes slideIn {
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                
                .animate-fade-in {
                    animation: simpleFadeIn 0.5s forwards;
                }
                
                @keyframes simpleFadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }
                
                .loading-wave-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                }
                
                .loading-bar {
                    display: inline-block;
                    width: 5px;
                    height: 20px;
                    margin: 0 3px;
                    border-radius: 4px;
                    background-color: #5271FF;
                    animation: loading-wave 1s ease-in-out infinite;
                }
                
                .loading-bar:nth-child(2) {
                    animation-delay: 0.1s;
                }
                
                .loading-bar:nth-child(3) {
                    animation-delay: 0.2s;
                }
                
                .loading-bar:nth-child(4) {
                    animation-delay: 0.3s;
                }
                
                .loading-bar:nth-child(5) {
                    animation-delay: 0.4s;
                }
                
                @keyframes loading-wave {
                    0% {
                        transform: scale(1);
                    }
                    20% {
                        transform: scale(1, 2);
                    }
                    40% {
                        transform: scale(1);
                    }
                }
                
                .tabs-scroll-container {
                    overflow-x: auto;
                    scrollbar-width: none; /* Firefox */
                    -ms-overflow-style: none; /* IE and Edge */
                    white-space: nowrap;
                }
                
                .tabs-scroll-container::-webkit-scrollbar {
                    display: none; /* Chrome, Safari, Opera */
                }
                
                .event-tabs {
                    flex-wrap: nowrap;
                    min-width: max-content;
                }
                
                .event-tabs a.active {
                    color: #5271FF;
                    border-bottom: 3px solid #5271FF;
                }
                
                .event-tabs a {
                    color: #666;
                    position: relative;
                    transition: all 0.3s ease;
                    white-space: nowrap;
                    text-align: center;
                }
                
                .event-tabs a:hover {
                    color: #5271FF;
                }
                
                .sticky-discussion {
                    position: sticky;
                    top: 80px;
                }
                
                .rank-badge, .top-post {
                    display: none;
                }
                
                .banner-container {
                    position: relative;
                    height: auto;
                    min-height: 150px;
                    max-height: 350px;
                    overflow: hidden;
                    margin-bottom: 0;
                }
                
                .event-banner-img {
                    object-fit: contain;
                    width: 100%;
                    height: auto;
                    max-height: 350px;
                    vertical-align: middle;
                    margin-bottom: -6px; /* Remove gap below image */
                }
                
                .banner-overlay {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 50%;
                    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.7));
                    z-index: 1;
                }
                
                .banner-info {
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    color: white;
                    z-index: 2;
                }
                
                .banner-title {
                    font-size: 28px;
                    font-weight: 700;
                    margin-bottom: 10px;
                    text-shadow: 1px 1px 3px rgba(0,0,0,0.3);
                }
                
                .countdown-wrapper {
                    margin-top: -5px;
                    background: white;
                    z-index: 3;
                    position: relative;
                    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
                
                .empty-state {
                    transition: all 0.3s ease;
                }
                
                .empty-state:hover {
                    transform: translateY(-5px);
                }
                
                .event-banner-card {
                    transform: translateY(0);
                    transition: all 0.3s ease;
                }
                
                .event-banner-card:hover {
                    transform: translateY(-5px);
                }
                
                @media (max-width: 768px) {
                    .banner-container {
                        min-height: 120px;
                        max-height: 300px;
                    }
                    
                    .event-banner-img {
                        max-height: 300px;
                        object-fit: cover;
                    }
                    
                    .banner-title {
                        font-size: 20px;
                    }
                }
                
                @media (max-width: 576px) {
                    .tab-icon {
                        display: inline-block;
                        margin-right: 5px;
                    }
                    
                    .event-tabs a {
                        font-size: 11px !important;
                        padding-left: 8px;
                        padding-right: 8px;
                    }
                    
                    .list-inline-item.me-2 {
                        margin-right: 0.25rem !important;
                    }
                }
                
                @media (max-width: 360px) {
                    .tab-text {
                        display: none;
                    }
                    
                    .tab-icon {
                        margin-right: 0;
                        font-size: 16px;
                    }
                    
                    .event-tabs a {
                        padding-left: 12px;
                        padding-right: 12px;
                    }
                }
            `}</style>
        </>
    );
};

export default EventBanner;
