import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Appfooter = () => {
    const location = useLocation(); // Get the current path

    // Get theme from localStorage or default to 'light'
    const theme = localStorage.getItem('theme') || 'light';
    const isDarkTheme = theme === 'dark';

    // Define colors based on theme
    const activeColor = isDarkTheme ? '#ffffff' : '#1e88e5';
    const inactiveColor = isDarkTheme ? '#adbdcc' : '#7c8a97';

    const containerStyle = {
        background: isDarkTheme ? '#1a2035' : '#ffffff',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        boxShadow: '0 -3px 15px rgba(0,0,0,0.08)',
        padding: '6px 20px',
        margin: '0 10px',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        paddingBottom: 'calc(6px + env(safe-area-inset-bottom))',
        border: isDarkTheme ? 'none' : '1px solid #e9eef6',
        borderBottom: 'none'
    };

    const getItemStyle = (path) => {
        const isActive = location.pathname === path;
        return {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            padding: '4px 0',
            transition: 'all 0.25s ease',
            flex: 1,
            textDecoration: 'none'
        };
    };

    const getIconContainerStyle = (path) => {
        const isActive = location.pathname === path;
        return {
            width: isActive ? '34px' : '30px',
            height: isActive ? '34px' : '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: isActive 
                ? 'linear-gradient(145deg, var(--theme-color), var(--theme-color-shade))' 
                : isDarkTheme ? '#2d3748' : '#f3f6fb',
            borderRadius: isActive ? '12px' : '10px',
            marginBottom: '3px',
            boxShadow: isActive 
                ? '0 4px 8px -2px rgba(var(--theme-rgb), 0.4)' 
                : 'none',
            transition: 'all 0.25s cubic-bezier(0.4, 0, 0.2, 1)'
        };
    };

    const getLabelStyle = (path) => {
        const isActive = location.pathname === path;
        return {
            fontSize: '9px',
            fontWeight: isActive ? '600' : '500',
            color: isActive ? activeColor : inactiveColor,
            transition: 'all 0.25s ease',
            opacity: isActive ? 1 : 0.7,
            lineHeight: '1'
        };
    };

    const getIconStyle = (path) => {
        const isActive = location.pathname === path;
        return {
            fontSize: isActive ? '18px' : '16px',
            color: isActive ? '#ffffff' : (isDarkTheme ? '#adbdcc' : '#7c8a97'),
            transition: 'all 0.25s ease'
        };
    };

    return (
        <div className="app-footer d-block d-md-none" style={containerStyle}>
            <div className="d-flex justify-content-between align-items-center">
                <Link to="/" style={getItemStyle('/')}>
                    <div style={getIconContainerStyle('/')}>
                        <i className="feather-home" style={getIconStyle('/')}></i>
                    </div>
                    <span style={getLabelStyle('/')}>Home</span>
                </Link>
                <Link to="/events" style={getItemStyle('/events')}>
                    <div style={getIconContainerStyle('/events')}>
                        <i className="feather-calendar" style={getIconStyle('/events')}></i>
                    </div>
                    <span style={getLabelStyle('/events')}>Events</span>
                </Link>
                <Link to="/feed" style={getItemStyle('/feed')}>
                    <div style={getIconContainerStyle('/feed')}>
                        <i className="feather-zap" style={getIconStyle('/feed')}></i>
                    </div>
                    <span style={getLabelStyle('/feed')}>Feed</span>
                </Link>
                <Link to="/winners" style={getItemStyle('/winners')}>
                    <div style={getIconContainerStyle('/winners')}>
                        <i className="feather-trending-up" style={getIconStyle('/winners')}></i>
                    </div>
                    <span style={getLabelStyle('/winners')}>Winners</span>
                </Link>
                <Link to="/profile" style={getItemStyle('/profile')}>
                    <div style={getIconContainerStyle('/profile')}>
                        <i className="feather-user" style={getIconStyle('/profile')}></i>
                    </div>
                    <span style={getLabelStyle('/profile')}>Profile</span>
                </Link>
            </div>
        </div>
    );
};

export default Appfooter;
