import React, { useState, useEffect, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { ReSendOTP } from "../api/authApi";

const Register = () => {
  const { verifyUserOTP, register, login } = useContext(AuthContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [formData, setFormData] = useState({
    mobileNumber: "",
    otp: "",
    fullName: "",
    dob: "",
    gender: "",
    password: "",
    confirmPassword: "",
    location: { latitude: null, longitude: null },
  });
  const [showInfo, setShowInfo] = useState(false);
  const [step, setStep] = useState(1);
  
  useEffect(() => {
    handleLocationDetection();
  }, []);

  const handleLocationDetection = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setFormData((prevState) => ({
          ...prevState,
          location: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        }));
      },
      (error) => {
        console.error("Error getting location:", error);
      }
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCreateAccount = async () => {
    setLoading(true); 
    const { password, confirmPassword, mobileNumber, fullName, dob, gender, location } = formData;

    if (!fullName || !dob || !gender || !password || !confirmPassword || !mobileNumber) {
      alert("All fields are required!");
      setLoading(false);
      return;
    }

    const whatsappRegex = /^[0-9]{10}$/;
    if (!whatsappRegex.test(mobileNumber)) {
      alert("Please enter a valid WhatsApp number!");
      setLoading(false);
      return;
    }
    
    if (password.length < 8) {
      alert("Password must be at least 8 characters long!");
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      setLoading(false);
      return;
    }

    const registerData = {
      name: fullName,
      gender,
      dob,
      mobileNumber: mobileNumber,
      password,
      latitude: location.latitude,
      longitude: location.longitude,
    };

    try {
      const response = await register(registerData);

      if (response.success) {
        setStep(2);
      } else {
        setLoading(false);
        alert(response.message);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        alert(error.response.data.message);
        setLoading(false);
      } else {
        alert("Error sending OTP. Please try again.");
        setLoading(false);
      }
    }
  };

  const handleVerifyOTP = async () => {
    const { otp, mobileNumber } = formData;

    if (!otp) {
      alert("Please enter the OTP!");
      return;
    }

    try {
      const response = await verifyUserOTP({ otp, mobileNumber });
      if (response.success) {
        const loginResponse = await login({ mobileNumber, password: formData.password });
        
        if (loginResponse.success) {
          alert("Registration successful!");
          const redirectURL = localStorage.getItem("redirectAfterLogin") || "/";
          localStorage.removeItem("redirectAfterLogin");
          window.location.href = redirectURL;
        } else {
          alert("Login failed after OTP verification. Please try logging in manually.");
          window.location.href = "/login";
        }
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert(error.response?.data?.message || "Failed to verify OTP. Please try again.");
    }
  };

  const handleResendOTP = async () => {
    if (resendTimer > 0) return;
    const { mobileNumber } = formData;

    try {
      await ReSendOTP({ mobileNumber });
      alert("OTP resent successfully! Please check your WhatsApp.");
      setResendTimer(30);
    } catch (error) {
      console.error("Error resending OTP:", error);
      alert("Failed to resend OTP. Please try again later.");
    }
  };

  const { mobileNumber, otp, fullName, dob, gender, password, confirmPassword } = formData;
  
  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendTimer]);
  
  return (
    <div className="bg-light" style={{minHeight: "100vh"}}>
      {/* Header */}
      <header className="bg-white shadow-sm">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center py-3">
            <a href="/" className="d-flex align-items-center text-decoration-none">
              <i className="feather-camera text-primary me-2" style={{fontSize: "24px"}}></i>
              <span className="fw-bold text-dark fs-4">tapcoe</span>
            </a>
            <div>
              <a 
                href="/login" 
                className="btn me-2" 
                style={{
                  borderRadius: "8px",
                  border: "1px solid #4285f4",
                  color: "#4285f4",
                  padding: "8px 16px",
                  fontWeight: "500",
                  fontSize: "14px",
                  textDecoration: "none"
                }}
              >
                Login
              </a>
              <a 
                href="/register" 
                className="btn" 
                style={{
                  borderRadius: "8px",
                  background: "linear-gradient(90deg, #4285f4, #7855f5)",
                  color: "white",
                  padding: "8px 16px",
                  fontWeight: "500",
                  fontSize: "14px",
                  textDecoration: "none"
                }}
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="container py-5">
        <div className="row">
          {/* Left side - Image and benefits (hidden on mobile) */}
          <div className="col-lg-6 d-none d-lg-block">
            <div className="card border-0 rounded-4 overflow-hidden h-100 bg-primary text-white position-relative">
              <div style={{
                background: "linear-gradient(45deg, rgba(66, 133, 244, 0.9), rgba(120, 85, 245, 0.8))",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1
              }}></div>
              <img 
                src="/assets/images/loginpic.png" 
                alt="Join Tapcoe" 
                className="card-img h-100"
                style={{
                  objectFit: "cover",
                  opacity: 0.6
                }}
              />
              <div className="card-img-overlay d-flex flex-column justify-content-center p-5" style={{zIndex: 2}}>
                <h2 className="fw-bold mb-4">Join Our Community</h2>
                <p className="mb-4">Create an account to participate in exciting contests, showcase your talent, and win amazing prizes!</p>
                
                <div className="mt-4">
                  <div className="d-flex align-items-center mb-3">
                    <div className="d-flex align-items-center justify-content-center rounded-circle bg-white bg-opacity-25 me-3" style={{width: "40px", height: "40px"}}>
                      <i className="feather-award text-white"></i>
                    </div>
                    <span>Participate in exciting contests</span>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="d-flex align-items-center justify-content-center rounded-circle bg-white bg-opacity-25 me-3" style={{width: "40px", height: "40px"}}>
                      <i className="feather-star text-white"></i>
                    </div>
                    <span>Win amazing prizes</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-center rounded-circle bg-white bg-opacity-25 me-3" style={{width: "40px", height: "40px"}}>
                      <i className="feather-users text-white"></i>
                    </div>
                    <span>Connect with like-minded people</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Right side - Form */}
          <div className="col-lg-6">
            <div className="card border-0 rounded-4 shadow-sm">
              <div className="card-body p-4 p-lg-5">
                {step === 1 ? (
                  <>
                    <div className="mb-4">
                      <div className="mb-3" style={{
                        width: "60px",
                        height: "4px",
                        background: "linear-gradient(90deg, #4285f4, #7855f5)",
                        borderRadius: "4px"
                      }}></div>
                      <h2 className="fw-bold mb-1">Create Your Account</h2>
                      <p className="text-muted small">Fill in your details to get started</p>
                    </div>
                    
                    <form onSubmit={(e) => {
                      e.preventDefault();
                      handleCreateAccount();
                    }}>
                      <div className="mb-3 position-relative">
                        <label htmlFor="mobileNumber" className="form-label small fw-medium">WhatsApp Number</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-smartphone text-muted"></i>
                          </span>
                          <input
                            type="text"
                            id="mobileNumber"
                            name="mobileNumber"
                            className="form-control bg-light border-start-0"
                            placeholder="10-digit number (e.g. 9723456789)"
                            value={mobileNumber}
                            onChange={handleInputChange}
                          />
                          <span 
                            className="input-group-text bg-light border-start-0 text-primary" 
                            style={{cursor: "pointer"}}
                            onClick={() => setShowInfo(!showInfo)}
                          >
                            <i className="feather-info"></i>
                          </span>
                        </div>
                        {showInfo && (
                          <div className="mt-2 p-2 rounded bg-light border small">
                            Please enter an Indian WhatsApp number without country code (+91).
                          </div>
                        )}
                      </div>
                      
                      <div className="mb-3">
                        <label htmlFor="fullName" className="form-label small fw-medium">Full Name</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-user text-muted"></i>
                          </span>
                          <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            className="form-control bg-light border-start-0"
                            placeholder="Enter your full name"
                            value={fullName}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      
                      <div className="mb-3">
                        <label htmlFor="dob" className="form-label small fw-medium">Date of Birth</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-calendar text-muted"></i>
                          </span>
                          <input
                            type="date"
                            id="dob"
                            name="dob"
                            className="form-control bg-light border-start-0"
                            value={dob}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label small fw-medium">Gender</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-users text-muted"></i>
                          </span>
                          <select
                            id="gender"
                            name="gender"
                            className="form-select bg-light border-start-0"
                            value={gender}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                      
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label small fw-medium">Password</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-lock text-muted"></i>
                          </span>
                          <input
                            type={passwordVisible ? "text" : "password"}
                            id="password"
                            name="password"
                            className="form-control bg-light border-start-0 border-end-0"
                            placeholder="Min. 8 characters"
                            value={password}
                            onChange={handleInputChange}
                          />
                          <span 
                            className="input-group-text bg-light border-start-0 text-primary" 
                            style={{cursor: "pointer"}}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            <i className={passwordVisible ? "feather-eye-off" : "feather-eye"}></i>
                          </span>
                        </div>
                      </div>
                      
                      <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label small fw-medium">Confirm Password</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-lock text-muted"></i>
                          </span>
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            id="confirmPassword"
                            name="confirmPassword"
                            className="form-control bg-light border-start-0 border-end-0"
                            placeholder="Confirm your password"
                            value={confirmPassword}
                            onChange={handleInputChange}
                          />
                          <span 
                            className="input-group-text bg-light border-start-0 text-primary" 
                            style={{cursor: "pointer"}}
                            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                          >
                            <i className={confirmPasswordVisible ? "feather-eye-off" : "feather-eye"}></i>
                          </span>
                        </div>
                      </div>
                      
                      <div className="mb-4 form-check">
                        <input type="checkbox" className="form-check-input" id="termsCheck" required />
                        <label className="form-check-label small" htmlFor="termsCheck">
                          I agree to the <a href="/terms" className="text-decoration-none text-primary">Terms</a> and <a href="/privacy" className="text-decoration-none text-primary">Privacy Policy</a>
                        </label>
                      </div>
                      
                      <button 
                        type="submit" 
                        className="btn w-100 py-2"
                        disabled={loading}
                        style={{
                          background: "linear-gradient(90deg, #4285f4, #7855f5)",
                          color: "white",
                          borderRadius: "8px",
                          fontWeight: "500"
                        }}
                      >
                        {loading ? (
                          <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Processing...
                          </>
                        ) : "Create Account"}
                      </button>
                      
                      <div className="text-center mt-4">
                        <p className="small text-muted mb-0">
                          Already have an account? <a href="/login" className="text-decoration-none text-primary fw-medium">Login</a>
                        </p>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <div className="mb-4 text-center">
                      <div className="mb-3 mx-auto" style={{
                        width: "60px",
                        height: "4px",
                        background: "linear-gradient(90deg, #4285f4, #7855f5)",
                        borderRadius: "4px"
                      }}></div>
                      <h2 className="fw-bold mb-1">Verify OTP</h2>
                      <p className="text-muted small">Enter the OTP sent to your WhatsApp</p>
                    </div>
                    
                    <div className="text-center mb-4">
                      <div className="d-inline-flex align-items-center justify-content-center rounded-circle mb-3" style={{
                        width: "80px",
                        height: "80px",
                        background: "linear-gradient(135deg, rgba(66, 133, 244, 0.1), rgba(120, 85, 245, 0.1))"
                      }}>
                        <i className="feather-shield" style={{
                          fontSize: "32px",
                          color: "#4285f4"
                        }}></i>
                      </div>
                      
                      <p className="text-muted small mb-0">
                        We've sent a verification code to<br/>
                        <span className="fw-medium text-dark">+91 {mobileNumber}</span>
                      </p>
                    </div>
                    
                    <form onSubmit={(e) => {
                      e.preventDefault();
                      handleVerifyOTP();
                    }}>
                      <div className="mb-4">
                        <label htmlFor="otp" className="form-label small fw-medium">Enter OTP</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-key text-muted"></i>
                          </span>
                          <input
                            type="text"
                            id="otp"
                            className="form-control bg-light border-start-0 text-center fw-medium"
                            placeholder="• • • • • •"
                            value={otp}
                            onChange={(e) => setFormData({ ...formData, otp: e.target.value })}
                            style={{
                              letterSpacing: "5px",
                              fontSize: "18px"
                            }}
                          />
                        </div>
                      </div>
                      
                      <button 
                        type="submit" 
                        className="btn w-100 py-2 mb-3"
                        style={{
                          background: "linear-gradient(90deg, #4285f4, #7855f5)",
                          color: "white",
                          borderRadius: "8px",
                          fontWeight: "500"
                        }}
                      >
                        Verify OTP
                      </button>
                      
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <button 
                          type="button" 
                          className="btn btn-link text-muted p-0 small text-decoration-none"
                          onClick={() => setStep(1)}
                        >
                          <i className="feather-arrow-left me-1"></i> Back
                        </button>
                        
                        <button 
                          type="button" 
                          className="btn btn-link p-0 small text-decoration-none"
                          onClick={handleResendOTP}
                          disabled={resendTimer > 0}
                          style={{
                            color: resendTimer > 0 ? "#aaa" : "#4285f4"
                          }}
                        >
                          {resendTimer > 0 ? `Resend in ${resendTimer}s` : "Resend OTP"}
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
