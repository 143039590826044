import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import { fetchSocialLinks, updateSocialLinks } from '../api/socialLink'; // Import API functions

const Socialaccount = () => {
    const [socialLinks, setSocialLinks] = useState({
        facebook: '',
        instagram: '',
        youtube: '',
        snapchat: '',
    });

    useEffect(() => {
        loadSocialLinks();
    }, []);

    const loadSocialLinks = async () => {
        try {
            const data = await fetchSocialLinks(); // Use API function
            setSocialLinks(data);
        } catch (error) {
            console.error('Failed to load social links');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSocialLinks((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateSocialLinks(socialLinks); // Use API function
        } catch (error) {
            console.error('Failed to update social links');
        }
    };

    const { facebook, instagram, youtube, snapchat } = socialLinks;

    return (
        <Fragment>
            <div className="main-wrapper">
                <Header />
                <Leftnav />
                {/* <Rightchat /> */}
                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/accountsettings" className="d-inline-block mt-2">
                                            <i className="ti-arrow-left font-sm text-white"></i>
                                        </Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Social Network</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss">Facebook</label>
                                                        <input
                                                            type="text"
                                                            name="facebook"
                                                            value={facebook}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss">Instagram</label>
                                                        <input
                                                            type="text"
                                                            name="instagram"
                                                            value={instagram}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss">YouTube</label>
                                                        <input
                                                            type="text"
                                                            name="youtube"
                                                            value={youtube}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 mb-3">
                                                    <div className="form-group">
                                                        <label className="mont-font fw-600 font-xsss">Snapchat</label>
                                                        <input
                                                            type="text"
                                                            name="snapchat"
                                                            value={snapchat}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mb-0 mt-2">
                                                    <button
                                                        type="submit"
                                                        className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />
        </Fragment>
    );
};

export default Socialaccount;
