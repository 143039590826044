import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Copyright from "../components/Copyright";
import { getEvents } from "../api/eventsApi";
import Countdown from "../components/CountDown";
import SEO from "../components/Seo";
import MemberSlider from "../components/Memberslider";
import CategoryList from "../components/CategoryList";
import BannerSlider from "../components/BannerSlider";
import { Link } from "react-router-dom";

const Event = ({ match }) => {
    const { slug = "public" } = match.params;
    // console.log(slug);
    <SEO
        title={`Event  - Participate & Win | Tapcoe`}
        description={`Join event  on tapcoe and stand a chance to win exciting prizes!`}
        url={`https://tapcoe.com/events`}
        image={`https://tapcoe.com/assets/og-image.webp`}
    />
    const [eventList, setEventList] = useState([]);
    const [counts, setCounts] = useState({
        topVoters: [],
    });
    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const response = await getEvents(slug); // Replace with your actual API endpoint
                setEventList(response);
            } catch (error) {
                console.error("Error fetching event data:", error);
            }
        };

        fetchEventData();
    }, [slug]);
    useEffect(() => {
        // Fetch data from the API
        const fetchCounts = async () => {
            try {
                const response = await fetch('https://api.tapcoe.com/api/v1/home');
                const data = await response.json();
                setCounts({
                    topVoters: data.topVoters, // Ensure this comes as an array from the API response
                });
                // console.log(counts); // Logging to debug
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchCounts();
    }, []);
    
    const categories = [
        {
            _id: "6795111bd68da8e5229915d7",
            name: "Brands",
            slug: "brands",
            icon: "https://media.picvot.com/uploads/category/Brands.webp",
        },
        {
            _id: "67951166d68da8e5229915da",
            name: "College",
            slug: "college",
            icon: "https://media.picvot.com/uploads/category/College.webp",
        },
        {
            _id: "6795118ed68da8e5229915dd",
            name: "Public",
            slug: "public",
            icon: "https://media.picvot.com/uploads/category/Public.webp",
        },
    ];
    const formatDate = (creationDate) => {
        const date = new Date(creationDate);
        const month = date.toLocaleString("en-US", { month: "short" }).toUpperCase(); // 3-letter month in uppercase
        const day = String(date.getDate()).padStart(2, "0"); // Day padded with '0' if it's a single digit
        return { month, day };
    };

    // Check if an event is expired
    const isEventExpired = (expiryDate) => {
        const now = new Date();
        const expiry = new Date(expiryDate);
        return now > expiry;
    };

    return (
        <Fragment>
            <Header />
            <Leftnav />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="col-xl-12">
                            <div className="card w-100 border-0 shadow-none rounded-xxl border-0 mb-3 overflow-hidden">
                                <div className="col-lg-12">
                                    <BannerSlider 
                                        images={[
                                            "https://media.picvot.com/uploads/web/bn1.webp",
                                            "https://media.picvot.com/uploads/web/bn4%20(1).webp"
                                        ]} 
                                    />
                                </div>
                            </div>
                        </div>
                        <CategoryList categories={categories} />
                        <div className="events-container">
                            <div className="row">
                                {eventList.map((value, index) => {
                                    const { month, day } = formatDate(value.creationDate || new Date());
                                    const joinedUserPics = value.joinedUserPics || [];
                                    const participantCount = joinedUserPics.length;
                                    const expired = isEventExpired(value.expiryDate);

                                    return (
                                        <div key={index} className="col-lg-4 col-md-6 mb-4">
                                            <div className="event-card">
                                                <div className="card-header">
                                                    <div className="card-countdown">
                                                        <Countdown expiryDate={value.expiryDate} />
                                                    </div>
                                                </div>

                                                <div className="card-image">
                                                    <img src={value.poster} alt={value.title} />
                                                    {value.type && (
                                                        <div className="type-badge">
                                                            {value.type}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="card-body">
                                                    <div className="card-meta">
                                                        <div className="meta-item">
                                                            <i className="feather-calendar"></i>
                                                            <span>{month} {day}</span>
                                                        </div>
                                                        <div className="meta-item">
                                                            <i className="feather-users"></i>
                                                            <span>{participantCount} joined</span>
                                                        </div>
                                                        <div className="meta-item" onClick={() => {
                                                            const { latitude, longitude } = value.location || {};
                                                            if (latitude && longitude) {
                                                                window.open(
                                                                    `https://www.google.com/maps?q=${latitude},${longitude}`,
                                                                    "_blank"
                                                                );
                                                            }
                                                        }}>
                                                            <i className="feather-map-pin"></i>
                                                            <span>{value.location?.name || " Location"}</span>
                                                        </div>
                                                    </div>
                                                    
                                                    <h3 className="card-title">{value.title}</h3>
                                                    
                                                    <div className="card-details">
                                                        <div className="detail-row">
                                                            <div className="detail-label">Type:</div>
                                                            <div className="detail-value">{value.type}</div>
                                                        </div>
                                                        <div className="detail-row">
                                                            <div className="detail-label">Status:</div>
                                                            <div className={`detail-value ${expired ? 'status-closed' : 'status-open'}`}>
                                                                {expired ? 'Closed' : 'Open'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card-participants">
                                                    <div className="participant-avatars">
                                                        {joinedUserPics.slice(0, 5).map((pic, idx) => (
                                                            <div key={idx} className="participant-avatar">
                                                                <img src={pic} alt={`Participant ${idx + 1}`} />
                                                            </div>
                                                        ))}
                                                        {participantCount > 5 && (
                                                            <div className="participant-count">
                                                                +{participantCount - 5}
                                                            </div>
                                                        )}
                                                    </div>
                                                    
                                                    <Link to={`/eventpost/${value.slug}`} className="join-event-btn">
                                                        <i className="feather-zap"></i>
                                                        <span>Join Now</span>
                                                    </Link>
                                                </div>

                                                <div className="card-footer">
                                                    <div className="footer-info">
                                                        <div className="prize-badge">
                                                            <i className="feather-award"></i>
                                                            <span>{value.prizeonewords || "Prize available"}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {/* Rest of the content for the page */}
                        <div className="col-xl-12">
                            <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-white d-block shadow-xss">
                                <div className="card-body mb-3 pb-0">
                                    <h2 className="fw-400 font-lg d-block">
                                        <b>Top Voters</b>
                                        {/* <a href="/events" className="float-right">
                                            <i className="feather-edit text-grey-500 font-xs"></i>
                                        </a> */}
                                    </h2>
                                </div>
                                <MemberSlider memberList={counts.topVoters} />
                            </div>
                        </div>

                        <Appfooter />

                    </div>
                </div>
            </div>

            <Popupchat />
            

            <style jsx="true">{`
                .events-container {
                    width: 100%;
                    margin-bottom: 30px;
                }
                
                .event-card {
                    position: relative;
                    background: white;
                    border-radius: 10px;
                    overflow: hidden;
                    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.07);
                    transition: all 0.4s ease;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    transform: translateY(0);
                }
                
                .event-card:hover {
                    transform: translateY(-8px);
                    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.12);
                }
                
                .card-header {
                    padding: 15px 20px 0;
                }
                
                .card-countdown {
                    text-align: center;
                    font-size: 13px;
                    color: #666;
                    font-weight: 500;
                }
                
                .card-image {
                    position: relative;
                    height: 150px;
                    overflow: hidden;
                    margin: 15px 15px 0;
                    border-radius: 10px;
                }
                
                .card-image img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform 0.7s ease;
                }
                
                .event-card:hover .card-image img {
                    transform: scale(1.05);
                }
                
                .type-badge {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background-color: rgba(0, 0, 0, 0.6);
                    color: white;
                    font-size: 11px;
                    font-weight: 600;
                    padding: 3px 8px;
                    border-radius: 4px;
                    text-transform: uppercase;
                }
                
                .card-body {
                    padding: 20px;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                }
                
                .card-meta {
                    display: flex;
                    gap: 15px;
                    margin-bottom: 15px;
                    flex-wrap: wrap;
                }
                
                .meta-item {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: 11px;
                    color: #666;
                }
                
                .meta-item i {
                    color: #3498db;
                    font-size: 12px;
                }
                
                .meta-item:last-child {
                    cursor: pointer;
                }
                
                .meta-item:last-child:hover {
                    color: #3498db;
                    text-decoration: underline;
                }
                
                .card-title {
                    font-size: 15px;
                    font-weight: 700;
                    color: #333;
                    margin-bottom: 15px;
                    line-height: 1.3;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                
                .card-details {
                    margin-top: auto;
                    margin-bottom: 10px;
                }
                
                .detail-row {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 5px;
                    font-size: 12px;
                }
                
                .detail-label {
                    color: #666;
                    font-weight: 500;
                }
                
                .detail-value {
                    color: #333;
                    font-weight: 600;
                }
                
                .status-open {
                    color: #4CAF50;
                }
                
                .status-closed {
                    color: #F44336;
                }
                
                .card-participants {
                    padding: 0 20px;
                    margin-bottom: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                
                .participant-avatars {
                    display: flex;
                    align-items: center;
                }
                
                .participant-avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    border: 2px solid white;
                    margin-left: -8px;
                    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
                }
                
                .participant-avatar:first-child {
                    margin-left: 0;
                }
                
                .participant-avatar img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                
                .participant-count {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: #f0f0f0;
                    color: #666;
                    font-size: 10px;
                    font-weight: 600;
                    margin-left: -5px;
                    border: 2px solid white;
                    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
                }
                
                .card-footer {
                    padding: 10px 20px;
                    border-top: 1px solid #f0f0f0;
                }
                
                .footer-info {
                    display: flex;
                    align-items: center;
                }
                
                .prize-badge {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    font-size: 11px;
                    color: #666;
                }
                
                .prize-badge i {
                    color: #3498db;
                }
                
                .join-event-btn {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    background: linear-gradient(135deg, #3498db, #2980b9);
                    color: white;
                    padding: 5px 12px;
                    border-radius: 30px;
                    font-size: 12px;
                    font-weight: 600;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    box-shadow: 0 4px 10px rgba(52, 152, 219, 0.2);
                }
                
                .join-event-btn:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 6px 15px rgba(52, 152, 219, 0.3);
                    color: white;
                }
                
                @media (max-width: 768px) {
                    .meta-item {
                        font-size: 10px;
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default Event;
