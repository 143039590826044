import React from "react";

const Copyright = () => {
  return (
    <div className="col-lg-12">
      <div className="card w-100 border-0 shadow-none p-2 rounded-xxl bg-lightblue2 mb-10 mb-lg-0">
        <div className="row">
          <div className="col-lg-12">
            <p
              className="text-center m-0"
              style={{ fontSize: "14px", marginBottom: "env(safe-area-inset-bottom, 15px)" }} // Ensures margin in mobile with safe area
            >
              © {new Date().getFullYear()} PicVot. Made with ❤️
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
