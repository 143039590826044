import React from "react";

const LocationComponent = ({ location }) => {
  if (!location || (location.city === "Unknown" && location.state === "Unknown" && location.country === "Unknown")) {
    return <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Location Unknown</span>; // Render if location is unknown or undefined
  }

  const { city, state, country } = location;

  // Function to render the location, filtering out "Unknown" values
  const renderLocation = () => {
    const locationParts = [];

    if (city && city !== "Unknown") locationParts.push(city);
    if (state && state !== "Unknown") locationParts.push(state);
    if (country && country !== "Unknown") locationParts.push(country);

    return locationParts.length > 0 ? locationParts.join(", ") : "Location Unknown";
  };

  return  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{renderLocation()}</span>
  
};

export default LocationComponent;
