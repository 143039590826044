import { useState, useRef, useEffect } from "react";
import { QRCodeCanvas } from "qrcode.react";

export default function GenerateQR({ logoUrl }) {
  const [qrValue, setQrValue] = useState("");
  const qrRef = useRef(null);
  const [qrSize, setQrSize] = useState(0);

  useEffect(() => {
    // Automatically generate QR code when the component mounts
    const currentURL = window.location.href;

    // Log the URL to ensure it's being set correctly
    console.log("Current URL:", currentURL);

    // Ensure the URL is fully qualified
    const fullURL = currentURL.includes("http") ? currentURL : `https://${currentURL}`;
    
    // Update the QR value with a timestamp to avoid caching issues
    setQrValue(fullURL + `?${Date.now()}`); // Adding a timestamp to prevent caching issues

    // Update the QR size based on the container width
    if (qrRef.current) {
      setQrSize(qrRef.current.offsetWidth);
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen gap-4 p-4">
      <div ref={qrRef} style={{ width: '100%', maxWidth: '400px' }}>
        {qrValue && (
          <QRCodeCanvas
            value={qrValue}
            size={qrSize} // Dynamically set the size based on the container width
            includeMargin={true}
            imageSettings={{
              src: logoUrl, // Dynamically passing the logo URL
              height: 40,
              width: 40,
              excavate: true,
              // Apply border-radius to make the logo circular
              style: {
                borderRadius: '50%', // Circular logo
              }
            }}
          />
        )}
      </div>
    </div>
  );
}
