import React, { useState, Fragment } from "react";
import axios from "axios";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import SEO from "../components/Seo";
import Copyright from "../components/Copyright";

const Advertisewithus = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');

    try {
      // Simulate form submission - replace with actual API endpoint when available
      await new Promise(resolve => setTimeout(resolve, 1000));
      setMessage("Your inquiry has been received! Our team will contact you soon.");
      setMessageType('success');
      setFormData({
        name: "",
        email: "",
        company: "",
        message: ""
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessage("There was an error submitting your inquiry. Please try again.");
      setMessageType('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <SEO
        title="Advertise with Us - Promote Your Brand on tapcoe"
        description="Reach a highly engaged audience by advertising with tapcoe. Partner with us to showcase your brand, sponsor events, and connect with active participants."
        keywords="advertise with tapcoe, brand promotion, sponsor events, advertising platform, reach audience, marketing opportunities"
        url="https://tapcoe.com/advertisewithus"
        image="https://tapcoe.com/assets/advertise-og-image.webp"
      />
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            {/* Hero Section */}
            <div className="advertise-hero position-relative overflow-hidden rounded-xxl mb-4"
              style={{
                background: "linear-gradient(135deg, rgba(24, 119, 242, 0.95), rgba(66, 133, 244, 0.95))",
                padding: "60px 0"
              }}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 text-lg-start text-center">
                    <div className="hero-content p-4">
                      <h1 className="fw-bold text-white mb-3" style={{ fontSize: "42px" }}>Advertise with tapcoe</h1>
                      <p className="text-white opacity-90 mb-4" style={{ fontSize: "18px", lineHeight: "1.6" }}>
                        Expand your reach with our highly engaged community of event participants and organizers
                      </p>
                      <a href="#contact-form" className="btn border-0 bg-white px-4 py-3 text-primary fw-600 rounded-lg d-inline-block">
                        Get Started
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 d-none d-lg-block text-center">
                    <img 
                      src="/assets/images/advertise-hero.png" 
                      alt="Advertise with Us" 
                      className="img-fluid" 
                      style={{
                        maxHeight: "320px",
                        filter: "drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15))"
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Decorative elements */}
              <div className="position-absolute" style={{ top: "15%", left: "10%", width: "150px", height: "150px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
              <div className="position-absolute" style={{ bottom: "10%", right: "15%", width: "120px", height: "120px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
              <div className="position-absolute" style={{ top: "30%", right: "20%", width: "30px", height: "30px", background: "rgba(255,255,255,0.2)", borderRadius: "50%" }}></div>
            </div>

            {/* Why Advertise With Us Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="section-heading mb-4">
                      <div className="decoration-line mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #1877f2, #4285f4)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-3" style={{ fontSize: "28px" }}>Why Advertise With Us</h2>
                      <p className="font-xsss fw-500 text-grey-500 lh-24">
                        If you are looking to reach a wider audience and promote your products or services to a community of engaged and active individuals, our online voting-based event hosting website is the perfect platform for you. We offer a range of advertising options to help you reach your target audience and drive meaningful engagement and conversions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Benefits Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <div className="section-heading text-center">
                      <div className="decoration-line mx-auto mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #1877f2, #4285f4)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Benefits of Advertising with Us</h2>
                      <p className="font-xsss fw-500 text-grey-500 mx-auto" style={{ maxWidth: "700px" }}>
                        Partner with tapcoe and experience these powerful advantages for your brand
                      </p>
                    </div>
                  </div>

                  {[
                    {
                      title: "Targeted Audience",
                      description: "Reach a diverse community of engaged individuals interested in events, competitions, and social participation.",
                      icon: "target"
                    },
                    {
                      title: "Increased Engagement",
                      description: "Drive conversions with interactive features like live polls, surveys, and voting opportunities.",
                      icon: "trending-up"
                    },
                    {
                      title: "Real-time Analytics",
                      description: "Access detailed performance metrics to track campaign effectiveness and ROI.",
                      icon: "bar-chart-2"
                    },
                    {
                      title: "Customized Campaigns",
                      description: "Tailor your advertising strategy with various format options to match your brand and goals.",
                      icon: "sliders"
                    }
                  ].map((benefit, index) => (
                    <div key={index} className="col-md-6 col-lg-3 mb-4">
                      <div className="benefit-card p-4 h-100 text-center rounded-lg" 
                        style={{ 
                          background: 'linear-gradient(135deg, rgba(24, 119, 242, 0.03) 0%, rgba(66, 133, 244, 0.06) 100%)',
                          border: '1px solid rgba(24, 119, 242, 0.1)',
                          transition: 'all 0.3s ease'
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = 'translateY(-5px)';
                          e.currentTarget.style.boxShadow = '0 10px 30px rgba(0, 0, 0, 0.08)';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = 'translateY(0)';
                          e.currentTarget.style.boxShadow = 'none';
                        }}
                      >
                        <div className="icon-wrapper mb-3 d-flex align-items-center justify-content-center rounded-circle mx-auto" 
                          style={{
                            width: "70px",
                            height: "70px",
                            background: "linear-gradient(135deg, rgba(24, 119, 242, 0.1) 0%, rgba(66, 133, 244, 0.2) 100%)"
                          }}
                        >
                          <i className={`feather-${benefit.icon}`} style={{ fontSize: "28px", color: "#1877f2" }}></i>
                        </div>
                        <h4 className="fw-700 text-grey-900 font-xs mb-2">{benefit.title}</h4>
                        <p className="font-xsss fw-500 text-grey-500 mb-0 lh-24">
                          {benefit.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Advertising Options Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <div className="section-heading text-center">
                      <div className="decoration-line mx-auto mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #1877f2, #4285f4)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Advertising Options</h2>
                      <p className="font-xsss fw-500 text-grey-500 mx-auto" style={{ maxWidth: "700px" }}>
                        Choose from our flexible range of advertising solutions to meet your marketing goals
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-10 mx-auto">
                    <div className="row">
                      {[
                        {
                          title: "Banner & Text Ads",
                          description: "Your company's banner/text ads will be prominently displayed on our website. These ads can be customized to suit your specific requirements, allowing users to easily explore and learn more about your products and services.",
                          icon: "layout",
                          imageUrl: "/assets/images/ad-banner-sample.png"
                        },
                        {
                          title: "Event Sponsorship",
                          description: "We offer a unique opportunity to efficiently advertise your products through event sponsorship. Our platform hosts events in various formats and categories, allowing you to promote your brand in a way that best aligns with your target audience.",
                          icon: "award",
                          imageUrl: "/assets/images/ad-sponsor-sample.png"
                        },
                        {
                          title: "Product Campaigns",
                          description: "Create dedicated product campaigns to expand your customer base. We'll help you design and implement effective marketing strategies that showcase your products to our engaged community of users.",
                          icon: "package",
                          imageUrl: "/assets/images/ad-product-sample.png"
                        },
                        {
                          title: "On-site Advertising",
                          description: "Promote your brand directly at event locations. Whether it's college festivals, organizational programs, or other events, we offer opportunities for posters, banners, coupon distribution, and product sampling.",
                          icon: "map-pin",
                          imageUrl: "/assets/images/ad-onsite-sample.png"
                        }
                      ].map((option, index) => (
                        <div key={index} className="col-md-6 mb-4">
                          <div className="ad-option-card p-0 rounded-lg overflow-hidden" 
                            style={{ 
                              border: '1px solid rgba(24, 119, 242, 0.1)',
                              transition: 'all 0.3s ease',
                              background: '#fff'
                            }}
                            onMouseOver={(e) => {
                              e.currentTarget.style.transform = 'translateY(-5px)';
                              e.currentTarget.style.boxShadow = '0 10px 30px rgba(0, 0, 0, 0.08)';
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.transform = 'translateY(0)';
                              e.currentTarget.style.boxShadow = 'none';
                            }}
                          >
                            <div className="img-preview-wrap" style={{ height: "160px", overflow: "hidden", position: "relative" }}>
                              <div className="icon-badge" style={{ 
                                position: "absolute", 
                                top: "15px", 
                                left: "15px", 
                                width: "40px", 
                                height: "40px", 
                                background: "linear-gradient(135deg, #1877f2, #4285f4)",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                zIndex: "2"
                              }}>
                                <i className={`feather-${option.icon}`} style={{ fontSize: "20px", color: "#fff" }}></i>
                              </div>
                              <div style={{ 
                                width: "100%", 
                                height: "100%", 
                                backgroundColor: "#e9f0ff", 
                                backgroundImage: option.imageUrl ? `url(${option.imageUrl})` : "none",
                                backgroundSize: "cover",
                                backgroundPosition: "center"
                              }}></div>
                            </div>
                            <div className="p-3">
                              <h4 className="fw-700 text-grey-900 font-xs mb-2">{option.title}</h4>
                              <p className="font-xsss fw-500 text-grey-500 mb-0 lh-24">
                                {option.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Testimonial Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4 bg-pattern" style={{ 
              background: "linear-gradient(135deg, rgba(24, 119, 242, 0.03) 0%, rgba(66, 133, 244, 0.07) 100%)",
              backgroundImage: "url('/assets/images/dot-pattern.png')",
              backgroundSize: "300px",
              backgroundPosition: "center"
            }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <div className="section-heading text-center">
                      <div className="decoration-line mx-auto mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #1877f2, #4285f4)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>What Advertisers Say</h2>
                    </div>
                  </div>

                  <div className="col-lg-8 mx-auto">
                    <div className="testimonial-card p-5 rounded-xxl text-center" style={{ 
                      background: "white", 
                      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.05)",
                      position: "relative"
                    }}>
                      <div className="quote-icon" style={{ 
                        position: "absolute", 
                        top: "-20px", 
                        left: "50%", 
                        transform: "translateX(-50%)",
                        fontSize: "40px", 
                        color: "#1877f2" 
                      }}>
                        <i className="feather-message-circle"></i>
                      </div>
                      <p className="font-md fw-500 text-grey-700 lh-28 mb-4" style={{ fontSize: "17px" }}>
                        "Advertising with tapcoe has been a game-changer for our brand. We've seen a significant increase in engagement and conversions since partnering with them. Their team is professional, responsive, and truly committed to helping us achieve our marketing goals."
                      </p>
                      <div className="author-info">
                        <div className="author-image mx-auto mb-2" style={{ 
                          width: "60px", 
                          height: "60px", 
                          borderRadius: "50%", 
                          overflow: "hidden",
                          border: "3px solid #e9f0ff"
                        }}>
                          <img src="/assets/images/testimonial-author.png" alt="Testimonial Author" className="w-100 h-100 object-cover" />
                        </div>
                        <h5 className="fw-700 text-grey-900 mb-1">Sarah Johnson</h5>
                        <p className="font-xsss fw-500 text-grey-500 mb-0">Marketing Director, TechLaunch Inc.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Form Section */}
            <div id="contact-form" className="card border-0 rounded-xxl shadow-xss mb-4">
              <div className="card-body p-0 overflow-hidden">
                <div className="row g-0">
                  <div className="col-lg-6 p-5">
                    <div className="form-section-heading mb-4">
                      <div className="decoration-line mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #1877f2, #4285f4)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Get in Touch</h2>
                      <p className="font-xsss fw-500 text-grey-500">
                        Fill out this form to learn more about our advertising options and pricing
                      </p>
                    </div>

                    {message && (
                      <div className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'} mb-4`}>
                        {message}
                      </div>
                    )}

                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label fw-500 text-grey-900">
                          Full Name <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-user" style={{ color: "#1877f2" }}></i>
                          </span>
                          <input
                            type="text"
                            className="form-control border-start-0 ps-0"
                            id="name"
                            placeholder="Enter your name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="email" className="form-label fw-500 text-grey-900">
                          Email <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-mail" style={{ color: "#1877f2" }}></i>
                          </span>
                          <input
                            type="email"
                            className="form-control border-start-0 ps-0"
                            id="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="company" className="form-label fw-500 text-grey-900">
                          Company <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-briefcase" style={{ color: "#1877f2" }}></i>
                          </span>
                          <input
                            type="text"
                            className="form-control border-start-0 ps-0"
                            id="company"
                            placeholder="Enter your company name"
                            value={formData.company}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          />
                        </div>
                      </div>

                      <div className="mb-4">
                        <label htmlFor="message" className="form-label fw-500 text-grey-900">
                          Message <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0 align-items-start pt-2" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-message-square" style={{ color: "#1877f2" }}></i>
                          </span>
                          <textarea
                            className="form-control border-start-0 ps-0"
                            id="message"
                            rows="4"
                            placeholder="Tell us about your advertising needs"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          ></textarea>
                        </div>
                      </div>

                      <button 
                        type="submit" 
                        className="btn border-0 w-100 p-3 rounded-lg d-inline-block"
                        disabled={isSubmitting}
                        style={{
                          background: "linear-gradient(90deg, #1877f2, #4285f4)",
                          color: "white",
                          fontWeight: "600",
                          fontSize: "16px"
                        }}
                      >
                        {isSubmitting ? (
                          <span>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Submitting...
                          </span>
                        ) : 'Submit Inquiry'}
                      </button>
                    </form>
                  </div>

                  <div className="col-lg-6 d-none d-lg-block p-0">
                    <div className="feature-image h-100" style={{ 
                      background: "linear-gradient(135deg, #1877f2, #4285f4)", 
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      padding: "40px"
                    }}>
                      <img 
                        src="/assets/images/advertise-contact.png" 
                        alt="Advertise with Us" 
                        className="img-fluid mb-4" 
                        style={{ 
                          maxHeight: "280px",
                          filter: "drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15))"
                        }}
                      />
                      <h3 className="fw-700 text-white mb-3" style={{ fontSize: "22px" }}>Why Choose tapcoe?</h3>
                      <p className="text-white opacity-90 text-center mb-4" style={{ maxWidth: "400px", fontSize: "15px" }}>
                        Our platform connects you with a highly engaged community ready to participate in events, contests, and meaningful interactions.
                      </p>
                      <div className="stats-container d-flex justify-content-between w-100 mb-3">
                        {[
                          { number: "50K+", label: "Monthly Users" },
                          { number: "300+", label: "Events Hosted" },
                          { number: "85%", label: "Engagement Rate" }
                        ].map((stat, index) => (
                          <div key={index} className="stat-item text-center">
                            <h4 className="fw-700 text-white mb-1" style={{ fontSize: "28px" }}>{stat.number}</h4>
                            <p className="text-white opacity-80 mb-0 font-xsss">{stat.label}</p>
                          </div>
                        ))}
                      </div>
                      <div className="features-list mt-3">
                        {[
                          "Targeted audience reach",
                          "Customizable ad formats",
                          "Performance analytics",
                          "Dedicated account support",
                          "Flexible pricing options"
                        ].map((feature, index) => (
                          <div key={index} className="d-flex align-items-center mb-2">
                            <div className="icon-check d-flex align-items-center justify-content-center rounded-circle me-2"
                              style={{
                                width: "22px",
                                height: "22px",
                                backgroundColor: "white"
                              }}
                            >
                              <i className="feather-check" style={{ fontSize: "14px", color: "#1877f2" }}></i>
                            </div>
                            <p className="text-white mb-0">{feature}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <Copyright />
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default Advertisewithus;
