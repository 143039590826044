import { createContext, useContext, useState, useEffect } from 'react';
import { login as loginApi, register as registerApi, verifyOTP, forgotPassword } from '../api/authApi'; // Import the APIs

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('isLoggedIn') === 'true'
  );
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  // Login function
  const login = async (credentials) => {
    try {
      const response = await loginApi(credentials); // Call the login API
      const { token, user } = response;

      console.log('Login successful:', response);
      setIsLoggedIn(true);
      setUser(user);

      // Store token in localStorage
      localStorage.setItem('token', token);
      window.location.href = "/";
    } catch (error) {
      
      console.error('Login failed:', error);
      throw error; 
    }
  };

  // Registration function
  const register = async (data) => {
    try {
      const response = await registerApi(data); // Call the registration API
      console.log('Registration successful:', response);
      return response; // Handle response accordingly
    } catch (error) {
      console.error('Registration failed:', error);
      throw error; // Throw the error to propagate it to the calling function
    }
  };
  

  // Send OTP function
  // const requestOTP = async (data) => {
  //   try {
  //     const response = await sendOTP(data); // Call send OTP API
  //     console.log('OTP sent:', response);
  //     return response;
  //   } catch (error) {
  //     console.error('Send OTP failed:', error);
  //   }
  // };

  // Verify OTP function
  const verifyUserOTP = async (data) => {
    try {
      const response = await verifyOTP(data); // Call verify OTP API
      console.log('OTP verified:', response);
      return response;
    } catch (error) {
      console.error('Verify OTP failed:', error);
      throw error; 
    }
  };

  // Forgot Password function
  const forgotUserPassword = async (data) => {
    try {
      const response = await forgotPassword(data); // Call forgot password API
      console.log('Forgot Password successful:', response);
      return response;
    } catch (error) {
      console.error('Forgot password failed:', error);
      throw error; 
    }
  };

  // Logout function
  const logout = () => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        login,
        register,
        
        verifyUserOTP,
        forgotUserPassword,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for using auth
export const useAuth = () => {
  return useContext(AuthContext);
};
