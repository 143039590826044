import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { AuthContext } from "../context/AuthContext";
import { fetchSocialLinks } from "../api/socialLink"; // Import API functions
import { Link } from "react-router-dom"; // Import Link

const ProfilecardThree = ({ supporter, about }) => {
    const { user } = useContext(AuthContext);
    const { name, email, coverPic, profilePic } = user || {}; // Destructure user
    const [socialLinks, setSocialLinks] = useState({
        facebook: "",
        instagram: "",
        youtube: "",
        snapchat: "",
    });

    useEffect(() => {
        loadSocialLinks();
    }, []);

    const loadSocialLinks = async () => {
        try {
            const data = await fetchSocialLinks(); // Use API function
            setSocialLinks(data);
        } catch (error) {
            console.error("Failed to load social links");
        }
    };

    return (
        <>
            <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-1">
                    <img
                        src={coverPic || "https://via.placeholder.com/1200x250.png"}
                        alt="cover"
                        style={{
                            width: "100%",
                            height: "auto",
                            maxWidth: "1200px",
                            aspectRatio: "1200 / 250",
                            objectFit: "cover",
                        }}
                    />
                </div>

                <div className="card-body p-3 d-flex flex-column flex-sm-row align-items-center">
                    <figure className="avatar w100 me-3">
                        <img
                            src={profilePic || "/assets/images/default-profile.webp"}
                            alt={name || "User"}
                            className="mt--1"
                            style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: "50%",
                                objectFit: "cover",
                            }}
                        />
                    </figure>
                    <div className="w-100">
                        <h4 className="fw-700 font-sm">
                            {name || "User"}
                            <span className="d-block fw-500 font-xssss text-grey-500">
                                {email || ""}
                            </span>
                        </h4>

                        <div className="d-flex align-items-center justify-content-start">
                            <span className="bg-success p-2 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">
                                {supporter} Supporters
                            </span>

                            <div className="ms-auto d-flex">
                                {socialLinks.instagram && (
                                    <a
                                        href={socialLinks.instagram}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="me-3 text-decoration-none"
                                    >
                                        <i className="feather-instagram"></i>
                                    </a>
                                )}
                                {socialLinks.snapchat && (
                                    <a
                                        href={socialLinks.snapchat}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="me-3 text-decoration-none"
                                    >
                                        <i className="feather-snapchat"></i>
                                    </a>
                                )}
                                {socialLinks.youtube && (
                                    <a
                                        href={socialLinks.youtube}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="me-3 text-decoration-none"
                                    >
                                        <i className="feather-youtube"></i>
                                    </a>
                                )}
                                {socialLinks.facebook && (
                                    <a
                                        href={socialLinks.facebook}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-decoration-none"
                                    >
                                        <i className="feather-facebook"></i>
                                    </a>
                                )}
                            </div>
                        </div>

                        {/* Update Profile Link */}
                        <Link to="/accountsettings" className="nav-content-bttn open-font mt-3 d-flex align-items-center">
                            <i className="font-xl text-current feather-settings me-3"></i>
                            <span>Update Profile</span>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Small About Section */}
            <div className="card w-100 mt-4 border-0 p-3 bg-white shadow-xss rounded-xxl">
                <h5 className="fw-700 font-xss mb-3">About {name || "User"}</h5>
                <p className="font-xssss text-grey-600">{about}</p>
            </div>
        </>
    );
};

// Prop Types for validation
ProfilecardThree.propTypes = {
    supporter: PropTypes.number.isRequired,
};

export default ProfilecardThree;
