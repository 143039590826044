import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const CountdownSection = () => {
    const [counts, setCounts] = useState({
        totalVotes: 0,
        totalCustomers: 0,
        totalEvents: 0,
        totalJoinedEvents: 0,
    });

    // Hook to manage the inView state for each card
    const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.5 });
    const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.5 });
    const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.5 });
    const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.5 });

    useEffect(() => {
        // Fetch data from the API
        const fetchCounts = async () => {
            try {
                const response = await fetch('https://api.picvot.com/api/v1/home');
                const data = await response.json();
                setCounts({
                    totalVotes: data.totalVotes,
                    totalCustomers: data.totalCustomers,
                    totalEvents: data.totalEvents,
                    totalJoinedEvents: data.totalJoinedEvents,
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchCounts();
    }, []);

    return (
        <div className="container font-sm text-center text-white p-4 rounded">
            <h2 className="font-xss fw-600 d-inline-block p-1">Story So Far</h2>
            <div className="row">
                {/* Total Votes */}
                <div className="col-lg-3 pe-2 ps-2" ref={ref1}>
                    <div
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#e5f6ff` }}
                    >
                        <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-primary-gradiant feather-thumbs-up font-md text-white"></i>
                            <h4 className="text-primary font-xl fw-700">
                                {inView1 && (
                                    <CountUp end={counts.totalVotes} duration={10} separator="," />
                                )}
                                <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                    Total Votes
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>

                {/* Total Users */}
                <div className="col-lg-3 pe-2 ps-2" ref={ref2}>
                    <div
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#f6f3ff` }}
                    >
                        <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-users font-md text-white"></i>
                            <h4 className="text-secondary font-xl fw-700">
                                {inView2 && (
                                    <CountUp end={counts.totalCustomers} duration={10} separator="," />
                                )}
                                <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                    Total Users
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>

                {/* Total Events */}
                <div className="col-lg-3 pe-2 ps-2" ref={ref3}>
                    <div
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#e2f6e9` }}
                    >
                        <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-success feather-calendar font-md text-white"></i>
                            <h4 className="text-success font-xl fw-700">
                                {inView3 && (
                                    <CountUp end={counts.totalEvents} duration={10} />
                                )}
                                <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                    Total Events
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>

                {/* Total Joined Events */}
                <div className="col-lg-3 pe-2 ps-2" ref={ref4}>
                    <div
                        className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
                        style={{ background: `#fff0e9` }}
                    >
                        <div className="card-body d-flex p-0">
                            <i className="btn-round-lg d-inline-block me-3 bg-warning feather-upload font-md text-white"></i>
                            <h4 className="text-warning font-xl fw-700">
                                {inView4 && (
                                    <CountUp end={counts.totalJoinedEvents} duration={10} separator="," />
                                )}
                                <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                                    Total Uploads
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountdownSection;
