import React, { useState, Fragment } from "react";
import axios from "axios";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";

const Campusambassador = () => {
  const [formData, setFormData] = useState({
    email: "",
    contactNumber: "",
    details: ""
  });
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');

    try {
      const response = await axios.post("https://api.tapcoe.com/api/v1/campusambasdor", formData);
      setMessage("Application submitted successfully! We'll contact you soon.");
      setMessageType('success');
      setFormData({
        email: "",
        contactNumber: "",
        details: ""
      });
    } catch (error) {
      console.error('Error submitting application:', error);
      setMessage(error.response?.data?.message || "Submission failed. Please try again.");
      setMessageType('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <SEO
        title="Become a Campus Ambassador - Represent tapcoe on Your Campus"
        description="Join the tapcoe Campus Ambassador Program to represent us on your campus, promote exciting events, and develop leadership skills. Earn rewards, build networks, and grow with us!"
        keywords="campus ambassador program, tapcoe ambassador, student leadership, event promotion, networking, earn rewards, tapcoe opportunities"
        url="https://tapcoe.com/campusambassador"
        image="https://tapcoe.com/assets/campus-ambassador-og-image.webp"
      />
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            {/* Hero Section */}
            <div className="campus-ambassador-hero position-relative overflow-hidden rounded-xxl mb-4"
              style={{
                background: "linear-gradient(135deg, rgba(66, 133, 244, 0.95), rgba(120, 85, 245, 0.95))",
                padding: "60px 0"
              }}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 text-lg-start text-center">
                    <div className="hero-content p-4">
                      <h1 className="fw-bold text-white mb-3" style={{ fontSize: "42px" }}>Become a Campus Ambassador</h1>
                      <p className="text-white opacity-90 mb-4" style={{ fontSize: "18px", lineHeight: "1.6" }}>
                        Represent tapcoe on your campus, build leadership skills, and earn exclusive rewards
                      </p>
                      <a href="#application-form" className="btn border-0 bg-white px-4 py-3 text-primary fw-600 rounded-lg d-inline-block">
                        Apply Now
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 d-none d-lg-block text-center">
                    <img 
                      src="/assets/images/campus-ambassador.png" 
                      alt="Campus Ambassador" 
                      className="img-fluid" 
                      style={{
                        maxHeight: "320px",
                        filter: "drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15))"
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Decorative elements */}
              <div className="position-absolute" style={{ top: "15%", left: "10%", width: "150px", height: "150px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
              <div className="position-absolute" style={{ bottom: "10%", right: "15%", width: "120px", height: "120px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
              <div className="position-absolute" style={{ top: "30%", right: "20%", width: "30px", height: "30px", background: "rgba(255,255,255,0.2)", borderRadius: "50%" }}></div>
            </div>

            {/* About the Program Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="section-heading mb-4">
                      <div className="decoration-line mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-3" style={{ fontSize: "28px" }}>About the Program</h2>
                      <p className="font-xsss fw-500 text-grey-500 lh-24">
                        We are excited to offer a campus ambassador program for students who are passionate about creating positive change and promoting participatory events in their communities. Our campus ambassadors play a crucial role in spreading the word about our online voting-based event hosting website and empowering their peers to participate in meaningful conversations and decisions.
                      </p>
                      <p className="font-xsss fw-500 text-grey-500 lh-24 mb-0">
                        As a campus ambassador, you will receive exclusive access to our platform, training and support from our team, and a range of resources to help you promote our platform and host successful events on your campus. You will also have the opportunity to connect with other like-minded students from around the world and build your network and leadership skills.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Benefits Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <div className="section-heading text-center">
                      <div className="decoration-line mx-auto mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Benefits of Being a Campus Ambassador</h2>
                      <p className="font-xsss fw-500 text-grey-500 mx-auto" style={{ maxWidth: "700px" }}>
                        Join our program and enjoy these exclusive benefits while representing tapcoe
                      </p>
                    </div>
                  </div>

                  {[
                    {
                      title: "Leadership Experience",
                      description: "Develop valuable leadership, communication, and event planning skills that will boost your resume.",
                      icon: "award"
                    },
                    {
                      title: "Make a Difference",
                      description: "Empower your peers to have their voices heard and create positive change in your community.",
                      icon: "zap"
                    },
                    {
                      title: "Build Your Network",
                      description: "Connect with like-minded students and industry professionals to expand your professional network.",
                      icon: "users"
                    },
                    {
                      title: "Earn Rewards",
                      description: "Receive exclusive merchandise, event access, and potential scholarship opportunities.",
                      icon: "gift"
                    }
                  ].map((benefit, index) => (
                    <div key={index} className="col-md-6 col-lg-3 mb-4">
                      <div className="benefit-card p-4 h-100 text-center rounded-lg" 
                        style={{ 
                          background: 'linear-gradient(135deg, rgba(120, 85, 245, 0.03) 0%, rgba(66, 133, 244, 0.06) 100%)',
                          border: '1px solid rgba(120, 85, 245, 0.1)',
                          transition: 'all 0.3s ease'
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = 'translateY(-5px)';
                          e.currentTarget.style.boxShadow = '0 10px 30px rgba(0, 0, 0, 0.08)';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = 'translateY(0)';
                          e.currentTarget.style.boxShadow = 'none';
                        }}
                      >
                        <div className="icon-wrapper mb-3 d-flex align-items-center justify-content-center rounded-circle mx-auto" 
                          style={{
                            width: "70px",
                            height: "70px",
                            background: "linear-gradient(135deg, rgba(120, 85, 245, 0.1) 0%, rgba(66, 133, 244, 0.2) 100%)"
                          }}
                        >
                          <i className={`feather-${benefit.icon}`} style={{ fontSize: "28px", color: "#4285f4" }}></i>
                        </div>
                        <h4 className="fw-700 text-grey-900 font-xs mb-2">{benefit.title}</h4>
                        <p className="font-xsss fw-500 text-grey-500 mb-0 lh-24">
                          {benefit.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Responsibilities Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <div className="section-heading text-center">
                      <div className="decoration-line mx-auto mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Your Responsibilities</h2>
                      <p className="font-xsss fw-500 text-grey-500 mx-auto" style={{ maxWidth: "700px" }}>
                        What you'll be doing as a tapcoe Campus Ambassador
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-10 mx-auto">
                    <div className="row">
                      {[
                        {
                          title: "Promote Events",
                          description: "Spread the word about tapcoe events on your campus through social media, posters, and word-of-mouth.",
                          icon: "share-2"
                        },
                        {
                          title: "Host Campus Events",
                          description: "Organize and facilitate tapcoe events at your college or university with our support.",
                          icon: "calendar"
                        },
                        {
                          title: "Provide Feedback",
                          description: "Share insights and suggestions to help us improve our platform and better serve your campus community.",
                          icon: "message-circle"
                        },
                        {
                          title: "Build Community",
                          description: "Create and nurture a community of engaged students who participate in tapcoe events and contests.",
                          icon: "home"
                        }
                      ].map((responsibility, index) => (
                        <div key={index} className="col-md-6 mb-4">
                          <div className="d-flex p-4 rounded-lg" style={{ 
                            background: 'linear-gradient(135deg, rgba(120, 85, 245, 0.02) 0%, rgba(66, 133, 244, 0.05) 100%)',
                            border: '1px solid rgba(120, 85, 245, 0.08)'
                          }}>
                            <div className="icon-wrapper me-4 d-flex align-items-start justify-content-center rounded-circle" 
                              style={{
                                minWidth: "50px",
                                height: "50px",
                                background: "linear-gradient(135deg, rgba(120, 85, 245, 0.1) 0%, rgba(66, 133, 244, 0.2) 100%)"
                              }}
                            >
                              <i className={`feather-${responsibility.icon}`} style={{ fontSize: "22px", color: "#4285f4", marginTop: "14px" }}></i>
                            </div>
                            <div>
                              <h4 className="fw-700 text-grey-900 font-xs mb-1">{responsibility.title}</h4>
                              <p className="font-xsss fw-500 text-grey-500 mb-0 lh-24">
                                {responsibility.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Application Form Section */}
            <div id="application-form" className="card border-0 rounded-xxl shadow-xss mb-4">
              <div className="card-body p-0 overflow-hidden">
                <div className="row g-0">
                  <div className="col-lg-6 p-5">
                    <div className="form-section-heading mb-4">
                      <div className="decoration-line mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Apply Now</h2>
                      <p className="font-xsss fw-500 text-grey-500">
                        Fill out this form to apply for the Campus Ambassador Program
                      </p>
                    </div>

                    {message && (
                      <div className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'} mb-4`}>
                        {message}
                      </div>
                    )}

                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label fw-500 text-grey-900">
                          Email <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-mail" style={{ color: "#4285f4" }}></i>
                          </span>
                          <input
                            type="email"
                            className="form-control border-start-0 ps-0"
                            id="email"
                            placeholder="Enter your college email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="contactNumber" className="form-label fw-500 text-grey-900">
                          Contact Number <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-phone" style={{ color: "#4285f4" }}></i>
                          </span>
                          <input
                            type="text"
                            className="form-control border-start-0 ps-0"
                            id="contactNumber"
                            placeholder="Enter your contact number"
                            value={formData.contactNumber}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          />
                        </div>
                      </div>

                      <div className="mb-4">
                        <label htmlFor="details" className="form-label fw-500 text-grey-900">
                          Why do you want to become a campus ambassador? <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0 align-items-start pt-2" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-file-text" style={{ color: "#4285f4" }}></i>
                          </span>
                          <textarea
                            className="form-control border-start-0 ps-0"
                            id="details"
                            rows="4"
                            placeholder="Tell us about yourself, your campus involvement, and why you'd be a great ambassador"
                            value={formData.details}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          ></textarea>
                        </div>
                        <small className="text-muted">Share your relevant experience, skills, and what makes you passionate about this role.</small>
                      </div>

                      <button 
                        type="submit" 
                        className="btn border-0 w-100 p-3 rounded-lg d-inline-block"
                        disabled={isSubmitting}
                        style={{
                          background: "linear-gradient(90deg, #4285f4, #7855f5)",
                          color: "white",
                          fontWeight: "600",
                          fontSize: "16px"
                        }}
                      >
                        {isSubmitting ? (
                          <span>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Submitting...
                          </span>
                        ) : 'Submit Application'}
                      </button>
                    </form>
                  </div>

                  <div className="col-lg-6 d-none d-lg-block p-0">
                    <div className="feature-image h-100" style={{ 
                      background: "linear-gradient(135deg, rgba(66, 133, 244, 0.9), rgba(120, 85, 245, 0.9))", 
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      padding: "40px"
                    }}>
                      <img 
                        src="/assets/images/campus-ambassador-side.png" 
                        alt="Campus Ambassador Features" 
                        className="img-fluid mb-4" 
                        style={{ 
                          maxHeight: "280px",
                          filter: "drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15))"
                        }}
                      />
                      <h3 className="fw-700 text-white mb-3" style={{ fontSize: "22px" }}>What We're Looking For</h3>
                      <p className="text-white opacity-90 text-center mb-4" style={{ maxWidth: "400px", fontSize: "15px" }}>
                        We're seeking passionate, motivated students who are excited about creating positive change in their campus communities.
                      </p>
                      <div className="features-list">
                        {[
                          "Strong communication skills",
                          "Active on campus and social media",
                          "Passionate about events and contests",
                          "Creative and innovative thinker",
                          "Ability to work independently"
                        ].map((feature, index) => (
                          <div key={index} className="d-flex align-items-center mb-2">
                            <div className="icon-check d-flex align-items-center justify-content-center rounded-circle me-2"
                              style={{
                                width: "22px",
                                height: "22px",
                                backgroundColor: "white"
                              }}
                            >
                              <i className="feather-check" style={{ fontSize: "14px", color: "#4285f4" }}></i>
                            </div>
                            <p className="text-white mb-0">{feature}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* FAQ Section */}
            <div className="card border-0 rounded-xxl shadow-xss p-4 mb-4">
              <div className="card-body">
                <div className="section-heading text-center mb-4">
                  <div className="decoration-line mx-auto mb-3" style={{
                    width: '60px',
                    height: '4px',
                    background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                    borderRadius: '4px'
                  }}></div>
                  <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Frequently Asked Questions</h2>
                </div>

                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <div className="accordion" id="campusAmbassadorFaq">
                      {[
                        {
                          question: "Who can become a Campus Ambassador?",
                          answer: "Any currently enrolled college or university student with a passion for events, community building, and marketing can apply. We're looking for students who are active on campus and have a strong network of peers."
                        },
                        {
                          question: "How much time commitment is required?",
                          answer: "The program is designed to be flexible with your academic schedule. We expect ambassadors to dedicate 3-5 hours per week to program activities, with potential for more during specific event periods."
                        },
                        {
                          question: "What support will I receive as an ambassador?",
                          answer: "You'll receive comprehensive training, marketing materials, regular guidance from our team, and access to a community of fellow ambassadors. We provide all the resources you need to be successful."
                        },
                        {
                          question: "How long does the program last?",
                          answer: "The Campus Ambassador program typically runs for one academic year (two semesters). High-performing ambassadors may be invited to continue for additional terms and take on leadership roles."
                        },
                        {
                          question: "What kind of rewards can I earn?",
                          answer: "Ambassadors can earn various rewards including tapcoe merchandise, exclusive event access, professional development opportunities, recommendation letters, and performance-based incentives or scholarships."
                        }
                      ].map((faq, index) => (
                        <div key={index} className="accordion-item border-0 mb-3 rounded-lg overflow-hidden" style={{ background: 'linear-gradient(135deg, rgba(120, 85, 245, 0.02) 0%, rgba(66, 133, 244, 0.04) 100%)' }}>
                          <h2 className="accordion-header" id={`heading${index}`}>
                            <button 
                              className="accordion-button collapsed fw-600" 
                              type="button" 
                              data-bs-toggle="collapse" 
                              data-bs-target={`#collapse${index}`} 
                              aria-expanded="false" 
                              aria-controls={`collapse${index}`}
                              style={{ background: 'transparent', fontSize: '16px' }}
                            >
                              {faq.question}
                            </button>
                          </h2>
                          <div 
                            id={`collapse${index}`} 
                            className="accordion-collapse collapse" 
                            aria-labelledby={`heading${index}`} 
                            data-bs-parent="#campusAmbassadorFaq"
                          >
                            <div className="accordion-body font-xsss fw-500 text-grey-500 lh-26">
                              {faq.answer}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <Copyright />
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default Campusambassador;
