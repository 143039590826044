import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { withRouter } from 'react-router-dom';
import ChatService from '../../services/ChatService';
import ConversationList from './ConversationList';
import { toast } from 'react-hot-toast';

const Chat = ({ history, location }) => {
    const { user, isLoggedIn } = useAuth();
    const [conversations, setConversations] = useState([]);
    const [messageRequests, setMessageRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('chats');

    useEffect(() => {
        if (!isLoggedIn) {
            history.push('/login', { from: '/test-chat' });
            return;
        }

        const initialize = async () => {
            try {
                await initializeChat();
                await loadConversations();
                await loadMessageRequests();
            } catch (error) {
                console.error('Error initializing chat:', error);
                toast.error('Failed to initialize chat');
            }
        };

        initialize();

        return () => {
            console.log('Disconnecting chat service');
            ChatService.disconnect();
        };
    }, [isLoggedIn, user]);

    const initializeChat = async () => {
        try {
            const token = localStorage.getItem('token');
            const user = JSON.parse(localStorage.getItem('user'));
            
            if (!token || !user) {
                history.push('/login');
                return;
            }

            ChatService.initialize(user.id, token);

            // Add event listeners
            ChatService.addEventListener('receiveChatMessage', handleNewMessage);
            ChatService.addEventListener('messageRequest', handleNewMessageRequest);
            ChatService.addEventListener('userOnline', handleUserOnline);
            ChatService.addEventListener('userOffline', handleUserOffline);

            return () => {
                // Remove event listeners
                ChatService.removeEventListener('receiveChatMessage', handleNewMessage);
                ChatService.removeEventListener('messageRequest', handleNewMessageRequest);
                ChatService.removeEventListener('userOnline', handleUserOnline);
                ChatService.removeEventListener('userOffline', handleUserOffline);
            };
        } catch (error) {
            console.error('Error initializing chat:', error);
            throw error;
        }
    };

    const loadConversations = async () => {
        try {
            const conversations = await ChatService.getConversations();
            const validConversations = conversations.filter(conv => conv.participants.length > 1);
            setConversations(validConversations);
            setLoading(false);
        } catch (error) {
            console.error('Error loading conversations:', error);
            setLoading(false);
            toast.error('Failed to load conversations');
        }
    };

    const loadMessageRequests = async () => {
        try {
            const requests = await ChatService.getMessageRequests();
            setMessageRequests(requests);
        } catch (error) {
            console.error('Error loading message requests:', error);
            toast.error('Failed to load message requests');
        }
    };

    const handleNewMessage = (message) => {
        console.log('Received new message:', message);
        loadConversations();
    };

    const handleNewMessageRequest = (request) => {
        console.log('Received new message request:', request);
        setMessageRequests(prev => [...prev, request]);
    };

    const handleUserOnline = (userId) => {
        console.log('User online:', userId);
        // Update user status in conversations
        setConversations(prev => prev.map(conv => {
            if (conv.participants.some(p => p._id === userId)) {
                return { ...conv, isOnline: true };
            }
            return conv;
        }));
    };

    const handleUserOffline = (userId) => {
        console.log('User offline:', userId);
        // Update user status in conversations
        setConversations(prev => prev.map(conv => {
            if (conv.participants.some(p => p._id === userId)) {
                return { ...conv, isOnline: false };
            }
            return conv;
        }));
    };

    const handleAcceptRequest = async (requestId) => {
        try {
            await ChatService.handleMessageRequest(requestId, 'accepted');
            toast.success('Message request accepted');
            loadConversations();
            loadMessageRequests();
        } catch (error) {
            console.error('Error accepting request:', error);
            toast.error(error.message || 'Failed to accept request');
        }
    };

    const handleBlockRequest = async (requestId) => {
        try {
            await ChatService.blockUser(requestId);
            toast.success('User blocked');
            loadMessageRequests();
        } catch (error) {
            console.error('Error blocking user:', error);
            toast.error(error.message || 'Failed to block user');
        }
    };

    const renderRequestsList = () => {
        if (messageRequests.length === 0) {
            return (
                <div className="empty-state">
                    <p>No pending message requests</p>
                </div>
            );
        }

        return (
            <div className="requests-list">
                {messageRequests.map(request => (
                    <div key={request._id} className="request-item">
                        <div className="request-info">
                            <img 
                                src={request.sender.profilePic || "/assets/images/default-profile.webp"} 
                                alt={request.sender.name}
                                className="request-avatar"
                            />
                            <div className="request-details">
                                <h4>{request.sender.name}</h4>
                                <p>{request.message}</p>
                            </div>
                        </div>
                        <div className="request-actions">
                            <button 
                                onClick={() => handleAcceptRequest(request._id)}
                                className="accept-button"
                            >
                                Accept
                            </button>
                            <button 
                                onClick={() => handleBlockRequest(request._id)}
                                className="block-button"
                            >
                                Block
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="chat-wrapper">
            <div className="chat-container">
                <div className="chat-sidebar">
                    <div className="chat-tabs">
                        <button 
                            className={`tab-button ${activeTab === 'chats' ? 'active' : ''}`}
                            onClick={() => setActiveTab('chats')}
                        >
                            Chats
                        </button>
                        <button 
                            className={`tab-button ${activeTab === 'requests' ? 'active' : ''}`}
                            onClick={() => setActiveTab('requests')}
                        >
                            Requests
                        </button>
                    </div>
                    
                    {activeTab === 'chats' ? (
                        <ConversationList
                            conversations={conversations}
                            loading={loading}
                        />
                    ) : (
                        renderRequestsList()
                    )}
                </div>
            </div>

            <style jsx>{`
                .chat-wrapper {
                    display: flex;
                    flex-direction: column;
                    height: 100vh;
                    max-height: 100vh;
                    background: #fff;
                }

                .chat-container {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    overflow: hidden;
                    position: relative;
                    background: #fff;
                    border-radius: 12px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                }

                .chat-sidebar {
                    width: 100%;
                    max-width: 600px;
                    margin: 0 auto;
                    border-right: 1px solid #eee;
                    display: flex;
                    flex-direction: column;
                }

                .chat-tabs {
                    display: flex;
                    border-bottom: 1px solid #eee;
                    padding: 10px;
                    gap: 10px;
                }

                .tab-button {
                    flex: 1;
                    padding: 8px;
                    border: none;
                    background: none;
                    cursor: pointer;
                    font-size: 14px;
                    color: #666;
                    border-radius: 4px;
                    transition: all 0.2s;
                }

                .tab-button.active {
                    background: #5271FF;
                    color: white;
                }

                .requests-list {
                    flex: 1;
                    overflow-y: auto;
                    padding: 10px;
                }

                .request-item {
                    padding: 15px;
                    border-bottom: 1px solid #eee;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .request-info {
                    display: flex;
                    align-items: center;
                    flex: 1;
                }

                .request-avatar {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 15px;
                    object-fit: cover;
                }

                .request-details {
                    flex: 1;
                }

                .request-details h4 {
                    margin: 0;
                    font-size: 14px;
                    color: #333;
                }

                .request-details p {
                    margin: 4px 0 0;
                    font-size: 12px;
                    color: #666;
                }

                .request-actions {
                    display: flex;
                    gap: 8px;
                }

                .accept-button, .block-button {
                    padding: 6px 12px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 12px;
                    transition: all 0.2s;
                }

                .accept-button {
                    background: #5271FF;
                    color: white;
                }

                .accept-button:hover {
                    background: #3d5af1;
                }

                .block-button {
                    background: #f8f9fa;
                    color: #dc3545;
                }

                .block-button:hover {
                    background: #e9ecef;
                }

                .empty-state {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #666;
                    font-size: 14px;
                }

                @media (max-width: 768px) {
                    .chat-wrapper {
                        height: calc(100vh - 56px);
                    }

                    .chat-container {
                        border-radius: 0;
                        box-shadow: none;
                    }
                }
            `}</style>
        </div>
    );
};

export default withRouter(Chat); 