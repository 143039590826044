import axios from 'axios';

const BASE_URL = "https://api.tapcoe.com/api/v1";
const token = localStorage.getItem('token');

// Get vote count for a specific post from localStorage
const getDevicePostVoteCount = (postId) => {
  const votes = JSON.parse(localStorage.getItem('postVotes') || '{}');
  return votes[postId] || 0;
};

// Increment the vote count for a specific post in localStorage
const incrementDevicePostVoteCount = (postId) => {
  const votes = JSON.parse(localStorage.getItem('postVotes') || '{}');
  votes[postId] = (votes[postId] || 0) + 1;
  localStorage.setItem('postVotes', JSON.stringify(votes));
};
const incrementDevicePostVoteCountHappy = (postId) => {
  const votes = JSON.parse(localStorage.getItem('postVoteshappy') || '{}');
  votes[postId] = (votes[postId] || 0) + 1;
  localStorage.setItem('postVoteshappy', JSON.stringify(votes));
};

export const castVote = async (eventId, postId) => {
  //const currentVotes = getDevicePostVoteCount(postId);

  // if (currentVotes >= 2) { // Restrict to a maximum of 2 votes
  //   return Promise.reject({
  //     response: {
  //       data: {
  //         message: 'You have reached the maximum of 2 votes for this post on this device.',
  //       },
  //     },
  //   });
  // }

  try {
    const response = await axios.post(
      `${BASE_URL}/votes/cast`,
      { eventId, postId },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Add token to request
        },
      }
    );

    // console.log('Vote cast successfully:', response.data);
    //incrementDevicePostVoteCount(postId); // Increment vote count only after a successful vote
    return response.data;
  } catch (error) {
    console.error('Error casting vote:', error);
    throw error;
  }
};

export const happyVote = async (eventId, postId) => {
  const currentVotes = incrementDevicePostVoteCountHappy(postId);

  if (currentVotes >= 12) { // Restrict to a maximum of 2 votes
    return Promise.reject({
      response: {
        data: {
          message: 'You have reached the maximum of 12 votes for this post on this device.',
        },
      },
    });
  }

  try {
    const response = await axios.post(
      `${BASE_URL}/votes/happycast`,
      { eventId, postId },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Add token to request
        },
      }
    );

    // console.log('Vote cast successfully:', response.data);
    incrementDevicePostVoteCountHappy(postId); // Increment vote count only after a successful vote
    return response.data;
  } catch (error) {
    console.error('Error casting vote:', error);
    throw error;
  }
};