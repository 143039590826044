import React, { useMemo, useRef, useState } from 'react';
import ReactWordcloud from 'react-wordcloud';
import { toPng } from 'html-to-image';

const WordCloud = ({ messages }) => {
  const wordCloudRef = useRef(null); // Reference for the WordCloud container
  const [refreshKey, setRefreshKey] = useState(0); // State to force re-render the WordCloud component

  // Generate word frequency data for the WordCloud
  const words = useMemo(() => {
    const wordMap = {};

    // Combine all messages and split into words
    messages.forEach((message) => {
      const messageWords = message.message.split(/\s+/);
      messageWords.forEach((word) => {
        const cleanedWord = word.toLowerCase().replace(/[^a-z0-9]/gi, ''); // Remove punctuation
        if (!cleanedWord) return;
        wordMap[cleanedWord] = (wordMap[cleanedWord] || 0) + 1;
      });
    });

    // Convert to an array of word objects
    return Object.entries(wordMap).map(([text, value]) => ({ text, value }));
  }, [messages]);

  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontSizes: [20, 50],
    scale: 'log', // Logarithmic scale
    spiral: 'archimedean',
  };

  // Handle download functionality
  const handleDownload = () => {
    if (wordCloudRef.current) {
      toPng(wordCloudRef.current, {
        cacheBust: true,
        pixelRatio: 2, // Higher resolution image
      })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'wordcloud.png';
          link.click();
        })
        .catch((error) => {
          console.error('Error generating image for download:', error);
        });
    }
  };

  // Refresh the WordCloud by changing the key
  const handleRefresh = () => {
    setRefreshKey(prevKey => prevKey + 1); // Increment the key to force a re-render
  };

  return (
    <div>
      {/* WordCloud Visualization with Logo and Footer */}
      <div
        ref={wordCloudRef} // Attach reference to this div
        style={{
          width: '100%',
          height: 'auto',
          margin: '20px 0',
          border: '1px solid #ddd',
          padding: '10px',
          backgroundColor: '#fff',
          textAlign: 'center', // Center align content
        }}
      >
        {/* Logo at the Top */}
        <div style={{ marginBottom: '10px' }}>
          <img
            src="https://media.picvot.com/uploads/promotion/Black%20And%20White%20Illustrated%20Eagle%20Logo.png" // Replace with your logo URL
            alt="Logo"
            style={{ height: '50px' }}
          />
        </div>

        {/* WordCloud Content */}
        <div
          style={{
            width: '100%',
            height: '300px',
          }}
        >
          {/* React Wordcloud component with a key prop to force refresh */}
          <ReactWordcloud key={refreshKey} words={words} options={options} />
        </div>

        {/* Footer with "Created by PicVot" */}
        <div style={{ marginTop: '10px', fontSize: '14px', color: '#555' }}>
          Created by{' '}
          <strong>
            {(() => {
              // Check if the 'user' object exists and has a 'name' key
              const user = JSON.parse(localStorage.getItem('user')); // Assuming the 'user' is stored as a JSON object
              return user && user.name ? user.name : 'PicVot'; // Fallback to 'PicVot' if no name exists
            })()}
          </strong>
        </div>

        {/* Download Button */}
        {/* <button
          onClick={handleDownload}
          style={{
            marginTop: '20px',
            padding: '10px 10px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Download Word Cloud
        </button> */}

        {/* Refresh Button */}
        <button
          onClick={handleRefresh}
          style={{
            marginTop: '10px',
            padding: '10px 10px',
            backgroundColor: '#2196F3',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Generate New Image
        </button>
      </div>
    </div>
  );
};

export default WordCloud;
