import React, { useState, useEffect, Fragment } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Copyright from "../components/Copyright";
import axios from "axios"; // Axios for API calls
import SEO from "../components/Seo";
import GoogleAd from "../components/GoogleAd";
const Badge = () => {
    const [winners, setWinners] = useState([]); // Winners data state
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [showModal, setShowModal] = useState(false); // Modal visibility state
    const [certificateURL, setCertificateURL] = useState(""); // Store selected certificate URL

    // Fetch winners data on component mount
    useEffect(() => {
        fetchWinners();
    }, []);

    const fetchWinners = async () => {
        try {
            const response = await axios.get(
                "https://api.picvot.com/api/v1/winner"
            ); // Replace with your API endpoint
            setWinners(response.data);
        } catch (error) {
            setError("Failed to fetch winners.");
        } finally {
            setLoading(false);
        }
    };

    const handleShowCertificate = (url) => {
        if (!url) {
            alert("Certificate is Not Exists For this Contest. Thank you!");
            return;
        }
        setCertificateURL(url); // Set the selected certificate URL
        setShowModal(true); // Open the modal
    };
    

    const handleCloseModal = () => {
        setShowModal(false); // Close the modal
        setCertificateURL(""); // Clear the URL
    };

    return (
        <Fragment>
            <SEO
                title="Winners - Celebrate Top Achievers & Contest Champions | PicVot"
                description="Discover the winners of PicVot events. Celebrate top achievers, view contest champions, and get inspired by their stories and achievements."
                keywords="PicVot winners, contest champions, event winners, top achievers, photo contest, voting winners, PicVot rewards"
                url="https://picvot.com/winners"
                image="https://picvot.com/assets/og-image.webp"
            />

            <Header />
            <Leftnav />
            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                
                            {loading ? (
    <h4>Loading Winners...</h4>
) : error ? (
    <h4 className="text-danger">{error}</h4>
) : (
    <div className="row ps-2 pe-1">
         <GoogleAd />
        {winners.map((winner, index) => (
            <div key={index} className="col-md-4 col-sm-6 pe-2 ps-2">
                <div className="card d-block border-0 shadow rounded-4 overflow-hidden mb-4">
                    {/* Image Section */}
                    <div className="position-relative">
                        <img
                            src={winner.photoUrl}
                            className="w-100 rounded-3"
                        />
                        <span
                            className="position-absolute top-0 start-0 m-2 badge"
                            style={{ backgroundColor: "#4CAF50", color: "white" }}
                        >
                            Votes: {winner.totalVotes}
                        </span>
                        <span
                            className="position-absolute top-0 end-0 m-2 badge"
                            style={{ backgroundColor: "gold", color: "black" }}
                        >
                            Rank: {winner.rank}
                        </span>
                    </div>

                    {/* Green Details Section Below Image */}
                    <div
                        className="w-100 bg-opacity-80"
                        style={{
                            backgroundColor: "rgb(76, 175, 80)",
                            padding: "1rem",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <div
                                className="me-2"
                                style={{ width: "50px", height: "50px" }}
                            >
                                <img
                                    src={winner.userDetails.profileUrl}
                                    className="w-100 h-100 rounded-circle border border-light"
                                />
                            </div>
                            <div className="text-white">
                                <h6 className="mb-0">{winner.userDetails.name}</h6>
                                <p className="text-light mb-0">
                                    {Math.ceil(
                                        (new Date(winner.eventDetails.expiryDate) -
                                            new Date(winner.eventDetails.createdAt)) /
                                        (1000 * 60 * 60 * 24)
                                    )}{" "}
                                    days live
                                </p>
                            </div>
                        </div>
                        <a
                            href={`/eventpost/${winner.eventDetails.slug}`}
                            className="btn"
                            style={{ backgroundColor: "gold", color: "black" }}
                        >
                            Open Event
                        </a>
                    </div>

                    {/* Certificate Button Section */}
                    <div className="p-3">
                        <button
                            onClick={() => handleShowCertificate(winner.certificateUrl)}
                            className="btn btn-light w-100 d-flex align-items-center justify-content-center"
                            style={{ gap: "5px" }}
                        >
                            <i className="feather icon-download"></i> Show Certificate
                        </button>
                    </div>
                </div>
            </div>
        ))}
    </div>
)}

                            </div>
                            <div className="mt-2">
                                <Copyright />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for Certificate */}
           {/* Modal for Certificate */}
{showModal && (
    <div className="modal show d-block" tabIndex="-1">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Certificate</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={handleCloseModal}
                    ></button>
                </div>
                <div className="modal-body">
                    {certificateURL.endsWith(".pdf") ? (
                        <iframe
                            src={certificateURL}
                            width="100%"
                            height="400px"
                            title="Certificate"
                            style={{ border: "none" }}
                        ></iframe>
                    ) : (
                        <img
                            src={certificateURL}
                            alt="Certificate"
                            className="w-100"
                        />
                    )}
                </div>
                <div className="modal-footer">
                    <a
                        href={certificateURL}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary"
                    >
                        Download Certificate
                    </a>
                </div>
            </div>
        </div>
    </div>
)}


            <Popupchat />
            <Appfooter />
        </Fragment>
    );
};

export default Badge;
