import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { getVoterNamesForPost } from '../api/getVoterNamesForPost';
import LocationComponent from './Location';
const VotingDashboard = ({ postId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [voterData, setVoterData] = useState([]);
  const usersPerPage = 5;
  const getTimeAgo = (postCreatedAt) => {
    const now = new Date();
    const postTime = new Date(postCreatedAt);
    const timeDifference = now - postTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
    if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
    if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `just now`;
};
  // Set initial chart data
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: 'bar',
        height: 250,
        toolbar: {
          show: true,
          tools: {
            download: true,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: { enabled: false },
      xaxis: {
        categories: ['0-15', '15-30', '30-60', '60-100'], // Adjust categories if needed
      },
      title: {
        text: 'Votes by Gender and Age',
        align: 'center',
      },
      colors: ['#008FFB', '#FF4560'],
    },
    series: [] // This will hold the series data from the response
  });

  useEffect(() => {
    const fetchVoterData = async () => {
      try {
        const data = await getVoterNamesForPost(postId);
        setVoterData(data.voters || []);
        setChartData((prevData) => ({
          ...prevData,
          series: data.series || [] // Use the series data from the response
        }));
      } catch (error) {
        console.error('Error fetching voter names:', error);
      }
    };

    fetchVoterData();
  }, [postId]);

  // Calculate the total number of pages
  const totalPages = Math.ceil(voterData.length / usersPerPage);

  // Get the current users for the page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = voterData.slice(indexOfFirstUser, indexOfLastUser);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      {/* Left Side: Chart */}
      <div className="card shadow-sm border border-primary p-4 mb-3" style={{ flex: 1, background: 'linear-gradient(135deg, #a8e6cf, #d5b7e1)', borderRadius: '12px', marginRight: '20px' }}>
        <div className="mt-4">
          <Chart
            options={chartData.options}
            series={chartData.series} // Updated to use state with real series data
            type="bar"
            height={250}
          />
        </div>
      </div>

      {/* Right Side: Voters List */}
      <div className="card shadow-sm border border-primary p-4 mb-3" style={{ flex: 1, background: '#fff', borderRadius: '12px', height: '350px', overflowY: 'auto' }}>
        <h5 className="mb-3 text-center">Users Who Voted</h5>
        <ul className="list-group" style={{ maxHeight: '200px', overflowY: 'auto', padding: 0 }}>
          {currentUsers.map((user, index) => (
            <li key={index} className="list-group-item d-flex align-items-center" style={{ padding: '10px 15px' }}>
              {/* Avatar Image */}
              <img
                src={user.profilePic} // Ensure this path is correct
                alt="User Avatar"
                style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
              />
              <div className="flex-grow-1">
                <span>{user.name}</span>
                <LocationComponent location={user.registerLocation} />
                <span className="badge bg-primary float-end">{getTimeAgo(user.votedAt)}</span>
              </div>
            </li>
          ))}
        </ul>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between mt-3">
          <button className="btn btn-outline-primary" onClick={handlePrev} disabled={currentPage === 1}>
            Previous
          </button>
          <button className="btn btn-outline-primary" onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default VotingDashboard;
