import React, { useState, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";

const Faq = () => {
    // State to track which category is active
    const [activeCategory, setActiveCategory] = useState('general');
    // State to track which accordion items are open
    const [openItems, setOpenItems] = useState({});

    // Toggle accordion item
    const toggleAccordion = (categoryId, itemId) => {
        setOpenItems(prevState => ({
            ...prevState,
            [`${categoryId}-${itemId}`]: !prevState[`${categoryId}-${itemId}`]
        }));
    };

    // FAQ categories and questions
    const faqCategories = [
        {
            id: 'general',
            name: 'General Questions',
            icon: 'help-circle',
            questions: [
                {
                    question: "What is tapcoe?",
                    answer: "tapcoe is a platform that enables users to participate in various events, contests, and voting activities. It's designed to bring together participants, voters, and event organizers in an engaging online environment."
                },
                {
                    question: "How do I create an account?",
                    answer: "You can create an account by clicking on the 'Register' button and following the instructions. You'll need to provide your name, phone number, and other basic information to complete the registration process."
                },
                {
                    question: "Is tapcoe free to use?",
                    answer: "Basic features of tapcoe are free to use. Some premium features or specific event participations may have associated costs, which will be clearly indicated before you proceed."
                }
            ]
        },
        {
            id: 'events',
            name: 'Event Participation',
            icon: 'calendar',
            questions: [
                {
                    question: "Eligibility of an Event",
                    answer: "Different events have distinct eligibility criteria, specified during the content uploading process. Each event page will clearly indicate who can participate."
                },
                {
                    question: "How to participate in an Event",
                    answer: "To participate in an event, select the desired event, review the guidelines, and upload the required content as mentioned in the event details."
                },
                {
                    question: "How to claim prizes in an Event",
                    answer: "Our team will contact you after the event to arrange the handover of your prize if you're a winner. Make sure your contact information is up to date in your profile."
                },
                {
                    question: "How to upload content",
                    answer: "Select the 'Upload' option, choose content from your device, and upload it to participate in the event. Make sure your content meets the guidelines for the specific event."
                }
            ]
        },
        {
            id: 'voting',
            name: 'Voting & Rewards',
            icon: 'award',
            questions: [
                {
                    question: "How does voting work?",
                    answer: "You can vote for your favorite participants by visiting their content page and clicking the vote button. Some events may have restrictions on the number of votes per day."
                },
                {
                    question: "What are daily voting rewards?",
                    answer: "You can earn rewards by voting daily on different events. The more you vote, the better rewards you unlock, such as points, badges, or special access."
                },
                {
                    question: "How do voting streaks work?",
                    answer: "Maintain a streak by voting daily. Streaks unlock exclusive rewards and improve your ranking in the voter leaderboards. Missing a day will reset your streak."
                },
                {
                    question: "How do leaderboards and rankings work?",
                    answer: "Leaderboards show the top participants and voters, helping you track your progress and compete with others. Rankings are updated in real-time based on votes received or given."
                }
            ]
        },
        {
            id: 'technical',
            name: 'Technical Support',
            icon: 'settings',
            questions: [
                {
                    question: "How to log in on the website",
                    answer: "You can log in through your registered mobile number on the website. Enter your number, receive an OTP, and verify to access your account."
                },
                {
                    question: "How to share content",
                    answer: "You can share your content by clicking on the share button and selecting a social media platform. This helps you get more votes and engagement."
                },
                {
                    question: "What browsers are supported?",
                    answer: "tapcoe works best on the latest versions of Chrome, Firefox, Safari, and Edge. Make sure to keep your browser updated for the best experience."
                },
                {
                    question: "How to report a technical issue",
                    answer: "If you encounter any technical issues, please visit the Contact Us page and submit a detailed description of the problem. Our support team will assist you as soon as possible."
                }
            ]
        }
    ];

    return (
        <Fragment>
            <SEO
                title="FAQ - Frequently Asked Questions | tapcoe"
                description="Find answers to the most common questions about tapcoe's events, voting, rewards, and platform features in our comprehensive FAQ section."
                keywords="tapcoe FAQ, frequently asked questions, event participation, voting rewards, technical support"
                url="https://tapcoe.com/faq"
                image="https://tapcoe.com/assets/faq-og-image.png"
            />
            <Header />
            <Leftnav />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        {/* Hero Section */}
                        <div className="faq-hero position-relative overflow-hidden rounded-xxl mb-4"
                            style={{
                                background: "linear-gradient(135deg, rgba(24, 119, 242, 0.95), rgba(66, 133, 244, 0.95))",
                                padding: "60px 0"
                            }}>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-8 text-lg-start text-center mx-auto">
                                        <div className="hero-content p-4">
                                            <h1 className="fw-bold text-white mb-3" style={{ fontSize: "42px" }}>Frequently Asked Questions</h1>
                                            <p className="text-white opacity-90 mb-4" style={{ fontSize: "18px", lineHeight: "1.6" }}>
                                                Find answers to the most common questions about using tapcoe's platform, event participation, voting, and rewards.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Decorative elements */}
                            <div className="position-absolute" style={{ top: "15%", left: "10%", width: "150px", height: "150px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
                            <div className="position-absolute" style={{ bottom: "10%", right: "15%", width: "120px", height: "120px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
                            <div className="position-absolute" style={{ top: "30%", right: "20%", width: "30px", height: "30px", background: "rgba(255,255,255,0.2)", borderRadius: "50%" }}></div>
                        </div>

                        {/* Search Bar */}
                        <div className="card border-0 rounded-xxl shadow-xss mb-4">
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-12 col-lg-8 mx-auto">
                                        <div className="form-group mb-0 position-relative">
                                            <i className="feather-search text-grey-500 font-lg position-absolute" style={{ top: "50%", transform: "translateY(-50%)", left: "15px" }}></i>
                                            <input 
                                                type="text" 
                                                className="form-control border rounded-pill pl-5 py-3"
                                                style={{ paddingLeft: "45px" }} 
                                                placeholder="Search for answers..." 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* FAQ Categories */}
                        <div className="card border-0 rounded-xxl shadow-xss mb-4">
                            <div className="card-body">
                                <div className="category-tabs mb-4">
                                    <div className="row">
                                        {faqCategories.map((category) => (
                                            <div key={category.id} className="col-6 col-md-3 mb-3">
                                                <div 
                                                    className={`category-item rounded-xxl p-4 text-center cursor-pointer h-100 ${activeCategory === category.id ? 'active' : ''}`}
                                                    style={{
                                                        background: activeCategory === category.id ? 
                                                            "linear-gradient(135deg, #1877f2, #4285f4)" : 
                                                            "linear-gradient(135deg, rgba(24, 119, 242, 0.05), rgba(66, 133, 244, 0.08))",
                                                        border: "1px solid rgba(24, 119, 242, 0.1)",
                                                        transition: "all 0.3s ease",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => setActiveCategory(category.id)}
                                                    onMouseOver={(e) => {
                                                        if (activeCategory !== category.id) {
                                                            e.currentTarget.style.transform = 'translateY(-5px)';
                                                            e.currentTarget.style.boxShadow = '0 10px 30px rgba(0, 0, 0, 0.08)';
                                                        }
                                                    }}
                                                    onMouseOut={(e) => {
                                                        if (activeCategory !== category.id) {
                                                            e.currentTarget.style.transform = 'translateY(0)';
                                                            e.currentTarget.style.boxShadow = 'none';
                                                        }
                                                    }}
                                                >
                                                    <div className="icon-wrapper mb-3 mx-auto d-flex align-items-center justify-content-center rounded-circle"
                                                        style={{
                                                            width: "60px",
                                                            height: "60px",
                                                            background: activeCategory === category.id ? 
                                                                "rgba(255, 255, 255, 0.2)" : 
                                                                "linear-gradient(135deg, #1877f2, #4285f4)"
                                                        }}
                                                    >
                                                        <i 
                                                            className={`feather-${category.icon}`} 
                                                            style={{ 
                                                                fontSize: "24px", 
                                                                color: activeCategory === category.id ? "#fff" : "#fff"
                                                            }}
                                                        ></i>
                                                    </div>
                                                    <h4 
                                                        className={`fw-700 font-xs mb-0 ${activeCategory === category.id ? 'text-white' : 'text-grey-900'}`}
                                                    >
                                                        {category.name}
                                                    </h4>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* FAQ Accordions */}
                                <div className="faq-accordions">
                                    {faqCategories.map((category) => (
                                        <div 
                                            key={category.id} 
                                            className="category-questions mb-4"
                                            style={{ display: activeCategory === category.id ? 'block' : 'none' }}
                                        >
                                            <h3 className="fw-700 text-grey-900 mb-3">{category.name}</h3>
                                            
                                            {category.questions.map((item, index) => (
                                                <div 
                                                    key={index} 
                                                    className="accordion-item mb-3 rounded-xxl overflow-hidden"
                                                    style={{ 
                                                        border: '1px solid rgba(24, 119, 242, 0.1)',
                                                        transition: 'all 0.3s ease'
                                                    }}
                                                >
                                                    <div 
                                                        className="accordion-header p-4 d-flex justify-content-between align-items-center cursor-pointer"
                                                        onClick={() => toggleAccordion(category.id, index)}
                                                        style={{ 
                                                            background: openItems[`${category.id}-${index}`] ? 
                                                                'linear-gradient(135deg, rgba(24, 119, 242, 0.08), rgba(66, 133, 244, 0.12))' : 
                                                                'white',
                                                            cursor: 'pointer',
                                                            transition: 'all 0.3s ease'
                                                        }}
                                                    >
                                                        <h5 className="fw-600 text-grey-900 font-xss mb-0">
                                                            {item.question}
                                                        </h5>
                                                        <div className="toggle-icon">
                                                            <i 
                                                                className={`feather-${openItems[`${category.id}-${index}`] ? 'minus' : 'plus'} text-primary`}
                                                                style={{ 
                                                                    transition: 'all 0.3s ease',
                                                                    transform: openItems[`${category.id}-${index}`] ? 'rotate(0deg)' : 'rotate(0deg)'
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="accordion-body"
                                                        style={{ 
                                                            padding: openItems[`${category.id}-${index}`] ? '20px' : '0 20px',
                                                            maxHeight: openItems[`${category.id}-${index}`] ? '1000px' : '0',
                                                            overflow: 'hidden',
                                                            transition: 'all 0.3s ease'
                                                        }}
                                                    >
                                                        <p className="font-xss fw-500 text-grey-600 lh-24">{item.answer}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Still Have Questions */}
                        <div className="card border-0 rounded-xxl shadow-xss mb-4 bg-pattern" style={{ 
                            background: "linear-gradient(135deg, rgba(24, 119, 242, 0.03), rgba(66, 133, 244, 0.07))",
                            backgroundImage: "url('/assets/images/dot-pattern.png')",
                            backgroundSize: "300px",
                            backgroundPosition: "center"
                        }}>
                            <div className="card-body p-5 text-center">
                                <div className="icon-wrapper mb-4 mx-auto d-flex align-items-center justify-content-center rounded-circle"
                                    style={{
                                        width: "80px",
                                        height: "80px",
                                        background: "linear-gradient(135deg, #1877f2, #4285f4)"
                                    }}
                                >
                                    <i className="feather-message-circle text-white" style={{ fontSize: "32px" }}></i>
                                </div>
                                <h3 className="fw-700 text-grey-900 mb-3">Still Have Questions?</h3>
                                <p className="font-xss fw-500 text-grey-500 mb-4 mx-auto" style={{ maxWidth: "600px" }}>
                                    Can't find the answer you're looking for? Please contact our friendly customer support team.
                                </p>
                                <a 
                                    href="/contact" 
                                    className="btn btn-primary border-0 p-3 px-5 rounded-lg d-inline-block"
                                    style={{
                                        background: "linear-gradient(90deg, #1877f2, #4285f4)",
                                        color: "white",
                                        fontWeight: "600",
                                        fontSize: "16px"
                                    }}
                                >
                                    Contact Support
                                </a>
                            </div>
                        </div>

                        <div className="mt-2">
                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />
        </Fragment>
    );
};

export default Faq;
