import React, { useEffect, useRef } from "react";

const GoogleAd = () => {
  const adRef = useRef(null);

  useEffect(() => {
    // Load the adsbygoogle.js script
    const loadAdsScript = () => {
      if (window) {
        if (!window.adsbygoogle) {
          const script = document.createElement("script");
          script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5875700591871635";
          script.async = true;
          script.crossOrigin = "anonymous";
          document.head.appendChild(script);
        }
        // Push new ad to the adsbygoogle queue
        if (window.adsbygoogle && adRef.current) {
          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          } catch (e) {
            console.error("Adsense error", e);
          }
        }
      }
    };

    loadAdsScript();
  }, []);

  return (
    <div style={{ textAlign: "center", margin: "10px 0" }}>
      <div style={{ fontSize: "12px", color: "#666", marginBottom: "5px" }}>Ads by Google</div>
      <ins
        ref={adRef}
        className="adsbygoogle"
        style={{ display: "block" }}
        // data-ad-client="ca-pub-5875700591871635"
        // data-ad-slot="1293193575"
        data-ad-client="ca-pub-5875700591871635"
        data-ad-slot="1293193575"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
     <div style={{ fontSize: "10px", color: "white", fontWeight: "bold", marginTop: "5px" }}>
     Click on above ads to support us , Thank You
</div>


    </div>
  );
};

export default GoogleAd;
