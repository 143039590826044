import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Countdown from "./CountDown";
import Createpost from '../components/Createpost';
import LiveDiscussion from './LiveDiscussion';
import Postview from '../components/Postview';
import Companyabout from '../components/Companyabout';
import { getEventBySlug } from '../api/getEventBySlug';
import { useAuth } from "../context/AuthContext";
import Load from '../components/Load';
import Profilephoto from '../components/Profilephoto';
import GoogleAd from './GoogleAd';
const EventBanner = ({ slug }) => {
    const history = useHistory();
    const { isLoggedIn } = useAuth();
    const [activeTab, setActiveTab] = useState('post'); // Default active tab
    const [eventDetails, setEventDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await getEventBySlug(slug); // Use the slug prop passed to the component
                setEventDetails(response);
                console.log(response);
            } catch (error) {
                console.error("Error fetching event details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchEventDetails();
    }, [slug]); // Fetch details whenever the slug changes
    
    // Function to handle tab switching
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const renderContent = () => {
        if (loading) {
            return <Load />; // Show loading indicator
        }
    
        switch (activeTab) {
            case 'post':
                return (
                    <div className="row no-gutters">
                        <div className="col-xl-8 col-xxl-9 col-lg-8">
                            {/* Check if user is logged in and event status is true */}
                            {isLoggedIn && eventDetails?.eventDetails ? (
                                eventDetails.eventDetails.restrictions.isUploadAllowed ? (
                                    <Createpost eventId={eventDetails.eventDetails._id} />
                                ) : (
                                    <div className="alert alert-warning mb-3">
                                        This event does not allow uploads.
                                    </div>
                                )
                            ) : (
                                <div className="mb-3">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            document.body.classList.remove("theme-dark");
                                            document.body.style.backgroundColor = "#5271ff";
                                            history.push(`${process.env.PUBLIC_URL}/login`);
                                        }}
                                    >
                                        Login to Create Post Or Vote
                                    </button>
                                </div>
                            )}
    
                            {eventDetails?.joinedPosts?.map((post, index) => (
                                <React.Fragment key={post.postId || index}>
                                    {post.postId ? (
                                        <Postview
                                            key={post.postId}
                                            postid={post.postId}
                                            rank={post.rank}
                                            id={eventDetails.eventDetails._id}
                                            postvideo={post.video}
                                            postimage={post.postImageUrl}
                                            avatar={post.userProfilePic}
                                            isPublic={post.isPublic}
                                            user={post.name}
                                            location={post.registerLocation}
                                            time={post.postedAt}
                                            des={post.description}
                                            vote={post.voteCount}
                                            hasvoted={post.hasVoted}
                                            status={post.status}
                                            rejectReason={post.rejectReason}
                                        />
                                    ) : (
                                        <div className="alert alert-warning mb-3">
                                            Info: You are the first to join the event!
                                        </div>
                                    )}
    
                                    {/* Insert GoogleAd after every 3 posts */}
                                    {(index + 1) % 3 === 0 && (
                                        <div className="mb-3">
                                            <GoogleAd />
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
    
                        <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-4">
                            <LiveDiscussion eventId={eventDetails?.eventDetails?._id} />
                            <Profilephoto eventDetails={eventDetails?.eventDetails?.eventPhotos} />
                            <div className="mb-3">
                                <img
                                    src={`${eventDetails?.eventDetails?.poster?.url}`}
                                    alt={eventDetails?.eventDetails?.poster?.url}
                                    className="img-fluid rounded-3 w-100"
                                />
                            </div>
                        </div>
                    </div>
                );
    
            case 'leaderboard':
                const sortedPosts = [...eventDetails?.joinedPosts].sort((a, b) => a.rank - b.rank);
                return (
                    <div className="row no-gutters">
                        <div className="col-xl-8 col-xxl-9 col-lg-8">
                            <h3>Leaderboard</h3>
                            {sortedPosts.map((post, index) => (
                                <React.Fragment key={post.postId || index}>
                                    {post.postId ? (
                                        <Postview
                                            key={post.postId}
                                            postid={post.postId}
                                            rank={post.rank}
                                            id={eventDetails.eventDetails._id}
                                            postvideo={post.video}
                                            postimage={post.postImageUrl}
                                            avatar={post.userProfilePic}
                                            isPublic={post.isPublic}
                                            location={post.registerLocation}
                                            user={post.name}
                                            time={post.postedAt}
                                            des={post.description}
                                            vote={post.voteCount}
                                            hasvoted={post.hasVoted}
                                            status={post.status}
                                            rejectReason={post.rejectReason}
                                        />
                                    ) : (
                                        <div className="alert alert-warning mb-3">
                                            Info: You are the first to join the event!
                                        </div>
                                    )}
    
                                    {/* Insert GoogleAd after every 3 posts */}
                                    {(index + 1) % 3 === 0 && (
                                        <div className="mb-3">
                                            <GoogleAd />
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
    
                        <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-4">
                            <LiveDiscussion eventId={eventDetails?.eventDetails?._id} />
                            <Profilephoto eventDetails={eventDetails?.eventDetails?.eventPhotos} />
                            <div className="mb-3">
                                <img
                                    src={`${eventDetails?.eventDetails?.poster?.url}`}
                                    alt={eventDetails?.eventDetails?.poster?.url}
                                    className="img-fluid rounded-3 w-100"
                                />
                            </div>
                        </div>
                    </div>
                );
    
            case 'about':
                return (
                    <div className="row no-gutters">
                        <div className="col-xl-8 col-xxl-9 col-lg-8">
                            <Companyabout about={eventDetails?.eventDetails?.about} prize={eventDetails?.eventDetails?.prize} />
                        </div>
                        <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-4">
                            <LiveDiscussion eventId={eventDetails?.eventDetails?._id} />
                            <Profilephoto eventDetails={eventDetails?.eventDetails?.eventPhotos} />
                            <div className="mb-3">
                                <img
                                    src={`${eventDetails?.eventDetails?.poster?.url}`}
                                    alt={eventDetails?.eventDetails?.poster?.url}
                                    className="img-fluid rounded-3 w-100"
                                />
                            </div>
                        </div>
                    </div>
                );
                
            default:
                return null;
        }
    };
    

    return (
        <>
            <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl mb-4">
                <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-1"><img
                    src={`${eventDetails?.eventDetails?.banner?.url}`}
                    alt="cover"
                    style={{
                        width: "100%",          // Full width of the container
                        height: "auto",         // Height will adjust automatically to maintain the aspect ratio
                        maxWidth: "1200px",     // Set a maximum width for larger screens
                        aspectRatio: "1200 / 250", // Maintain the aspect ratio
                        objectFit: "cover"      // Ensures the image fits the container without distortion
                    }}
                />
                </div>

                <Countdown eventName={eventDetails?.eventDetails?.title} expiryDate={eventDetails?.eventDetails?.expiryDate} />
                <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                    <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                        <li className={`list-inline-item me-5 ${activeTab === 'post' ? 'active' : ''}`}>
                            <a
                                className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block`}
                                onClick={() => handleTabClick('post')}
                                href="#post"
                            >
                                Post
                            </a>
                        </li>
                        <li className={`list-inline-item me-5 ${activeTab === 'leaderboard' ? 'active' : ''}`}>
                            <a
                                className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block`}
                                onClick={() => handleTabClick('leaderboard')}
                                href="#leaderboard"
                            >
                                Leaderboard
                            </a>
                        </li>
                        <li className={`list-inline-item me-5 ${activeTab === 'about' ? 'active' : ''}`}>
                            <a
                                className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block`}
                                onClick={() => handleTabClick('about')}
                                href="#about"
                            >
                                About
                            </a>
                        </li>
                    </ul>
                </div>
            </div>


            <div className="tab-content">
                {renderContent()}
            </div>
            <GoogleAd />
        </>
    );
};

export default EventBanner;
