import axios from 'axios';

const BASE_URL = "https://api.tapcoe.com/api/v1";

// Function to get post details by event ID and post ID
export const getPostDetails = async (eventId, postId) => {
  try {
    // console.log(eventId, postId);
    const token = localStorage.getItem('token');
    // Configure headers with optional token
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    const response = await axios.get(
      `${BASE_URL}/joinedevents/post/${eventId}/${postId}`,
      { headers }  // Pass headers conditionally
    );

    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching post details:', error);
    throw error;
  }
};
