import React, { useState, useEffect, useContext, Fragment } from "react";
import { AuthContext } from "../context/AuthContext"; // Import AuthContext

const Login = () => {
  const { isLoggedIn, login } = useContext(AuthContext); // Access AuthContext

  const [mobileNumber, setmobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Success message state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  
  
  useEffect(() => {
    if (isLoggedIn) {
      // Redirect if already logged in
      window.location.href = "/";
    }
  }, [isLoggedIn]);

  const handlemobileNumberChange = (e) => {
    setmobileNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const response = await login({ mobileNumber, password }); // Call login function

      if (response.data.success) {
        setSuccessMessage(response.data.message); // Display success message
        setErrorMessage(""); // Clear any previous error message
      } else {
        setErrorMessage(response.data.message); // Display error message
        setSuccessMessage(""); // Clear any previous success message
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message); // Show error from API
      } else {
        setSuccessMessage("Login Success."); // Fallback error
      }
      setSuccessMessage(""); // Clear any previous success message
    }
  };

  return (
    <Fragment>
      <div className="main-wrap" style={{ backgroundColor: "#5271ff" }}>
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100">
            <a href="/" aria-label="Go to homepage">
              <i className="feather-camera text-current display1-size me-2 ms-0"></i>
              <span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">
                PicVot
              </span>
            </a>
            <a
              href="/login"
              className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl"
            >
              Login
            </a>
            <a
              href="/register"
              className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl"
            >
              Register
            </a>
          </div>
        </div>

        <div className="row">
          <div
            className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{
              backgroundImage: `url("assets/images/loginpic.png")`,
            }}
          ></div>
          <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-4">
                  Login <br />
                  your account
                </h2>
                <form>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-mobile text-grey-500 pe-0"></i>
                    <input
                      type="text"
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Whatsapp Number / Email"
                      value={mobileNumber}
                      onChange={handlemobileNumberChange}
                    />
                  </div>

                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-key text-grey-500 pe-0"></i>
                    <input
                      type="password"
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </div>

                  <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1">
                      <button
                        type="button"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                        onClick={handleLogin}
                      >
                        Login
                      </button>
                    </div>

                    {/* Success and Error Messages */}
                    {successMessage && (
                      <div className="alert alert-success mt-3">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="alert alert-danger mt-3">
                        {errorMessage}
                      </div>
                    )}

                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                      Don't have an account?{" "}
                      <a href="/register" className="fw-700 ms-1">
                        Register
                      </a>
                    </h6>
                    <h6 className="text-grey-500 font-xsss fw-500 mt-2 mb-0 lh-32">
                      <a href="/forgot" className="fw-700 ms-1">
                        Forgot Password?
                      </a>
                    </h6>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
