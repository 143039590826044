import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { castVote } from '../api/castVoteApi';
import { useAuth } from "../context/AuthContext";
import LocationComponent from './Location';
const Postview = ({ user, time, vote, postid, des, avatar, postimage, postvideo, id, rank, hasvoted, status, rejectReason,location }) => {
    const { isLoggedIn } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [currentVote, setCurrentVote] = useState(vote); // State for current vote count
    const [lastShare] = useState('https://socia.be/1rGxjoJKVF0');
    const [showOptions, setShowOptions] = useState(false);
    const history = useHistory();
    const [isVoting, setIsVoting] = useState(false); // State to handle loading
    const [showLocation, setShowLocation] = useState(true);

    useEffect(() => {
      const interval = setInterval(() => {
        setShowLocation((prev) => !prev);
      }, 2000); // Toggle every 2 seconds
  
      return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);
    const animateVoteIncrease = () => {
        let startVote = currentVote; // Store the initial vote
        const targetVote = startVote + 1; // Increment by 1

        const increment = () => {
            if (startVote < targetVote) {
                setCurrentVote((prev) => prev + 1); // Increment vote count in UI
                startVote++; // Increment the startVote
                setTimeout(increment, 50); // Recursively call for smooth animation
            }
        };
        increment();
    };

    const handleVoteClick = async () => {
        try {
            setIsVoting(true); // Start loading
            const response = await castVote(id, postid);
            alert('Vote cast successfully!');
            console.log('Vote Response:', response);

            // Call animation function after successfully casting vote
            animateVoteIncrease();
        } catch (error) {
            alert(error.response.data.message);
            console.error('Error during vote:', error.response.data.message);
        } finally {
            setIsVoting(false); // Stop loading
        }
    };

    const handleMoreClick = () => {
        setShowOptions(!showOptions);
    };

    const handleOptionClick = (option) => {
        switch (option) {
            case 'post':
                history.push(`/post/${id}/${postid}`); // Redirect to post without reload
                break;
            case 'profile':
                history.push(`/profile`); // Redirect to profile without reload
                break;
            case 'report':
                alert('This Post Is reported, admin will check and update you!');
                break;
            default:
                break;
        }
        setShowOptions(false); // Hide the dropdown after clicking an option
    };

    const obfuscateId = (id) => {
        const salt = 53; // Static or dynamic salt value
        const numericId = parseInt(id, 36); // Convert the id to base 36 for alphanumeric support
        const transformedId = (numericId + salt) % 10000; // Add salt and take mod to reduce size
        return transformedId.toString().slice(0, 2); // Take the first 2 digits
    };

    const getTimeAgo = (postCreatedAt) => {
        const now = new Date();
        const postTime = new Date(postCreatedAt);
        const timeDifference = now - postTime;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
        if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
        if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        return `just now`;
    };

    const displayedId = obfuscateId(postid);

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-2 mb-3">
            <div className="card-body p-0 d-flex">
                <figure className="avatar me-3" style={{ height: '40px', width: '40px' }}>
                    <img
                        src={`${avatar}`}
                        alt="avatar"
                        className="shadow-sm rounded-circle"
                        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    />
                </figure>

                <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                {user}
      {showLocation ? (
        <LocationComponent location={location} />
      ) : (
        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{getTimeAgo(time)}</span>
      )}
                  </h4>
                <div className="ms-auto pointer position-relative">
                    <i
                        className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"
                        onClick={handleMoreClick}
                        style={{ cursor: 'pointer' }}
                    ></i>
                    {showOptions && (
                        <div
                            className="dropdown-menu show position-absolute"
                            style={{
                                right: 0,
                                top: '100%',
                                maxWidth: '200px',
                                width: '100%',
                                padding: '5px',
                                backgroundColor: '#fff',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                borderRadius: '12px',
                                zIndex: '999',
                                overflow: 'hidden',
                            }}
                        >
                            <ul className="list-group">
                                <li
                                    className="list-group-item border-0 p-2"
                                    onClick={() => handleOptionClick('post')}
                                    style={{ fontSize: '10px' }}
                                >
                                    Open Post
                                </li>
                                <li
                                    className="list-group-item border-0 p-2"
                                    onClick={() => handleOptionClick('profile')}
                                    style={{ fontSize: '10px' }}
                                >
                                    Open Profile
                                </li>
                                <li
                                    className="list-group-item border-0 p-2 text-danger"
                                    onClick={() => handleOptionClick('report')}
                                    style={{ fontSize: '10px' }}
                                >
                                    Report
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            {postvideo && (
                <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                    <a href="/defaultvideo" className="video-btn">
                        <video autoPlay loop className="float-right w-100">
                            <source src={`/assets/images/${postvideo}`} type="video/mp4" />
                        </video>
                    </a>
                </div>
            )}
            {postimage && (
                <div className="card-body d-block p-0 mb-3">
                    <div className="row ps-2 pe-2">
                        <div className="col-sm-12 p-1">
                            <a href={`/post/${id}/${postid}`} rel="noopener noreferrer">
                                <img src={`${postimage}`} className="rounded-3 w-100" alt="post" />
                            </a>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <div className="card-body d-flex p-0">
                    <button className="btn btn-primary me-2">{`ID: ${displayedId}`}</button>
                    {typeof rank !== "undefined" ? (
                        <>
                            <button className="btn btn-secondary mx-auto me-2">{`Vote: ${currentVote}`}</button> {/* Use currentVote */}
                            <button className="btn btn-info ms-auto">{`Rank: ${rank}`}</button>
                        </>
                    ) : (
                        <button className="btn btn-secondary ms-auto">{`Vote: ${currentVote}`}</button>
                    )}
                </div>
                {isLoggedIn ? (
                    status === 'rejected' ? (
                        <div className="alert alert-danger w-100 mt-2">
                            {rejectReason}
                        </div>
                    ) : (
                        hasvoted ? (
                            <div className="alert alert-success w-100 mt-2">
                                You have already voted!
                            </div>
                        ) : (
                            <button
                                className="btn btn-success w-100 mt-2"
                                onClick={handleVoteClick}
                                disabled={isVoting} // Disable button while voting
                            >
                                {isVoting ? 'Voting...' : 'Click here to vote'}
                            </button>
                        )
                    )
                ) : (
                    <div className="alert alert-warning w-100 mt-2">
                        Please log in to vote.
                    </div>
                )}

            </div>
        </div>
    );
};

export default Postview;
