import React, { useState, Fragment, useEffect } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";

const Termandcondition = () => {
    const [activeSection, setActiveSection] = useState('introduction');
    const [showBackToTop, setShowBackToTop] = useState(false);

    // Terms sections data
    const termsSections = [
        {
            id: 'introduction',
            title: 'Introduction',
            content: `These Website Standard Terms and Conditions written on this webpage shall manage your use of this website. These Terms will be applied fully and affect your use of this Website. By using this Website, you agree to accept all terms and conditions written here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.`
        },
        {
            id: 'intellectual-property',
            title: 'Intellectual Property Rights',
            content: `Other than the content you own, under these Terms, tapcoe.com and/or its licensors own all the intellectual property rights and materials contained on this Website. You are granted a limited license only for the purposes of viewing the material contained on this Website.`
        },
        {
            id: 'restrictions',
            title: 'Restrictions',
            content: `You are specifically restricted from all of the following:
            
            - Selling, sublicensing, and/or otherwise commercializing any Website material
            - Publicly performing and/or showing any Website material
            - Using this Website in any way that is or may be damaging to this Website
            - Using this Website in any way that impacts user access to this Website
            - Using this Website contrary to applicable laws and regulations or in any way that may cause harm to the Website or to any person or business entity
            - Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this Website
            - Using this Website to engage in any advertising or marketing
            
            Certain areas of this Website are restricted from being accessed by you, and tapcoe.com may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential, and you must maintain confidentiality as well.`
        },
        {
            id: 'your-content',
            title: 'Your Content',
            content: `In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images, or other material you choose to display on this Website. By displaying Your Content, you grant tapcoe.com a non-exclusive, worldwide irrevocable, sublicensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media. Your Content must be your own and must not be invading any third-party's rights. tapcoe.com reserves the right to remove any of Your Content from this Website at any time without notice.`
        },
        {
            id: 'no-warranties',
            title: 'No Warranties',
            content: `This Website is provided "as is," with all faults, and tapcoe.com expresses no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.`
        },
        {
            id: 'limitation-liability',
            title: 'Limitation of Liability',
            content: `In no event shall tapcoe.com, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract. tapcoe.com, including its officers, directors, and employees, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.`
        },
        {
            id: 'indemnification',
            title: 'Indemnification',
            content: `You hereby indemnify to the fullest extent tapcoe.com from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.`
        },
        {
            id: 'severability',
            title: 'Severability',
            content: `If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.`
        },
        {
            id: 'variation-terms',
            title: 'Variation of Terms',
            content: `tapcoe.com is permitted to revise these Terms at any time as it sees fit, and by using this Website, you are expected to review these Terms on a regular basis.`
        },
        {
            id: 'assignment',
            title: 'Assignment',
            content: `tapcoe.com is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.`
        },
        {
            id: 'entire-agreement',
            title: 'Entire Agreement',
            content: `These Terms constitute the entire agreement between tapcoe.com and you in relation to your use of this Website and supersede all prior agreements and understandings.`
        },
        {
            id: 'governing-law',
            title: 'Governing Law & Jurisdiction',
            content: `These Terms will be governed by and interpreted in accordance with the laws of India, and you submit to the non-exclusive jurisdiction of the state and federal courts located in India for the resolution of any disputes.`
        }
    ];

    // Handle scroll to section
    const scrollToSection = (sectionId) => {
        setActiveSection(sectionId);
        const element = document.getElementById(sectionId);
        if (element) {
            const yOffset = -100; // Offset for fixed header
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    };

    // Handle scroll event to update active section and show/hide back to top button
    useEffect(() => {
        const handleScroll = () => {
            // Show/hide back to top button
            if (window.scrollY > 300) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }

            // Update active section based on scroll position
            const sections = termsSections.map(section => document.getElementById(section.id));
            const scrollPosition = window.scrollY + 150; // Add offset

            for (let i = sections.length - 1; i >= 0; i--) {
                if (sections[i] && sections[i].offsetTop <= scrollPosition) {
                    setActiveSection(termsSections[i].id);
                    break;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [termsSections]);

    // Back to top function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Fragment>
            <SEO
                title="Terms and Conditions | tapcoe"
                description="Read the terms and conditions that govern your use of tapcoe platform. Understand your rights and obligations when using our services."
                keywords="tapcoe terms and conditions, legal terms, user agreement, terms of use, website terms"
                url="https://tapcoe.com/terms"
                image="https://tapcoe.com/assets/terms-og-image.png"
            />
            <Header />
            <Leftnav />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        {/* Hero Section */}
                        <div className="terms-hero position-relative overflow-hidden rounded-xxl mb-4"
                            style={{
                                background: "linear-gradient(135deg, rgba(24, 119, 242, 0.95), rgba(66, 133, 244, 0.95))",
                                padding: "60px 0"
                            }}>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-8 text-lg-start text-center mx-auto">
                                        <div className="hero-content p-4">
                                            <h1 className="fw-bold text-white mb-3" style={{ fontSize: "42px" }}>Terms and Conditions</h1>
                                            <p className="text-white opacity-90 mb-4" style={{ fontSize: "18px", lineHeight: "1.6" }}>
                                                Please read these terms carefully before using the tapcoe platform. By accessing or using our services, you agree to be bound by these terms.
                                            </p>
                                            <p className="text-white opacity-90 mb-4" style={{ fontSize: "14px", lineHeight: "1.6" }}>
                                                Last Updated: March 20, 2023
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Decorative elements */}
                            <div className="position-absolute" style={{ top: "15%", left: "10%", width: "150px", height: "150px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
                            <div className="position-absolute" style={{ bottom: "10%", right: "15%", width: "120px", height: "120px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
                            <div className="position-absolute" style={{ top: "30%", right: "20%", width: "30px", height: "30px", background: "rgba(255,255,255,0.2)", borderRadius: "50%" }}></div>
                        </div>

                        <div className="row">
                            {/* Sidebar navigation */}
                            <div className="col-lg-3 d-none d-lg-block">
                                <div className="card border-0 rounded-xxl shadow-xss mb-4 sticky-top" style={{ top: '100px' }}>
                                    <div className="card-body p-4">
                                        <h4 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">Table of Contents</h4>
                                        <div className="nav flex-column">
                                            {termsSections.map((section) => (
                                                <a 
                                                    key={section.id}
                                                    href={`#${section.id}`}
                                                    className={`nav-link mb-1 rounded-pill px-3 py-2 font-xsss fw-500 ${activeSection === section.id ? 'active' : ''}`}
                                                    style={{
                                                        color: activeSection === section.id ? '#fff' : '#555',
                                                        background: activeSection === section.id ? 'linear-gradient(90deg, #1877f2, #4285f4)' : 'transparent',
                                                        transition: 'all 0.3s ease'
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        scrollToSection(section.id);
                                                    }}
                                                >
                                                    {section.title}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Main content */}
                            <div className="col-lg-9">
                                <div className="card border-0 rounded-xxl shadow-xss mb-4">
                                    <div className="card-body p-4 p-lg-5">
                                        <div className="terms-content">
                                            {termsSections.map((section) => (
                                                <div 
                                                    key={section.id} 
                                                    id={section.id} 
                                                    className="terms-section mb-5"
                                                >
                                                    <h3 className="fw-700 text-grey-900 mb-3 d-flex align-items-center">
                                                        <div className="decoration-circle me-3" style={{
                                                            width: '8px',
                                                            height: '8px',
                                                            borderRadius: '50%',
                                                            background: 'linear-gradient(90deg, #1877f2, #4285f4)'
                                                        }}></div>
                                                        {section.title}
                                                    </h3>
                                                    <div className="font-xss fw-500 text-grey-600 lh-28" style={{ whiteSpace: 'pre-line' }}>
                                                        {section.content}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                {/* Document Information */}
                                <div className="card border-0 rounded-xxl shadow-xss mb-4 bg-pattern" style={{ 
                                    background: "linear-gradient(135deg, rgba(24, 119, 242, 0.03), rgba(66, 133, 244, 0.07))",
                                    backgroundImage: "url('/assets/images/dot-pattern.png')",
                                    backgroundSize: "300px",
                                    backgroundPosition: "center"
                                }}>
                                    <div className="card-body p-4">
                                        <div className="row align-items-center">
                                            <div className="col-md-8">
                                                <h4 className="fw-700 font-sm mb-2">Questions About Our Terms and Conditions?</h4>
                                                <p className="font-xss fw-500 text-grey-600 mb-0">
                                                    If you have any questions or concerns about our terms, please don't hesitate to contact our support team for clarification.
                                                </p>
                                            </div>
                                            <div className="col-md-4 text-md-end mt-3 mt-md-0">
                                                <a 
                                                    href="/contact" 
                                                    className="btn btn-primary border-0 p-3 rounded-lg d-inline-block"
                                                    style={{
                                                        background: "linear-gradient(90deg, #1877f2, #4285f4)",
                                                        color: "white",
                                                        fontWeight: "600",
                                                        fontSize: "14px"
                                                    }}
                                                >
                                                    <i className="feather-mail me-2"></i>
                                                    Contact Support
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Back to Top Button */}
                        {showBackToTop && (
                            <button 
                                className="back-to-top position-fixed d-flex align-items-center justify-content-center"
                                style={{
                                    bottom: '30px',
                                    right: '30px',
                                    width: '50px',
                                    height: '50px',
                                    background: 'linear-gradient(90deg, #1877f2, #4285f4)',
                                    color: 'white',
                                    borderRadius: '50%',
                                    border: 'none',
                                    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.15)',
                                    cursor: 'pointer',
                                    zIndex: 100,
                                    transition: 'all 0.3s ease'
                                }}
                                onClick={scrollToTop}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = 'translateY(-5px)';
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = 'translateY(0)';
                                }}
                            >
                                <i className="feather-chevron-up"></i>
                            </button>
                        )}

                        <div className="mt-2">
                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />
        </Fragment>
    );
};

export default Termandcondition;
