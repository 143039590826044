import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Chat from '../components/Chat/Chat';

const ChatPage = (props) => {
    return (
        <>
            <Header />
            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row">
                            <div className="col-12">
                                <Chat {...props} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Appfooter />
        </>
    );
};

export default ChatPage;