import React, { useState, useRef, useEffect, useContext } from 'react';
import { io } from 'socket.io-client';
import { AuthContext } from '../context/AuthContext';
import LiveIndicator from './LiveIndicator';

const LiveDiscussion = ({ eventId }) => {
  const { user } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isTTSEnabled, setIsTTSEnabled] = useState(
    JSON.parse(localStorage.getItem('isTTSEnabled')) ?? true
  );
  const messagesEndRef = useRef(null);
  const messagesEndRefContainer = useRef(null);
  const socketRef = useRef();
  const ttsQueue = useRef([]);
  const isPlaying = useRef(false);

  useEffect(() => {
    // Save TTS preference in localStorage
    localStorage.setItem('isTTSEnabled', JSON.stringify(isTTSEnabled));
  }, [isTTSEnabled]);

  useEffect(() => {
    // Initialize socket connection
    socketRef.current = io('https://api.picvot.com', { query: { eventId } });

    // Listen for incoming messages via socket
    socketRef.current.on('receiveMessage', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
      if (isTTSEnabled) {
        ttsQueue.current.push(message.message); // Add message to the TTS queue
        playNextTTS(); // Start TTS playback
      }
    });

    // Fetch previous messages
    const fetchMessages = async () => {
      try {
        const response = await fetch(`https://api.picvot.com/api/v1/livediscussion/${eventId}`);
        const data = await response.json();
        setMessages(data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();

    return () => {
      socketRef.current.off('receiveMessage');
      socketRef.current.disconnect();
    };
  }, [eventId, isTTSEnabled]);

  // Scroll only the chat area to the bottom
  useEffect(() => {
    if (messagesEndRefContainer.current) {
      messagesEndRefContainer.current.scrollTop = messagesEndRefContainer.current.scrollHeight;
    }
  }, [messages]);

  const playNextTTS = async () => {
    if (ttsQueue.current.length === 0 || isPlaying.current || !isTTSEnabled) {
      return;
    }

    isPlaying.current = true;
    const text = ttsQueue.current.shift();

    try {
      const response = await fetch(
        `https://texttospeech.googleapis.com/v1/text:synthesize?key=AIzaSyAkeVdl8x_XliuD2DnCFh59FIj1qQhn65Y`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            input: { text },
            voice: {
              languageCode: 'hi-IN',
              name: 'hi-IN-Standard-E',
            },
            audioConfig: { audioEncoding: 'MP3' },
          }),
        }
      );

      const data = await response.json();
      if (!data.audioContent) {
        console.error('Error fetching TTS audio:', data.error);
        isPlaying.current = false;
        playNextTTS();
        return;
      }

      const audio = new Audio(`data:audio/mp3;base64,${data.audioContent}`);
      audio.play();
      audio.onended = () => {
        isPlaying.current = false;
        playNextTTS();
      };
      audio.onerror = () => {
        console.error('Audio playback error.');
        isPlaying.current = false;
        playNextTTS();
      };
    } catch (error) {
      console.error('Error during TTS playback:', error);
      isPlaying.current = false;
      playNextTTS();
    }
  };

  return (
    <div style={{ width: '100%', marginBottom: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
      <div style={{ padding: '16px', borderBottom: '1px solid #ddd', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <LiveIndicator />

        <div
          style={{
            position: 'relative',
            width: '40px',
            height: '20px',
            backgroundColor: isTTSEnabled ? '#4CAF50' : '#ddd',
            borderRadius: '10px',
            transition: 'background-color 0.3s',
          }}
          onClick={() => setIsTTSEnabled(!isTTSEnabled)}
        >
          <div
            style={{
              position: 'absolute',
              top: '2px',
              left: isTTSEnabled ? '22px' : '2px',
              width: '16px',
              height: '16px',
              backgroundColor: '#fff',
              borderRadius: '50%',
              transition: 'left 0.3s',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            }}
          />
        </div>
      </div>

      <div
  style={{
    padding: '16px',
    overflowY: 'auto',
    maxHeight: '300px',
  }}
  ref={messagesEndRefContainer}
>
  {messages.map((message) => (
    <div
      key={message._id}
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: message?.userId === user?.id ? '#606060' : '#505050',
        color: 'white',
        padding: '10px',
        borderRadius: '8px',
        marginBottom: '8px',
        flexDirection: 'row',
      }}
    >
      {/* Profile Image */}
      <div
        style={{
          flexShrink: 0,
          width: '50px',
          height: '50px',
          marginRight: '10px',
        }}
      >
        <img
          src={message.userProfilePic}
          alt={`${message.Name}'s profile`}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            objectFit: 'cover',
            border: '2px solid #ddd',
          }}
        />
      </div>

      {/* Message Content */}
      <div style={{ flex: 1 }}>
        {/* Name */}
        <div style={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {message.Name}
        </div>

        {/* Timestamp */}
        <div style={{ fontSize: '0.8em', color: '#ccc', marginTop: '4px' }}>
          {new Date(message.timestamp).toLocaleString()}
        </div>

        {/* Divider Line */}
        <div
          style={{
            height: '1px',
            backgroundColor: '#ccc',
            margin: '8px 0',
          }}
        ></div>

        {/* Message Text */}
        <div style={{ wordWrap: 'break-word' }}>{message.message}</div>
      </div>
    </div>
  ))}
  <div ref={messagesEndRef}></div>
</div>



      <div style={{ padding: '16px', display: 'flex', alignItems: 'center' }}>
        {!user ? (
          <div style={{ color: '#606060', padding: '8px', border: '1px solid #ddd', borderRadius: '4px' }}>
            Please log in to discuss.
          </div>
        ) : (
          <>
            <input
              type="text"
              style={{ flex: 1, padding: '8px', borderRadius: '4px', marginRight: '8px', border: '1px solid #ddd' }}
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <button
              style={{ padding: '8px', backgroundColor: '#606060', color: 'white', borderRadius: '4px' }}
              onClick={() => {
                const messageData = { message: newMessage, eventId, userId: user.id };
                socketRef.current.emit('newMessage', messageData);
                setNewMessage('');
              }}
            >
              Send
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default LiveDiscussion;
