import axios from 'axios';

const API_URL = "https://api.tapcoe.com/api/v1";

// Get events API with optional authentication
export const feedView = async (page) => {
  try {
    const token = localStorage.getItem('token'); // Get the token from localStorage (if available)
    
    // Configure headers with optional token
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    // Make the GET request with headers
    const response = await axios.get(`${API_URL}/joinedevents/feed?page=${page}`, { headers });
    
    // Check if the response has data property and it's an array
    if (response.data && response.data.data && Array.isArray(response.data.data)) {
      return response.data.data; // Return just the data array
    } 
    // If the response itself is an array
    else if (response.data && Array.isArray(response.data)) {
      return response.data; // Return the entire data array
    }
    // Fallback to empty array if no valid data found
    return [];
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error; // Handle error appropriately
  }
};
