import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const CategorySlider = ({ categories = [] }) => {
  // Ensure categories is an array
  if (!Array.isArray(categories)) {
    console.error("categories should be an array");
    return null; // Return null if it's not an array
  }

  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
    customPaging: () => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: "rgba(66, 133, 244, 0.2)",
          display: "inline-block",
          margin: "0 4px",
          transition: "all 0.3s ease",
        }}
      ></div>
    ),
    dotsClass: "slick-dots custom-dots",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      }
    ]
  };

  return (
    <div 
      className="category-slider-container py-5 my-4"
      style={{
        background: "linear-gradient(to right, rgba(24, 119, 242, 0.02), rgba(66, 133, 244, 0.05))",
        borderRadius: "20px"
      }}
    >
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="col-12">
            <div className="section-heading d-flex flex-column align-items-center">
              <div className="decoration-line mb-3" style={{
                width: '60px',
                height: '4px',
                background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                borderRadius: '4px'
              }}></div>
              <h2 className="fw-bold text-center mb-2" style={{fontSize: '28px', color: '#333333'}}>Explore Categories</h2>
              <p className="text-muted text-center mb-0" style={{maxWidth: '600px', fontSize: '15px', lineHeight: '1.5'}}>
                Find events and contests in your preferred category
              </p>
            </div>
          </div>
        </div>

        <div className="position-relative category-slider-wrapper">
          {/* Navigation Buttons */}
          <div className="category-nav d-none d-md-block">
            <button 
              className="category-nav-prev position-absolute"
              onClick={() => {
                const slider = document.querySelector('.category-slider');
                if (slider) {
                  slider.slick?.slickPrev();
                }
              }}
              style={{
                left: '0',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: '10',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: 'linear-gradient(45deg, #1877f2, #4285f4)',
                color: 'white',
                border: 'none',
                boxShadow: '0 4px 10px rgba(24, 119, 242, 0.3)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.3s ease'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = 'translateY(-50%) scale(1.1)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = 'translateY(-50%)';
              }}
            >
              <i className="feather-chevron-left" style={{fontSize: '18px'}}></i>
            </button>
            <button 
              className="category-nav-next position-absolute"
              onClick={() => {
                const slider = document.querySelector('.category-slider');
                if (slider) {
                  slider.slick?.slickNext();
                }
              }}
              style={{
                right: '0',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: '10',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: 'linear-gradient(45deg, #1877f2, #4285f4)',
                color: 'white',
                border: 'none',
                boxShadow: '0 4px 10px rgba(24, 119, 242, 0.3)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.3s ease'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = 'translateY(-50%) scale(1.1)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = 'translateY(-50%)';
              }}
            >
              <i className="feather-chevron-right" style={{fontSize: '18px'}}></i>
            </button>
          </div>

          <Slider {...sliderSettings} className="category-slider">
            {categories.map((category, index) => (
              <div className="category-slide-item px-3" key={index}>
                <Link
                  to={`/events/${category.slug}`}
                  className="category-card d-block position-relative"
                  style={{
                    width: "180px",
                    padding: "20px 15px",
                    borderRadius: "18px",
                    background: "linear-gradient(135deg, #ffffff 0%, #f5f8ff 100%)",
                    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.05)",
                    overflow: "hidden",
                    textDecoration: "none",
                    transition: "all 0.3s ease",
                    border: "1px solid rgba(120, 85, 245, 0.08)",
                    transform: "translateY(0)",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "translateY(-8px)";
                    e.currentTarget.style.boxShadow = "0 20px 30px rgba(66, 133, 244, 0.15)";
                    e.currentTarget.style.borderColor = "rgba(120, 85, 245, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "translateY(0)";
                    e.currentTarget.style.boxShadow = "0 10px 20px rgba(0, 0, 0, 0.05)";
                    e.currentTarget.style.borderColor = "rgba(120, 85, 245, 0.08)";
                  }}
                >
                  {/* Decorative elements */}
                  <div 
                    className="decoration-dot position-absolute"
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      background: 'rgba(66, 133, 244, 0.3)',
                      top: '15px',
                      right: '15px'
                    }}
                  ></div>
                  <div 
                    className="decoration-dot position-absolute"
                    style={{
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      background: 'rgba(120, 85, 245, 0.3)',
                      top: '30px',
                      right: '25px'
                    }}
                  ></div>
                  
                  <div 
                    className="category-icon-wrapper d-flex justify-content-center align-items-center mb-3 mx-auto"
                    style={{
                      width: '90px',
                      height: '90px',
                      background: 'linear-gradient(135deg, rgba(120, 85, 245, 0.08) 0%, rgba(66, 133, 244, 0.12) 100%)',
                      borderRadius: '50%',
                      padding: '4px'
                    }}
                  >
                    <img
                      src={category.icon}
                      alt={category.name}
                      className="rounded-circle bg-white p-2"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        border: "4px solid white",
                        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.05)"
                      }}
                    />
                  </div>
                  
                  <div className="category-content text-center">
                    <h4
                      className="mb-1 fw-bold"
                      style={{
                        fontSize: "17px",
                        color: "#333",
                      }}
                    >
                      {category.name}
                    </h4>
                    <div className="d-flex align-items-center justify-content-center">
                      <p 
                        className="mb-0 text-muted me-1"
                        style={{
                          fontSize: "13px",
                        }}
                      >
                        View Events
                      </p>
                      <i className="feather-arrow-right" style={{ fontSize: "14px", color: "#4285f4" }}></i>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>

        {/* Category count indicator */}
        <div className="row mt-4 justify-content-center">
          <div className="col-auto">
            <div 
              className="category-count d-inline-flex align-items-center py-2 px-3 rounded-pill"
              style={{
                background: "rgba(24, 119, 242, 0.08)",
                border: "1px solid rgba(24, 119, 242, 0.1)"
              }}
            >
              <span className="fw-500 me-2" style={{ fontSize: "14px", color: "#1877f2" }}>
                {categories.length} Categories
              </span>
              <div 
                className="pulse"
                style={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  background: "#1877f2"
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorySlider;
