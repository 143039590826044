import React, { Component } from 'react';

class Events extends Component {
    render() {
        const { joinedEvents } = this.props;

        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex align-items-center p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">My Recent Events</h4>
                    <a href="/events" className="fw-600 ms-auto font-xssss text-primary">See all</a>
                </div>
                {joinedEvents && joinedEvents.length > 0 ? (
                    joinedEvents.map((event, index) => {
                        const eventDetail = event.eventDetails; // Get event details
                        const hasValidDetails = Object.keys(eventDetail).length > 0; // Check if eventDetails has data

                        if (!hasValidDetails) return <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                        <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                            You have not joined any events.
                        </h4>
                    </div>; // Skip rendering if eventDetails is empty

                        const date = new Date(eventDetail.createdAt);
                        const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
                        const day = date.getDate();

                        return (
                            <a href={`/eventpost/${eventDetail.slug}`} key={index} className="text-decoration-none">
                                <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                                    <div className={`me-2 p-3 rounded-xxl ${eventDetail.status ? 'bg-success' : 'bg-danger'}`}>
                                        <h4 className="fw-700 font-lg ls-3 lh-1 text-white mb-0">
                                            <span className="ls-1 d-block font-xsss text-white fw-600">{month}</span>
                                            {day}
                                        </h4>
                                    </div>
                                    <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                                        {eventDetail.title || 'Untitled Event'}
                                        <span className="d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500">
                                         {/* //   {eventDetail.prize || 'No Prize'} */}
                                        </span>
                                    </h4>
                                </div>
                            </a>
                        );
                    })
                ) : (
                    <div className="card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden">
                        <h4 className="fw-700 text-grey-900 font-xssss mt-2">
                            You have not joined any events.
                        </h4>
                    </div>
                )}

            </div>
        );
    }
}

export default Events;
