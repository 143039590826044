import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import moment from 'moment';
import { getCommentDetails, sendComment, sendReply } from '../api/getComments';

// Define API base URL directly since constant file is missing
const API_BASE_URL = "https://api.tapcoe.com/api/v1/comments";

const CommentSection = ({ postId, eventId }) => {
    const { user, isLoggedIn } = useAuth();
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const commentsContainerRef = useRef(null);
    const lastCommentRef = useRef(null);
    const [replyingTo, setReplyingTo] = useState(null);
    const [replyText, setReplyText] = useState('');

    useEffect(() => {
        fetchComments();
    }, [postId]);

    // Scroll to bottom when comments change
    useEffect(() => {
        scrollToBottom();
    }, [comments]);

    const fetchComments = async () => {
        try {
            setLoading(true);
            const response = await getCommentDetails(postId);
            // Ensure we're handling an array of comments
            const commentsArray = Array.isArray(response) ? response : [];
            setComments(commentsArray);
            setLoading(false);
            
            // Scroll to bottom after loading comments
            setTimeout(() => {
                scrollToBottom();
            }, 100);
        } catch (error) {
            console.error('Error fetching comments:', error);
            setLoading(false);
        }
    };

    const scrollToBottom = () => {
        if (commentsContainerRef.current) {
            commentsContainerRef.current.scrollTop = commentsContainerRef.current.scrollHeight;
        }
        if (lastCommentRef.current) {
            lastCommentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSubmitComment = async (e) => {
        e.preventDefault();
        if (!newComment.trim() || !isLoggedIn) return;
        
        try {
            setSubmitting(true);
            const response = await sendComment(postId, eventId, newComment);
            
            // Add the new comment to the list
            setComments(prevComments => [...prevComments, response]);
            setNewComment('');
            setSubmitting(false);
            
            // Scroll to the new comment
            setTimeout(() => {
                scrollToBottom();
            }, 100);
        } catch (error) {
            console.error('Error posting comment:', error);
            setSubmitting(false);
        }
    };

    const handleReplyClick = (commentId) => {
        setReplyingTo(commentId);
        setReplyText('');
    };

    const handleCancelReply = () => {
        setReplyingTo(null);
        setReplyText('');
    };

    const handleSubmitReply = async (commentId) => {
        if (!replyText.trim() || !isLoggedIn) return;
        
        try {
            setSubmitting(true);
            const response = await sendReply(postId, eventId, replyText, commentId);
            
            // Update the comment with the new reply
            setComments(prevComments => 
                prevComments.map(comment => 
                    comment._id === commentId 
                        ? {
                            ...comment,
                            replies: [...(comment.replies || []), response]
                          }
                        : comment
                )
            );
            
            setReplyText('');
            setReplyingTo(null);
            setSubmitting(false);
        } catch (error) {
            console.error('Error posting reply:', error);
            setSubmitting(false);
        }
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);

        if (diffInSeconds < 60) return 'just now';
        if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
        if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
        if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d ago`;
        return moment(date).format('MMM D, YYYY');
    };

    return (
        <div className="comments-wrapper">
            <div className="comments-container" ref={commentsContainerRef}>
                {loading ? (
                    <div className="text-center py-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-2">Loading comments...</p>
                    </div>
                ) : comments.length === 0 ? (
                    <div className="empty-state text-center py-5">
                        <div className="empty-icon mb-3">
                            <i className="feather-message-square display-4 text-muted"></i>
                        </div>
                        <h5 className="mb-2">No comments yet</h5>
                        <p className="text-muted">Be the first to leave a comment</p>
                    </div>
                ) : (
                    <div className="comments-list p-3">
                        {comments.map((comment, index) => {
                            const isLastComment = index === comments.length - 1;
                            const ref = isLastComment ? lastCommentRef : null;
                            
                            return (
                                <div 
                                    key={comment._id} 
                                    ref={ref}
                                    className="comment-item mb-3"
                                >
                                    <div className="comment-header d-flex align-items-center mb-2">
                                        <div className="avatar me-2">
                                            <img 
                                                src={comment.userDetails?.profilePic || 'https://via.placeholder.com/40'} 
                                                alt={comment.userDetails?.name || 'User'} 
                                                className="rounded-circle" 
                                                width="40" 
                                                height="40"
                                                style={{ objectFit: 'cover' }}
                                            />
                                        </div>
                                        <div className="comment-author">
                                            <div className="author-name fw-bold">
                                                {comment.userDetails?.name || 'Unknown User'}
                                            </div>
                                            <div className="comment-time text-muted small">
                                                {formatTime(comment.createdAt)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-content ms-5 ps-2">
                                        <div className="comment-text">
                                            {comment.commentText}
                                        </div>
                                        
                                        {isLoggedIn && (
                                            <div className="comment-actions mt-1">
                                                <button 
                                                    className="btn btn-sm btn-link p-0 text-primary"
                                                    onClick={() => handleReplyClick(comment._id)}
                                                >
                                                    Reply
                                                </button>
                                            </div>
                                        )}
                                        
                                        {replyingTo === comment._id && (
                                            <div className="reply-form mt-2">
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm rounded-pill"
                                                        placeholder="Write a reply..."
                                                        value={replyText}
                                                        onChange={(e) => setReplyText(e.target.value)}
                                                        disabled={submitting}
                                                    />
                                                    <div className="d-flex">
                                                        <button 
                                                            className="btn btn-sm btn-primary ms-2 rounded-pill"
                                                            onClick={() => handleSubmitReply(comment._id)}
                                                            disabled={!replyText.trim() || submitting}
                                                        >
                                                            Reply
                                                        </button>
                                                        <button 
                                                            className="btn btn-sm btn-light ms-1 rounded-pill"
                                                            onClick={handleCancelReply}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        
                                        {comment.replies && comment.replies.length > 0 && (
                                            <div className="replies-section mt-2">
                                                {comment.replies.map(reply => (
                                                    <div key={reply._id} className="reply-item ms-3 mt-2">
                                                        <div className="reply-header d-flex align-items-center">
                                                            <div className="avatar me-2">
                                                                <img 
                                                                    src={reply.userDetails?.profilePic || 'https://via.placeholder.com/30'} 
                                                                    alt={reply.userDetails?.name || 'User'} 
                                                                    className="rounded-circle" 
                                                                    width="30" 
                                                                    height="30"
                                                                    style={{ objectFit: 'cover' }}
                                                                />
                                                            </div>
                                                            <div className="reply-author">
                                                                <div className="author-name fw-bold small">
                                                                    {reply.userDetails?.name || 'Unknown User'}
                                                                </div>
                                                                <div className="reply-time text-muted smaller">
                                                                    {formatTime(reply.createdAt)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="reply-content ms-5 ps-2">
                                                            <div className="reply-text small">
                                                                {reply.commentText}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            
            <div className="comment-form-container border-top p-3">
                {isLoggedIn ? (
                    <form onSubmit={handleSubmitComment} className="comment-form">
                        <div className="input-group">
                            <div className="input-group-prepend d-none d-md-block">
                                <div className="input-avatar me-2">
                                    <img 
                                        src={user?.photoURL || 'https://via.placeholder.com/40'} 
                                        alt="Your avatar" 
                                        className="rounded-circle" 
                                        width="40" 
                                        height="40"
                                        style={{ objectFit: 'cover' }}
                                    />
                                </div>
                            </div>
                            <input
                                type="text"
                                className="form-control rounded-pill"
                                placeholder="Write a comment..."
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                disabled={submitting}
                            />
                            <button 
                                type="submit" 
                                className="btn btn-primary ms-2 rounded-circle comment-submit"
                                disabled={!newComment.trim() || submitting}
                            >
                                {submitting ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    <i className="feather-send"></i>
                                )}
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="login-prompt text-center p-3 bg-light rounded">
                        <i className="feather-lock me-2"></i>
                        Please <a href="/login" className="text-primary fw-bold">login</a> to leave a comment
                    </div>
                )}
            </div>
            
            <style jsx="true">{`
                .comments-wrapper {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    min-height: 400px;
                }
                
                .comments-container {
                    flex: 1;
                    overflow-y: auto;
                    background-color: #f8f9fa;
                    scroll-behavior: smooth;
                    height: 400px; /* Fixed height */
                    max-height: 400px; /* Maximum height */
                }
                
                .comments-list {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 20px; /* Add padding at bottom for better scrolling */
                }
                
                .comment-item {
                    background-color: white;
                    border-radius: 12px;
                    padding: 15px;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.08);
                }
                
                .comment-header {
                    position: relative;
                }
                
                .comment-author {
                    flex: 1;
                }
                
                .author-name {
                    font-size: 0.95rem;
                    line-height: 1.2;
                }
                
                .comment-time {
                    font-size: 0.75rem;
                    opacity: 0.8;
                }
                
                .comment-content {
                    border-left: 2px solid #e9ecef;
                }
                
                .comment-text {
                    white-space: pre-wrap;
                    font-size: 0.95rem;
                    line-height: 1.5;
                }
                
                .comment-actions {
                    display: flex;
                    gap: 15px;
                }
                
                .comment-actions button {
                    font-size: 0.8rem;
                    opacity: 0.8;
                    transition: opacity 0.2s;
                }
                
                .comment-actions button:hover {
                    opacity: 1;
                    text-decoration: underline;
                }
                
                .reply-form {
                    margin-left: 20px;
                }
                
                .comment-form-container {
                    background-color: white;
                    position: relative;
                    z-index: 10;
                }
                
                .comment-form {
                    display: flex;
                    align-items: center;
                }
                
                .input-avatar {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;
                }
                
                .input-avatar img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                
                .comment-submit {
                    width: 40px;
                    height: 40px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
                .replies-section {
                    border-left: 2px solid #e9ecef;
                    margin-left: 20px;
                    padding-left: 15px;
                }
                
                .reply-item {
                    background-color: #f8f9fa;
                    border-radius: 8px;
                    padding: 10px;
                    margin-top: 8px;
                }
                
                .reply-header {
                    margin-bottom: 5px;
                }
                
                .reply-author {
                    flex: 1;
                }
                
                .reply-time {
                    font-size: 0.7rem;
                }
                
                .reply-content {
                    margin-left: 15px;
                }
                
                .reply-text {
                    color: #495057;
                }
                
                .smaller {
                    font-size: 0.7rem;
                }
                
                .empty-state {
                    color: #6c757d;
                }
                
                .empty-icon {
                    opacity: 0.5;
                }
                
                /* Mobile responsiveness */
                @media (max-width: 768px) {
                    .comment-content {
                        margin-left: 15px !important;
                        padding-left: 10px !important;
                    }
                    
                    .replies-section {
                        margin-left: 10px;
                    }
                    
                    .reply-item {
                        padding: 8px;
                    }
                    
                    .reply-form .input-group {
                        flex-direction: column;
                    }
                    
                    .reply-form .input-group .d-flex {
                        margin-top: 8px;
                    }
                }
            `}</style>
        </div>
    );
};

export default CommentSection;
