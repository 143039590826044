import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Copyright from "../components/Copyright";
import GoogleAd from "../components/GoogleAd";
import AdComponent from "../components/AdComponent";
import Feedview from "../components/Feedview";
import { feedView } from "../api/feedApi";
import { useAuth } from "../context/AuthContext";
import ContentLoader from "react-content-loader";
import PremiumAd from "../components/PremiumAd";

const Feed = () => {
    const { isLoggedIn } = useAuth();
    const [eventList, setEventList] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);
    const observer = useRef();
    const lastPostElementRef = useRef(null);
    const loadingRef = useRef(null);
    const [isSticky, setIsSticky] = useState(false);

    // Create Post States
    const [showCreatePost, setShowCreatePost] = useState(false);
    const [postText, setPostText] = useState('');
    const [postImage, setPostImage] = useState(null);
    const [postVideo, setPostVideo] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [videoPreview, setVideoPreview] = useState('');
    const [uploadingPost, setUploadingPost] = useState(false);
    const fileInputRef = useRef(null);
    const createPostRef = useRef(null);

    // Fetch feed data
    const fetchFeedData = async (isLoadMore = false) => {
        if (loading || loadingMore || !hasMore) return;

        if (isLoadMore) setLoadingMore(true);
        else setLoading(true);

        try {
            console.log(`Fetching page ${page}`); // Debug log
            const response = await feedView(page);
            console.log('API Response:', response); // Debug log
            
            if (response && response.length > 0) {
                console.log(`Found ${response.length} posts`); // Debug log
                
                setEventList(prev => [...prev, ...response]);
                setPage(prevPage => prevPage + 1);
                
                // If we received less than expected items (e.g., less than 10), assume it's the last page
                if (response.length < 10) {
                    console.log('Reached last page (received less than 10 items)'); // Debug log
                    setHasMore(false);
                }
                
                // Add small delay before fade in effect
                if (!isLoadMore) {
                    setTimeout(() => setFadeIn(true), 150);
                }
            } else {
                console.log('No more posts available'); // Debug log
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching event data:", error);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    // Intersection Observer callback for infinite scrolling
    const lastElementRef = useCallback(node => {
        if (loadingMore || !hasMore) return;
        
        if (observer.current) {
            observer.current.disconnect();
        }
        
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                console.log('Last post visible, loading more...'); // Debug log
                fetchFeedData(true);
            }
        }, {
            rootMargin: '200px', // Load a bit earlier for smoother experience
            threshold: 0.1
        });
        
        if (node) {
            console.log('Observing last element'); // Debug log
            observer.current.observe(node);
        }
    }, [loadingMore, hasMore]);

    // Handle scroll for sticky create post
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            
            // Make create post sticky when scrolled past a certain point
            if (scrollPosition > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
            
            // Existing scroll logic for infinite loading
            const pageBottom = document.documentElement.offsetHeight - 500;
            if (scrollPosition + window.innerHeight >= pageBottom && !loading && !loadingMore && hasMore) {
                console.log('Scroll position triggered loading more');
                fetchFeedData(true);
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, loadingMore, hasMore]);

    // Load initial data
    useEffect(() => {
        fetchFeedData();
        
        // Clean up observer when component unmounts
        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, []);

    // Handle file selection
    const handleFileSelect = (e, type) => {
        const file = e.target.files[0];
        if (!file) return;

        if (type === 'image') {
            setPostImage(file);
            setPostVideo(null);
            setVideoPreview('');
            
            // Create image preview
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else if (type === 'video') {
            setPostVideo(file);
            setPostImage(null);
            setImagePreview('');
            
            // Create video preview
            const reader = new FileReader();
            reader.onloadend = () => {
                setVideoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // Clear the create post form
    const clearCreatePostForm = () => {
        setPostText('');
        setPostImage(null);
        setPostVideo(null);
        setImagePreview('');
        setVideoPreview('');
        setShowCreatePost(false);
    };

    // Handle post submission
    const handlePostSubmit = async (e) => {
        e.preventDefault();
        
        if (!postText && !postImage && !postVideo) {
            alert('Please add some content to post');
            return;
        }
        
        setUploadingPost(true);
        
        try {
            // Simulation of post submission - replace with actual API call
            setTimeout(() => {
                // Success message
                alert('Features coming soon! Stay tuned for updates.');
                setUploadingPost(false);
                clearCreatePostForm();
                
                // TODO: Implement actual API call to submit post
                // const formData = new FormData();
                // formData.append('text', postText);
                // if (postImage) formData.append('image', postImage);
                // if (postVideo) formData.append('video', postVideo);
                // await submitPost(formData);
            }, 1500);
        } catch (error) {
            console.error('Error submitting post:', error);
            alert('Failed to submit post. Please try again.');
            setUploadingPost(false);
        }
    };

    // Fancy loader for initial loading
    const FancyLoader = () => (
        <div className="d-flex justify-content-center align-items-center py-5">
            <div className="fancy-loader">
                <div className="ring"></div>
                <div className="ring"></div>
                <div className="dot"></div>
                <p className="loading-text mt-3">Loading amazing content...</p>
            </div>
        </div>
    );

    return (
        <>
            <Header />
            <Leftnav />
            <div className="main-content-wrapper">
                <div className="main-content">
                    <div className="row feed-body mt-5">
                        <div className="col-xl-8 col-xxl-9 col-lg-8">
                            {/* Create Post Card - Sticky */}
                            <div 
                                ref={createPostRef}
                                className={`card w-100 shadow-sm rounded-lg border-0 p-0 mb-4 ${isSticky ? 'sticky-create-post' : ''}`}
                                style={{
                                    position: isSticky ? 'sticky' : 'relative',
                                    top: isSticky ? '80px' : 'auto',
                                    zIndex: isSticky ? '990' : 'auto',
                                    transition: 'all 0.3s ease',
                                    boxShadow: isSticky ? '0 6px 12px rgba(0,0,0,0.1)' : '',
                                    transform: isSticky ? 'translateY(0)' : 'none',
                                    animation: isSticky ? 'slideDown 0.3s forwards' : 'none',
                                    borderBottom: isSticky ? '1px solid rgba(0,0,0,0.1)' : 'none'
                                }}
                            >
                                <div className="card-body p-3">
                                    {!showCreatePost ? (
                                        <div className="d-flex align-items-center">
                                            <figure className="avatar me-3 mb-0" style={{ height: '45px', width: '45px' }}>
                                                <img 
                                                    src={isLoggedIn ? "https://placehold.co/100x100?text=User" : "https://placehold.co/100x100?text=Guest"} 
                                                    alt="User" 
                                                    className="shadow-sm rounded-circle"
                                                    style={{ height: '100%', width: '100%', objectFit: 'cover', border: '2px solid #ebedf6' }} 
                                                />
                                            </figure>
                                            <div 
                                                className="form-control rounded-xxl border-0 bg-lightblue w-100 p-3"
                                                onClick={() => isLoggedIn ? setShowCreatePost(true) : alert('Please login to create a post')}
                                                style={{ 
                                                    cursor: 'pointer',
                                                    boxShadow: 'none',
                                                    transition: 'all 0.2s',
                                                    background: '#f5f8fa',
                                                    borderRadius: '30px',
                                                    ':hover': {
                                                        background: '#eef3f7'
                                                    }
                                                }}
                                            >
                                                <div className="fw-500 text-grey-500">What's on your mind?</div>
                                            </div>
                                        </div>
                                    ) : (
                                        <form onSubmit={handlePostSubmit}>
                                            <div className="form-group">
                                                <textarea
                                                    className="form-control border-0 p-3 bg-lightblue rounded-lg"
                                                    rows="3"
                                                    placeholder="What's on your mind?"
                                                    value={postText}
                                                    onChange={(e) => setPostText(e.target.value)}
                                                ></textarea>
                                            </div>
                                            
                                            {imagePreview && (
                                                <div className="image-preview mt-3 position-relative">
                                                    <img 
                                                        src={imagePreview} 
                                                        alt="Preview" 
                                                        className="rounded-lg w-100"
                                                        style={{ maxHeight: '300px', objectFit: 'contain' }}
                                                    />
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-danger btn-sm position-absolute"
                                                        style={{ top: '10px', right: '10px', borderRadius: '50%', padding: '5px 10px' }}
                                                        onClick={() => {
                                                            setPostImage(null);
                                                            setImagePreview('');
                                                        }}
                                                    >
                                                        <i className="feather-x"></i>
                                                    </button>
                                                </div>
                                            )}
                                            
                                            {videoPreview && (
                                                <div className="video-preview mt-3 position-relative">
                                                    <video 
                                                        src={videoPreview} 
                                                        controls 
                                                        className="rounded-lg w-100"
                                                        style={{ maxHeight: '300px' }}
                                                    ></video>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-danger btn-sm position-absolute"
                                                        style={{ top: '10px', right: '10px', borderRadius: '50%', padding: '5px 10px' }}
                                                        onClick={() => {
                                                            setPostVideo(null);
                                                            setVideoPreview('');
                                                        }}
                                                    >
                                                        <i className="feather-x"></i>
                                                    </button>
                                                </div>
                                            )}
                                            
                                            <div className="d-flex justify-content-between align-items-center mt-3">
                                                <div className="post-options d-flex gap-2">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-light d-flex align-items-center"
                                                        onClick={() => {
                                                            fileInputRef.current.accept = 'image/*';
                                                            fileInputRef.current.click();
                                                        }}
                                                    >
                                                        <i className="feather-image text-success me-2"></i>
                                                        <span className="d-none d-md-block">Photo</span>
                                                    </button>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-light d-flex align-items-center"
                                                        onClick={() => {
                                                            fileInputRef.current.accept = 'video/*';
                                                            fileInputRef.current.click();
                                                        }}
                                                    >
                                                        <i className="feather-video text-danger me-2"></i>
                                                        <span className="d-none d-md-block">Video</span>
                                                    </button>
                                                    <input 
                                                        type="file" 
                                                        ref={fileInputRef}
                                                        className="d-none"
                                                        onChange={(e) => {
                                                            if (fileInputRef.current.accept === 'image/*') {
                                                                handleFileSelect(e, 'image');
                                                            } else {
                                                                handleFileSelect(e, 'video');
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-light"
                                                        onClick={clearCreatePostForm}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button 
                                                        type="submit" 
                                                        className="btn btn-primary"
                                                        disabled={uploadingPost || (!postText && !postImage && !postVideo)}
                                                    >
                                                        {uploadingPost ? (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                                Posting...
                                                            </>
                                                        ) : (
                                                            'Post'
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>

                            {/* Style for sticky create post animation */}
                            <style jsx="true">{`
                                @keyframes slideDown {
                                    from {
                                        transform: translateY(-100%);
                                        opacity: 0;
                                    }
                                    to {
                                        transform: translateY(0);
                                        opacity: 1;
                                    }
                                }
                                
                                .sticky-create-post {
                                    backdrop-filter: blur(10px);
                                    background-color: rgba(255,255,255,0.95);
                                    border-radius: 15px;
                                    margin-bottom: 20px;
                                }
                            `}</style>

                            {loading && eventList.length === 0 ? (
                                <FancyLoader />
                            ) : (
                                <>
                                    <div className={`posts-container ${fadeIn ? 'fade-in' : ''}`}>
                                        {eventList.length === 0 && !loading ? (
                                            <div className="empty-state text-center p-5 bg-light rounded-lg shadow-sm">
                                                <div className="empty-icon mb-3">
                                                    <i className="feather-image text-grey-500" style={{fontSize: '48px'}}></i>
                                                </div>
                                                <h4 className="fw-600 text-grey-900">No posts available yet</h4>
                                                <p className="font-xssss fw-400 text-grey-500 mt-2">
                                                    Check back later for new posts!
                                                </p>
                                            </div>
                                        ) : (
                                            <>
                                                {console.log('Rendering posts: ', eventList.length)}
                                                {eventList.map((event, index) => {
                                                    console.log('Rendering post', index, event.postId);
                                                    return (
                                                        <div 
                                                            key={event.postId || index} 
                                                            ref={index === eventList.length - 1 ? lastElementRef : null}
                                                            className={`post-item animate-item delay-${index % 5}`}
                                                        >
                                                            <Feedview
                                                                id={event.postId}
                                                                eventId={event.eventDetails?.eventId}
                                                                postId={event.postId}
                                                                postvideo={event.postvideo || ""}
                                                                voteCount={event.voteCount || 0}
                                                                hasVoted={event.hasVoted || false}
                                                                location={event.userDetails?.registerLocation || ""}
                                                                postimage={event.imageUrl || "post.png"}
                                                                avater={event.userDetails?.profilePic || "user.png"}
                                                                users={event.userDetails?.name || "Anonymous"}
                                                                time={event.createdAt || "Just now"}
                                                                des={event.description || ""}
                                                                slug={event.eventDetails?.slug || ""}
                                                                status={event.status || ""}
                                                                rejectReason={event.rejectReason || ""}
                                                                name={event.userDetails?.name || "Anonymous"}
                                                                userName={event.userDetails?.username || "anonymous"}
                                                                userProfilePic={event.userDetails?.profilePic || "user.png"}
                                                                postImageUrl={event.imageUrl || "post.png"}
                                                                postedAt={event.createdAt || "Just now"}
                                                                registerLocation={
                                                                    typeof event.userDetails?.registerLocation === 'object' 
                                                                    ? (event.userDetails?.registerLocation?.city || '') + 
                                                                      (event.userDetails?.registerLocation?.state ? ', ' + event.userDetails?.registerLocation?.state : '') +
                                                                      (event.userDetails?.registerLocation?.country ? ', ' + event.userDetails?.registerLocation?.country : '')
                                                                    : event.userDetails?.registerLocation || ""
                                                                }
                                                                userId={event.userDetails?.userId || ""}
                                                            />
                                                            {(index + 1) % 3 === 0 && 
                                                                <div className="ad-container">
                                                                    {(index + 1) % 9 === 0 ? (
                                                                        <PremiumAd 
                                                                            title="Partner Spotlight: Photography Contest" 
                                                                            description="Join our exclusive photo contest and showcase your best work. Cash prizes and photography gear for winners."
                                                                            ctaText="Learn More"
                                                                            ctaLink="/hostevent"
                                                                            imageUrl="https://placehold.co/600x400/1877f2/ffffff?text=Photo+Contest"
                                                                            slot="4752297090"
                                                                        />
                                                                    ) : (index + 1) % 6 === 0 ? (
                                                                        <AdComponent 
                                                                            format="rectangle" 
                                                                            slot="4752297090"
                                                                            className="mb-4 animate-item"
                                                                        />
                                                                    ) : (
                                                                        <GoogleAd />
                                                                    )}
                                                                </div>
                                                            }
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </div>

                                    {/* Loading wave indicator for loading more */}
                                    {loadingMore && (
                                        <div className="loading-wave-container text-center py-3 mt-3">
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                        </div>
                                    )}

                                    {/* End of content message */}
                                    {!hasMore && eventList.length > 0 && !loadingMore && (
                                        <div className="end-message text-center py-3 text-grey-500 font-xsss mt-3 bg-light rounded-lg shadow-sm">
                                            <i className="feather-check-circle me-2"></i>
                                            You've seen all posts
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <Appfooter />
            </div>
            <Copyright />

            <style jsx="true">{`
                .fancy-loader {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    height: 100px;
                }
                .ring {
                    width: 60px;
                    height: 60px;
                    border: 4px solid rgba(0, 0, 0, 0.1);
                    border-radius: 50%;
                    position: absolute;
                }
                .ring:nth-child(1) {
                    border-bottom-color: #5271FF;
                    animation: rotate 1.5s linear infinite;
                }
                .ring:nth-child(2) {
                    border-top-color: #5271FF;
                    width: 40px;
                    height: 40px;
                    animation: rotate 1.5s linear infinite reverse;
                }
                .dot {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #5271FF;
                }
                .loading-text {
                    font-size: 14px;
                    color: #5271FF;
                    margin-top: 10px;
                }
                @keyframes rotate {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                
                .fade-in {
                    animation: fadeIn 0.4s ease-in;
                }
                @keyframes fadeIn {
                    from { opacity: 0; transform: translateY(10px); }
                    to { opacity: 1; transform: translateY(0); }
                }
                
                .post-item {
                    opacity: 0;
                    transform: translateY(15px);
                    animation: slideIn 0.5s forwards;
                    margin-bottom: 20px;
                }
                
                .animate-item {
                    opacity: 0;
                    transform: translateY(15px);
                    animation: slideIn 0.5s forwards;
                }
                
                .delay-0 { animation-delay: 0s; }
                .delay-1 { animation-delay: 0.1s; }
                .delay-2 { animation-delay: 0.2s; }
                .delay-3 { animation-delay: 0.3s; }
                .delay-4 { animation-delay: 0.4s; }
                
                @keyframes slideIn {
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                
                .loading-wave-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                }
                
                .loading-bar {
                    display: inline-block;
                    width: 5px;
                    height: 20px;
                    margin: 0 3px;
                    border-radius: 4px;
                    background-color: #5271FF;
                    animation: loading-wave 1s ease-in-out infinite;
                }
                
                .loading-bar:nth-child(2) {
                    animation-delay: 0.1s;
                }
                
                .loading-bar:nth-child(3) {
                    animation-delay: 0.2s;
                }
                
                .loading-bar:nth-child(4) {
                    animation-delay: 0.3s;
                }
                
                .loading-bar:nth-child(5) {
                    animation-delay: 0.4s;
                }
                
                @keyframes loading-wave {
                    0% {
                        transform: scale(1);
                    }
                    20% {
                        transform: scale(1, 2);
                    }
                    40% {
                        transform: scale(1);
                    }
                }
                
                .end-message {
                    padding: 15px;
                    border-radius: 10px;
                    transition: all 0.3s ease;
                }
                
                .end-message:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 10px 25px rgba(0,0,0,0.1) !important;
                }
                
                .empty-state {
                    transition: all 0.3s ease;
                }
                
                .empty-state:hover {
                    transform: translateY(-5px);
                }
            `}</style>
        </>
    );
};

export default Feed;
