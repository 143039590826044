import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { profileUpdateOrGet, updateProfile, uploadProfileImage, uploadCoverImage } from "../api/profileUpdateOrGet"; // Add uploadProfileImage and uploadCoverImage imports

const Account = () => {
    const [userData, setUserData] = useState({
        name: "",
        username: "",
        dob: "",
        email: "",
        profile: "", // Change avatar to profile
        coverPhoto: "",
        about: "", // Add about section to userData state
    });

    const [profilePreview, setProfilePreview] = useState(null); // Change avatarPreview to profilePreview
    const [coverPreview, setCoverPreview] = useState(null);
    const [profileFile, setProfileFile] = useState(null); // Change avatarFile to profileFile
    const [coverFile, setCoverFile] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            const data = await profileUpdateOrGet();
            setUserData(data);

            setProfilePreview(data?.profilePic); // Change avatarPreview to profilePreview
            setCoverPreview(data?.coverPic);
        };
        fetchUserDetails();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = async (e, type) => {
        const file = e.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            if (type === "profile") {
                setProfileFile(file);
                setProfilePreview(previewUrl); // Change avatarPreview to profilePreview

                // Call uploadProfileImage here
                try {
                    const profileResponse = await uploadProfileImage(file);
                    if (profileResponse.success) {
                        alert("Profile image uploaded successfully!");
                        // Optionally, update userData with new profile image URL
                        setUserData((prev) => ({ ...prev, profile: profileResponse.imageUrl })); // Adjust based on response structure
                    } else {
                        alert(profileResponse.message || "Failed to upload profile image.");
                    }
                } catch (error) {
                    console.error("Error uploading profile image:", error);
                    alert("An error occurred while uploading the profile image.");
                }
            } else if (type === "cover") {
                setCoverFile(file);
                setCoverPreview(previewUrl);

                // Call uploadCoverImage here
                try {
                    const coverResponse = await uploadCoverImage(file);
                    if (coverResponse.success) {
                        alert("Cover image uploaded successfully!");
                        // Optionally, update userData with new cover image URL
                        setUserData((prev) => ({ ...prev, coverPhoto: coverResponse.imageUrl })); // Adjust based on response structure
                    } else {
                        alert(coverResponse.message || "Failed to upload cover image.");
                    }
                } catch (error) {
                    console.error("Error uploading cover image:", error);
                    alert("An error occurred while uploading the cover image.");
                }
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare the body with only necessary fields
        const updateData = {
            name: userData.name,
            username: userData.username,
            dob: userData.dob,
            email: userData.email,
            about: userData.about,
        };

        try {
            const response = await updateProfile(updateData);

            if (response.success) {
                alert("Profile updated successfully!");
            } else {
                alert(response.message || "Failed to update profile.");
            }
        } catch (error) {
            console.error("Error updating profile:", error);
            alert(error.response.data.message || "Failed to update profile");
        }
    };

    return (
        <Fragment>
            <Header />
            <Leftnav />
            {/* <Rightchat /> */}
            <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to="/accountsettings" className="d-inline-block mt-2">
                                        <i className="ti-arrow-left font-sm text-white"></i>
                                    </Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                                        Update Profile
                                    </h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit}>
                                        {/* Cover Photo with Edit Button */}
                                        <div className="position-relative text-center mb-4">
                                            <img
                                                src={
                                                    coverPreview ||
                                                    "https://via.placeholder.com/1200x250.png"
                                                }
                                                alt="cover"
                                                className="rounded-3 w-100 mb-3"
                                                style={{ height: "200px", objectFit: "cover" }}
                                            />
                                            <input
                                                type="file"
                                                onChange={(e) => handleFileChange(e, "cover")}
                                                accept="image/*"
                                                className="d-none"
                                                id="cover-upload"
                                            />
                                            <label
                                                htmlFor="cover-upload"
                                                className="position-absolute"
                                                style={{
                                                    top: "10px",
                                                    right: "10px",
                                                    cursor: "pointer",
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                    borderRadius: "50%",
                                                    padding: "8px",
                                                }}
                                            >
                                                <i className="feather-edit text-white"></i>
                                            </label>
                                        </div>

                                        {/* Profile Image with Edit Button */}
                                        <div className="position-relative text-center mb-4">
                                            <img
                                                src={
                                                    profilePreview ||
                                                    "https://via.placeholder.com/100x100.png"
                                                }
                                                alt="profile"
                                                className="rounded-circle shadow-sm"
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    objectFit: "cover",
                                                }}
                                            />
                                            <input
                                                type="file"
                                                onChange={(e) => handleFileChange(e, "profile")}
                                                accept="image/*"
                                                className="d-none"
                                                id="profile-upload"
                                            />
                                            <label
                                                htmlFor="profile-upload"
                                                className="position-absolute"
                                                style={{
                                                    top: "10px",
                                                    right: "10px",
                                                    cursor: "pointer",
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                    borderRadius: "50%",
                                                    padding: "8px",
                                                }}
                                            >
                                                <i className="feather-edit text-white"></i>
                                            </label>
                                        </div>

                                        {/* User Info Fields in Row Layout */}
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="name" className="form-label">Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control text-dark"
                                                    id="name"
                                                    name="name"
                                                    value={userData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="username" className="form-label">Username</label>
                                                <input
                                                    type="text"
                                                    className="form-control text-dark"
                                                    id="username"
                                                    name="username"
                                                    value={userData.username}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="dob" className="form-label">Date of Birth:</label>
                                                <input
                                                    id="dob"
                                                    type="date"
                                                    name="dob"
                                                    value={userData.dob} // Change this to userData.dob if that's how you manage state
                                                    onChange={handleChange} // Change this to handleChange if using the same handler
                                                    style={{
                                                        flex: '2',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        padding: '8px',
                                                        width: '100%',
                                                        minWidth: '50%',
                                                    }}
                                                    className="form-control text-dark" // Ensure it fits with the existing styles
                                                    
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="email" className="form-label">Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control text-dark"
                                                    id="email"
                                                    name="email"
                                                    value={userData.email}
                                                    onChange={handleChange}
                                                    
                                                />
                                            </div>
                                        </div>


                                        <div className="mb-3">
                                            <label htmlFor="about" className="form-label">About</label>
                                            <textarea
                                                className="form-control text-dark"
                                                id="about"
                                                name="about"
                                                rows="3"
                                                value={userData.about}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <button type="submit" className="btn btn-primary">
                                            Update Profile
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Appfooter />
                <Popupchat />
            </div>
        </Fragment>
    );
};

export default Account;
