import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import { withRouter } from 'react-router-dom';
import ChatService from '../../services/ChatService';
import MessageList from './MessageList';
import ChatInput from './ChatInput';
import { toast } from 'react-hot-toast';
import './ChatPage.css';

const ChatPage = ({ history, location, match }) => {
    const { user, isLoggedIn } = useAuth();
    const [messages, setMessages] = useState([]);
    const [recipient, setRecipient] = useState(null);
    const [loading, setLoading] = useState(true);
    const messagesEndRef = useRef(null);

    // Add effect to track recipient changes
    useEffect(() => {
        console.log('Recipient state changed:', recipient);
    }, [recipient]);

    useEffect(() => {
        if (!isLoggedIn) {
            history.push('/login');
            return;
        }

        const initializeChat = async () => {
            console.group('🚀 Chat Initialization');
            try {
                const token = localStorage.getItem('token');
                const userData = JSON.parse(localStorage.getItem('user'));
               
                console.log('Auth Data:', {
                    hasToken: !!token,
                    hasUser: !!userData,
                    userId: userData?.id
                });

                if (!token || !userData) {
                    console.error('❌ Missing auth data');
                    history.push('/login');
                    return;
                }

                // Initialize socket connection
                ChatService.initialize(userData.id, token);

                // Load initial data and join chat room first
                let currentRecipient = null;
                if (location.state?.recipient) {
                    console.log('📥 Loading chat from location state:', location.state.recipient);
                    currentRecipient = location.state.recipient;
                    setRecipient(location.state.recipient);
                    await loadMessages(location.state.recipient._id);
                    ChatService.joinChatRoom(location.state.recipient._id);
                } else if (match.params.userId) {
                    console.log('📥 Loading chat from URL params:', match.params.userId);
                    const conversation = await ChatService.initializeOrGetConversation(match.params.userId);
                    const otherParticipant = conversation.participants.find(p => p._id !== userData.id);
                    console.log('Found other participant:', otherParticipant);
                    if (otherParticipant) {
                        currentRecipient = otherParticipant;
                        setRecipient(otherParticipant);
                        await loadMessages(otherParticipant._id);
                        ChatService.joinChatRoom(otherParticipant._id);
                    }
                }

                // Listen for new messages after recipient is set
                const messageHandler = (message) => {
                    console.group('📩 Message Handler');
                    console.log('Message received:', message);
                    console.log('Current recipient:', currentRecipient || recipient);
                    console.log('Current user:', userData);
                    
                    // Only process messages that are part of this chat
                    const isRelevantMessage = 
                        (message.sender._id === (currentRecipient?._id || recipient?._id) && message.receiver === userData.id) ||
                        (message.sender._id === userData.id && message.receiver === (currentRecipient?._id || recipient?._id));
                    
                    console.log('Message relevance check:', {
                        messageSender: message.sender,
                        messageReceiver: message.receiver,
                        recipientId: currentRecipient?._id || recipient?._id,
                        currentUserId: userData.id,
                        isRelevant: isRelevantMessage,
                        senderMatch: message.sender._id === (currentRecipient?._id || recipient?._id),
                        receiverMatch: message.receiver === userData.id,
                        currentUserSenderMatch: message.sender._id === userData.id,
                        currentUserReceiverMatch: message.receiver === (currentRecipient?._id || recipient?._id)
                    });
                    
                    if (!isRelevantMessage) {
                        console.log('🚫 Ignoring message from different chat');
                        console.groupEnd();
                        return;
                    }

                    setMessages(prev => {
                        // Avoid duplicate messages
                        if (prev.some(m => m._id === message._id)) {
                            console.log('🔄 Duplicate message skipped');
                            return prev;
                        }
                        
                        const transformedMessage = {
                            _id: message._id,
                            message: message.message,
                            sender: message.sender,
                            createdAt: message.createdAt,
                            media: message.media
                        };
                        
                        console.log('➕ Adding new message to state:', transformedMessage);
                        return [...prev, transformedMessage];
                    });
                    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
                };

                ChatService.addEventListener('receiveChatMessage', messageHandler);

                // Listen for typing indicators
                ChatService.addEventListener('typing', (data) => {
                    console.log('⌨️ Typing event received:', data);
                    if (data.sender === recipient?._id) {
                        console.log('⌨️ Typing event received:', data);
                    }
                });

                setLoading(false);
                console.log('✅ Chat initialization complete');

                // Add effect to log recipient changes
                console.log('Current recipient state:', recipient);

                // Cleanup function
                return () => {
                    ChatService.removeEventListener('receiveChatMessage', messageHandler);
                };
            } catch (error) {
                console.error('❌ Chat initialization error:', error);
                toast.error('Failed to initialize chat');
            }
            console.groupEnd();
        };

        initializeChat();

        // Cleanup on unmount
        return () => {
            ChatService.disconnect();
        };
    }, [isLoggedIn, location.state, match.params.userId]);

    const loadMessages = async (receiverId) => {
        try {
            const messages = await ChatService.getMessages(receiverId);
            console.log('Loaded messages:', messages);
            
            const transformedMessages = messages.map(msg => ({
                _id: msg._id,
                message: msg.message,
                sender: msg.sender._id,
                senderProfilePic: msg.sender.profilePic,
                createdAt: msg.createdAt
            }));
            setMessages(transformedMessages);
        } catch (error) {
            console.error('Error loading messages:', error);
        }
    };

    const handleSendMessage = async (text) => {
        console.group('📤 Sending Message');
        try {
            console.log('Sending message to:', recipient?._id);
            const message = await ChatService.sendMessage(recipient._id, text);
            
            const transformedMessage = {
                _id: message._id,
                message: text,
                sender: message.sender._id,
                senderProfilePic: message.sender.profilePic,
                createdAt: message.createdAt || new Date().toISOString()
            };

            setMessages(prev => [...prev, transformedMessage]);
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        } catch (error) {
            console.error('❌ Error sending message:', error);
            toast.error('Failed to send message');
        }
        console.groupEnd();
    };

    if (loading) {
        return (
            <div className="loading-state">
                <p>Loading chat...</p>
            </div>
        );
    }

    if (!recipient) {
        return null;
    }

    return (
        <div className="chat-page">
            <div className="chat-header">
                <div className="header-content">
                    <button 
                        className="back-button"
                        onClick={() => history.push('/test-chat')}
                    >
                        <i className="feather-arrow-left"></i>
                    </button>
                    
                    <div className="recipient-profile">
                        <div className="avatar-wrapper">
                            <img 
                                src={recipient.profilePic || "/assets/images/default-profile.webp"} 
                                alt={recipient.name}
                                className="recipient-avatar"
                            />
                            <span className="online-status"></span>
                        </div>
                        <div className="recipient-info">
                            <h3 className="recipient-name">{recipient.name}</h3>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="messages-container">
                <MessageList
                    messages={messages}
                    currentUser={user}
                    messagesEndRef={messagesEndRef}
                />
            </div>
            
            <ChatInput
                onSendMessage={handleSendMessage}
            />

            <style jsx>{`
                .chat-page {
                    display: flex;
                    flex-direction: column;
                    height: 100vh;
                    background: #fff;
                }

                .chat-header {
                    padding: 12px 16px;
                    background: #ffffff;
                    border-bottom: 1px solid #e4e6eb;
                    position: sticky;
                    top: 0;
                    z-index: 100;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
                }

                .header-content {
                    max-width: 768px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }

                .back-button {
                    background: transparent;
                    border: none;
                    padding: 8px;
                    cursor: pointer;
                    color: #65676B;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    transition: background-color 0.2s;
                }

                .back-button:hover {
                    background-color: #F2F2F2;
                }

                .back-button i {
                    font-size: 22px;
                }

                .recipient-profile {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    flex: 1;
                }

                .avatar-wrapper {
                    position: relative;
                }

                .recipient-avatar {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                    border: 2px solid #ffffff;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
                }

                .online-status {
                    position: absolute;
                    bottom: 2px;
                    right: 2px;
                    width: 10px;
                    height: 10px;
                    background-color: #31A24C;
                    border-radius: 50%;
                    border: 2px solid #ffffff;
                }

                .recipient-info {
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                }

                .recipient-name {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 600;
                    color: #050505;
                    line-height: 1.2;
                }

                .messages-container {
                    flex: 1;
                    overflow-y: auto;
                    padding: 20px;
                    scroll-behavior: smooth;
                    -webkit-overflow-scrolling: touch;
                }

                .loading-state {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100vh;
                    color: #666;
                }

                @media (max-width: 768px) {
                    .chat-header {
                        padding: 8px 12px;
                    }

                    .recipient-name {
                        font-size: 15px;
                    }
                }
            `}</style>
        </div>
    );
};

export default withRouter(ChatPage); 