import React, { useState, Fragment, useEffect } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";

const Policy = () => {
    const [activeSection, setActiveSection] = useState('introduction');
    const [showBackToTop, setShowBackToTop] = useState(false);

    // Privacy Policy sections data
    const policySections = [
        {
            id: 'introduction',
            title: 'Introduction',
            content: `tapcoe.COM takes privacy issues very seriously. This Privacy Policy is designed to help you understand how we collect, use, and safeguard your information.

Engage your users through our events in different categories. Easily create interesting contests and events that would help your brand reach the masses and create zeal and enthusiasm amongst them.`
        },
        {
            id: 'what-this-covers',
            title: 'What This Privacy Policy Covers',
            content: `This policy covers how tapcoe.COM treats personal information that tapcoe.COM collects and receives when you visit this Website, and use the Services or access the Content. Personal information is information about you that is personally identifiable like your name, address, email address, or phone number, and that is not otherwise publicly available.

This policy does not apply to the practices of companies that tapcoe.COM does not own or control, or to people that are not affiliated with tapcoe.COM.`
        },
        {
            id: 'information-collection',
            title: 'Information Collection and Use',
            content: `tapcoe.COM collects personal information when you register with tapcoe.COM, when you visit this Website, and use the Services or access the Content. When you register on this Website, we ask for information such as your name, e-mail address, mailing address, occupation, industry, and personal interests, etc.

Once you register with tapcoe.COM and/or Log-in, you are not anonymous to us. tapcoe.COM collects a variety of information about you, including information about your gifts and donations to tapcoe.COM, and your use of the Services and the Content. tapcoe.COM automatically receives and records information on our server logs from your browser, including your IP address, tapcoe.COM cookie information, and the page you request.

All the above data and information will be collectively referred to as "Information". tapcoe.COM uses Information for the following general purposes: to customize the content you see, fulfill your requests for Content and Services, improve our services, contact you, conduct research, and provide anonymous reporting for internal and external clients.`
        },
        {
            id: 'information-sharing',
            title: 'Information Sharing and Disclosure',
            content: `tapcoe.COM does not rent, sell, or share Information with other people or non-affiliated entities except to provide services that you have requested; when we have your permission; or under the circumstances detailed below.

tapcoe.COM reserves the right to conduct research including, but not limited to, statistical and other forms of analyses based on the Information. Using such research and analyses and the Information collected from you, tapcoe.COM reserves the right to send you certain communications relating to tapcoe.COM and the Content and Services, such as announcements, administrative messages, and newsletters, without offering you the opportunity to opt-out of receiving them.

tapcoe.COM reserves the right to provide the Information to trusted partners who work on behalf of or with tapcoe.COM, within the bounds of confidentiality. tapcoe.COM also reserves the right to disclose the Information when required by law or regulation, or when we believe it is necessary to share Information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of tapcoe.COM's terms of use, or as otherwise required by law.

tapcoe.COM reserves the right to publish research and analyses based on the Information in public forums, including magazines, newspapers, brochures, books, and any other form of public disclosure. tapcoe.COM will make every reasonable effort to maintain and protect the privacy of individual members while conducting such research and analyses, and publishing them in a public forum.`
        },
        {
            id: 'user-rights',
            title: 'Your Rights & Choices',
            content: `You have the right to access, correct, or delete your personal information that we collect and maintain. You can also request to know what personal information we have collected about you.

Please note that if you have any concerns about the above Privacy Policy, you can write to us to express your concerns or delete your registration entry by contacting the admin at support@tapcoe.com.`
        },
        {
            id: 'data-security',
            title: 'Data Security',
            content: `We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.

We offer the use of a secure server. All supplied sensitive information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Database to be only accessed by those authorized with special access rights to our systems, and are required to keep the information confidential.`
        },
        {
            id: 'cookies',
            title: 'Cookies and Tracking Technologies',
            content: `We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.

Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.

You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.`
        },
        {
            id: 'third-party',
            title: 'Third-Party Services',
            content: `Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.

We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.`
        },
        {
            id: 'policy-changes',
            title: 'Changes to This Privacy Policy',
            content: `We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.

You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.`
        },
        {
            id: 'contact-us',
            title: 'Contact Us',
            content: `If you have any questions about this Privacy Policy, please contact us at support@tapcoe.com.`
        }
    ];

    // Handle scroll to section
    const scrollToSection = (sectionId) => {
        setActiveSection(sectionId);
        const element = document.getElementById(sectionId);
        if (element) {
            const yOffset = -100; // Offset for fixed header
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    };

    // Handle scroll event to update active section and show/hide back to top button
    useEffect(() => {
        const handleScroll = () => {
            // Show/hide back to top button
            if (window.scrollY > 300) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }

            // Update active section based on scroll position
            const sections = policySections.map(section => document.getElementById(section.id));
            const scrollPosition = window.scrollY + 150; // Add offset

            for (let i = sections.length - 1; i >= 0; i--) {
                if (sections[i] && sections[i].offsetTop <= scrollPosition) {
                    setActiveSection(policySections[i].id);
                    break;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [policySections]);

    // Back to top function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Fragment>
            <SEO
                title="Privacy Policy | tapcoe"
                description="Learn about how tapcoe collects, uses, and protects your personal information. Our privacy policy outlines our commitment to protecting your data."
                keywords="tapcoe privacy policy, data protection, personal information, privacy, data security"
                url="https://tapcoe.com/policy"
                image="https://tapcoe.com/assets/privacy-og-image.png"
            />
            <Header />
            <Leftnav />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        {/* Hero Section */}
                        <div className="privacy-hero position-relative overflow-hidden rounded-xxl mb-4"
                            style={{
                                background: "linear-gradient(135deg, rgba(24, 119, 242, 0.95), rgba(66, 133, 244, 0.95))",
                                padding: "60px 0"
                            }}>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-8 text-lg-start text-center mx-auto">
                                        <div className="hero-content p-4">
                                            <h1 className="fw-bold text-white mb-3" style={{ fontSize: "42px" }}>Privacy Policy</h1>
                                            <p className="text-white opacity-90 mb-2" style={{ fontSize: "18px", lineHeight: "1.6" }}>
                                                We value your privacy and are committed to protecting your personal information. This policy explains how we collect, use, and safeguard your data.
                                            </p>
                                            <p className="text-white opacity-90 mb-4" style={{ fontSize: "14px", lineHeight: "1.6" }}>
                                                Last Updated: March 20, 2023
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Decorative elements */}
                            <div className="position-absolute" style={{ top: "15%", left: "10%", width: "150px", height: "150px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
                            <div className="position-absolute" style={{ bottom: "10%", right: "15%", width: "120px", height: "120px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
                            <div className="position-absolute" style={{ top: "30%", right: "20%", width: "30px", height: "30px", background: "rgba(255,255,255,0.2)", borderRadius: "50%" }}></div>
                        </div>

                        <div className="row">
                            {/* Sidebar navigation */}
                            <div className="col-lg-3 d-none d-lg-block">
                                <div className="card border-0 rounded-xxl shadow-xss mb-4 sticky-top" style={{ top: '100px' }}>
                                    <div className="card-body p-4">
                                        <h4 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">Table of Contents</h4>
                                        <div className="nav flex-column">
                                            {policySections.map((section) => (
                                                <a 
                                                    key={section.id}
                                                    href={`#${section.id}`}
                                                    className={`nav-link mb-1 rounded-pill px-3 py-2 font-xsss fw-500 ${activeSection === section.id ? 'active' : ''}`}
                                                    style={{
                                                        color: activeSection === section.id ? '#fff' : '#555',
                                                        background: activeSection === section.id ? 'linear-gradient(90deg, #1877f2, #4285f4)' : 'transparent',
                                                        transition: 'all 0.3s ease'
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        scrollToSection(section.id);
                                                    }}
                                                >
                                                    {section.title}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Main content */}
                            <div className="col-lg-9">
                                <div className="card border-0 rounded-xxl shadow-xss mb-4">
                                    <div className="card-body p-4 p-lg-5">
                                        <div className="privacy-content">
                                            {policySections.map((section) => (
                                                <div 
                                                    key={section.id} 
                                                    id={section.id} 
                                                    className="privacy-section mb-5"
                                                >
                                                    <h3 className="fw-700 text-grey-900 mb-3 d-flex align-items-center">
                                                        <div className="decoration-circle me-3" style={{
                                                            width: '8px',
                                                            height: '8px',
                                                            borderRadius: '50%',
                                                            background: 'linear-gradient(90deg, #1877f2, #4285f4)'
                                                        }}></div>
                                                        {section.title}
                                                    </h3>
                                                    <div className="font-xss fw-500 text-grey-600 lh-28" style={{ whiteSpace: 'pre-line' }}>
                                                        {section.content}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                {/* Data Protection Information */}
                                <div className="card border-0 rounded-xxl shadow-xss mb-4" style={{ 
                                    background: "linear-gradient(135deg, rgba(24, 119, 242, 0.03), rgba(66, 133, 244, 0.07))",
                                }}>
                                    <div className="card-body p-4 p-lg-5">
                                        <div className="row">
                                            <div className="col-lg-4 text-center mb-4 mb-lg-0">
                                                <div className="icon-wrapper mb-3 mx-auto d-flex align-items-center justify-content-center rounded-circle"
                                                    style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        background: "linear-gradient(135deg, #1877f2, #4285f4)"
                                                    }}
                                                >
                                                    <i className="feather-shield text-white" style={{ fontSize: "28px" }}></i>
                                                </div>
                                                <h4 className="fw-700 text-grey-900 font-sm mb-2">Data Protection</h4>
                                                <p className="font-xsss fw-500 text-grey-500">
                                                    We implement modern security measures to protect your personal information
                                                </p>
                                            </div>
                                            <div className="col-lg-4 text-center mb-4 mb-lg-0">
                                                <div className="icon-wrapper mb-3 mx-auto d-flex align-items-center justify-content-center rounded-circle"
                                                    style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        background: "linear-gradient(135deg, #1877f2, #4285f4)"
                                                    }}
                                                >
                                                    <i className="feather-user-check text-white" style={{ fontSize: "28px" }}></i>
                                                </div>
                                                <h4 className="fw-700 text-grey-900 font-sm mb-2">Your Control</h4>
                                                <p className="font-xsss fw-500 text-grey-500">
                                                    We provide options for you to access, modify, or delete your personal data
                                                </p>
                                            </div>
                                            <div className="col-lg-4 text-center">
                                                <div className="icon-wrapper mb-3 mx-auto d-flex align-items-center justify-content-center rounded-circle"
                                                    style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        background: "linear-gradient(135deg, #1877f2, #4285f4)"
                                                    }}
                                                >
                                                    <i className="feather-lock text-white" style={{ fontSize: "28px" }}></i>
                                                </div>
                                                <h4 className="fw-700 text-grey-900 font-sm mb-2">Secure Communications</h4>
                                                <p className="font-xsss fw-500 text-grey-500">
                                                    All data transfers are encrypted to ensure your information stays private
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Contact For Privacy Questions */}
                                <div className="card border-0 rounded-xxl shadow-xss mb-4">
                                    <div className="card-body p-4">
                                        <div className="row align-items-center">
                                            <div className="col-md-8">
                                                <h4 className="fw-700 font-sm mb-2">Questions About Our Privacy Policy?</h4>
                                                <p className="font-xss fw-500 text-grey-600 mb-0">
                                                    If you have any questions or concerns about our privacy practices, please don't hesitate to contact our data protection team.
                                                </p>
                                            </div>
                                            <div className="col-md-4 text-md-end mt-3 mt-md-0">
                                                <a 
                                                    href="/contact" 
                                                    className="btn btn-primary border-0 p-3 rounded-lg d-inline-block"
                                                    style={{
                                                        background: "linear-gradient(90deg, #1877f2, #4285f4)",
                                                        color: "white",
                                                        fontWeight: "600",
                                                        fontSize: "14px"
                                                    }}
                                                >
                                                    <i className="feather-mail me-2"></i>
                                                    Contact Support
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Back to Top Button */}
                        {showBackToTop && (
                            <button 
                                className="back-to-top position-fixed d-flex align-items-center justify-content-center"
                                style={{
                                    bottom: '30px',
                                    right: '30px',
                                    width: '50px',
                                    height: '50px',
                                    background: 'linear-gradient(90deg, #1877f2, #4285f4)',
                                    color: 'white',
                                    borderRadius: '50%',
                                    border: 'none',
                                    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.15)',
                                    cursor: 'pointer',
                                    zIndex: 100,
                                    transition: 'all 0.3s ease'
                                }}
                                onClick={scrollToTop}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.transform = 'translateY(-5px)';
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.transform = 'translateY(0)';
                                }}
                            >
                                <i className="feather-chevron-up"></i>
                            </button>
                        )}

                        <div className="mt-2">
                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />
        </Fragment>
    );
};

export default Policy;
