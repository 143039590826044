import React, { useState, useEffect } from 'react';

const Countdown = ({ eventName, expiryDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(expiryDate));
  
  // Calculate time left based on the given target date
  function calculateTimeLeft(targetDate) {
    const now = new Date().getTime();
    const distance = new Date(targetDate).getTime() - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds, distance };
  }

  useEffect(() => {
    // Update the time left every second
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(expiryDate));
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on unmount
  }, [expiryDate]); // Dependency array includes expiryDate

  const isExpired = timeLeft.distance <= 0; // Check if the event has expired

  return (
    <div className="countdown-container">
      {eventName && (
        <div className="countdown-title">
          <h6>{eventName}</h6>
        </div>
      )}

      {/* Countdown Timer */}
      {isExpired ? (
        <div className="countdown-expired">
          <span>Event Ended</span>
        </div>
      ) : (
        <div className="countdown-timer">
          <div className="time-unit">
            <div className="time-value">{String(timeLeft.days).padStart(2, '0')}</div>
            <div className="time-label">Days</div>
          </div>
          
          <div className="time-separator">:</div>
          
          <div className="time-unit">
            <div className="time-value">{String(timeLeft.hours).padStart(2, '0')}</div>
            <div className="time-label">Hrs</div>
          </div>
          
          <div className="time-separator">:</div>
          
          <div className="time-unit">
            <div className="time-value">{String(timeLeft.minutes).padStart(2, '0')}</div>
            <div className="time-label">Min</div>
          </div>
          
          <div className="time-separator">:</div>
          
          <div className="time-unit">
            <div className="time-value">{String(timeLeft.seconds).padStart(2, '0')}</div>
            <div className="time-label">Sec</div>
          </div>
        </div>
      )}

      <style jsx="true">{`
        .countdown-container {
          width: 100%;
          text-align: center;
          padding: 8px 0;
          position: relative;
        }
        
        .countdown-title h6 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        
        .countdown-timer {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          background: linear-gradient(135deg, rgba(255,215,0,0.15), rgba(255,140,0,0.1));
          padding: 8px 10px;
          border-radius: 12px;
        }
        
        .time-unit {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 36px;
        }
        
        .time-value {
          font-size: 16px;
          font-weight: 700;
          color: #FF8C00;
          background-color: white;
          border-radius: 6px;
          padding: 4px 6px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          min-width: 30px;
        }
        
        .time-label {
          font-size: 9px;
          color: #666;
          font-weight: 500;
          margin-top: 2px;
          text-transform: uppercase;
        }
        
        .time-separator {
          font-size: 16px;
          font-weight: 700;
          color: #FF8C00;
          margin-top: -10px;
        }
        
        .countdown-expired {
          display: inline-block;
          background-color: #f8d7da;
          color: #721c24;
          padding: 6px 12px;
          border-radius: 30px;
          font-size: 13px;
          font-weight: 600;
          box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
        
        @media (max-width: 768px) {
          .countdown-timer {
            gap: 3px;
            padding: 6px 8px;
          }
          
          .time-value {
            font-size: 14px;
            min-width: 28px;
          }
          
          .time-label {
            font-size: 8px;
          }
        }
      `}</style>
    </div>
  );
};

export default Countdown;
