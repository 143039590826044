import axios from 'axios';

// Base URL for your API
const BASE_URL = "https://api.picvot.com//api/v1";

// Function to get event details by slug
export const getEventBySlug = async (slug) => {
  try {
    // Retrieve the token from localStorage or sessionStorage (if available)
    const token = localStorage.getItem('token');// Adjust based on your storage strategy

    // Prepare the headers with the optional Authorization token
    const headers = token
      ? { Authorization: `Bearer ${token}` }
      : {}; // No authorization header if not logged in

    // Make the GET request to fetch event details
    const response = await axios.get(`${BASE_URL}/events/slug/${slug}`, { headers });

    // Return the event details data
    return response.data;
  } catch (error) {
    console.error('Error fetching event details:', error);
    throw error;
  }
};
