import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const MessageList = ({ messages = [], currentUser = null, onLoadMore, hasMore, messagesEndRef }) => {
    const observerRef = useRef();
    const firstMessageRef = useRef();
    const history = useHistory();

    useEffect(() => {
        if (hasMore) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            };

            observerRef.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    onLoadMore();
                }
            }, options);

            if (firstMessageRef.current) {
                observerRef.current.observe(firstMessageRef.current);
            }

            return () => {
                if (observerRef.current) {
                    observerRef.current.disconnect();
                }
            };
        }
    }, [hasMore, onLoadMore]);

    if (!messages || messages.length === 0) {
        return (
            <div className="no-messages">
                <p>No messages yet. Start a conversation!</p>
            </div>
        );
    }

    if (!currentUser?.id) {
        return (
            <div className="no-messages">
                <p>Please log in to view messages</p>
            </div>
        );
    }

    return (
        <div className="chat-container">
            
            <div className="messages-list">
                {messages.map((message) => {
                    const isCurrentUser = message.sender === currentUser.id;
                    console.log('Rendering message:', {
                        messageId: message._id,
                        sender: message.sender,
                        currentUser: currentUser.id,
                        isCurrentUser
                    });

                    const showAvatar = message.index === 0 || messages[message.index - 1]?.sender !== message.sender;
                    const showTimestamp = message.index === messages.length - 1 || messages[message.index + 1]?.sender !== message.sender;

                    return (
                        <div
                            key={message._id}
                            className={`message-wrapper ${isCurrentUser ? 'sent' : 'received'}`}
                        >
                            {!isCurrentUser && showAvatar && (
                                <div className="message-avatar">
                                    <img
                                        src={message.senderProfilePic }
                                        alt="Profile"
                                    />
                                </div>
                            )}
                            <div className="message-content">
                                <div className={`message-bubble ${isCurrentUser ? 'sent' : 'received'}`}>
                                    {message.message}
                                </div>
                                {showTimestamp && (
                                    <div className="message-timestamp">
                                        {moment(message.createdAt).format('h:mm A')}
                                    </div>
                                )}
                            </div>
                            {isCurrentUser && showAvatar && (
                                <div className="message-avatar">
                                    <img
                                        src={message.senderProfilePic }
                                        alt="Profile"
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
                <div ref={messagesEndRef} />
            </div>

            <style jsx>{`
                .chat-container {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                .chat-header {
                    padding: 12px 16px;
                    border-bottom: 1px solid #e1e4e8;
                    display: flex;
                    align-items: center;
                }

                .back-button {
                    background: none;
                    border: none;
                    padding: 8px;
                    cursor: pointer;
                    color: #5271FF;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .back-button:hover {
                    color: #3d5de6;
                }

                .messages-list {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    padding: 16px;
                    flex: 1;
                    overflow-y: auto;
                }

                .message-wrapper {
                    display: flex;
                    align-items: flex-end;
                    gap: 8px;
                    max-width: 85%;
                    width: 100%;
                }

                .sent {
                    margin-left: auto;
                    flex-direction: row-reverse;
                }

                .received {
                    margin-right: auto;
                    flex-direction: row;
                }

                .message-avatar {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    overflow: hidden;
                    flex-shrink: 0;
                }

                .message-avatar img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .message-content {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    max-width: 100%;
                }

                .message-bubble {
                    padding: 8px 12px;
                    border-radius: 16px;
                    font-size: 14px;
                    line-height: 1.4;
                    word-wrap: break-word;
                    white-space: pre-wrap;
                    max-width: 100%;
                    overflow-wrap: break-word;
                    word-break: break-word;
                }

                .sent .message-bubble {
                    background: #5271FF;
                    color: white;
                    border-radius: 16px;
                    border-bottom-right-radius: 4px;
                }

                .received .message-bubble {
                    background: #e1e4e8;
                    border-radius: 16px;
                    border-bottom-left-radius: 4px;
                }

                .message-timestamp {
                    font-size: 11px;
                    color: #8e8e8e;
                    padding: 0 4px;
                }

                .no-messages {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    color: #8e8e8e;
                    font-size: 14px;
                }

                @media (max-width: 768px) {
                    .message-wrapper {
                        max-width: 90%;
                    }

                    .message-bubble {
                        padding: 6px 10px;
                        font-size: 13px;
                    }

                    .message-avatar {
                        width: 28px;
                        height: 28px;
                    }
                }

                @media (max-width: 480px) {
                    .message-wrapper {
                        max-width: 95%;
                        gap: 6px;
                    }

                    .message-bubble {
                        padding: 6px 8px;
                        font-size: 13px;
                    }

                    .message-avatar {
                        width: 24px;
                        height: 24px;
                    }

                    .message-timestamp {
                        font-size: 10px;
                    }
                }
            `}</style>
        </div>
    );
};

MessageList.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
            sender: PropTypes.shape({
                _id: PropTypes.string.isRequired,
                username: PropTypes.string.isRequired,
                profilePic: PropTypes.string
            }).isRequired,
            receiver: PropTypes.shape({
                _id: PropTypes.string.isRequired,
                username: PropTypes.string.isRequired,
                profilePic: PropTypes.string
            }).isRequired,
            createdAt: PropTypes.string.isRequired,
            updatedAt: PropTypes.string.isRequired,
            isRead: PropTypes.bool.isRequired,
            status: PropTypes.string.isRequired
        })
    ),
    currentUser: PropTypes.shape({
        id: PropTypes.string.isRequired,
        profilePic: PropTypes.string
    }),
    onLoadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    messagesEndRef: PropTypes.object.isRequired
};

export default MessageList; 