import React, { Component, Fragment } from "react";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";
class AboutUs extends Component {
    render() {
        <SEO
        title="About Us - Discover the Vision & Story Behind PicVot"
        description="Learn about PicVot, our mission, and how we connect users and vendors through exciting photo contests. Join us in celebrating creativity and community!"
        keywords="About PicVot, our story, PicVot mission, photo contest platform, community engagement, vendor partnerships"
        url="https://picvot.com/about"
        image="https://picvot.com/assets/about-us-og-image.webp"
      />
        return (
            
            <Fragment>
                <Header />
                <Leftnav />
                {/* <Rightchat /> */}

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row justify-content-center">
                                <div className="col-xl-10">
                                    <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                                        style={{ backgroundImage: `url("/assets/images/help-bg.png")` }}>
                                        <div className="card-body p-md-5 p-4 text-center"
                                            style={{ backgroundColor: 'rgba(0,85,255,0.8)' }}>
                                            <h2 className="fw-700 display2-size text-white display2-md-size lh-2">Welcome to the PicVot!</h2>
                                        </div>
                                    </div>

                                    <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
                                        <div className="card-body">
                                            <div className="mb-4">
                                                <h5 className="fw-600 mb-3">Who are we?</h5>
                                                <p>Here's all the credit you need for all the talent you have, the work you do, and how amazingly you show off your content. Tap the best with PicVot.</p>
                                            </div>

                                            <div className="mb-4">
                                                <h5 className="fw-600 mb-3">About Us</h5>
                                                <p>
                                                    At our online voting-based event hosting website, we are passionate about creating innovative solutions that make event hosting and voting easy and accessible to all. Our platform is designed to help event organizers and hosts create engaging, interactive events that facilitate meaningful conversations and decisions.<br /><br />
                                                    Our team of experienced developers, designers, and event professionals is committed to delivering exceptional service and support to our clients. We believe that technology should serve people, not the other way around. That's why we've designed our platform to be user-friendly, customizable, and secure.<br /><br />
                                                    Our platform is built on a foundation of cutting-edge technology and best practices in data security and privacy. We understand the importance of protecting your data and ensuring that your event is fair and transparent. That's why we use industry-standard encryption technology and provide a comprehensive audit trail of all voting activities.<br /><br />
                                                    We believe that every event has the potential to be transformational. Whether you're hosting a corporate conference, a community town hall, or a school election, our platform offers a range of features to help you engage your audience and make informed decisions. From live polls to Q&A sessions, our platform is designed to facilitate meaningful conversations and create a more inclusive, participatory event experience.<br /><br />
                                                    At our online voting-based event hosting website, we are committed to empowering our clients to create events that matter. We believe that technology has the power to bring people together, facilitate change, and make the world a better place. That's why we're dedicated to providing you with the tools and support you need to make your event a success.
                                                </p>
                                            </div>



                                            <div className="mb-4">
                                                <h5 className="fw-600 mb-3">How it Works</h5>
                                                <p>
                                                    Our online voting platform makes the voting process simple and accessible to all users. Here's how it works:{'\n\n'}<br /><br />
                                                    1. Create Your Event:<br />
                                                    First, you need to create an event poll. You can choose from various events. You can also set up voting rules, such as restricting the number of votes per user, requiring authentication, or setting a deadline for the online event poll.{'\n\n'}<br /><br />
                                                    2. Share Your Content:{'\n'}<br />
                                                    Once you've participated, you can share it with your intended audience. You can send an email invitation to your voters, post the content on social media, or embed it on your website.{'\n\n'}<br /><br />
                                                    3. Cast Your Vote:{'\n'}<br />
                                                    Voters can easily access the poll from any device with an internet connection. They can then cast their vote using the options you've provided. Our platform ensures that each user can vote only once, and their vote is counted accurately.{'\n\n'}<br /><br />
                                                    4. Track the Results:{'\n'}<br />
                                                    You can track the poll results in real-time. Our platform provides detailed reports on the number of votes cast, the percentage of votes for each option, and other relevant statistics.{'\n\n'}<br /><br />
                                                    5. Analyze the Results:{'\n'}<br />
                                                    Once the voting is complete, you can analyze the results to make informed decisions.{'\n\n'}<br /><br />
                                                    In summary, our online voting platform streamlines the voting process, making it easy for you to create and manage polls, share them with your intended audience, and track and analyze the results. With our secure and user-friendly interface, you can be confident that your votes are counted accurately and that your data is kept safe and secure.
                                                </p>
                                            </div>



                                            <div>
                                                <h5 className="fw-600 mb-3">Our Mission</h5>
                                                <p>First and foremost, our goal is to spread the talent of one person that can be appreciated by a variety of other people who can prove their talent by voting for the best contestants...</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2">

                               <Copyright  />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popupchat />
                <Appfooter />
            </Fragment>
        );
    }
}

export default AboutUs;
