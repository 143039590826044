import React, { Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import EventBanner from '../components/EventBanner';
import Copyright from "../components/Copyright";

const EventPost = ({ match }) => {
    const { slug } = match.params; // Destructuring the slug from match.params

    return (
        <Fragment> 
            <Header />
            <Leftnav />
            {/* <Rightchat /> */}

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row feed-body">
                            <div className="col-xl-12 mb-3">
                                <EventBanner slug={slug} />
                            </div>
                            <Copyright/>
                        </div>
                    </div>
                </div>
            </div>
            <Popupchat />
            <Appfooter /> 
        </Fragment>
    );
}

export default EventPost;
