import axios from 'axios';

const BASE_URL = "https://api.tapcoe.com/api/v1";
const TOKEN  = localStorage.getItem('token');  // Replace with your actual token

// API function to join an event
const joinEvent = async (eventId) => {
  try {
    const response = await axios.post(`${BASE_URL}/joinedevents/join`, 
      { eventId }, 
      {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    );

    // console.log('Joined event successfully:', response.data);
    return response.data; // Return response data for further use
  } catch (error) {
    console.error('Error during event join:', error);
    throw error;
  }
};

// API function to upload an image with progress tracking
const uploadImage = async (eventId, mediaType, media, setProgress) => {
  try {
    const formData = new FormData();
    formData.append('eventId', eventId);
    formData.append('mediaType', mediaType);
    formData.append('media', media);

    const response = await axios.post(`${BASE_URL}/media/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${TOKEN}`,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted); // Update progress state
      },
    });

    // console.log('Image uploaded successfully:', response.data);
    return response.data; // Return uploaded image data for further use
  } catch (error) {
    console.error('Error during image upload:', error);
    throw error;
  }
};

export { joinEvent, uploadImage };
