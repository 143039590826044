import React from 'react';

const Companyabout = ({ about ,prize}) => {
    return (
        <div className="about-section card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
            <div className="card-body p-0">
                {/* Company Image at the top with full width */}
                {/* <div className="w-100 overflow-hidden rounded-top-xxl">
                    <img 
                        src="https://via.placeholder.com/1200x400.png" 
                        alt="Company"
                        className="img-fluid w-100"
                    />
                </div> */}

<div className="p-4">
    <h2 className="fw-700 mb-4">About Event</h2>
    <p className="text-grey-500 font-xssss lh-28">
        {about ? about : "No details available about this event."}
    </p>
</div>

<div className="p-4">
    <h2 className="fw-700 mb-4">About Prize</h2>
    <p className="text-grey-500 font-xssss lh-28">
        {prize ? prize : "No Prize available for this event."}
    </p>
</div>

<div className="p-4">
    <h2 className="fw-700 mb-4">Rules and Guidelines (Niyam)</h2>
    <p className="text-grey-500 font-xssss lh-28">
        <strong>•</strong> Photos must be <strong>clicked</strong> by the participant; AI-generated images are <strong>not allowed</strong>.<br />
        <strong>•</strong> The <strong>winning prize</strong> will be awarded <strong>only</strong> if the participant’s profile is complete, meaning a valid profile picture and all required details are uploaded.<br />
        <strong>•</strong> Participants should ensure that the photos comply with the event’s <strong>theme and guidelines</strong>.<br />
        <strong>•</strong> Misconduct or use of fraudulent means may result in <strong>disqualification</strong>.<br />
    </p>
</div>

            </div>
        </div>
    );
};

export default Companyabout;
