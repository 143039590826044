import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { castVote } from '../api/castVoteApi';
import { useAuth } from "../context/AuthContext";
import LocationComponent from "./Location";
const Feedview = (props) => {
  const { isLoggedIn } = useAuth();
  const { users, time, avater, postimage, postvideo, id, voteCount, eventId, postId, slug, hasVoted, status, rejectReason, location } = props;
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();
  const [showOptions, setShowOptions] = useState(false);
  const [isVoting, setIsVoting] = useState(false);
  const [currentVote, setCurrentVote] = useState(voteCount);
  const [showLocation, setShowLocation] = useState(true);
  const[clickHere,setClickHere] = useState(hasVoted)

  useEffect(() => {
    const interval = setInterval(() => {
      setShowLocation((prev) => !prev);
    }, 2000);

    return () => clearInterval(interval);
  }, []);
  
  const obfuscateId = (id) => {
    const salt = 53;
    const numericId = parseInt(id, 36);
    const transformedId = (numericId + salt) % 10000;
    return transformedId.toString().slice(0, 2);
  };

  const getTimeAgo = (postCreatedAt) => {
    const now = new Date();
    const postTime = new Date(postCreatedAt);
    const timeDifference = now - postTime;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
    if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
    if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `just now`;
  };
  const displayedId = obfuscateId(postId);
  const handleMoreClick = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionClick = (option) => {
    switch (option) {
      case 'post':
        history.push(`/post/${eventId}/${postId}`);
        break;
      case 'profile':
        alert('Comming Soon!');
        break;
      case 'report':
        alert('This Post Is reported , admin will check and update you!');
        break;
      default:
        break;
    }
    setShowOptions(false);
  };
  const animateVoteIncrease = () => {
    let startVote = currentVote;
    const targetVote = startVote + 1;

    const increment = () => {
      if (startVote < targetVote) {
        setCurrentVote((prev) => prev + 1);
        startVote++;
        setTimeout(increment, 50);
      }
    };
    increment();
  };

  const handleVoteClick = async () => {
    try {
      setIsVoting(true);
      const response = await castVote(eventId, postId);
      alert('Vote cast successfully!');
      animateVoteIncrease();
      setClickHere(true)
    } catch (error) {
      alert(error.response.data.message);
      console.error('Error during vote:', error.response.data.message);
    } finally {
      setIsVoting(false);
    }
  };

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 p-2 mb-3">
      <div className="card-body p-0 d-flex">
        <figure className="avatar me-3" style={{ height: '40px', width: '40px' }}>
          <img
            src={`${avater}`}
            alt="avatar"
            className="shadow-sm rounded-circle"
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        </figure>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          {users}
          {showLocation ? (
            <LocationComponent location={location} />
          ) : (
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{getTimeAgo(time)}</span>
          )}
        </h4>
        <div className="ms-auto pointer position-relative">
          <i
            className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"
            onClick={handleMoreClick}
            style={{ cursor: 'pointer' }}
          ></i>
          {showOptions && (
            <div
              className="dropdown-menu show position-absolute"
              style={{
                right: 0,
                top: '100%',
                maxWidth: '200px',
                width: '100%',
                padding: '5px',
                backgroundColor: '#fff',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                borderRadius: '12px',
                zIndex: '999',
                overflow: 'hidden',
              }}
            >
              <ul className="list-group">
                <li
                  className="list-group-item border-0 p-2"
                  onClick={() => handleOptionClick('post')}
                  style={{ fontSize: '10px' }}
                >
                  Open Post
                </li>
                <li
                  className="list-group-item border-0 p-2"
                  onClick={() => handleOptionClick('profile')}
                  style={{ fontSize: '10px' }}
                >
                  Open Profile
                </li>
                <li
                  className="list-group-item border-0 p-2 text-danger"
                  onClick={() => handleOptionClick('report')}
                  style={{ fontSize: '10px' }}
                >
                  Report
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {postvideo && (
        <div className="card-body p-0 mb-2 rounded-3 overflow-hidden">
          <a href="/defaultvideo" className="video-btn">
            <video autoPlay loop className="float-right w-100">
              <source src={`/assets/images/${postvideo}`} type="video/mp4" />
            </video>
          </a>
        </div>
      )}
      {postimage && (
        <div className="card-body d-block p-0 mb-2">
          <div className="row ps-2 pe-2">
            <div className="col-sm-12 p-1">
              <a href={`/post/${eventId}/${postId}`} rel="noopener noreferrer">
                <img
                  src={`${postimage}`}
                  className="rounded-3 w-100"
                  alt="post"
                />
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="card-body pt-2 pb-0 px-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <button className="stat-button id-button">
            <i className="feather-hash me-1"></i>
            ID: {displayedId}
          </button>

          <button className="stat-button vote-button">
            <i className="feather-heart me-1"></i>
            Vote: {currentVote}
          </button>
        </div>

        {isLoggedIn ? (
          status === 'rejected' ? (
            <div className="alert-message alert-danger">
              <i className="feather-x-circle me-2"></i>
              {rejectReason}
            </div>
          ) : (
            clickHere ? (
              <div className="alert-message alert-success">
                <i className="feather-check-circle me-2"></i>
                You have already voted!
              </div>
            ) : (
              <button
                className="action-button vote-action"
                onClick={handleVoteClick}
                disabled={isVoting}
              >
                {isVoting ? (
                  <><i className="feather-loader rotating me-2"></i> Voting...</>
                ) : (
                  <><i className="feather-thumbs-up me-2"></i> Click Here to Vote</>
                )}
              </button>
            )
          )
        ) : (
          <button
            className="action-button login-action"
            onClick={() => history.push('/login')}
          >
            <i className="feather-log-in me-2"></i> Login to Vote
          </button>
        )}
      </div>
    </div>
  );
};

export default Feedview;
