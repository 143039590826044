import axios from 'axios';

const API_URL = "https://api.tapcoe.com/api/v1"; // API base URL

// Get token from localStorage
const token = localStorage.getItem('token');

// Create a configured axios instance with the Authorization header
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        Authorization: `Bearer ${token}`, // Pass the Bearer token
        'Content-Type': 'application/json',
    },
});

// Fetch social links for the authenticated user
export const fetchSocialLinks = async () => {
    try {
        const response = await axiosInstance.get('/social');
        return response.data;
    } catch (error) {
        console.error('Error fetching social links:', error);
        throw error;
    }
};

// Update or create social links for the authenticated user
export const updateSocialLinks = async (socialLinks) => {
    try {
        await axiosInstance.put('/social', socialLinks);
        alert('Social links updated successfully!');
    } catch (error) {
        console.error('Error updating social links:', error);
        throw error;
    }
};
