import React, { useState } from "react";
import { verifyForget, forgotPassword } from "../api/authApi";

const Forgot = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1); // Step: 1 = enter email/phone, 2 = verify OTP
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "emailOrPhone") setEmailOrPhone(value);
    if (name === "otp") setOtp(value);
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    
    if (!emailOrPhone) {
      setErrorMessage("Please enter your WhatsApp number");
      return;
    }

    const whatsappRegex = /^[0-9]{10}$/;
    if (!whatsappRegex.test(emailOrPhone)) {
      setErrorMessage("Please enter a valid 10-digit WhatsApp number");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    
    try {
      await forgotPassword({ mobileNumber: emailOrPhone });
      setSuccessMessage("OTP sent successfully! Please check your WhatsApp.");
      setStep(2); // Move to OTP verification step
    } catch (error) {
      console.error("Error sending OTP:", error);
      setErrorMessage(error.response?.data?.message || "Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    
    if (!otp) {
      setErrorMessage("Please enter the OTP");
      return;
    }
    
    if (!newPassword) {
      setErrorMessage("Please enter a new password");
      return;
    }
    
    if (newPassword.length < 8) {
      setErrorMessage("Password must be at least 8 characters long");
      return;
    }
    
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    
    try {
      const payload = {
        otp,
        mobileNumber: emailOrPhone,
        newPassword,
      };
      await verifyForget(payload); // Send OTP + New Password
      setSuccessMessage("Password changed successfully! Redirecting to login page...");
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage(error.response?.data?.message || "Failed to verify OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-light" style={{minHeight: "100vh"}}>
      {/* Header */}
      <header className="bg-white shadow-sm">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center py-3">
            <a href="/" className="d-flex align-items-center text-decoration-none">
              <i className="feather-camera text-primary me-2" style={{fontSize: "24px"}}></i>
              <span className="fw-bold text-dark fs-4">tapcoe</span>
            </a>
            <div>
              <a 
                href="/login" 
                className="btn me-2" 
                style={{
                  borderRadius: "8px",
                  border: "1px solid #4285f4",
                  color: "#4285f4",
                  padding: "8px 16px",
                  fontWeight: "500",
                  fontSize: "14px",
                  textDecoration: "none"
                }}
              >
                Login
              </a>
              <a 
                href="/register" 
                className="btn" 
                style={{
                  borderRadius: "8px",
                  border: "1px solid #4285f4",
                  color: "#4285f4",
                  padding: "8px 16px",
                  fontWeight: "500",
                  fontSize: "14px",
                  textDecoration: "none"
                }}
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="container py-5">
        <div className="row">
          {/* Left side - Image and benefits (hidden on mobile) */}
          <div className="col-lg-6 d-none d-lg-block">
            <div className="card border-0 rounded-4 overflow-hidden h-100 bg-primary text-white position-relative">
              <div style={{
                background: "linear-gradient(45deg, rgba(66, 133, 244, 0.9), rgba(120, 85, 245, 0.8))",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1
              }}></div>
              <img 
                src="/assets/images/loginpic.png" 
                alt="Reset Password" 
                className="card-img h-100"
                style={{
                  objectFit: "cover",
                  opacity: 0.6
                }}
              />
              <div className="card-img-overlay d-flex flex-column justify-content-center p-5" style={{zIndex: 2}}>
                <h2 className="fw-bold mb-4">Reset Your Password</h2>
                <p className="mb-4">We'll help you reset your password in just a few simple steps. Enter your WhatsApp number to receive a verification code.</p>
                
                <div className="mt-4">
                  <div className="d-flex align-items-center mb-3">
                    <div className="d-flex align-items-center justify-content-center rounded-circle bg-white bg-opacity-25 me-3" style={{width: "40px", height: "40px"}}>
                      <i className="feather-smartphone text-white"></i>
                    </div>
                    <span>Enter your WhatsApp number</span>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="d-flex align-items-center justify-content-center rounded-circle bg-white bg-opacity-25 me-3" style={{width: "40px", height: "40px"}}>
                      <i className="feather-shield text-white"></i>
                    </div>
                    <span>Verify with an OTP</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-center rounded-circle bg-white bg-opacity-25 me-3" style={{width: "40px", height: "40px"}}>
                      <i className="feather-lock text-white"></i>
                    </div>
                    <span>Create a new password</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Right side - Form */}
          <div className="col-lg-6">
            <div className="card border-0 rounded-4 shadow-sm">
              <div className="card-body p-4 p-lg-5">
                {step === 1 ? (
                  <>
                    <div className="mb-4">
                      <div className="mb-3" style={{
                        width: "60px",
                        height: "4px",
                        background: "linear-gradient(90deg, #4285f4, #7855f5)",
                        borderRadius: "4px"
                      }}></div>
                      <h2 className="fw-bold mb-1">Forgot Password</h2>
                      <p className="text-muted small">Enter your WhatsApp number to receive a verification code</p>
                    </div>
                    
                    <form onSubmit={handleSendOtp}>
                      <div className="mb-4">
                        <label htmlFor="emailOrPhone" className="form-label small fw-medium">WhatsApp Number</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-smartphone text-muted"></i>
                          </span>
                          <input
                            type="text"
                            id="emailOrPhone"
                            name="emailOrPhone"
                            className="form-control bg-light border-start-0"
                            placeholder="10-digit number (e.g. 9723456789)"
                            value={emailOrPhone}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-text small mt-2">We'll send a verification code to this number</div>
                      </div>
                      
                      {successMessage && (
                        <div className="alert alert-success mb-3 py-2" role="alert">
                          <i className="feather-check-circle me-2"></i>
                          {successMessage}
                        </div>
                      )}
                      
                      {errorMessage && (
                        <div className="alert alert-danger mb-3 py-2" role="alert">
                          <i className="feather-alert-circle me-2"></i>
                          {errorMessage}
                        </div>
                      )}
                      
                      <button 
                        type="submit" 
                        className="btn w-100 py-2 mb-3"
                        disabled={loading}
                        style={{
                          background: "linear-gradient(90deg, #4285f4, #7855f5)",
                          color: "white",
                          borderRadius: "8px",
                          fontWeight: "500"
                        }}
                      >
                        {loading ? (
                          <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Sending...
                          </>
                        ) : "Send Verification Code"}
                      </button>
                      
                      <div className="text-center mt-4">
                        <p className="small text-muted mb-0">
                          Remember your password? <a href="/login" className="text-decoration-none text-primary fw-medium">Login</a>
                        </p>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <div className="mb-4">
                      <div className="mb-3" style={{
                        width: "60px",
                        height: "4px",
                        background: "linear-gradient(90deg, #4285f4, #7855f5)",
                        borderRadius: "4px"
                      }}></div>
                      <h2 className="fw-bold mb-1">Reset Password</h2>
                      <p className="text-muted small">Enter the verification code and your new password</p>
                    </div>
                    
                    <form onSubmit={handleVerifyOtp}>
                      <div className="mb-3">
                        <label htmlFor="otp" className="form-label small fw-medium">Verification Code</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-shield text-muted"></i>
                          </span>
                          <input
                            type="text"
                            id="otp"
                            name="otp"
                            className="form-control bg-light border-start-0 text-center fw-medium"
                            placeholder="• • • • • •"
                            value={otp}
                            onChange={handleInputChange}
                            style={{
                              letterSpacing: "5px",
                              fontSize: "18px"
                            }}
                          />
                        </div>
                        <div className="form-text small mt-2">Enter the code sent to +91 {emailOrPhone}</div>
                      </div>
                      
                      <div className="mb-3">
                        <label htmlFor="newPassword" className="form-label small fw-medium">New Password</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-lock text-muted"></i>
                          </span>
                          <input
                            type={passwordVisible ? "text" : "password"}
                            id="newPassword"
                            name="newPassword"
                            className="form-control bg-light border-start-0 border-end-0"
                            placeholder="Min. 8 characters"
                            value={newPassword}
                            onChange={handleInputChange}
                          />
                          <span 
                            className="input-group-text bg-light border-start-0 text-primary" 
                            style={{cursor: "pointer"}}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            <i className={passwordVisible ? "feather-eye-off" : "feather-eye"}></i>
                          </span>
                        </div>
                      </div>
                      
                      <div className="mb-4">
                        <label htmlFor="confirmPassword" className="form-label small fw-medium">Confirm Password</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-lock text-muted"></i>
                          </span>
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            id="confirmPassword"
                            name="confirmPassword"
                            className="form-control bg-light border-start-0 border-end-0"
                            placeholder="Confirm your password"
                            value={confirmPassword}
                            onChange={handleInputChange}
                          />
                          <span 
                            className="input-group-text bg-light border-start-0 text-primary" 
                            style={{cursor: "pointer"}}
                            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                          >
                            <i className={confirmPasswordVisible ? "feather-eye-off" : "feather-eye"}></i>
                          </span>
                        </div>
                      </div>
                      
                      {successMessage && (
                        <div className="alert alert-success mb-3 py-2" role="alert">
                          <i className="feather-check-circle me-2"></i>
                          {successMessage}
                        </div>
                      )}
                      
                      {errorMessage && (
                        <div className="alert alert-danger mb-3 py-2" role="alert">
                          <i className="feather-alert-circle me-2"></i>
                          {errorMessage}
                        </div>
                      )}
                      
                      <button 
                        type="submit" 
                        className="btn w-100 py-2 mb-3"
                        disabled={loading}
                        style={{
                          background: "linear-gradient(90deg, #4285f4, #7855f5)",
                          color: "white",
                          borderRadius: "8px",
                          fontWeight: "500"
                        }}
                      >
                        {loading ? (
                          <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Processing...
                          </>
                        ) : "Reset Password"}
                      </button>
                      
                      <div className="d-flex justify-content-between align-items-center">
                        <button 
                          type="button" 
                          className="btn btn-link text-muted p-0 small text-decoration-none"
                          onClick={() => setStep(1)}
                        >
                          <i className="feather-arrow-left me-1"></i> Back
                        </button>
                        
                        <button 
                          type="button" 
                          className="btn btn-link p-0 small text-decoration-none"
                          onClick={handleSendOtp}
                          style={{
                            color: "#4285f4"
                          }}
                        >
                          Resend Code
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
