import React, { useEffect, useState } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import EventBanner from '../components/EventBanner';
import Friends from '../components/Friends';
import Contacts from '../components/Contacts';
import Group from '../components/LiveDiscussion';
import Events from '../components/Events';
import Createpost from '../components/Createpost';
import Memberslider from '../components/Memberslider';
import Friendsilder from '../components/Friendsilder';
import Storyslider from '../components/Storyslider';
import Feedview from '../components/Feedview';
import Load from '../components/Load';
import Profilephoto from '../components/Profilephoto';
import SEO from "../components/Seo";
import Copyright from "../components/Copyright";
import GoogleAd from "../components/GoogleAd";
import { feedView } from "../api/feedApi";

const Feed = () => {
    <SEO
        title="Feed - Discover Trending Events & Vote | PicVot"
        description="Explore the latest events and photos shared by participants on PicVot. Vote, engage, and stay updated with trending content."
        keywords="PicVot feed, trending events, photo contest, vote now, latest events, social engagement"
        url="https://picvot.com/feed"
        image="https://picvot.com/assets/og-image.webp"
    />
    const [eventList, setEventList] = useState([]);

    useEffect(() => {
        const fetchFeedData = async () => {
            try {
                const response = await feedView();
                setEventList(response);
            } catch (error) {
                console.error("Error fetching event data:", error);
            }
        };

        fetchFeedData();
    }, []);

    return (
        <>
            <Header />
            <Leftnav />
            {/* <Rightchat /> */}

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row feed-body">
                            <div className="col-xl-12 mb-3">
                                {/* <EventBanner /> */}
                            </div>
                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                {/* <Storyslider /> */}
                                {/* <Createpost /> */}

                                {eventList.length > 0 ? (
                                    eventList.map((event, index) => (
                                        <div key={event.postId || index}>
                                            <Feedview
                                                id={event.postId}
                                                eventId={event.eventDetails.eventId}
                                                postId={event.postId}
                                                postvideo={event.postvideo || ""}
                                                voteCount={event.voteCount}
                                                hasVoted={event.hasVoted}
                                                location={event.userDetails.registerLocation}
                                                postimage={event.imageUrl || "post.png"}
                                                avater={event.userDetails.profilePic || "user.png"}
                                                user={event.userDetails.name}
                                                time={event.createdAt || "Just now"}
                                                des={event.description || "Description not available."}
                                                slug={event.eventDetails.slug}
                                                status={event.status}
                                                rejectReason={event.rejectReason} 
                                            />
                                            {(index + 1) % 3 === 0 && <GoogleAd />}
                                        </div>
                                    ))
                                ) : (
                                    <Load />
                                )}
                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                {/* <Friends /> */}
                                {/* <Contacts /> */}
                                {/* <Group /> */}
                                {/* <Events /> */}
                                {/* <Profilephoto /> */}
                            </div>
                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />
        </>
    );
}

export default Feed;
