import React, { Fragment } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Story from "../components/Story";
import Slider from "react-slick";
import "./custom.css";
import Friendsilder from "../components/Friendsilder";
import TestimonialSlider from "../components/TestimonialSlider";
import Experience from "../components/Experience";
import Eventslist from "../components/EventList";
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";
import MemberSlider from "../components/Memberslider";
const Home = () => {
  <SEO
  title="Home - PicVot"
  description="Explore PicVot's latest events and contests. Participate now and win amazing prizes!"
  url="https://picvot.com/"
  image="https://picvot.com/assets/home-og-image.webp"
/>
  
  // const sliderStyle = {
  //   paddingRight: "20px !important",
  // };

  const shopSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 3000, // Time in milliseconds between slides (3 seconds)
  };
  

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* <Rightchat /> */}

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="card w-100 border-0 shadow-none rounded-xxl border-0 mb-3 overflow-hidden">
                  <div className="col-lg-12">
                    <div className="banner-wrapper bg-greylight overflow-hidden rounded-3 shop-slider">
                      <Slider {...shopSettings}>
                        {/* <div className="style1 d-flex align-items-center bg-cyan">
                          <div className="row">
                            <div className="col-12">
                              <img
                                src="https://media.picvot.com/uploads/Banner/Best%20Diwali%20Moments"
                                alt="PicVot Banner"
                                className="img-fluid p-md-2 p-1"
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="style1 d-flex align-items-center bg-cyan">
                          <div className="row">
                            <div className="col-12">
                              <img
                                src="/assets/images/bn1.png"
                                alt="PicVot Banner"
                                className="img-fluid p-md-2 p-1"
                              />
                            </div>
                          </div>
                        </div>

                       
                        <div className="style1 d-flex align-items-center bg-cyan">
                          <div className="row">
                            <div className="col-12">
                              <img
                                src="/assets/images/bn2.png"
                                alt="PicVot Banner"
                                className="img-fluid p-md-2 p-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="style1 d-flex align-items-center bg-cyan">
                          <div className="row">
                            <div className="col-12">
                              <img
                                src="/assets/images/bn3.png"
                                alt="PicVot Banner"
                                className="img-fluid p-md-2 p-1"
                              />
                            </div>
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>

              <Eventslist  />
              <Story />
                        {/* <MemberSlider /> */}
              <Experience />

              <div className="col-lg-12">
                <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                  <div className="row">
                    <div className="col-lg-12 mt-4">
                      <TestimonialSlider />
                    </div>
                  </div>
                </div>
              </div>

              <Copyright />


            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />

    </Fragment>


  );
};

export default Home;
