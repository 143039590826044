import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ChatInput = ({ onSendMessage }) => {
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim()) {
            onSendMessage(message);
            setMessage('');
        }
    };

    return (
        <div className="chat-input-container">
            <form onSubmit={handleSubmit} className="chat-form">
                <div className="input-wrapper">
                    <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Type a message..."
                        className="message-input"
                    />
                </div>
                <button 
                    type="submit" 
                    className="send-button"
                    disabled={!message.trim()}
                >
                    <i className="feather-send"></i>
                </button>
            </form>

            <style jsx>{`
                .chat-input-container {
                    padding: 16px;
                    background: #ffffff;
                    border-top: 1px solid #e4e6eb;
                    position: sticky;
                    bottom: 0;
                    z-index: 10;
                }

                .chat-form {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    max-width: 768px;
                    margin: 0 auto;
                }

                .input-wrapper {
                    flex: 1;
                    background: #f0f2f5;
                    border-radius: 24px;
                    padding: 8px 16px;
                    transition: background-color 0.2s;
                }

                .input-wrapper:focus-within {
                    background: #ffffff;
                    box-shadow: 0 0 0 2px #e4e6eb;
                }

                .message-input {
                    width: 100%;
                    border: none;
                    background: transparent;
                    font-size: 15px;
                    line-height: 20px;
                    padding: 4px 0;
                    color: #000000 !important;
                    outline: none;
                }

                .message-input::placeholder {
                    color: #65676b !important;
                }

                .send-button {
                    background: #5271FF;
                    border: none;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.2s;
                    flex-shrink: 0;
                }

                .send-button:disabled {
                    background: #e4e6eb;
                    cursor: not-allowed;
                }

                .send-button:not(:disabled):hover {
                    background: #3d5de6;
                    transform: scale(1.05);
                }

                .send-button i {
                    color: white;
                    font-size: 18px;
                    transform: rotate(45deg);
                }

                @media (max-width: 768px) {
                    .chat-input-container {
                        padding: 12px;
                    }

                    .input-wrapper {
                        padding: 6px 12px;
                    }

                    .message-input {
                        font-size: 14px;
                    }

                    .send-button {
                        width: 36px;
                        height: 36px;
                    }

                    .send-button i {
                        font-size: 16px;
                    }
                }
            `}</style>
        </div>
    );
};

ChatInput.propTypes = {
    onSendMessage: PropTypes.func.isRequired
};

export default ChatInput; 