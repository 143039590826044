import axios from 'axios';

// Base URL for your API
const BASE_URL = "https://api.tapcoe.com/api/v1";

// Helper function to get the token from localStorage
const getAuthToken = () => localStorage.getItem('token');

// Function to get comments for a specific post by post ID
export const getCommentDetails = async (postId) => {
  try {
    const response = await axios.get(`${BASE_URL}/comments/comments/${postId}`);
    return response.data; // Return comments data
  } catch (error) {
    console.error('Error fetching comment details:', error);
    throw error; // Rethrow the error for further handling
  }
};

export const sendComment = async (postId, eventId, commentText) => {
  try {
    const token = getAuthToken();
    if (!token) throw new Error('No token found. Please log in.');

    const response = await axios.post(
      `${BASE_URL}/comments/comments`,
      { postId, eventId, commentText },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data; // Return response data
  } catch (error) {
    console.error('Error sending comment:', error);
    throw error; // Rethrow error for calling code to handle
  }
};

// Function to send a reply to a specific comment
export const sendReply = async (postId, eventId, commentText, parentCommentId) => {
  try {
    const token = getAuthToken();
    if (!token) throw new Error('No token found. Please log in.');

    const response = await axios.post(
      `${BASE_URL}/comments/comments/${parentCommentId}/reply`, // Same endpoint used for both comments and replies
      { 
        postId, 
        eventId, 
        commentText, 
        parentComment: parentCommentId // Reference to the parent comment
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data; // Return response data
  } catch (error) {
    console.error('Error sending reply:', error);
    throw error; // Rethrow error for calling code to handle
  }
};
