import React, { Component, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import Copyright from "../components/Copyright";
class Faq extends Component {
    state = {
        activeIndex: null, // Track which accordion is active
    };

    toggleAccordion = (index) => {
        this.setState({ activeIndex: this.state.activeIndex === index ? null : index });
    };

    renderAccordionItem(title, content, index) {
        const { activeIndex } = this.state;
        const isActive = activeIndex === index;

        return (
            <div className="accordion-item mb-3" key={index}>
                <h5 className="accordion-header">
                    <button
                        className={`accordion-button ${isActive ? "" : "collapsed"}`}
                        style={{ 
                            fontSize: '1.25rem', 
                            fontWeight: '600',
                            backgroundColor: 'rgba(0,85,255,0.8)', // Match theme color
                            color: 'white' // White text
                        }}
                        onClick={() => this.toggleAccordion(index)}
                    >
                        {isActive ? "▼" : "►"} {/* Use simple text symbols for expand/collapse */}
                        {title}
                    </button>
                </h5>
                <div className={`accordion-collapse collapse ${isActive ? "show" : ""}`}>
                    <div className="accordion-body" style={{ 
                        fontSize: '1rem', 
                        backgroundColor: '#002d72',  // Darker blue for the content area
                        color: 'white' // White text for readability
                    }}>
                        {content}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Fragment>
                <Header />
                <Leftnav />
                {/* <Rightchat /> */}

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row justify-content-center">
                                <div className="col-xl-10">
                                    <div className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                                         style={{ backgroundImage: `url("/assets/images/help-bg.png")` }}>
                                        <div className="card-body p-md-5 p-4 text-center"
                                             style={{ backgroundColor: 'rgba(0,85,255,0.8)' }}>
                                            <h2 className="fw-700 display2-size text-white display2-md-size lh-2">
                                                Welcome to PicVot!
                                            </h2>
                                        </div>
                                    </div>

                                    {/* Custom Accordion */}
                                    <div className="mt-5">
                                        {this.renderAccordionItem("Eligibility of an Event", 
                                            "Different events have distinct eligibility criteria, specified during the content uploading process.", 0)}
                                        {this.renderAccordionItem("Participating in an Event", 
                                            "To participate in an event, select the desired event, and upload the required content as mentioned.", 1)}
                                        {this.renderAccordionItem("Claiming Prizes in an Event", 
                                            "Our team will contact you after the event to arrange the handover of your prize if you're a winner.", 2)}
                                        {this.renderAccordionItem("Logging In on the Website", 
                                            "You can log in through your registered whatsapp number on the website.", 3)}
                                        {this.renderAccordionItem("Uploading Content", 
                                            "Select the 'Upload' option, choose content from your device, and upload it to participate in the event.", 4)}
                                        {this.renderAccordionItem("Sharing Your Content", 
                                            "You can share your content by clicking on the share button and selecting a social media platform.", 5)}
                                        {this.renderAccordionItem("Daily Voting Rewards", 
                                            "You can earn rewards by voting daily on different events. The more you vote, the better rewards you unlock.", 6)}
                                        {this.renderAccordionItem("Voting Streaks", 
                                            "Maintain a streak by voting daily. Streaks unlock exclusive rewards and improve your ranking.", 7)}
                                        {this.renderAccordionItem("Leaderboards and Rankings", 
                                            "Leaderboards show the top participants and voters, helping you track your progress and compete with others.", 8)}
                                    </div>
                                </div>
                                <div className="mt-2">

<Copyright  />
</div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popupchat />
                <Appfooter />
            </Fragment>
        );
    }
}

export default Faq;
