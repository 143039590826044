import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Darkbutton from "../components/Darkbutton";
import Darkbuttonm from "../components/Darkbuttonm";
import { AuthContext } from "../context/AuthContext";
import { BsChat } from 'react-icons/bs';

const Header = () => {
  const { user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isNoti, setIsNoti] = useState(false);
  const [activeSection, setActiveSection] = useState("main");

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleActive = () => setIsActive(!isActive);
  const toggleisNoti = () => setIsNoti(!isNoti);
  const toggleSection = (section) => setActiveSection(section === activeSection ? "main" : section);

  const navClass = isOpen ? " nav-active" : "";
  const buttonClass = isOpen ? " active" : "";
  const searchClass = isActive ? " show" : "";
  const notiClass = isNoti ? " show" : "";
  // console.log(user, "hai")
  return (
    <div className="nav-header bg-white shadow-xs border-0">
      <div className="nav-top d-flex align-items-center justify-content-between px-3">
    {/* Dark Mode Button on the Left */}
    {/* <div className="d-flex align-items-center ">
        <Darkbuttonm />
        
    </div> */}
    


    {/* Logo in the Center */}
    <Link to="/" className="d-flex align-items-center">
        {/* <i className="feather-camera text-primary display2-size" style={{ fontSize: '2rem' }}></i> */}
        <span
            className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0"
            style={{ fontSize: '2rem' }}
        >
            tapcoe
        </span>
    </Link>

    {/* Message Icon - Only show if user is logged in */}
    {user && (
      <Link 
          to="/test-chat" 
          className="d-flex align-items-center"
          style={{
              marginLeft: 'auto',
              marginRight: '15px',
              color: '#666'
          }}
      >
          <BsChat style={{ fontSize: '24px' }} />
      </Link>
    )}

    {/* Hamburger Menu on the Right */}
    <button
        onClick={toggleOpen}
        className={`nav-menu me-0 ${buttonClass}`}
        style={{ fontSize: '1.5rem' }}
    ></button>
</div>

      <NavLink activeClassName="active" to="/" className="p-2 text-center ms-3 menu-icon center-menu-icon">
        <i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>
      <NavLink activeClassName="active" to="/events" className="p-2 text-center ms-3 menu-icon center-menu-icon">
        <i className="feather-calendar font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500"></i>
      </NavLink>
      <NavLink activeClassName="active" to="/feed" className="p-2 text-center ms-0 menu-icon center-menu-icon">
        <i className="feather-zap font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>
      <NavLink activeClassName="active" to="/winners" className="p-2 text-center ms-0 menu-icon center-menu-icon">
        <i className="feather-trending-up font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>

      <NavLink activeClassName="active" to="/profile" className="p-2 text-center ms-0 menu-icon center-menu-icon">
        <i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>


      <span
        className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`}
        id="dropdownMenu3"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={toggleisNoti}
      ></span>


      {/* <Link to="/defaultsettings" className="p-0 ms-3 menu-icon">
        <img src="/assets/images/user.png" alt="user" className="w40 mt--1" />
      </Link> */}
      <Link to="/accountsettings" className="p-0 ms-3 menu-icon">
        <div
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: user?.profilePic ? "transparent" : "blue", // Set background color only if no profile pic
            padding: user?.profilePic ? "0" : "5px", // Add padding only if no profile pic
          }}
        >
          {user?.profilePic ? (
            <img
              src={user.profilePic}
              alt={user.name || "user"} // Fallback for alt text
              className="mt--1"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <span style={{ color: "white", fontWeight: "bold" }}>tapcoe</span> // Set text color to blue
          )}
        </div>



        {/* <img
          src="/assets/images/profile.webp"
          alt="user"
          className="mt--1"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            objectFit: "cover"
          }}
        /> */}
      </Link>

      <nav className={`navigation scroll-bar ${navClass}`}>
        <div className="container ps-0 pe-0">
          <div className="nav-content">
            {/* User Profile Section */}
            {user && (
              <div className="d-flex align-items-center justify-content-center flex-column py-3 mb-2">
                <div
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: user?.profilePic ? "transparent" : "#1877f2",
                    padding: user?.profilePic ? "0" : "5px",
                    border: "3px solid #ffffff",
                    boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
                    margin: "0 auto 10px auto",
                  }}
                >
                  {user?.profilePic ? (
                    <img
                      src={user.profilePic}
                      alt={user.name || "user"}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <span style={{ color: "white", fontWeight: "bold", fontSize: "20px" }}>
                      {user?.name?.charAt(0) || "T"}
                    </span>
                  )}
                </div>
                <h4 className="fw-700 font-sm text-grey-900 mb-0">{user.name || "tapcoe user"}</h4>
                <span className="font-xssss text-grey-500">@tapcoe</span>
              </div>
            )}

            {/* Main Menu Section */}
            <div 
              className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2"
              style={{
                borderLeft: "4px solid #1877f2", 
                transition: "all 0.3s ease"
              }}
            >
              <div 
                className="nav-caption fw-600 font-xsss text-grey-500 px-3 mb-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid rgba(0,0,0,0.05)",
                  paddingBottom: "10px"
                }}
              >
                <div>
                  <span style={{ color: '#1877f2', fontWeight: 'bold', fontSize: '14px', marginRight: '5px' }}>tapcoe </span>
                  <span>Menu</span>
                </div>
                <i className="feather-chevron-down font-xsss text-grey-500"></i>
              </div>
              <ul className="mb-1 top-content">
                <li>
                  <Link to="/" className="nav-content-bttn open-font">
                    <div style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      padding: '8px 10px',
                      borderRadius: '10px',
                      transition: 'all 0.3s ease',
                      backgroundColor: window.location.pathname === '/' ? 'rgba(24, 119, 242, 0.1)' : 'transparent',
                    }}>
                      <i className="feather-home btn-round-md me-3" style={{ 
                        backgroundColor: window.location.pathname === '/' ? '#1877f2' : '#f0f2f5',
                        color: window.location.pathname === '/' ? '#ffffff' : '#666',
                        boxShadow: window.location.pathname === '/' ? '0 5px 10px rgba(24, 119, 242, 0.25)' : 'none'
                      }}></i>
                      <span style={{ 
                        fontWeight: window.location.pathname === '/' ? '600' : '500',
                        color: window.location.pathname === '/' ? '#1877f2' : '#666'
                      }}>Home</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/events" className="nav-content-bttn open-font">
                    <div style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      padding: '8px 10px',
                      borderRadius: '10px',
                      transition: 'all 0.3s ease',
                      backgroundColor: window.location.pathname === '/events' ? 'rgba(15, 157, 88, 0.1)' : 'transparent'
                    }}>
                      <i className="feather-calendar btn-round-md me-3" style={{ 
                        backgroundColor: window.location.pathname === '/events' ? '#0f9d58' : '#f0f2f5',
                        color: window.location.pathname === '/events' ? '#ffffff' : '#666',
                        boxShadow: window.location.pathname === '/events' ? '0 5px 10px rgba(15, 157, 88, 0.25)' : 'none'
                      }}></i>
                      <span style={{ 
                        fontWeight: window.location.pathname === '/events' ? '600' : '500',
                        color: window.location.pathname === '/events' ? '#0f9d58' : '#666'
                      }}>Events</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/feed" className="nav-content-bttn open-font">
                    <div style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      padding: '8px 10px',
                      borderRadius: '10px',
                      transition: 'all 0.3s ease',
                      backgroundColor: window.location.pathname === '/feed' ? 'rgba(255, 77, 0, 0.1)' : 'transparent'
                    }}>
                      <i className="feather-zap btn-round-md me-3" style={{ 
                        backgroundColor: window.location.pathname === '/feed' ? '#ff4d00' : '#f0f2f5',
                        color: window.location.pathname === '/feed' ? '#ffffff' : '#666',
                        boxShadow: window.location.pathname === '/feed' ? '0 5px 10px rgba(255, 77, 0, 0.25)' : 'none'
                      }}></i>
                      <span style={{ 
                        fontWeight: window.location.pathname === '/feed' ? '600' : '500',
                        color: window.location.pathname === '/feed' ? '#ff4d00' : '#666'
                      }}>Feed</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/winners" className="nav-content-bttn open-font">
                    <div style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      padding: '8px 10px',
                      borderRadius: '10px',
                      transition: 'all 0.3s ease',
                      backgroundColor: window.location.pathname === '/winners' ? 'rgba(255, 152, 0, 0.1)' : 'transparent'
                    }}>
                      <i className="feather-trending-up btn-round-md me-3" style={{ 
                        backgroundColor: window.location.pathname === '/winners' ? '#ff9800' : '#f0f2f5',
                        color: window.location.pathname === '/winners' ? '#ffffff' : '#666',
                        boxShadow: window.location.pathname === '/winners' ? '0 5px 10px rgba(255, 152, 0, 0.25)' : 'none'
                      }}></i>
                      <span style={{ 
                        fontWeight: window.location.pathname === '/winners' ? '600' : '500',
                        color: window.location.pathname === '/winners' ? '#ff9800' : '#666'
                      }}>Winners</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/profile" className="nav-content-bttn open-font">
                    <div style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      padding: '8px 10px',
                      borderRadius: '10px',
                      transition: 'all 0.3s ease',
                      backgroundColor: window.location.pathname === '/profile' ? 'rgba(94, 80, 238, 0.1)' : 'transparent'
                    }}>
                      <i className="feather-user btn-round-md me-3" style={{ 
                        backgroundColor: window.location.pathname === '/profile' ? '#5e50ee' : '#f0f2f5',
                        color: window.location.pathname === '/profile' ? '#ffffff' : '#666',
                        boxShadow: window.location.pathname === '/profile' ? '0 5px 10px rgba(94, 80, 238, 0.25)' : 'none'
                      }}></i>
                      <span style={{ 
                        fontWeight: window.location.pathname === '/profile' ? '600' : '500',
                        color: window.location.pathname === '/profile' ? '#5e50ee' : '#666'
                      }}>Profile</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>

            {/* More Pages Section */}
            <div 
              className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2"
              style={{
                borderLeft: "4px solid #ff9800", 
                transition: "all 0.3s ease"
              }}
            >
              <div 
                className="nav-caption fw-600 font-xsss text-grey-500 px-3 mb-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid rgba(0,0,0,0.05)",
                  paddingBottom: "10px"
                }}
              >
                <div>
                  <span style={{ color: '#ff9800', fontWeight: 'bold', fontSize: '14px', marginRight: '5px' }}>More </span>
                  <span>Pages</span>
                </div>
                <i className="feather-chevron-down font-xsss text-grey-500"></i>
              </div>
              <ul className="mb-3">
                {/* Group categories */}
                <div className="px-3 py-2">
                  <div className="category-group" style={{ marginBottom: '15px' }}>
                    <h6 className="font-xssss text-grey-500 fw-600 mb-2">Account</h6>
                    <li>
                      <Link to="/accountsettings" className="nav-content-bttn open-font">
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          padding: '8px 10px',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease',
                          backgroundColor: window.location.pathname === '/accountsettings' ? 'rgba(24, 119, 242, 0.1)' : 'transparent'
                        }}>
                          <i className="font-xl text-current feather-settings me-3"></i>
                          <span>Update Profile</span>
                        </div>
                      </Link>
                    </li>
                  </div>

                  <div className="category-group" style={{ marginBottom: '15px' }}>
                    <h6 className="font-xssss text-grey-500 fw-600 mb-2">Information</h6>
                    <li>
                      <Link to="/about-us" className="nav-content-bttn open-font">
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          padding: '8px 10px',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease'
                        }}>
                          <i className="font-xl text-current feather-info me-3"></i>
                          <span>About Us</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" className="nav-content-bttn open-font">
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          padding: '8px 10px',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease'
                        }}>
                          <i className="font-xl text-current feather-help-circle me-3"></i>
                          <span>FAQ</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" className="nav-content-bttn open-font">
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          padding: '8px 10px',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease'
                        }}>
                          <i className="font-xl text-current feather-shield me-3"></i>
                          <span>Privacy Policy</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions" className="nav-content-bttn open-font">
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          padding: '8px 10px',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease'
                        }}>
                          <i className="font-xl text-current feather-slash me-3"></i>
                          <span>Terms & Conditions</span>
                        </div>
                      </Link>
                    </li>
                  </div>

                  <div className="category-group" style={{ marginBottom: '15px' }}>
                    <h6 className="font-xssss text-grey-500 fw-600 mb-2">Partnership</h6>
                    <li>
                      <Link to="/hostevent" className="nav-content-bttn open-font">
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          padding: '8px 10px',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease'
                        }}>
                          <i className="font-xl text-current feather-calendar me-3"></i>
                          <span>Host An Event</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/campusambassador" className="nav-content-bttn open-font">
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          padding: '8px 10px',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease'
                        }}>
                          <i className="font-xl text-current feather-users me-3"></i>
                          <span>Campus Ambassador</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/advertisewithus" className="nav-content-bttn open-font">
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          padding: '8px 10px',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease'
                        }}>
                          <i className="font-xl text-current feather-video me-3"></i>
                          <span>Advertise With Us</span>
                        </div>
                      </Link>
                    </li>
                  </div>

                  <div className="category-group">
                    <h6 className="font-xssss text-grey-500 fw-600 mb-2">Contact</h6>
                    <li>
                      <Link to="/contact-us" className="nav-content-bttn open-font">
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          padding: '8px 10px',
                          borderRadius: '10px',
                          transition: 'all 0.3s ease'
                        }}>
                          <i className="font-xl text-current feather-mail me-3"></i>
                          <span>Contact Us</span>
                        </div>
                      </Link>
                    </li>
                  </div>
                </div>
              </ul>
            </div>

            {/* Social Links and Dark Mode */}
            <div className="p-3 border-top mb-3 rounded-xxl shadow-xss" style={{ backgroundColor: '#fff' }}>
              
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="fw-600 text-grey-900 font-xssss mb-0">Follow Us</h6>
                <div className="social-links">
                  {/* YouTube */}
                  <a
                    href="https://www.youtube.com/@picvotdotcom"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-round-sm font-xs"
                    style={{ 
                      backgroundColor: '#ff0000', 
                      color: '#fff',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginLeft: '10px'
                    }}
                  >
                    <i className="feather-youtube font-xs"></i>
                  </a>

                  {/* Instagram */}
                  <a
                    href="https://www.instagram.com/tapcoedotcom"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-round-sm font-xs"
                    style={{ 
                      background: 'linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888)', 
                      color: '#fff',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginLeft: '10px'
                    }}
                  >
                    <i className="feather-instagram font-xs"></i>
                  </a>

                  {/* LinkedIn */}
                  <a
                    href="https://www.linkedin.com/company/picvotdotcom"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-round-sm font-xs"
                    style={{ 
                      backgroundColor: '#0077b5', 
                      color: '#fff',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginLeft: '10px'
                    }}
                  >
                    <i className="feather-linkedin font-xs"></i>
                  </a>
                </div>
              </div>
            </div>

            {/* Copyright */}
            <div className="px-3 pb-3 text-center">
              <p className="font-xssss text-grey-500 mb-0">© 2024 tapcoe. All rights reserved.</p>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
