import axios from 'axios';

// Base URL for your API
const BASE_URL = "https://api.tapcoe.com//api/v1";

// Function to get event details by slug
export const getEventBySlug = async (slug, page = 1, limit = 10) => {
  try {
    const token = localStorage.getItem('token'); // Adjust storage strategy if needed
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    // Add pagination parameters to the request
    const response = await axios.get(`${BASE_URL}/events/slug/${slug}?page=${page}&limit=${limit}`, { headers });

    return response.data;
  } catch (error) {
    console.error('Error fetching event details:', error);
    throw error;
  }
};
