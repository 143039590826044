import axios from 'axios';

const API_URL = "https://api.tapcoe.com/api/v1"; // API base URL
const token = localStorage.getItem('token');
// API call to get user profile details
export const profileUpdateOrGet = async () => {

    if (!token) {
        console.error('No token found, please log in.');
        return;
      }
  try {
    const response = await axios.get(`${API_URL}/customers/userProfile`, {
      headers: {
        Authorization: `Bearer ${token}`, // Use the provided token in the request headers
      },
    });
    // console.log("dedkho",response.data);
    return response.data?.data; // Assuming the API sends the user profile in the response data
  } catch (error) {
    console.error('Error fetching profile details:', error);
    throw error;
  }
};



export const updateProfile = async (updateData) => {
 // Ensure you fetch the token correctly

  if (!token) {
    console.error('No token found, please log in.');
    return;
  }

  try {
    const response = await axios.put(
      `${API_URL}/customers/updateProfile`, 
      updateData, // Send the data as the request body
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in headers
          'Content-Type': 'application/json', // JSON content type
        },
      }
    );

    console.log(response.data);
    return response.data; // Assuming API returns updated profile data
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};




export const uploadProfileImage = async (file) => {
    const formData = new FormData();
    formData.append('profile', file); // Use 'profile' for the field name in your backend

    // Retrieve the token (modify according to your token storage method)
    //const token = localStorage.getItem('token'); // Or use your auth context

    try {
        const response = await axios.post(`${API_URL}/uploads/uploadProfileImage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`, // Pass the token
            },
        });
        return response.data; // Return response data for success/error handling
    } catch (error) {
        console.error("Error uploading profile image:", error);
        throw error; // Rethrow error for handling in the component
    }
};

export const uploadCoverImage = async (file) => {
    const formData = new FormData();
    formData.append('cover', file); // Use 'cover' for the field name in your backend

    // Retrieve the token (modify according to your token storage method)
    //const token = localStorage.getItem('token'); // Or use your auth context

    try {
        const response = await axios.post(`${API_URL}/uploads/uploadCoverImage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`, // Pass the token
            },
        });
        return response.data; // Return response data for success/error handling
    } catch (error) {
        console.error("Error uploading cover image:", error);
        throw error; // Rethrow error for handling in the component
    }
};





