import React, { useState, useEffect, useContext, Fragment } from "react";
import { AuthContext } from "../context/AuthContext"; // Assuming you have an AuthContext
import { ReSendOTP } from "../api/authApi";
const Register = () => {
  const { verifyUserOTP, register,login  } = useContext(AuthContext); // Include resendOTP function
  const [formData, setFormData] = useState({
    mobileNumber: "",
    otp: "",
    fullName: "",
    dob: "",
    gender: "",
    password: "",
    confirmPassword: "",
    location: { latitude: null, longitude: null },
  });
  const [showInfo, setShowInfo] = useState(false);
  const [step, setStep] = useState(1); // Step 1: Enter Additional Info, Step 2: Verify OTP

  useEffect(() => {
    handleLocationDetection();
  }, []);

  const handleLocationDetection = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setFormData((prevState) => ({
          ...prevState,
          location: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        }));
      },
      (error) => {
        console.error("Error getting location:", error);
      }
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCreateAccount = async () => {
    const { password, confirmPassword, mobileNumber, fullName, dob, gender, location } = formData;
console.log(location)
    // Check if all required fields are filled
    if (!fullName || !dob || !gender || !password || !confirmPassword || !mobileNumber) {
      alert("All fields are required!");
      return;
    }

    // Validate WhatsApp number format
    const whatsappRegex = /^[0-9]{10}$/; // Adjust this for country code if needed
    if (!whatsappRegex.test(mobileNumber)) {
      alert("Please enter a valid WhatsApp number!");
      return;
    }
    if (password.length < 8) {
      alert("Password must be at least 8 characters long!");
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    if (location.latitude === null || location.longitude === null) {
      alert("Location access is required to register. Please enable location access.");
      handleLocationDetection(); // Re-attempt location detection if needed
      return;
    }
    // if (!location || location.latitude === undefined || location.longitude === undefined) {
    //   alert("Location access is required to register. Please enable location access.");
    //   return;
    // }

    // Prepare data to send to the register API
    const registerData = {
      name: fullName,
      gender,
      dob, // Ensure this is in 'YYYY-MM-DD' format
      mobileNumber: mobileNumber,
      password,
      latitude: location.latitude,
      longitude: location.longitude,
    };

    // Call register API to send OTP
    try {
      const response = await register(registerData); // Assuming `register` is your API function

      if (response.success) {
        //alert(response.message); // Show success message
        setStep(2); // Move to OTP verification step
      } else {
        alert(response.message); // Show error message from API if success is false
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        alert(error.response.data.message); // Show the specific error message from the API
      } else {
        alert("Error sending OTP. Please try again."); // Fallback error message
      }
    }

  };

  const handleVerifyOTP = async () => {
    const { otp, mobileNumber } = formData;
  
    if (!otp) {
      alert("Please enter the OTP!");
      return;
    }
  
    try {
      // Verify the OTP with the provided API function
      const response = await verifyUserOTP({ otp, mobileNumber });
      console.log('OTP verification:', response);
      if (response.success) {
        alert("Registration successful!");
        
        // Automatically log in the user upon successful OTP verification
        const loginResponse = await login({ mobileNumber, password: formData.password });
        if (loginResponse.data.success) {
          // Redirect the user to the home page after successful login
          window.location.href = "/";
        } else {
          alert("Login failed after OTP verification. Please try logging in manually.");
          window.location.href = "/login";
        }
      } else {
        alert(response.message); // Show any error message returned by the OTP verification API
       // alert("Login failed after OTP verification. Please try logging in manually.");
        //  window.location.href = "/login";
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert(error.response.data.message || "Failed to verify OTP. Please try again.");
    }
  };
  

  const handleResendOTP = async () => {
    const { mobileNumber } = formData;

    // Call resend OTP API
    try {
      await ReSendOTP({mobileNumber}); // Assuming resendOTP is your API function to resend the OTP
      alert("OTP resent successfully! Please check your WhatsApp.");
    } catch (error) {
      console.error("Error resending OTP:", error);
      alert("Failed to resend OTP. Please try again later.");
    }
  };

  const { mobileNumber, otp, fullName, dob, gender, password, confirmPassword } = formData;

  return (
    <Fragment>
      <div className="main-wrap">
        <div className="row">
          <div
            className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{ backgroundImage: `url("assets/images/loginpic.png")` }}
          ></div>
          <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                {step === 1 && (
                  <>
                    <h2 className="fw-700 display1-size display2-md-size mb-4">
                      Enter Additional Info
                    </h2>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleCreateAccount();
                      }}
                    >


                      <div className="form-group icon-input mb-3 position-relative">
                        {/* Input Field */}
                        <input
                          type="text"
                          name="mobileNumber"
                          className="style2-input ps-5 pe-5 form-control text-grey-900 font-xsss fw-600"
                          placeholder="Indian Whatsapp Number Only EX-(9723456789)"
                          value={mobileNumber}
                          onChange={handleInputChange}
                          style={{
                            paddingRight: "40px", // Extra padding to prevent overlap
                            '::placeholder': { fontSize: '12px' }, // Placeholder font size
                          }}
                        />



                        <i
                          className="position-absolute d-flex align-items-center justify-content-center"
                          style={{
                            right: "10px",
                            top: "50%",
                            transform: showInfo ? "translateY(-50%) rotate(180deg)" : "translateY(-50%)",
                            cursor: "pointer",
                            transition: "transform 0.3s",
                            backgroundColor: "#f0f0f0",
                            borderRadius: "50%",
                            width: "25px",
                            height: "25px",
                            color: "#666",
                          }}
                          onClick={() => setShowInfo(!showInfo)}
                        >
                          ℹ️
                        </i>






                        {/* Tooltip Message */}
                        {showInfo && (
                          <div
                            className="position-absolute bg-light text-dark p-2 rounded"
                            style={{
                              top: "45px",
                              right: "0px",
                              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                              zIndex: 100,
                            }}
                          >
                            Please enter an Indian WhatsApp number without the country code (+91).
                          </div>
                        )}
                      </div>



                      <div className="form-group icon-input mb-3">
                        <input
                          type="text"
                          name="fullName"
                          className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                          placeholder="Full Name"
                          value={fullName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div
                        className="form-group"
                        style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}
                      >
                        <label
                          htmlFor="dob"
                          style={{ marginRight: '10px', flex: '1' }}
                        >
                          Date of Birth:
                        </label>

                        <input
                          id="dob"
                          type="date"
                          name="dob"
                          value={dob}
                          onChange={handleInputChange}
                          style={{
                            flex: '2',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '8px',
                            width: '100%',
                            minWidth: '50%',
                          }}
                        />
                      </div>





                      <div className="form-group icon-input mb-3">
                        <select
                          name="gender"
                          className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                          value={gender}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                      <div className="form-group icon-input mb-3">
                        <input
                          type="password"
                          name="password"
                          className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                          placeholder="Password"
                          value={password}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group icon-input mb-3">
                        <input
                          type="password"
                          name="confirmPassword"
                          className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={handleInputChange}
                        />
                      </div>
                      <button
                        type="submit"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                      >
                        Register
                      </button>
                    </form>
                  </>
                )}

                {step === 2 && (
                  <>
                    <h2 className="fw-700 display1-size display2-md-size mb-4">Verify OTP</h2>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleVerifyOTP();
                      }}
                    >
                      <div className="form-group icon-input mb-3">
                        <input
                          type="text"
                          className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setFormData({ ...formData, otp: e.target.value })}
                        />
                      </div>
                      <button
                        type="submit"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                      >
                        Verify OTP
                      </button>
                    </form>
                    <button
                      onClick={handleResendOTP}
                      className="btn btn-link mt-3" // Add styling for the button
                    >
                      Resend OTP
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Register;
