import axios from 'axios';

const API_URL = "https://api.picvot.com//api/v1";

// Get events API with optional authentication
export const feedView = async () => {
  try {
    const token = localStorage.getItem('token'); // Get the token from localStorage (if available)
    
    // Configure headers with optional token
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    // Make the GET request with headers
    const response = await axios.get(`${API_URL}/joinedevents/feed`, { headers });

    return response.data; // Return the response data
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error; // Handle error appropriately
  }
};
