import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

const ConversationList = ({ conversations = [], loading, history }) => {
    if (loading) {
        return (
            <div className="conversations-list">
                {[1, 2, 3].map(i => (
                    <div key={i} className="conversation-item skeleton">
                        <div className="avatar skeleton-avatar"></div>
                        <div className="conversation-info">
                            <div className="name skeleton-text"></div>
                            <div className="message skeleton-text"></div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    // Filter out conversations without otherParticipant
    const validConversations = conversations.filter(conv => conv.otherParticipant);

    if (!validConversations || validConversations.length === 0) {
        return (
            <div className="empty-state">
                <p>No conversations yet</p>
            </div>
        );
    }

    return (
        <div className="conversations-list">
            {validConversations.map(conversation => {
                const { otherParticipant, lastMessage } = conversation;
                
                return (
                    <div
                        key={conversation._id}
                        className="conversation-item"
                        onClick={() => {
                            history.push(`/chat/${conversation._id}`, {
                                conversation,
                                recipient: {
                                    _id: otherParticipant._id,
                                    name: otherParticipant.name,
                                    profilePic: otherParticipant.profilePic
                                }
                            });
                        }}
                    >
                        <div className="avatar">
                            <img
                                src={otherParticipant.profilePic || "/assets/images/default-profile.webp"}
                                alt={otherParticipant.name}
                            />
                        </div>
                        <div className="conversation-info">
                            <div className="name">{otherParticipant.name}</div>
                            <div className="message-preview">
                                {lastMessage ? (
                                    <>
                                        <span className="message-text">
                                            {lastMessage.message}
                                        </span>
                                        <span className="timestamp">
                                            {moment(lastMessage.createdAt).fromNow()}
                                        </span>
                                    </>
                                ) : (
                                    <span className="no-message">No messages yet</span>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}

            <style jsx>{`
                .conversations-list {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    padding: 16px;
                    overflow-y: auto;
                    height: 100%;
                }

                .conversation-item {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    padding: 12px;
                    border-radius: 12px;
                    cursor: pointer;
                    transition: background-color 0.2s;
                }

                .conversation-item:hover {
                    background-color: #f0f2f5;
                }

                .avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    overflow: hidden;
                    flex-shrink: 0;
                }

                .avatar img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .conversation-info {
                    flex: 1;
                    min-width: 0;
                }

                .name {
                    font-weight: 600;
                    font-size: 14px;
                    color: #1a1a1a;
                    margin-bottom: 4px;
                }

                .message-preview {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-size: 13px;
                    color: #8e8e8e;
                }

                .message-text {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .timestamp {
                    font-size: 12px;
                    color: #8e8e8e;
                    flex-shrink: 0;
                }

                .no-message {
                    font-style: italic;
                }

                .empty-state {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    color: #8e8e8e;
                    font-size: 14px;
                }

                /* Skeleton loading styles */
                .skeleton {
                    animation: pulse 1.5s infinite;
                }

                .skeleton-avatar {
                    background-color: #e1e4e8;
                }

                .skeleton-text {
                    height: 12px;
                    background-color: #e1e4e8;
                    border-radius: 4px;
                    margin-bottom: 4px;
                }

                .skeleton-text:last-child {
                    width: 60%;
                }

                @keyframes pulse {
                    0% {
                        opacity: 0.6;
                    }
                    50% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0.6;
                    }
                }

                @media (max-width: 768px) {
                    .conversations-list {
                        padding: 12px;
                    }

                    .conversation-item {
                        padding: 10px;
                    }

                    .avatar {
                        width: 40px;
                        height: 40px;
                    }

                    .name {
                        font-size: 13px;
                    }

                    .message-preview {
                        font-size: 12px;
                    }
                }

                @media (max-width: 480px) {
                    .conversations-list {
                        padding: 8px;
                    }

                    .conversation-item {
                        padding: 8px;
                        gap: 8px;
                    }

                    .avatar {
                        width: 36px;
                        height: 36px;
                    }

                    .timestamp {
                        font-size: 11px;
                    }
                }
            `}</style>
        </div>
    );
};

ConversationList.propTypes = {
    conversations: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            participants: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.string.isRequired,
                    username: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    profilePic: PropTypes.string
                })
            ).isRequired,
            otherParticipant: PropTypes.shape({
                _id: PropTypes.string.isRequired,
                username: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                profilePic: PropTypes.string
            }),
            lastMessage: PropTypes.shape({
                _id: PropTypes.string.isRequired,
                message: PropTypes.string.isRequired,
                isRead: PropTypes.bool.isRequired,
                status: PropTypes.string.isRequired,
                createdAt: PropTypes.string.isRequired
            }),
            updatedAt: PropTypes.string.isRequired
        })
    ),
    loading: PropTypes.bool,
    history: PropTypes.object.isRequired
};

export default withRouter(ConversationList); 