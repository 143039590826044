import React from "react";

const Copyright = () => {
  const currentYear = new Date().getFullYear();
  
  // Social media links
  const socialLinks = [
    { icon: "facebook", url: "https://facebook.com/tapcoe" },
    { icon: "twitter", url: "https://twitter.com/tapcoe" },
    { icon: "instagram", url: "https://instagram.com/tapcoe" },
    { icon: "linkedin", url: "https://linkedin.com/company/tapcoe" },
    { icon: "youtube", url: "https://youtube.com/tapcoe" }
  ];

  return (
    <div className="col-lg-12">
      <div 
        className="card w-100 border-0 shadow-xss p-4 rounded-xxl mb-10 mb-lg-0"
        style={{
          background: "linear-gradient(to right, rgba(24, 119, 242, 0.02), rgba(66, 133, 244, 0.05))",
          borderTop: "1px solid rgba(24, 119, 242, 0.05)"
        }}
      >
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 text-center text-md-start mb-3 mb-md-0">
            <div className="d-flex align-items-center justify-content-center justify-content-md-start">
              <div 
                className="logo-wrapper me-3 d-flex align-items-center justify-content-center"
                style={{ 
                  width: "40px", 
                  height: "40px", 
                  background: "linear-gradient(45deg, #1877f2, #4285f4)",
                  borderRadius: "12px"
                }}
              >
                <span className="font-sm fw-600 text-white">T</span>
              </div>
              <div>
                <p className="font-xss fw-500 text-grey-600 mb-0">
                  © {currentYear} Tapcoe. All rights reserved.
                </p>
                <div className="d-flex flex-wrap mt-1">
                  <a href="/terms" className="font-xssss fw-500 text-grey-600 me-3 text-decoration-none">Terms</a>
                  <a href="/policy" className="font-xssss fw-500 text-grey-600 me-3 text-decoration-none">Privacy</a>
                  <a href="/contact" className="font-xssss fw-500 text-grey-600 text-decoration-none">Contact</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 text-center text-md-end">
            <div className="d-flex align-items-center justify-content-center justify-content-md-end">
              <div>
                <p className="font-xssss fw-500 text-grey-500 mb-0">
                  Made with <span style={{ color: "#FF5E5B" }}>❤️</span> in India<br />
                  & Crafted in Bihar
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div 
        className="safe-area-spacer" 
        style={{ 
          height: "env(safe-area-inset-bottom, 15px)",
          background: "linear-gradient(to right, rgba(24, 119, 242, 0.02), rgba(66, 133, 244, 0.05))"
        }}
      ></div>
    </div>
  );
};

export default Copyright;
