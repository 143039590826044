import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BannerSlider = ({ images }) => {
  // Default banner images, can be overridden by props
  const defaultBannerImages = [
    "/assets/images/bn4.png",
    "/assets/images/bn1.png"
  ];

  // Use provided images or default ones
  const bannerImages = images || defaultBannerImages;

  // Settings for the slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false
  };

  return (
    <div className="banner-wrapper overflow-hidden rounded-xxl">
      <Slider {...settings}>
        {bannerImages.map((src, index) => (
          <div key={index} className="banner-slide">
            <div className="d-flex align-items-center">
              <div className="row">
                <div className="col-12">
                  <img 
                    src={src} 
                    alt={`Tapcoe Banner ${index + 1}`} 
                    className="img-fluid w-100"
                    style={{ 
                      borderRadius: "15px",
                      objectFit: "cover",
                      height: "auto",
                      maxHeight: "380px"
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BannerSlider; 