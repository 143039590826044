import axios from 'axios';

// Base URL for your API
const BASE_URL = "https://api.picvot.com/api/v1";

// Function to get voter names for a specific post by post ID
export const getVoterNamesForPost = async (postId,eventId) => {
  try {
    // Make the GET request to fetch voter names for the post
    const response = await axios.get(`${BASE_URL}/votes/posts/${postId}/${eventId}/voter-names`);
    
    // Check if response is successful and data exists
    if (response.status === 200 && response.data) {
      // Return the voter names data
      return response.data;
    } else {
      throw new Error('Failed to fetch voter names, server returned an invalid response');
    }
  } catch (error) {
    // Log the error and provide a more descriptive message
    console.error('Error fetching voter names for post:', error);
    
    // Throw the error to be handled by the caller
    throw new Error(error.response?.data?.message || 'An error occurred while fetching voter names');
  }
};
