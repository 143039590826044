import axios from 'axios';

const API_URL = "https://api.tapcoe.com/api/v1"; // API base URL

// API call to get user profile details
export const getProfileDetails = async (token) => {
    if (!token) {
        console.error('No token found, please log in.');
        return;
      }
  try {
    const response = await axios.get(`${API_URL}/customers/profile`, {
      headers: {
        Authorization: `Bearer ${token}`, // Use the provided token in the request headers
      },
    });
    // console.log(response.data);
    return response.data; // Assuming the API sends the user profile in the response data
  } catch (error) {
    console.error('Error fetching profile details:', error);
    throw error;
  }
};

export const getPublicProfileDetails = async (username) => {
  if (!username) {
      console.error('No username found, please Check in.');
      return;
    }
try {
  const response = await axios.get(`${API_URL}/customers/profile/${username}`);
  console.log(response.data);
  return response.data; // Assuming the API sends the user profile in the response data
} catch (error) {
  console.error('Error fetching profile details:', error);
  throw error;
}
};



