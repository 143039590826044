import React, { useEffect, useRef, useState } from "react";

const AdComponent = ({ 
  format = "auto", // auto, rectangle, horizontal, vertical
  slot = "4752297090", // Default slot from existing GoogleAd
  style = {},
  className = ""
}) => {
  const adRef = useRef(null);
  const [isAdLoaded, setIsAdLoaded] = useState(false);
  const [isAdError, setIsAdError] = useState(false);
  const [adVisibility, setAdVisibility] = useState(false);

  useEffect(() => {
    // Function to load AdSense script
    const loadAdsScript = () => {
      if (!window.adsbygoogle) {
        const script = document.createElement("script");
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4215966675525086";
        script.async = true;
        script.crossOrigin = "anonymous";
        
        script.onerror = () => {
          console.error("AdSense script failed to load");
          setIsAdError(true);
        };
        
        document.head.appendChild(script);
      }
    };

    // Create intersection observer to load ads only when visible
    const createVisibilityObserver = () => {
      const options = {
        threshold: 0.1 // 10% of the ad needs to be visible
      };
      
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setAdVisibility(true);
            observer.disconnect(); // Once visible, no need to observe anymore
          }
        });
      }, options);
      
      if (adRef.current) {
        observer.observe(adRef.current);
      }
      
      return observer;
    };

    // Function to check if ad is loaded
    const checkAdLoaded = () => {
      if (adRef.current) {
        const observer = new MutationObserver(() => {
          if (adRef.current && adRef.current.childNodes.length > 0) {
            setIsAdLoaded(true); // Show ad when it is loaded
            observer.disconnect(); // Stop observing
          }
        });

        observer.observe(adRef.current, { childList: true, subtree: true });
        
        // Safety timeout - if ad doesn't load within 3 seconds, show placeholder
        setTimeout(() => {
          if (!isAdLoaded) {
            observer.disconnect();
          }
        }, 3000);
      }
    };

    // First create the visibility observer
    const visibilityObserver = createVisibilityObserver();
    
    // Only load the ad when it's visible
    if (adVisibility) {
      loadAdsScript();
      
      // Small delay to ensure DOM is ready
      const timer = setTimeout(() => {
        if (window.adsbygoogle && adRef.current) {
          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            checkAdLoaded(); // Start observing after pushing the ad
          } catch (e) {
            console.error("AdSense error", e);
            setIsAdError(true);
          }
        }
      }, 100);
      
      return () => clearTimeout(timer);
    }
    
    return () => {
      visibilityObserver.disconnect();
    };
  }, [isAdLoaded, adVisibility]);

  // Get format-specific styles
  const getFormatStyles = () => {
    switch(format) {
      case "rectangle":
        return { minHeight: "280px", height: "280px", maxWidth: "336px" };
      case "horizontal":
        return { minHeight: "90px", height: "90px", maxWidth: "728px" };
      case "vertical":
        return { minHeight: "600px", height: "600px", maxWidth: "300px" };
      case "auto":
      default:
        return { minHeight: "250px" };
    }
  };

  // Placeholder for when ad is loading
  const AdPlaceholder = () => (
    <div className="ad-placeholder">
      <div className="ad-content">
        <div className="ad-label">Advertisement</div>
        <div className="ad-shape"></div>
        <div className="ad-lines">
          <div className="ad-line"></div>
          <div className="ad-line"></div>
          <div className="ad-line short"></div>
        </div>
      </div>
    </div>
  );
  
  // Error message if ad fails to load
  const AdError = () => (
    <div className="ad-error">
      <div className="ad-label">Advertisement</div>
      <p>Content temporarily unavailable</p>
    </div>
  );

  return (
    <div className={`ad-component-wrapper ${className}`} style={{ margin: "20px 0" }}>
      <div 
        className="ad-component-container"
        style={{
          position: "relative",
          display: "block",
          backgroundColor: "#f8f9fa",
          borderRadius: "8px",
          overflow: "hidden",
          margin: "0 auto",
          width: "100%",
          transition: "all 0.3s ease",
          boxShadow: "0 1px 3px rgba(0,0,0,0.08)",
          ...getFormatStyles(),
          ...style
        }}
      >
        {!isAdLoaded && !isAdError && <AdPlaceholder />}
        {isAdError && <AdError />}
        
        <ins
          ref={adRef}
          className="adsbygoogle"
          style={{ 
            display: "block",
            width: "100%", 
            height: "100%",
            opacity: isAdLoaded ? 1 : 0,
            transition: "opacity 0.5s ease"
          }}
          data-ad-client="ca-pub-4215966675525086"
          data-ad-slot={slot}
          data-ad-format={format === "auto" ? "auto" : ""}
          data-full-width-responsive={format === "auto" ? "true" : "false"}
        ></ins>
      </div>
      
      <style jsx="true">{`
        .ad-component-container {
          overflow: hidden;
          position: relative;
        }
        
        .ad-component-container:hover {
          box-shadow: 0 4px 12px rgba(0,0,0,0.1);
          transform: translateY(-2px);
        }
        
        .ad-placeholder, .ad-error {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          padding: 15px;
        }
        
        .ad-content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        
        .ad-label {
          font-size: 10px;
          font-weight: 500;
          text-transform: uppercase;
          color: #adb5bd;
          letter-spacing: 0.5px;
          margin-bottom: 10px;
        }
        
        .ad-shape {
          width: 60%;
          height: 40%;
          background: linear-gradient(135deg, #f1f3f5 0%, #e9ecef 100%);
          border-radius: 4px;
          margin-bottom: 15px;
          animation: pulse 2s infinite ease-in-out;
        }
        
        .ad-lines {
          width: 60%;
        }
        
        .ad-line {
          height: 8px;
          background-color: #e9ecef;
          border-radius: 4px;
          margin-bottom: 8px;
          width: 100%;
          animation: pulse 2s infinite ease-in-out;
        }
        
        .ad-line.short {
          width: 60%;
        }
        
        .ad-error {
          background-color: #f8f9fa;
          text-align: center;
        }
        
        .ad-error p {
          color: #adb5bd;
          font-size: 12px;
          margin-top: 0;
        }
        
        @keyframes pulse {
          0% { opacity: 0.6; }
          50% { opacity: 1; }
          100% { opacity: 0.6; }
        }
        
        @media (max-width: 768px) {
          .ad-component-container {
            width: 100% !important;
          }
          
          .ad-shape {
            width: 70%;
          }
          
          .ad-lines {
            width: 70%;
          }
        }
      `}</style>
    </div>
  );
};

export default AdComponent; 