// src/components/SEO.js
import { Helmet } from "react-helmet";

const SEO = ({
  title = "PicVot - Participate, Vote & Win Amazing Prizes",
  description = "Join exciting events, upload your photos, collect votes, and win fantastic prizes on PicVot. Engage with participants, vendors, and enjoy daily rewards!",
  keywords = "PicVot, photo contest, voting platform, win prizes, upload photos, daily rewards, social events",
  author = "Gulshan Raj",
  url = "https://picvot.com/",
  image = "https://picvot.com/assets/og-image.webp",
}) => (
  <Helmet>
    {/* Basic SEO */}
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="author" content={author} />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:url" content={url} />

    {/* Twitter Card */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />

    {/* Mobile Optimization */}
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    {/* Canonical URL */}
    <link rel="canonical" href={url} />
  </Helmet>
);

export default SEO;
