// src/analytics.js
import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-21MLEH6Q7N'; // Your GA4 Measurement ID

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const logPageView = (page) => {
  ReactGA.send({ hitType: 'pageview', page });
};

export const logEvent = (category, action, label = '') => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
