import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Appfooter = () => {
    const location = useLocation(); // Get the current path

    const getActiveStyle = (path) => ({
        color: location.pathname === path ? '#00ff00' : '#aaa', // Active color: Green
        borderBottom: location.pathname === path ? '3px solid #00ff00' : 'none', // Green underline
        padding: '12px',
    });

    return (
        <div className="app-footer d-block d-md-none border-0 shadow-lg bg-primary-gradiant d-flex justify-content-around">
            <Link to="/" className="nav-content-bttn nav-center" style={getActiveStyle('/')}>
                <i className="feather-home"></i>
            </Link>
            <Link to="/events" className="p-2 text-center ms-3 menu-icon" style={getActiveStyle('/events')}>
                <i className="feather-calendar"></i>
            </Link>
            <Link to="/feed" className="nav-content-bttn" style={getActiveStyle('/feed')}>
                <i className="feather-zap"></i>
            </Link>
            <Link to="/winners" className="nav-content-bttn" style={getActiveStyle('/winners')}>
                <i className="feather-trending-up"></i>
            </Link>
            <Link to="/profile" className="nav-content-bttn" style={getActiveStyle('/profile')}>
                <i className="feather-user"></i>
            </Link>
        </div>
    );
};

export default Appfooter;
