import React, { useState, useRef } from 'react';
import { joinEvent, uploadImage } from '../api/joinedEventApi';
import { logEvent } from '../analytics';
const CreatePost = ({ eventId }) => {  // Destructure eventId from props
  const [postMessage, setPostMessage] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const preview = URL.createObjectURL(file);
      setUploadedImage(file);
      setPreviewImage(preview);
      setIsImageUploaded(false);  // Reset upload status when new file is selected
    }
  };

  const handleUploadImage = async () => {
    if (!uploadedImage) {
      alert('Please select an image to upload.');
      return;
    }

    try {
      setUploading(true);
      setProgress(0);

      const uploadResponse = await uploadImage(
        eventId,  // Pass eventId directly
        'image',
        uploadedImage,
        setProgress
      );

      console.log('Upload successful:', uploadResponse);
      alert('Image uploaded successfully! Click On Join Event');
      setIsImageUploaded(true);
    } catch (error) {
      console.error('Upload failed:', error);
      alert(error.response.data.message);
      setIsImageUploaded(false);
    } finally {
      setUploading(false);
    }
  };

  const handleJoinEvent = async () => {
    logEvent('JoiningEvent', eventId, 'Join Event Click');
    if (!isImageUploaded) {
      alert('Please upload an image before joining the event.');
      return;
    }

    try {
      const eventResponse = await joinEvent(eventId);  // Use eventId directly
      console.log('Joined Event Response:', eventResponse);
      alert('Joined event successfully!');

      // Reset state after joining the event
      setPostMessage('');
      setUploadedImage(null);
      setPreviewImage(null);
      setIsImageUploaded(false);
    } catch (error) {
      console.error('Error during join event:', error.response.data.message);
      alert(error.response.data.message);
    }
  };

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
      <div className="card-body p-0">
        {previewImage && (
          <div className="row ps-2 pe-2 mb-3">
            <div className="col-sm-12 p-1">
              <img src={previewImage} className="rounded-3 w-100" alt="post" />
            </div>
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-secondary w-50 me-2"
            onClick={() => fileInputRef.current.click()}
          >
            Select Image
          </button>

          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />

          {uploading ? (
            <div className="w-50 text-center ms-2">
              <p>Uploading... {progress}%</p>
            </div>
          ) : (
            <button
              className="btn btn-success w-50 ms-2"
              onClick={handleUploadImage}
            >
              Upload Image
            </button>
          )}
        </div>

        <div className="mt-3">
          <button
            className="btn btn-primary w-100"
            onClick={handleJoinEvent}
            disabled={!isImageUploaded}  // Disable if the image is not uploaded
          >
            Join Event
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
