import React from 'react';

const LiveIndicator = () => {
  const blinkingStyle = {
    display: 'flex',
    alignItems: 'center',
    color: 'red',
    fontSize: '14px',
    fontWeight: 'bold',
  };

  const iconStyle = {
    marginRight: '4px',
    fontSize: '16px',
    animation: 'blink 1s infinite', // Inline animation
  };

  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(`
    @keyframes blink {
      0%, 100% { opacity: 1; }
      50% { opacity: 0; }
    }
  `, styleSheet.cssRules.length); // Add keyframes to the stylesheet

  return (
    <div style={blinkingStyle}>
      <i className="feather-zap text-danger" style={iconStyle}></i>
      Live Discussion
      {/* <i className="feather-zap text-danger" style={iconStyle}></i> */}
    </div>
  );
};

export default LiveIndicator;
