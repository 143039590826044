import React, { Fragment, lazy, Suspense, memo } from "react";
import { Link } from "react-router-dom";
import "./custom.css";

const Header = lazy(() => import("../components/Header"));
const Leftnav = lazy(() => import("../components/Leftnav"));
const Appfooter = lazy(() => import("../components/Appfooter"));
const Popupchat = lazy(() => import("../components/Popupchat"));
const Story = lazy(() => import("../components/Story"));
const TestimonialSlider = lazy(() => import("../components/TestimonialSlider"));
const HowItWorks = lazy(() => import("../components/HowItWorks"));
const Eventslist = lazy(() => import("../components/EventList"));
const BestEvents = lazy(()=> import ("../components/BestEvents"));
const Copyright = lazy(() => import("../components/Copyright"));
const SEO = lazy(() => import("../components/Seo"));
const BannerSlider = lazy(() => import("../components/BannerSlider"));

const Home = memo(() => {
  const categories = [
    { _id: "6795111bd68da8e5229915d7", name: "Brands", slug: "brands", icon: "https://media.picvot.com/uploads/category/Brands.webp" },
    { _id: "67951166d68da8e5229915da", name: "College", slug: "college", icon: "https://media.picvot.com/uploads/category/College.webp" },
    { _id: "6795118ed68da8e5229915dd", name: "Public", slug: "public", icon: "https://media.picvot.com/uploads/category/Public.webp" },
  ];

  return (
    <Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <SEO title="Home - tapcoe" description="Explore tapcoe's latest events and contests. Participate now and win amazing prizes!" url="https://tapcoe.com/" image="https://picvot.com/assets/home-og-image.webp" />
        <Header />
        <Leftnav />
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card w-100 border-0 shadow-none rounded-xxl border-0 mb-3 overflow-hidden">
                    <div className="col-lg-12">
                      <BannerSlider />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mb-4">
                  <div className="card w-100 border-0 shadow-sm p-0 rounded-xxl overflow-hidden bg-white position-relative">
                    <div className="experience-background position-absolute w-100 h-100" style={{ 
                      background: 'linear-gradient(135deg, rgba(120, 85, 245, 0.03) 0%, rgba(66, 133, 244, 0.06) 100%)',
                      zIndex: 1 
                    }}></div>
                    <div className="row align-items-center position-relative" style={{ zIndex: 2 }}>
                      <div className="col-lg-6 p-5">
                        <span className="badge px-3 py-2 rounded-pill bg-primary text-white font-xsssss fw-700 mb-3">FOR BRANDS & ORGANIZATIONS</span>
                        <h2 className="display-7 fw-bold text-grey-900 mb-3">Experience Tapcoe</h2>
                        <p className="font-xssss fw-500 text-grey-600 lh-26 mb-4">
                          Are you a brand or an organization that wants to boost engagement in the most creative way?
                          Engage your users through our events in different categories.
                        </p>
                        <div className="feature-list mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="bg-primary-gradiant rounded-circle d-inline-block me-3" style={{ width: '8px', height: '8px' }}></span>
                            <p className="font-xssss fw-500 text-grey-600 mb-0">Create exciting contests and events</p>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <span className="bg-primary-gradiant rounded-circle d-inline-block me-3" style={{ width: '8px', height: '8px' }}></span>
                            <p className="font-xssss fw-500 text-grey-600 mb-0">Reach the masses with your brand</p>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="bg-primary-gradiant rounded-circle d-inline-block me-3" style={{ width: '8px', height: '8px' }}></span>
                            <p className="font-xssss fw-500 text-grey-600 mb-0">Spark enthusiasm among participants</p>
                          </div>
                        </div>
                        <Link to="/about-us" className="btn border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-lg d-inline-block font-xssss">
                          Learn More
                        </Link>
                      </div>
                      <div className="col-lg-6 p-5 text-center">
                        <div className="img-container" style={{ position: 'relative' }}>
                          <div className="img-highlight" style={{ 
                            position: 'absolute',
                            width: '90%',
                            height: '90%',
                            background: 'linear-gradient(135deg, rgba(120, 85, 245, 0.3) 0%, rgba(66, 133, 244, 0.3) 100%)',
                            borderRadius: '12px',
                            top: '15%',
                            left: '5%',
                            zIndex: 1
                          }}></div>
                          <img 
                            src="/assets/images/exp.png" 
                            alt="Experience Tapcoe" 
                            className="rounded-xl w-100 position-relative shadow-sm" 
                            style={{ 
                              maxHeight: "280px", 
                              objectFit: "cover",
                              zIndex: 2,
                              transform: 'translateY(-5%)'
                            }} 
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container p-0 mb-4">
                  <div className="row justify-content-center">
                    <div className="col-12 mb-4 text-center">
                      <div className="section-heading d-flex flex-column align-items-center">
                        <div className="decoration-line mb-3" style={{
                          width: '60px',
                          height: '4px',
                          background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                          borderRadius: '4px'
                        }}></div>
                        <h2 className="fw-900 text-grey-900 mb-2" style={{fontSize: '28px'}}>Explore Categories</h2>
                        <p className="fw-500 text-grey-600" style={{fontSize: '15px', maxWidth: '600px'}}>
                          Find the perfect events and contests that match your interests
                        </p>
                      </div>
                    </div>
                    
                    {categories.map((category) => (
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={category._id}>
                        <Link 
                          to={`/events/${category.slug}`} 
                          className="card w-100 border-0 p-4 rounded-xl text-center position-relative overflow-hidden" 
                          style={{ 
                            background: 'linear-gradient(135deg, #ffffff 0%, #f5f8ff 100%)',
                            transition: "all 0.3s ease-in-out",
                            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.05)"
                          }}
                        >
                          <div className="category-icon-wrapper d-flex justify-content-center align-items-center mb-3 mx-auto"
                            style={{
                              width: '110px',
                              height: '110px',
                              background: 'linear-gradient(135deg, rgba(120, 85, 245, 0.08) 0%, rgba(66, 133, 244, 0.12) 100%)',
                              borderRadius: '50%',
                              padding: '3px'
                            }}
                          >
                            <img 
                              src={category.icon} 
                              alt={category.name} 
                              className="rounded-circle bg-white p-2" 
                              style={{ 
                                width: "100%", 
                                height: "100%", 
                                objectFit: "cover", 
                                border: "4px solid white",
                                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.05)" 
                              }} 
                            />
                          </div>
                          <h3 className="fw-800 text-grey-900 mb-2" style={{fontSize: '20px'}}>{category.name}</h3>
                          <p className="fw-500 text-grey-600 mb-4" style={{fontSize: '14px'}}>Explore {category.name} Contests</p>
                          
                          <div className="btn view-btn rounded-pill d-inline-flex align-items-center justify-content-center mx-auto" style={{
                            background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                            color: '#fff',
                            padding: '8px 20px',
                            fontSize: '13px',
                            fontWeight: 600,
                            boxShadow: '0 5px 15px rgba(66, 133, 244, 0.2)'
                          }}>
                            View Events <i className="feather-arrow-right ms-2" style={{fontSize: '13px'}}></i>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="row justify-content-center mb-4">
                  <div className="col-12 text-center">
                    <div className="section-heading d-flex flex-column align-items-center">
                      <div className="decoration-line mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-900 text-grey-900 mb-2" style={{fontSize: '28px'}}>Live Running Events</h2>
                      <p className="fw-500 text-grey-600" style={{fontSize: '15px', maxWidth: '600px'}}>
                        Join these ongoing events now and showcase your talent to win exciting prizes
                      </p>
                    </div>
                  </div>
                </div>
                <div id="live-events-section">
                  <Eventslist />
                </div>

                <div className="row justify-content-center mb-4 mt-5">
                  <div className="col-12 text-center">
                    <div className="section-heading d-flex flex-column align-items-center">
                      <div className="decoration-line mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #4285f4, #7855f5)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-900 text-grey-900 mb-2" style={{fontSize: '28px'}}>Top Events</h2>
                      <p className="fw-500 text-grey-600" style={{fontSize: '15px', maxWidth: '600px'}}>
                        Our most popular events with highest participation and engagement
                      </p>
                    </div>
                  </div>
                </div>
                <BestEvents />

                <div className="col-lg-12 mb-4 mt-4">
                  <div className="card w-100 border-0 shadow-sm p-0 rounded-xxl overflow-hidden bg-white position-relative">
                    <div className="what-we-do-background position-absolute w-100 h-100" style={{ 
                      background: 'linear-gradient(45deg, rgba(66, 133, 244, 0.06) 0%, rgba(120, 85, 245, 0.03) 100%)',
                      zIndex: 1 
                    }}></div>
                    <div className="row align-items-center position-relative" style={{ zIndex: 2 }}>
                      <div className="col-lg-6 p-5 text-center order-lg-1 order-2">
                        <div className="img-container" style={{ position: 'relative' }}>
                          <div className="img-highlight" style={{ 
                            position: 'absolute',
                            width: '90%',
                            height: '90%',
                            background: 'linear-gradient(45deg, rgba(66, 133, 244, 0.3) 0%, rgba(120, 85, 245, 0.3) 100%)',
                            borderRadius: '12px',
                            top: '15%',
                            left: '5%',
                            zIndex: 1
                          }}></div>
                          <img 
                            src="https://media.picvot.com/uploads/Banner/hostevent.png" 
                            alt="What Do We Do" 
                            className="rounded-xl w-100 position-relative shadow-sm" 
                            style={{ 
                              maxHeight: "300px", 
                              objectFit: "cover",
                              zIndex: 2,
                              transform: 'translateY(-5%)'
                            }} 
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 p-5 order-lg-2 order-1">
                        <span className="badge px-3 py-2 rounded-pill bg-primary text-white font-xsssss fw-700 mb-3">GROW YOUR AUDIENCE</span>
                        <h2 className="display-7 fw-bold text-grey-900 mb-3">What Do We Do</h2>
                        <p className="font-xssss fw-500 text-grey-600 lh-26 mb-4">
                          Want to make your events go viral? Engage thousands of users, boost participation, and create a buzz like never before!
                        </p>
                        <div className="feature-list mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="bg-primary-gradiant rounded-circle d-flex align-items-center justify-content-center me-3" style={{ width: '24px', height: '24px' }}>
                              <i className="feather-check text-white" style={{ fontSize: '12px' }}></i>
                            </span>
                            <p className="font-xssss fw-500 text-grey-600 mb-0">Organize <strong>exciting contests</strong></p>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <span className="bg-primary-gradiant rounded-circle d-flex align-items-center justify-content-center me-3" style={{ width: '24px', height: '24px' }}>
                              <i className="feather-check text-white" style={{ fontSize: '12px' }}></i>
                            </span>
                            <p className="font-xssss fw-500 text-grey-600 mb-0">Create <strong>interactive challenges</strong></p>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="bg-primary-gradiant rounded-circle d-flex align-items-center justify-content-center me-3" style={{ width: '24px', height: '24px' }}>
                              <i className="feather-check text-white" style={{ fontSize: '12px' }}></i>
                            </span>
                            <p className="font-xssss fw-500 text-grey-600 mb-0">Maximize <strong>reach and engagement</strong></p>
                          </div>
                        </div>
                        <Link to="/hostevent" className="btn border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-lg d-inline-block font-xssss">
                          Host an Event
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <Story />
                <HowItWorks />
                <TestimonialSlider />
                <Copyright />
              </div>
            </div>
          </div>
        </div>
        <Popupchat />
        <Appfooter />
      </Suspense>
    </Fragment>
  );
});

export default Home;
