import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import { useAuth } from '../context/AuthContext';
import moment from 'moment';
import { 
  IoSend, 
  IoHappy, 
  IoHeart, 
  IoThumbsUp, 
  IoArrowDown, 
  IoEllipsisVertical 
} from 'react-icons/io5';
import LiveIndicator from './LiveIndicator';

const LiveDiscussion = ({ eventId }) => {
    const { user, isLoggedIn } = useAuth();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [showEmoji, setShowEmoji] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const chatBodyRef = useRef(null);
    const lastMessageRef = useRef(null);
    const socketRef = useRef();
    const inputRef = useRef(null);

    useEffect(() => {
        // Connect to socket server
        socketRef.current = io('https://api.tapcoe.com', { 
            query: { 
                eventId,
                userId: user?.id 
            } 
        });

        // Listen for new messages
        socketRef.current.on('receiveMessage', (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
            
            // Check if we should scroll to new message
            if (chatBodyRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = chatBodyRef.current;
                const isAtBottom = scrollHeight - scrollTop - clientHeight < 100;
                
                if (!isAtBottom) {
                    setUnreadCount(prev => prev + 1);
                } else {
                    // Scroll to bottom when new message arrives
                    setTimeout(() => {
                        scrollToBottom();
                    }, 100);
                }
            }
        });
        
        // Listen for typing events
        socketRef.current.on('userTyping', ({ userId, isTyping: typing }) => {
            if (userId !== user?.id) {
                setIsTyping(typing);
                
                if (typing) {
                    // Auto-clear typing indicator after 3 seconds
                    setTimeout(() => setIsTyping(false), 3000);
                }
            }
        });

        // Fetch initial messages
        const fetchMessages = async () => {
            try {
                setLoading(true);
                const response = await fetch(`https://api.tapcoe.com/api/v1/livediscussion/${eventId}`);
                const data = await response.json();
                
                // Ensure data is an array before setting messages
                if (Array.isArray(data)) {
                    setMessages(data);
                } else {
                    console.error('API returned non-array data:', data);
                    setMessages([]); // Set empty array as fallback
                }
                
                setLoading(false);
                
                // Scroll to bottom after loading messages
                setTimeout(() => {
                    scrollToBottom();
                }, 100);
            } catch (error) {
                console.error('Error fetching messages:', error);
                setLoading(false);
            }
        };

        fetchMessages();

        // Clean up
        return () => {
            if (socketRef.current) {
                socketRef.current.off('receiveMessage');
                socketRef.current.off('userTyping');
                socketRef.current.disconnect();
            }
        };
    }, [eventId, user]);

    // Handle scroll to manage unread count
    const handleScroll = () => {
        if (chatBodyRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatBodyRef.current;
            const isAtBottom = scrollHeight - scrollTop - clientHeight < 50;
            
            if (isAtBottom && unreadCount > 0) {
                setUnreadCount(0);
            }
        }
    };

    // Scroll to the bottom of the chat
    const scrollToBottom = () => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
            setUnreadCount(0);
        }
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Group messages by sender and time
    const groupMessages = (messages) => {
        // Ensure messages is an array before trying to use reduce
        if (!Array.isArray(messages)) {
            console.warn('Received non-array messages:', messages);
            return []; // Return empty array to avoid rendering errors
        }
        
        return messages.reduce((groups, message, index) => {
            // First message starts a new group
            if (index === 0) {
                return [[message]];
            }
            
            const lastGroup = groups[groups.length - 1];
            const lastMessage = lastGroup[lastGroup.length - 1];
            
            // If message is from the same user and within 5 minutes of the previous one, add to current group
            if (
                message.userId === lastMessage.userId && 
                moment(message.timestamp).diff(moment(lastMessage.timestamp), 'minutes') < 5
            ) {
                lastGroup.push(message);
                return groups;
            } else {
                // Otherwise, start a new group
                return [...groups, [message]];
            }
        }, []);
    };

    // Handle sending messages
    const handleSend = (e) => {
        e.preventDefault();
        if (newMessage.trim() && isLoggedIn && eventId) {
            const messageData = { 
                message: newMessage, 
                eventId, 
                userId: user.id 
            };
            socketRef.current.emit('newMessage', messageData);
            setNewMessage('');
            
            // Emit "stopped typing" event
            socketRef.current.emit('userTyping', { eventId, userId: user.id, isTyping: false });
            
            // Scroll to bottom after sending a message
            setTimeout(() => {
                scrollToBottom();
            }, 100);
            
            // Focus the input field
            inputRef.current?.focus();
        }
    };

    // Handle typing event
    const handleTyping = (e) => {
        setNewMessage(e.target.value);
        
        // Only emit typing event if logged in
        if (isLoggedIn && socketRef.current) {
            socketRef.current.emit('userTyping', { 
                eventId, 
                userId: user.id, 
                isTyping: e.target.value.length > 0 
            });
        }
    };

    // Format timestamp
    const formatTime = (timestamp) => {
        const messageDate = moment(timestamp);
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        
        if (messageDate.isSame(today, 'd')) {
            return messageDate.format('h:mm A');
        } else if (messageDate.isSame(yesterday, 'd')) {
            return 'Yesterday ' + messageDate.format('h:mm A');
        } else {
            return messageDate.format('MMM D, h:mm A');
        }
    };

    return (
        <div className="chat-wrapper">
            <div className="chat-header">
                <div className="d-flex align-items-center">
                    <LiveIndicator />
                    <h6 className="mb-0 ms-2">Live Discussion</h6>
                    <div className="ms-2 text-muted small">
                        {messages.length} {messages.length === 1 ? 'message' : 'messages'}
                    </div>
                </div>
                <div className="chat-actions">
                    <button 
                        className="action-btn"
                        onClick={() => alert('Feature coming soon!')}
                        title="Options"
                    >
                        <IoEllipsisVertical />
                    </button>
                    {unreadCount > 0 && (
                        <button 
                            className="scroll-bottom-btn"
                            onClick={scrollToBottom}
                            title="New messages"
                        >
                            <IoArrowDown />
                            <span className="unread-badge">{unreadCount}</span>
                        </button>
                    )}
                </div>
            </div>
            
            <div 
                className="chat-body" 
                ref={chatBodyRef}
                onScroll={handleScroll}
            >
                {loading ? (
                    <div className="text-center py-5">
                        <div className="chat-loader">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <p className="mt-2 text-muted">Loading messages...</p>
                    </div>
                ) : messages.length === 0 ? (
                    <div className="empty-state text-center py-5">
                        <div className="empty-icon mb-3">
                            <i className="feather-message-circle display-4 text-muted"></i>
                        </div>
                        <h5 className="mb-2">No messages yet</h5>
                        <p className="text-muted">Be the first to start the conversation</p>
                    </div>
                ) : (
                    <div className="message-list p-3">
                        {groupMessages(messages).map((group, groupIndex) => {
                            const isCurrentUser = isLoggedIn && group[0].userId === user?.id;
                            const lastInGroup = groupIndex === groupMessages(messages).length - 1;
                            const ref = lastInGroup ? lastMessageRef : null;
                            
                            return (
                                <div 
                                    key={group[0]._id} 
                                    ref={ref}
                                    className={`message-group mb-4 ${isCurrentUser ? 'message-group-right' : 'message-group-left'}`}
                                >
                                    <div className="message-group-header">
                                        {!isCurrentUser && (
                                            <div className="avatar me-2">
                                                <img 
                                                    src={group[0].userProfilePic || 'https://via.placeholder.com/50'} 
                                                    alt="avatar" 
                                                    className="rounded-circle" 
                                                    width="40" 
                                                    height="40"
                                                />
                                            </div>
                                        )}
                                        
                                        <div className="message-author d-flex align-items-center">
                                            <div className="fw-bold">
                                                {isCurrentUser ? 'You' : group[0].Name}
                                            </div>
                                            <div className="message-time text-muted ms-2">
                                                {formatTime(group[0].timestamp)}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className={`message-bubbles ${isCurrentUser ? 'justify-content-end' : 'ms-5'}`}>
                                        {group.map((message, msgIndex) => (
                                            <div 
                                                key={message._id}
                                                className={`message-bubble ${isCurrentUser ? 'message-bubble-right' : 'message-bubble-left'} ${msgIndex < group.length - 1 ? 'mb-1' : ''}`}
                                            >
                                                <div className="message-text">{message.message}</div>
                                                
                                                <div className="message-actions">
                                                    <button className="reaction-btn" title="Like">
                                                        <IoThumbsUp />
                                                    </button>
                                                    <button className="reaction-btn" title="Love">
                                                        <IoHeart />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                        
                        {isTyping && (
                            <div className="typing-indicator">
                                <div className="typing-dot"></div>
                                <div className="typing-dot"></div>
                                <div className="typing-dot"></div>
                                <span className="typing-text">Someone is typing...</span>
                            </div>
                        )}
                    </div>
                )}
            </div>
            
            <div className="chat-footer border-top p-3">
                {isLoggedIn ? (
                    <form onSubmit={handleSend} className="message-form d-flex align-items-center">
                        <button 
                            type="button"
                            className="emoji-btn me-2"
                            onClick={() => setShowEmoji(!showEmoji)}
                        >
                            <IoHappy />
                        </button>
                        <div className="input-wrapper flex-grow-1 position-relative">
                            <input
                                type="text"
                                ref={inputRef}
                                className="form-control pr-4"
                                placeholder="Type your message..."
                                value={newMessage}
                                onChange={handleTyping}
                            />
                            {showEmoji && (
                                <div className="emoji-picker">
                                    <div className="emoji-list">
                                        {['😊', '😂', '❤️', '👍', '😍', '😎', '🙏', '🔥'].map(emoji => (
                                            <button 
                                                key={emoji} 
                                                type="button"
                                                className="emoji-item"
                                                onClick={() => {
                                                    setNewMessage(prev => prev + emoji);
                                                    setShowEmoji(false);
                                                    inputRef.current?.focus();
                                                }}
                                            >
                                                {emoji}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <button 
                            type="submit" 
                            className="send-button btn btn-primary rounded-circle ms-2"
                            disabled={!newMessage.trim()}
                        >
                            <IoSend />
                        </button>
                    </form>
                ) : (
                    <div className="login-prompt text-center p-3 bg-light rounded">
                        <i className="feather-lock me-2"></i>
                        Please <a href="/login" className="text-primary fw-bold">login</a> to join the discussion
                    </div>
                )}
            </div>
            
            <style jsx="true">{`
                .chat-wrapper {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    min-height: 400px;
                    background-color: white;
                    border-radius: 12px;
                    overflow: hidden;
                    box-shadow: 0 2px 12px rgba(0,0,0,0.1);
                }
                
                .chat-header {
                    padding: 15px;
                    border-bottom: 1px solid #e9ecef;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: white;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
                    z-index: 10;
                }
                
                .chat-actions {
                    display: flex;
                    align-items: center;
                }
                
                .action-btn, .scroll-bottom-btn {
                    background: none;
                    border: none;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #5271ff;
                    border-radius: 50%;
                    cursor: pointer;
                    transition: all 0.2s ease;
                    position: relative;
                    margin-left: 5px;
                }
                
                .action-btn:hover, .scroll-bottom-btn:hover {
                    background-color: #f0f2f5;
                }
                
                .scroll-bottom-btn {
                    background-color: #5271ff;
                    color: white;
                }
                
                .scroll-bottom-btn:hover {
                    background-color: #4060ef;
                }
                
                .unread-badge {
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    background-color: #e74c3c;
                    color: white;
                    font-size: 10px;
                    min-width: 18px;
                    height: 18px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                }
                
                .chat-body {
                    flex: 1;
                    overflow-y: auto;
                    scroll-behavior: smooth;
                    background-color: #f8f9fa;
                }
                
                .message-list {
                    display: flex;
                    flex-direction: column;
                }
                
                .message-group {
                    margin-bottom: 20px;
                    max-width: 85%;
                }
                
                .message-group-right {
                    align-self: flex-end;
                }
                
                .message-group-left {
                    align-self: flex-start;
                }
                
                .message-group-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                }
                
                .message-author {
                    display: flex;
                    align-items: center;
                }
                
                .message-author .fw-bold {
                    font-size: 0.85rem;
                }
                
                .message-bubbles {
                    display: flex;
                    flex-direction: column;
                }
                
                .message-bubble {
                    position: relative;
                    padding: 10px 15px;
                    border-radius: 18px;
                    max-width: 100%;
                    word-wrap: break-word;
                    transition: all 0.2s ease;
                    font-size: 0.85rem;
                }
                
                .message-bubble:hover .message-actions {
                    opacity: 1;
                }
                
                .message-bubble-left {
                    background-color: #f0f0f0;
                    color: #333;
                    border-top-left-radius: 4px;
                    align-self: flex-start;
                }
                
                .message-bubble-right {
                    background-color: #5271ff;
                    color: white;
                    border-top-right-radius: 4px;
                    align-self: flex-end;
                }
                
                .message-text {
                    font-size: 0.85rem;
                    line-height: 1.3;
                }
                
                .message-time {
                    font-size: 0.65rem;
                }
                
                .message-actions {
                    position: absolute;
                    bottom: -18px;
                    display: flex;
                    background: white;
                    border-radius: 30px;
                    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
                    padding: 3px;
                    opacity: 0;
                    transition: opacity 0.2s ease;
                    z-index: 2;
                }
                
                .message-bubble-left .message-actions {
                    left: 0;
                }
                
                .message-bubble-right .message-actions {
                    right: 0;
                }
                
                .reaction-btn {
                    width: 24px;
                    height: 24px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    background: none;
                    color: #5271ff;
                    border-radius: 50%;
                    cursor: pointer;
                    font-size: 14px;
                }
                
                .reaction-btn:hover {
                    background-color: #f0f2f5;
                }
                
                .chat-footer {
                    background-color: white;
                    padding: 12px 15px;
                    box-shadow: 0 -1px 3px rgba(0,0,0,0.05);
                    z-index: 10;
                }
                
                .message-form {
                    width: 100%;
                }
                
                .input-wrapper {
                    position: relative;
                }
                
                .emoji-btn {
                    background: none;
                    border: none;
                    color: #5271ff;
                    font-size: 20px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    transition: all 0.2s ease;
                }
                
                .emoji-btn:hover {
                    background-color: #f0f2f5;
                }
                
                .emoji-picker {
                    position: absolute;
                    bottom: 50px;
                    left: 0;
                    background-color: white;
                    border-radius: 10px;
                    box-shadow: 0 3px 12px rgba(0,0,0,0.15);
                    padding: 10px;
                    z-index: 100;
                }
                
                .emoji-list {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 8px;
                }
                
                .emoji-item {
                    font-size: 20px;
                    background: none;
                    border: none;
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                    transition: all 0.2s ease;
                }
                
                .emoji-item:hover {
                    background-color: #f0f2f5;
                }
                
                .send-button {
                    width: 40px;
                    height: 40px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
                .send-button:disabled {
                    opacity: 0.7;
                }
                
                .empty-state {
                    color: #6c757d;
                }
                
                .chat-loader {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    margin-bottom: 15px;
                }
                
                .dot {
                    width: 8px;
                    height: 8px;
                    background-color: #5271ff;
                    border-radius: 50%;
                    animation: bounce 1.5s infinite ease-in-out;
                }
                
                .dot:nth-child(1) {
                    animation-delay: 0s;
                }
                
                .dot:nth-child(2) {
                    animation-delay: 0.2s;
                }
                
                .dot:nth-child(3) {
                    animation-delay: 0.4s;
                }
                
                @keyframes bounce {
                    0%, 100% {
                        transform: translateY(0);
                    }
                    50% {
                        transform: translateY(-10px);
                    }
                }
                
                .typing-indicator {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    background-color: white;
                    border-radius: 18px;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.08);
                    margin-top: 10px;
                    width: fit-content;
                    max-width: 60%;
                }
                
                .typing-dot {
                    width: 6px;
                    height: 6px;
                    background-color: #aaa;
                    border-radius: 50%;
                    margin-right: 4px;
                    animation: typing-bounce 1.2s infinite ease-in-out;
                }
                
                .typing-dot:nth-child(1) {
                    animation-delay: 0s;
                }
                
                .typing-dot:nth-child(2) {
                    animation-delay: 0.2s;
                }
                
                .typing-dot:nth-child(3) {
                    animation-delay: 0.4s;
                }
                
                .typing-text {
                    font-size: 12px;
                    color: #888;
                    margin-left: 6px;
                }
                
                @keyframes typing-bounce {
                    0%, 100% {
                        transform: translateY(0);
                    }
                    50% {
                        transform: translateY(-4px);
                    }
                }
                
                .fullscreen-discussion .chat-wrapper {
                    height: calc(100vh - 60px);
                }
                
                @media (max-width: 768px) {
                    .message-group {
                        max-width: 95%;
                    }
                    
                    .message-text {
                        font-size: 0.8rem;
                    }
                    
                    .emoji-list {
                        grid-template-columns: repeat(4, 1fr);
                    }
                    
                    .emoji-item {
                        width: 36px;
                        height: 36px;
                    }
                }
            `}</style>
        </div>
    );
};

export default LiveDiscussion;
