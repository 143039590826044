import React, { useState, useRef } from 'react';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { joinEvent, uploadImage } from '../api/joinedEventApi';
import { logEvent } from '../analytics';

const CreatePost = ({ eventId }) => {
  const [uploadedImage, setUploadedImage] = useState(null); // Used for both preview and crop editor
  const [cropper, setCropper] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
        setShowEditor(false); // Reset to show preview by default
        setIsImageUploaded(false); // Reset upload status
      };
      reader.readAsDataURL(file);
    }
  };

  const cropImage = () => {
    if (cropper) {
      const croppedDataURL = cropper.getCroppedCanvas().toDataURL();
      setUploadedImage(croppedDataURL); // Update preview with cropped image
      setShowEditor(false); // Return to preview after cropping
    }
  };

  const discardImage = () => {
    setUploadedImage(null);
    setIsImageUploaded(false);
    setShowEditor(false);
  };

  const rotateImage = (direction) => {
    if (cropper) {
      const angle = direction === 'left' ? -45 : 45;
      cropper.rotate(angle);
    }
  };

  const handleUploadImage = async () => {
    if (!uploadedImage) {
      alert('Please upload or crop the image before proceeding.');
      return;
    }

    try {
      setUploading(true);
      setProgress(0);

      const blob = await fetch(uploadedImage).then((res) => res.blob());

      const uploadResponse = await uploadImage(
        eventId,
        'image',
        blob,
        setProgress
      );

      console.log('Upload successful:', uploadResponse);
      alert('Image uploaded successfully!');
      setIsImageUploaded(true);
    } catch (error) {
      console.error('Upload failed:', error);
      alert(error.response?.data?.message || 'An error occurred.');
      setIsImageUploaded(false);
    } finally {
      setUploading(false);
    }
  };

  const handleJoinEvent = async () => {
    logEvent('JoiningEvent', eventId, 'Join Event Click');
    if (!isImageUploaded) {
      alert('Please upload an image before joining the event.');
      return;
    }

    try {
      const eventResponse = await joinEvent(eventId);
      console.log('Joined Event Response:', eventResponse);
      alert('Joined event successfully!');
      discardImage(); // Reset state after joining the event
    } catch (error) {
      console.error('Error during join event:', error.response?.data?.message);
      alert(error.response?.data?.message || 'An error occurred.');
    }
  };

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
      <div className="card-body p-0">
        {/* Preview Section */}
        {uploadedImage && !showEditor && (
          <div className="row ps-2 pe-2 mb-3">
            <div className="col-sm-12 p-1">
              <img src={uploadedImage} className="rounded-3 w-100" alt="preview" />
            </div>
          </div>
        )}

        {/* Editor Section */}
        {uploadedImage && showEditor && (
          <Cropper
            src={uploadedImage}
            style={{ height: 400, width: '100%' }}
            aspectRatio={1}
            guides={true}
            viewMode={1}
            onInitialized={(instance) => setCropper(instance)}
          />
        )}

        {/* Actions */}
        {uploadedImage && showEditor && (
          <div className="d-flex justify-content-center align-items-center mt-3">
            <button
              className="btn btn-warning me-2"
              onClick={() => rotateImage('left')}
            >
              Rotate Left
            </button>
            <button
              className="btn btn-warning me-2"
              onClick={() => rotateImage('right')}
            >
              Rotate Right
            </button>
            <button className="btn btn-success me-2" onClick={cropImage}>
              Crop Image
            </button>
            <button className="btn btn-danger" onClick={discardImage}>
              Discard
            </button>
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center mt-3">
          <button
            className="btn btn-secondary w-50 me-2"
            onClick={() => fileInputRef.current.click()}
          >
            Select Image
          </button>

          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />

          {uploading ? (
            <div className="w-50 text-center ms-2">
              <p>Uploading... {progress}%</p>
            </div>
          ) : (
            <button
              className="btn btn-success w-50 ms-2"
              onClick={handleUploadImage}
              disabled={!uploadedImage}
            >
              Upload Image
            </button>
          )}
        </div>

        <div className="mt-3 d-flex justify-content-center">
          {!showEditor && uploadedImage && (
            <button
              className="btn btn-info me-2"
              onClick={() => setShowEditor(true)}
            >
              Edit Image
            </button>
          )}
          <button
            className="btn btn-primary w-50"
            onClick={handleJoinEvent}
            disabled={!isImageUploaded}
          >
            Join Event
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
