import React from "react";
import AdComponent from "./AdComponent";

const PremiumAd = ({ title, description, ctaText, ctaLink, imageUrl, ...adProps }) => {
  return (
    <div className="premium-ad-wrapper">
      <div className="premium-ad-content">
        {/* Sponsored Badge */}
        <div className="premium-ad-badge">
          <i className="feather-star"></i>
          <span>Sponsored</span>
        </div>

        {/* Main Content Area */}
        <div className="premium-ad-container">
          {/* Left: Image */}
          {imageUrl && (
            <div className="premium-ad-image">
              <img src={imageUrl} alt={title || "Sponsored content"} />
            </div>
          )}

          {/* Right: Text Content */}
          <div className="premium-ad-text">
            {title && <h4 className="premium-ad-title">{title}</h4>}
            {description && <p className="premium-ad-description">{description}</p>}
            {ctaText && ctaLink && (
              <a href={ctaLink} className="premium-ad-cta" target="_blank" rel="noopener noreferrer">
                {ctaText}
              </a>
            )}
          </div>
        </div>

        {/* Ad Component Below */}
        <div className="premium-ad-banner">
          <AdComponent
            format="horizontal"
            className="premium-ad-component"
            {...adProps}
          />
        </div>
      </div>

      <style jsx="true">{`
        .premium-ad-wrapper {
          margin: 25px 0;
          border-radius: 12px;
          overflow: hidden;
          background: white;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
          border: 1px solid #eaeaea;
          position: relative;
          transition: all 0.3s ease;
        }

        .premium-ad-wrapper:hover {
          transform: translateY(-3px);
          box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        }

        .premium-ad-wrapper::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 4px;
          background: linear-gradient(90deg, #1877f2, #5cb85c);
        }

        .premium-ad-content {
          padding: 20px;
        }

        .premium-ad-badge {
          display: inline-flex;
          align-items: center;
          background: #f8f9fa;
          padding: 4px 10px;
          border-radius: 50px;
          margin-bottom: 15px;
        }

        .premium-ad-badge i {
          color: #ffc107;
          font-size: 12px;
          margin-right: 5px;
        }

        .premium-ad-badge span {
          font-size: 11px;
          color: #6c757d;
          font-weight: 500;
        }

        .premium-ad-container {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }

        .premium-ad-image {
          flex: 0 0 120px;
          margin-right: 20px;
        }

        .premium-ad-image img {
          width: 100%;
          height: auto;
          border-radius: 8px;
          object-fit: cover;
        }

        .premium-ad-text {
          flex: 1;
        }

        .premium-ad-title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 8px;
          color: #343a40;
        }

        .premium-ad-description {
          font-size: 14px;
          color: #6c757d;
          margin-bottom: 12px;
          line-height: 1.5;
        }

        .premium-ad-cta {
          display: inline-block;
          background: #1877f2;
          color: white;
          padding: 6px 16px;
          border-radius: 50px;
          font-size: 13px;
          text-decoration: none;
          font-weight: 500;
          transition: background 0.2s ease;
        }

        .premium-ad-cta:hover {
          background: #166fe5;
          text-decoration: none;
          color: white;
        }

        .premium-ad-banner {
          margin-top: 5px;
        }

        @media (max-width: 768px) {
          .premium-ad-container {
            flex-direction: column;
          }

          .premium-ad-image {
            flex: 0 0 auto;
            margin-right: 0;
            margin-bottom: 15px;
            width: 100%;
          }

          .premium-ad-image img {
            width: 100%;
            max-height: 200px;
            object-fit: cover;
          }
        }
      `}</style>
    </div>
  );
};

export default PremiumAd; 