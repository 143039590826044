import React, { useState, useRef } from 'react';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { joinEvent, uploadImage } from '../api/joinedEventApi';
import { logEvent } from '../analytics';

const CreatePost = ({ eventId, alertMessage,refreshParent}) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePost = async () => {
    if (!uploadedImage) {
      alert('Please select an image before posting.');
      return;
    }
    try {
      setUploading(true);
      setProgress(0);
      const blob = await fetch(uploadedImage).then((res) => res.blob());
      await uploadImage(eventId, 'image', blob, setProgress);
      alert('Image uploaded successfully!');
      await joinEvent(eventId);
      alert('Joined event successfully!');
      setUploadedImage(null);
      refreshParent();
    } catch (error) {
      console.error('Error:', error.response?.data?.message);
      alert(error.response?.data?.message || 'An error occurred.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
      {alertMessage && (
        <div className="text-center mb-2 p-1" style={{ backgroundColor: '#f8f9fa', color: '#6c757d', fontSize: '12px', borderRadius: '4px' }}>
          {alertMessage}
        </div>
      )}

      <div className="card-body p-0">
        {uploadedImage && (
          <div className="row mb-3">
            <div className="col-sm-12">
              <img src={uploadedImage} className="rounded-3 w-100" alt="preview" />
            </div>
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center mt-3">
          <button className="btn btn-secondary w-50" onClick={() => fileInputRef.current.click()}>
            Select Image
          </button>
          <input type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} ref={fileInputRef} />
          <button className="btn btn-primary w-50 ms-2" onClick={handlePost} disabled={!uploadedImage || uploading}>
            {uploading ? `Uploading... ${progress}%` : 'Post'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePost;