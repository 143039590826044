import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './context/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          // Reset styles before redirecting
          (() => {
            document.body.classList.remove("theme-dark"); // Ensure dark theme is removed
            document.body.style.backgroundColor = "#5271ff"; // Set to desired background color
            return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
          })()
        )
      }
    />
  );
};

export default PrivateRoute;
