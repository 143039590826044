import React, { useEffect, useRef, useState } from "react";

const GoogleAd = () => {
  const adRef = useRef(null);
  const [isAdLoaded, setIsAdLoaded] = useState(false);

  useEffect(() => {
    // Function to load AdSense script
    const loadAdsScript = () => {
      if (!window.adsbygoogle) {
        const script = document.createElement("script");
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4215966675525086";
        script.async = true;
        script.crossOrigin = "anonymous";
        document.head.appendChild(script);
      }
    };

    // Function to check if ad is loaded
    const checkAdLoaded = () => {
      if (adRef.current) {
        const observer = new MutationObserver(() => {
          if (adRef.current && adRef.current.childNodes.length > 0) {
            setIsAdLoaded(true); // Show ad when it is loaded
            observer.disconnect(); // Stop observing
          }
        });

        observer.observe(adRef.current, { childList: true });
      }
    };

    loadAdsScript();

    if (window.adsbygoogle && adRef.current) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        checkAdLoaded(); // Start observing after pushing the ad
      } catch (e) {
        console.error("Adsense error", e);
      }
    }
  }, []);

  return isAdLoaded ? (
    <div style={{ textAlign: "center", margin: "10px 0" }}>
      <ins
        ref={adRef}
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-4215966675525086"
        data-ad-slot="4752297090"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  ) : null; // Hide if ad is not loaded
};

export default GoogleAd;
