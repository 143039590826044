import React, { useState, Fragment } from "react";
import axios from "axios";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Copyright from "../components/Copyright";
import SEO from "../components/Seo";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  // Form submit handler
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccessMessage("");

    try {
      const response = await axios.post("https://api.tapcoe.com/api/v1/contact", {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        subject: formData.subject,
        typeOfQuestion: formData.message,
      });

      // Success response handling
      setLoading(false);
      setSuccessMessage("Your message has been sent successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      // Error response handling
      setLoading(false);
      setError(error.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    <Fragment>
      <SEO
        title="Contact Us - Get in Touch with tapcoe"
        description="Reach out to the tapcoe team with your questions, feedback, or support needs. We're here to help you make the most of your experience."
        keywords="contact tapcoe, get in touch, customer support, help, questions, feedback"
        url="https://tapcoe.com/contact"
        image="https://tapcoe.com/assets/contact-og-image.webp"
      />
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            {/* Hero Section */}
            <div className="contact-hero position-relative overflow-hidden rounded-xxl mb-4"
              style={{
                background: "linear-gradient(135deg, rgba(24, 119, 242, 0.95), rgba(66, 133, 244, 0.95))",
                padding: "60px 0"
              }}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 text-lg-start text-center">
                    <div className="hero-content p-4">
                      <h1 className="fw-bold text-white mb-3" style={{ fontSize: "42px" }}>Get in Touch</h1>
                      <p className="text-white opacity-90 mb-4" style={{ fontSize: "18px", lineHeight: "1.6" }}>
                        Have questions or feedback? Our team is here to help. Reach out to us and we'll respond as soon as possible.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 d-none d-lg-block text-center">
                    <img 
                      src="/assets/images/contact-hero.png" 
                      alt="Contact Us" 
                      className="img-fluid" 
                      style={{
                        maxHeight: "320px",
                        filter: "drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15))"
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Decorative elements */}
              <div className="position-absolute" style={{ top: "15%", left: "10%", width: "150px", height: "150px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
              <div className="position-absolute" style={{ bottom: "10%", right: "15%", width: "120px", height: "120px", background: "radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)", borderRadius: "50%" }}></div>
              <div className="position-absolute" style={{ top: "30%", right: "20%", width: "30px", height: "30px", background: "rgba(255,255,255,0.2)", borderRadius: "50%" }}></div>
            </div>

            {/* Contact Info Cards */}
            <div className="contact-info mb-4">
              <div className="row">
                {[
                  {
                    icon: "mail",
                    title: "Email Us",
                    description: "Our team will respond within 24 hours",
                    contact: "support@tapcoe.com",
                    link: "mailto:support@tapcoe.com"
                  },
                  {
                    icon: "phone",
                    title: "Call Us",
                    description: "Available Monday to Friday, 9am - 6pm",
                    contact: "+91 98765 43210",
                    link: "tel:+919876543210"
                  },
                  {
                    icon: "map-pin",
                    title: "Visit Us",
                    description: "Our office location",
                    contact: "123 Tech Park, Bangalore, India",
                    link: "https://maps.google.com/?q=Tech+Park+Bangalore"
                  }
                ].map((info, index) => (
                  <div key={index} className="col-md-4 mb-3">
                    <div className="card border-0 rounded-xxl p-4 h-100" 
                      style={{ 
                        background: 'linear-gradient(135deg, rgba(24, 119, 242, 0.03) 0%, rgba(66, 133, 244, 0.06) 100%)',
                        border: '1px solid rgba(24, 119, 242, 0.1)',
                        transition: 'all 0.3s ease'
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = 'translateY(-5px)';
                        e.currentTarget.style.boxShadow = '0 10px 30px rgba(0, 0, 0, 0.08)';
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = 'translateY(0)';
                        e.currentTarget.style.boxShadow = 'none';
                      }}
                    >
                      <div className="icon-wrapper mb-3 d-flex align-items-center justify-content-center rounded-circle"
                        style={{
                          width: "60px",
                          height: "60px",
                          background: "linear-gradient(135deg, #1877f2, #4285f4)"
                        }}
                      >
                        <i className={`feather-${info.icon}`} style={{ fontSize: "24px", color: "#fff" }}></i>
                      </div>
                      <h4 className="fw-700 text-grey-900 font-sm mb-2">{info.title}</h4>
                      <p className="font-xsss fw-500 text-grey-500 mb-2">{info.description}</p>
                      <a href={info.link} className="font-xss fw-600 text-primary text-decoration-none">{info.contact}</a>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Contact Form and Map */}
            <div className="card border-0 rounded-xxl shadow-xss mb-4">
              <div className="card-body p-0 overflow-hidden">
                <div className="row g-0">
                  <div className="col-lg-6 p-5">
                    <div className="form-section-heading mb-4">
                      <div className="decoration-line mb-3" style={{
                        width: '60px',
                        height: '4px',
                        background: 'linear-gradient(90deg, #1877f2, #4285f4)',
                        borderRadius: '4px'
                      }}></div>
                      <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Send Us a Message</h2>
                      <p className="font-xsss fw-500 text-grey-500">
                        Fill out the form below and we'll get back to you as soon as possible
                      </p>
                    </div>

                    {error && (
                      <div className="alert alert-danger mb-4">
                        {error}
                      </div>
                    )}

                    {successMessage && (
                      <div className="alert alert-success mb-4">
                        {successMessage}
                      </div>
                    )}

                    <form onSubmit={submitHandler}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="firstName" className="form-label fw-500 text-grey-900">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                              <i className="feather-user" style={{ color: "#1877f2" }}></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0 ps-0"
                              id="firstName"
                              placeholder="First name"
                              value={formData.firstName}
                              onChange={handleChange}
                              required
                              style={{ borderColor: "#e5e5e5" }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="lastName" className="form-label fw-500 text-grey-900">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                              <i className="feather-user" style={{ color: "#1877f2" }}></i>
                            </span>
                            <input
                              type="text"
                              className="form-control border-start-0 ps-0"
                              id="lastName"
                              placeholder="Last name"
                              value={formData.lastName}
                              onChange={handleChange}
                              required
                              style={{ borderColor: "#e5e5e5" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="email" className="form-label fw-500 text-grey-900">
                          Email <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-mail" style={{ color: "#1877f2" }}></i>
                          </span>
                          <input
                            type="email"
                            className="form-control border-start-0 ps-0"
                            id="email"
                            placeholder="Email address"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="phoneNumber" className="form-label fw-500 text-grey-900">
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-phone" style={{ color: "#1877f2" }}></i>
                          </span>
                          <input
                            type="text"
                            className="form-control border-start-0 ps-0"
                            id="phoneNumber"
                            placeholder="Phone number"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          />
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="subject" className="form-label fw-500 text-grey-900">
                          Subject <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-file-text" style={{ color: "#1877f2" }}></i>
                          </span>
                          <input
                            type="text"
                            className="form-control border-start-0 ps-0"
                            id="subject"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          />
                        </div>
                      </div>

                      <div className="mb-4">
                        <label htmlFor="message" className="form-label fw-500 text-grey-900">
                          Message <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-transparent border-end-0 align-items-start pt-2" style={{ borderColor: "#e5e5e5" }}>
                            <i className="feather-message-square" style={{ color: "#1877f2" }}></i>
                          </span>
                          <textarea
                            className="form-control border-start-0 ps-0"
                            id="message"
                            rows="4"
                            placeholder="Your message here..."
                            value={formData.message}
                            onChange={handleChange}
                            required
                            style={{ borderColor: "#e5e5e5" }}
                          ></textarea>
                        </div>
                      </div>

                      <button 
                        type="submit" 
                        className="btn border-0 w-100 p-3 rounded-lg d-inline-block"
                        disabled={loading}
                        style={{
                          background: "linear-gradient(90deg, #1877f2, #4285f4)",
                          color: "white",
                          fontWeight: "600",
                          fontSize: "16px"
                        }}
                      >
                        {loading ? (
                          <span>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Sending...
                          </span>
                        ) : 'Send Message'}
                      </button>
                    </form>
                  </div>

                  <div className="col-lg-6 d-none d-lg-block p-0">
                    <div className="map-container h-100" style={{ minHeight: "600px" }}>
                      <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d497699.9973874144!2d77.35074421903534!3d12.95384772557775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1635309875022!5m2!1sen!2sin" 
                        width="100%" 
                        height="100%" 
                        style={{ border: 0 }} 
                        allowFullScreen={true} 
                        loading="lazy"
                        title="tapcoe office location"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* FAQ Section */}
            <div className="card border-0 rounded-xxl shadow-xss mb-4 bg-pattern" style={{ 
              background: "linear-gradient(135deg, rgba(24, 119, 242, 0.03) 0%, rgba(66, 133, 244, 0.07) 100%)",
              backgroundImage: "url('/assets/images/dot-pattern.png')",
              backgroundSize: "300px",
              backgroundPosition: "center"
            }}>
              <div className="card-body p-5">
                <div className="section-heading text-center mb-5">
                  <div className="decoration-line mx-auto mb-3" style={{
                    width: '60px',
                    height: '4px',
                    background: 'linear-gradient(90deg, #1877f2, #4285f4)',
                    borderRadius: '4px'
                  }}></div>
                  <h2 className="fw-700 text-grey-900 mb-2" style={{ fontSize: "28px" }}>Frequently Asked Questions</h2>
                  <p className="font-xsss fw-500 text-grey-500 mx-auto" style={{ maxWidth: "600px" }}>
                    Find quick answers to common questions about our platform and services
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-10 mx-auto">
                    <div className="accordion" id="contactFaq">
                      {[
                        {
                          id: "faq1",
                          question: "How do I register for an event on tapcoe?",
                          answer: "You can register for events by creating an account on our platform, browsing the available events, and clicking on the 'Register' button on the event page. Follow the instructions to complete your registration."
                        },
                        {
                          id: "faq2",
                          question: "What payment methods do you accept?",
                          answer: "We accept credit/debit cards, net banking, UPI, and various digital wallets for event registration payments. All transactions are secured with industry-standard encryption."
                        },
                        {
                          id: "faq3",
                          question: "How can I participate in a voting event?",
                          answer: "To participate in voting events, log in to your account, navigate to the event page, and follow the voting instructions. Each event may have different voting rules, so please read the guidelines carefully."
                        },
                        {
                          id: "faq4",
                          question: "How can I host my own event on tapcoe?",
                          answer: "To host an event, log in to your account and click on 'Create Event' in your dashboard. Fill out the event details, set up the parameters for participation and voting, and submit for approval."
                        },
                        {
                          id: "faq5",
                          question: "What if I need to cancel my registration?",
                          answer: "You can cancel your registration through your account dashboard. Our refund policy varies depending on how far in advance you cancel, so please refer to the specific event's terms and conditions."
                        }
                      ].map((faq, index) => (
                        <div key={index} className="accordion-item border-0 mb-3 rounded-xxl overflow-hidden" style={{ background: 'white' }}>
                          <h2 className="accordion-header" id={`heading${faq.id}`}>
                            <button 
                              className="accordion-button collapsed fw-600 font-xss"
                              type="button" 
                              data-bs-toggle="collapse" 
                              data-bs-target={`#collapse${faq.id}`} 
                              aria-expanded="false" 
                              aria-controls={`collapse${faq.id}`}
                              style={{ color: '#1877f2' }}
                            >
                              {faq.question}
                            </button>
                          </h2>
                          <div 
                            id={`collapse${faq.id}`} 
                            className="accordion-collapse collapse" 
                            aria-labelledby={`heading${faq.id}`} 
                            data-bs-parent="#contactFaq"
                          >
                            <div className="accordion-body font-xss fw-500 text-grey-500 lh-24">
                              {faq.answer}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Social Media Section */}
            <div className="card border-0 rounded-xxl shadow-xss mb-4">
              <div className="card-body p-5 text-center">
                <h4 className="fw-700 text-grey-900 mb-4">Connect With Us on Social Media</h4>
                <div className="social-icons d-flex justify-content-center">
                  {[
                    { icon: "facebook", url: "https://facebook.com/tapcoe", color: "#1877f2" },
                    { icon: "twitter", url: "https://twitter.com/tapcoe", color: "#1DA1F2" },
                    { icon: "instagram", url: "https://instagram.com/tapcoe", color: "#E1306C" },
                    { icon: "linkedin", url: "https://linkedin.com/company/tapcoe", color: "#0A66C2" },
                    { icon: "youtube", url: "https://youtube.com/tapcoe", color: "#FF0000" }
                  ].map((social, index) => (
                    <a 
                      key={index}
                      href={social.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="social-icon d-flex align-items-center justify-content-center rounded-circle mx-2"
                      style={{
                        width: "50px",
                        height: "50px",
                        background: `rgba(${social.color.replace('#', '').match(/.{2}/g).map(hex => parseInt(hex, 16)).join(', ')}, 0.1)`,
                        transition: 'all 0.3s ease'
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.background = social.color;
                        e.currentTarget.querySelector('i').style.color = 'white';
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.background = `rgba(${social.color.replace('#', '').match(/.{2}/g).map(hex => parseInt(hex, 16)).join(', ')}, 0.1)`;
                        e.currentTarget.querySelector('i').style.color = social.color;
                      }}
                    >
                      <i 
                        className={`feather-${social.icon}`} 
                        style={{ 
                          fontSize: "20px", 
                          color: social.color, 
                          transition: 'all 0.3s ease'
                        }}
                      ></i>
                    </a>
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <Copyright />
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default Contact;
