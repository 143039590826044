import { io } from 'socket.io-client';

class ChatService {
    constructor() {
        this.baseUrl = process.env.REACT_APP_API_URL || 'https://api.tapcoe.com/api/v1';
        this.socketRef = null;
        this.eventListeners = new Map();
        this.pendingMessages = [];
        this.userId = null;
        this.token = null;
    }

    initialize(userId, token) {
        this.userId = userId;
        this.token = token;

        // Disconnect existing socket if any
        if (this.socketRef) {
            this.socketRef.disconnect();
        }

        // Create new socket connection
        this.socketRef = io('https://api.tapcoe.com', {
            auth: {
                token: this.token
            },
            query: {
                userId: this.userId
            },
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            timeout: 20000,
            transports: ['polling'],
            path: '/socket.io',
            secure: true,
            rejectUnauthorized: false,
            forceNew: true,
            autoConnect: true,
            upgrade: false
        });

        // Set up event listeners
        this.socketRef.on('connect', () => {
            console.log('Socket connected');
            this.socketRef.emit('join', { userId: this.userId });
            this.setupEventListeners();
            this.resendPendingMessages();
        });

        this.socketRef.on('disconnect', () => {
            console.log('Socket disconnected');
        });

        this.socketRef.on('error', (error) => {
            console.error('Socket error:', error);
        });

        this.socketRef.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
            // If authentication error, try connecting without auth
            if (error.message.includes('Authentication error')) {
                console.log('Retrying connection without authentication...');
                this.socketRef.disconnect();
                this.socketRef = io('https://api.tapcoe.com', {
                    query: {
                        userId: this.userId
                    },
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 1000,
                    reconnectionDelayMax: 5000,
                    timeout: 20000,
                    transports: ['polling'],
                    path: '/socket.io',
                    secure: true,
                    rejectUnauthorized: false,
                    forceNew: true,
                    autoConnect: true,
                    upgrade: false
                });
            }
        });
    }

    setupEventListeners() {
        if (!this.socketRef) {
            console.log('Socket not initialized, cannot setup event listeners');
            return;
        }
        console.log('Setting up event listeners');
        
        // Remove existing listeners to prevent duplicates
        this.socketRef.off('receiveChatMessage');
        this.socketRef.off('messageReadReceipt');
        this.socketRef.off('userOnline');
        this.socketRef.off('userOffline');
        
        this.socketRef.on('receiveChatMessage', (chat) => {
            console.log('📩 Received chat message:', chat);
            this.notifyListeners('receiveChatMessage', chat);
        });

        this.socketRef.on('messageReadReceipt', (data) => {
            console.log('👁️ Message read receipt:', data);
            this.notifyListeners('messageRead', data);
        });

        this.socketRef.on('userOnline', (userId) => {
            console.log('🟢 User online:', userId);
            this.notifyListeners('userOnline', userId);
        });

        this.socketRef.on('userOffline', (userId) => {
            console.log('🔴 User offline:', userId);
            this.notifyListeners('userOffline', userId);
        });
    }

    addEventListener(event, callback) {
        if (!this.eventListeners.has(event)) {
            this.eventListeners.set(event, new Set());
        }
        this.eventListeners.get(event).add(callback);
    }

    removeEventListener(event, callback) {
        if (this.eventListeners.has(event)) {
            this.eventListeners.get(event).delete(callback);
        }
    }

    async sendMessage(receiverId, message) {
        console.group('📤 Sending Message');
        try {
            console.log('Sending message to:', receiverId);
            const response = await fetch(`${this.baseUrl}/chats/messages/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    receiver: receiverId,
                    message: message.trim()
                })
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => ({}));
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message || 'Unknown error'}`);
            }

            const data = await response.json();
            console.log('✅ API Response:', data);

            // Ensure socket is connected before emitting
            if (!this.socketRef?.connected) {
                console.log('🔄 Socket disconnected, attempting to reconnect...');
                const token = localStorage.getItem('token');
                const user = JSON.parse(localStorage.getItem('user'));
                if (token && user?.id) {
                    this.initialize(user.id, token);
                    // Wait for socket to connect
                    await new Promise((resolve) => {
                        const checkConnection = () => {
                            if (this.socketRef?.connected) {
                                resolve();
                            } else {
                                setTimeout(checkConnection, 100);
                            }
                        };
                        checkConnection();
                    });
                }
            }

            // Emit the message through socket
            if (this.socketRef?.connected) {
                const currentUser = JSON.parse(localStorage.getItem('user'));
                const senderId = currentUser.id;
                const room = [senderId, receiverId].sort().join('_');
                
                // Join the room before sending message
                this.socketRef.emit('joinChatRoom', room);
                
                this.socketRef.emit('sendChatMessage', {
                    sender: senderId,
                    receiver: receiverId,
                    message: message.trim(),
                    room,
                    messageId: data._id
                });
                console.log('✅ Message emitted through socket', room);
            } else {
                console.warn('⚠️ Socket still not connected after reconnection attempt');
                // Queue the message for later
                this.pendingMessages.push({ receiverId, message });
            }

            return data;
        } catch (error) {
            console.error('❌ Error sending message:', error);
            throw error;
        } finally {
            console.groupEnd();
        }
    }

    async getConversations() {
        try {
            const response = await fetch(`${this.baseUrl}/chats/conversations`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            return await response.json();
        } catch (error) {
            console.error('Error fetching conversations:', error);
            throw error;
        }
    }

    async getMessageRequests() {
        try {
            const response = await fetch(`${this.baseUrl}/chats/requests`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            return await response.json();
        } catch (error) {
            console.error('Error fetching message requests:', error);
            throw error;
        }
    }

    async getMessages(chatId) {
        try {
            const response = await fetch(`${this.baseUrl}/chats/messages/${chatId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            return await response.json();
        } catch (error) {
            console.error('Error fetching messages:', error);
            throw error;
        }
    }

    async joinChatRoom(chatId) {
        if (!this.socketRef?.connected) {
            console.warn('Socket not connected, cannot join chat room');
            return;
        }

        try {
            console.log('Joining chat room:', chatId);
            this.socketRef.emit('joinChatRoom', chatId);
            
            // Listen for messages in this room
            this.socketRef.on(`chat:${chatId}`, (message) => {
                console.log('Received message in room:', chatId, message);
                this.notifyListeners('receiveChatMessage', message);
            });
        } catch (error) {
            console.error('Error joining chat room:', error);
            throw error;
        }
    }

    resendPendingMessages() {
        if (this.pendingMessages.length > 0 && this.socketRef?.connected) {
            console.log(`📡 Resending ${this.pendingMessages.length} pending messages`);
            this.pendingMessages.forEach(({ receiverId, message }) => {
                this.sendMessage(receiverId, message);
            });
            this.pendingMessages = [];
        }
    }

    notifyListeners(event, data) {
        if (this.eventListeners.has(event)) {
            this.eventListeners.get(event).forEach(callback => callback(data));
        }
    }

    disconnect() {
        if (this.socketRef) {
            this.socketRef.disconnect();
            this.socketRef = null;
        }
    }
}

const chatService = new ChatService();
export default chatService;
