import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";

const Login = () => {
  const { isLoggedIn, login, loginOTPs } = useContext(AuthContext);
  const history = useHistory();
  const [mobileNumber, setmobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  
  useEffect(() => {
    if (isLoggedIn) {
      const redirectURL = localStorage.getItem("redirectAfterLogin") || "/";
      localStorage.removeItem("redirectAfterLogin");
      history.push(redirectURL);
    }
  }, [isLoggedIn, history]);

  const handlemobileNumberChange = (e) => setmobileNumber(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = async () => {
    try {
      const response = await login({ mobileNumber, password });

      if (response.success) {
        // Successful login
        setSuccessMessage(response.message);
        setErrorMessage("");
        setShowOtpBox(false);
        const redirectURL = localStorage.getItem("redirectAfterLogin") || "/";
        localStorage.removeItem("redirectAfterLogin");
        setTimeout(() => {
          history.push(redirectURL); // Redirect user
        }, 2000); // Reduced delay for better UX
      } else if (response.requiresOtp) {
        // OTP required
        setShowOtpBox(true);
        setErrorMessage(response.message || "OTP required. Please check your WhatsApp.");
        setSuccessMessage("");
      } else {
        // Other failure cases
        setErrorMessage(response.message || "Login failed. Please try again.");
        setSuccessMessage("");
      }
    } catch (error) {
      if (error.response?.data?.requiresOtp) {
        // OTP required during error handling
        setShowOtpBox(true);
        setErrorMessage(error.response.data.message || "OTP required. Please check your WhatsApp.");
        setSuccessMessage("");
      } else {
        // General error handling
        setErrorMessage(error.response?.data?.message || "An error occurred. Please try again.");
        setSuccessMessage("");
      }
    }
  };

  const handleOtpVerification = async () => {
    try {
      const response = await loginOTPs({ mobileNumber, otp });

      if (response.success) {
        setSuccessMessage("OTP verified. Logging you in...");
        setErrorMessage("");
        const redirectURL = localStorage.getItem("redirectAfterLogin") || "/";
        localStorage.removeItem("redirectAfterLogin");
        setTimeout(() => {
          history.push(redirectURL); // Redirect user
        }, 2000); // Reduced delay for better UX
      } else {
        setErrorMessage(response.data?.message || "Verification failed. Please try again.");
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage("OTP verification failed. Please try again.");
      setSuccessMessage("");
    }
  };

  return (
    <div className="bg-light" style={{minHeight: "100vh"}}>
      {/* Header */}
      <header className="bg-white shadow-sm">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center py-3">
            <a href="/" className="d-flex align-items-center text-decoration-none">
              <i className="feather-camera text-primary me-2" style={{fontSize: "24px"}}></i>
              <span className="fw-bold text-dark fs-4">tapcoe</span>
            </a>
            <div>
              <a 
                href="/login" 
                className="btn me-2" 
                style={{
                  borderRadius: "8px",
                  background: "linear-gradient(90deg, #4285f4, #7855f5)",
                  color: "white",
                  padding: "8px 16px",
                  fontWeight: "500",
                  fontSize: "14px",
                  textDecoration: "none"
                }}
              >
                Login
              </a>
              <a 
                href="/register" 
                className="btn" 
                style={{
                  borderRadius: "8px",
                  border: "1px solid #4285f4",
                  color: "#4285f4",
                  padding: "8px 16px",
                  fontWeight: "500",
                  fontSize: "14px",
                  textDecoration: "none"
                }}
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="container py-5">
        <div className="row">
          {/* Left side - Image and benefits (hidden on mobile) */}
          <div className="col-lg-6 d-none d-lg-block">
            <div className="card border-0 rounded-4 overflow-hidden h-100 bg-primary text-white position-relative">
              <div style={{
                background: "linear-gradient(45deg, rgba(66, 133, 244, 0.9), rgba(120, 85, 245, 0.8))",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1
              }}></div>
              <img 
                src="/assets/images/loginpic.png" 
                alt="Welcome Back" 
                className="card-img h-100"
                style={{
                  objectFit: "cover",
                  opacity: 0.6
                }}
              />
              <div className="card-img-overlay d-flex flex-column justify-content-center p-5" style={{zIndex: 2}}>
                <h2 className="fw-bold mb-4">Welcome Back</h2>
                <p className="mb-4">Sign in to your account to access your profile, participate in contests and view your submissions.</p>
                
                <div className="mt-4">
                  <div className="d-flex align-items-center mb-3">
                    <div className="d-flex align-items-center justify-content-center rounded-circle bg-white bg-opacity-25 me-3" style={{width: "40px", height: "40px"}}>
                      <i className="feather-user text-white"></i>
                    </div>
                    <span>Access your personal dashboard</span>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="d-flex align-items-center justify-content-center rounded-circle bg-white bg-opacity-25 me-3" style={{width: "40px", height: "40px"}}>
                      <i className="feather-award text-white"></i>
                    </div>
                    <span>View your contest entries</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-center rounded-circle bg-white bg-opacity-25 me-3" style={{width: "40px", height: "40px"}}>
                      <i className="feather-bell text-white"></i>
                    </div>
                    <span>Get notified about new contests</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Right side - Form */}
          <div className="col-lg-6">
            <div className="card border-0 rounded-4 shadow-sm">
              <div className="card-body p-4 p-lg-5">
                {!showOtpBox ? (
                  <>
                    <div className="mb-4">
                      <div className="mb-3" style={{
                        width: "60px",
                        height: "4px",
                        background: "linear-gradient(90deg, #4285f4, #7855f5)",
                        borderRadius: "4px"
                      }}></div>
                      <h2 className="fw-bold mb-1">Sign In</h2>
                      <p className="text-muted small">Enter your credentials to access your account</p>
                    </div>
                    
                    <form onSubmit={(e) => {
                      e.preventDefault();
                      handleLogin();
                    }}>
                      <div className="mb-3">
                        <label htmlFor="mobileNumber" className="form-label small fw-medium">WhatsApp Number</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-smartphone text-muted"></i>
                          </span>
                          <input
                            type="text"
                            id="mobileNumber"
                            className="form-control bg-light border-start-0"
                            placeholder="10-digit number (e.g. 9723456789)"
                            value={mobileNumber}
                            onChange={handlemobileNumberChange}
                          />
                        </div>
                      </div>
                      
                      <div className="mb-4">
                        <div className="d-flex justify-content-between">
                          <label htmlFor="password" className="form-label small fw-medium">Password</label>
                          <a href="/forgot" className="text-decoration-none small text-primary">Forgot Password?</a>
                        </div>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-lock text-muted"></i>
                          </span>
                          <input
                            type={passwordVisible ? "text" : "password"}
                            id="password"
                            className="form-control bg-light border-start-0 border-end-0"
                            placeholder="Enter your password"
                            value={password}
                            onChange={handlePasswordChange}
                          />
                          <span 
                            className="input-group-text bg-light border-start-0 text-primary" 
                            style={{cursor: "pointer"}}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            <i className={passwordVisible ? "feather-eye-off" : "feather-eye"}></i>
                          </span>
                        </div>
                      </div>
                      
                      {successMessage && (
                        <div className="alert alert-success mb-3 py-2" role="alert">
                          <i className="feather-check-circle me-2"></i>
                          {successMessage}
                        </div>
                      )}
                      
                      {errorMessage && (
                        <div className="alert alert-danger mb-3 py-2" role="alert">
                          <i className="feather-alert-circle me-2"></i>
                          {errorMessage}
                        </div>
                      )}
                      
                      <button 
                        type="submit" 
                        className="btn w-100 py-2 mb-3"
                        style={{
                          background: "linear-gradient(90deg, #4285f4, #7855f5)",
                          color: "white",
                          borderRadius: "8px",
                          fontWeight: "500"
                        }}
                      >
                        Sign In
                      </button>
                      
                      <div className="text-center mt-4">
                        <p className="small text-muted mb-0">
                          Don't have an account? <a href="/register" className="text-decoration-none text-primary fw-medium">Register</a>
                        </p>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <div className="mb-4 text-center">
                      <div className="mb-3 mx-auto" style={{
                        width: "60px",
                        height: "4px",
                        background: "linear-gradient(90deg, #4285f4, #7855f5)",
                        borderRadius: "4px"
                      }}></div>
                      <h2 className="fw-bold mb-1">Verify OTP</h2>
                      <p className="text-muted small">Enter the OTP sent to your WhatsApp</p>
                    </div>
                    
                    <div className="text-center mb-4">
                      <div className="d-inline-flex align-items-center justify-content-center rounded-circle mb-3" style={{
                        width: "80px",
                        height: "80px",
                        background: "linear-gradient(135deg, rgba(66, 133, 244, 0.1), rgba(120, 85, 245, 0.1))"
                      }}>
                        <i className="feather-shield" style={{
                          fontSize: "32px",
                          color: "#4285f4"
                        }}></i>
                      </div>
                      
                      <p className="text-muted small mb-0">
                        We've sent a verification code to<br/>
                        <span className="fw-medium text-dark">+91 {mobileNumber}</span>
                      </p>
                    </div>
                    
                    <form onSubmit={(e) => {
                      e.preventDefault();
                      handleOtpVerification();
                    }}>
                      <div className="mb-4">
                        <label htmlFor="otp" className="form-label small fw-medium">Enter OTP</label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-end-0">
                            <i className="feather-key text-muted"></i>
                          </span>
                          <input
                            type="text"
                            id="otp"
                            className="form-control bg-light border-start-0 text-center fw-medium"
                            placeholder="• • • • • •"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            style={{
                              letterSpacing: "5px",
                              fontSize: "18px"
                            }}
                          />
                        </div>
                      </div>
                      
                      {successMessage && (
                        <div className="alert alert-success mb-3 py-2" role="alert">
                          <i className="feather-check-circle me-2"></i>
                          {successMessage}
                        </div>
                      )}
                      
                      {errorMessage && (
                        <div className="alert alert-danger mb-3 py-2" role="alert">
                          <i className="feather-alert-circle me-2"></i>
                          {errorMessage}
                        </div>
                      )}
                      
                      <button 
                        type="submit" 
                        className="btn w-100 py-2 mb-3"
                        style={{
                          background: "linear-gradient(90deg, #4285f4, #7855f5)",
                          color: "white",
                          borderRadius: "8px",
                          fontWeight: "500"
                        }}
                      >
                        Verify OTP
                      </button>
                      
                      <div className="d-flex justify-content-between align-items-center">
                        <button 
                          type="button" 
                          className="btn btn-link text-muted p-0 small text-decoration-none"
                          onClick={() => setShowOtpBox(false)}
                        >
                          <i className="feather-arrow-left me-1"></i> Back
                        </button>
                        
                        <button 
                          type="button" 
                          className="btn btn-link p-0 small text-decoration-none"
                          onClick={handleLogin}
                          style={{
                            color: "#4285f4"
                          }}
                        >
                          Resend OTP
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
